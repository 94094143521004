import React from 'react';
import styled from 'styled-components';
import useI18n from '../../i18n';
import { useDispatch, useSelector } from 'Hooks';
import Button from 'Components/Button';
import { Link } from 'react-router-dom';
import { setLoginScreen } from '../../actions';

const Wrapper = styled.div`
  margin-bottom: 88px;
  padding-top: ${() => window.headerHeight}px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Content = styled.div`
  @media (min-width: 1200px) {
    padding: 0 24px;
  }
`;

const Inner = styled.div`
  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin: 40px 24px 0;
`;

const Terms = styled.p`
  font-size: 14px;
  margin: 40px 24px 0;
  white-space: pre-wrap;
`;

const InvoiceSignup: React.FC = () => {
  const { i18n } = useI18n();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  return (
    <Wrapper>
      <Content>
        {user ? (
          <Inner>
            <iframe
              src={`https://docs.google.com/forms/d/e/1FAIpQLSdf7OqaEGVqVaUiDgs4GRYTxL1ZuBl1SLEOzoIGSg8FweZMfA/viewform?usp=pp_url&entry.449973212=${user.email}&entry.1382756382=${user.name}`}
              width={window.innerWidth > 1200 ? '100%' : '100%'}
              height="1200"
              style={{
                border: 'none',
              }}>
              Loading…
            </iframe>

            <Terms>{i18n('InvoiceSignup.Terms')}</Terms>
          </Inner>
        ) : (
          <Inner>
            <Title>{i18n('InvoiceSignup.Title')}</Title>
            <Terms>
              {i18n('InvoiceSignup.RegisterFirst')}
              <br />
              <br />
              <Link
                to={'#'}
                onClick={() => {
                  dispatch(setLoginScreen('signup'));
                }}>
                <Button text={i18n('Navigation.CreateAccount')} />
              </Link>
            </Terms>
          </Inner>
        )}
      </Content>
    </Wrapper>
  );
};

export default InvoiceSignup;
