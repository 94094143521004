import React from 'react';
import styled from 'styled-components';

interface ToggleProps {
  name: string;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const ToggleSwitch = styled.div`
  position: relative;
  width: 62px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  border-radius: 20px;
  overflow: hidden;
`;

const CheckBox = styled.input`
  display: none;
`;

const Label = styled.label<{ checked: boolean; disabled: boolean }>`
  display: flex;
  background-color: ${({ disabled, checked }) =>
    disabled ? '#b6b6b6' : checked ? 'black' : '#a7a7a9'};
  padding: 4px;
  justify-content: ${({ checked }) => (checked ? 'right' : 'left')};
  &:after {
    top: 8px;
    display: block;
    content: '';
    height: 24px;
    width: 24px;
    border-radius: 20px;
    background-color: white;
  }
  ${({ disabled }) =>
    !disabled &&
    `
    &:hover {
    cursor: pointer;
  }  
  `}
`;

export default function Toggle({ name, onChange, disabled, checked, ...rest }: ToggleProps) {
  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <ToggleSwitch>
      <CheckBox
        type="checkbox"
        name={name}
        id={name}
        onChange={(e) => handleToggle(e)}
        checked={!!checked}
        disabled={disabled}
        {...rest}
      />
      <Label htmlFor={name} checked={!!checked} disabled={!!disabled} />
    </ToggleSwitch>
  );
}
