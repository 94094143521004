import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { Link, useLocation } from 'react-router-dom';
import OrderStatus from 'Components/OrderStatus';
import { AnonymousOrder } from 'Components/Storage';
import { getHistoryOrder, getHistoryOrderVariables } from './__queries__';
import getTexts from 'Scenes/OrderStatus/utils/getTexts';
import useI18n from 'i18n';
import getLocale from 'i18n/getLocale';
import dayjs from 'dayjs';

export const ANONYMOUS_ORDER_QUERY = gql`
  query getHistoryOrder($orderId: ID!) {
    Order(id: $orderId) {
      id
      desiredTime
      easyId
      status
      estimatedFinishTime
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const OrderWrapper = styled(Link)``;

type Colors = {
  [key: string]: string;
};
const colors: Colors = {
  PENDING: '#314fee',
  ACCEPTED: '#ff8400',
  FINALIZING: '#ff8400',
  READY: '#209946',
  REJECTED: '#FF0000',
  COMPLETED: '#209946',
};

interface Props {
  orderId: string;
  venueId?: string;
  anonymousOrder: AnonymousOrder;
}

const Orders = ({ orderId, venueId, anonymousOrder }: Props) => {
  const [color, setColor] = useState('');
  const [currentTime, setCurrentTime] = useState(dayjs().format());
  const { pathname } = useLocation();
  const { i18n } = useI18n();
  const locale = getLocale();
  const { data } = useQuery<getHistoryOrder, getHistoryOrderVariables>(ANONYMOUS_ORDER_QUERY, {
    variables: {
      orderId: orderId,
    },
    pollInterval: 5000,
    fetchPolicy: 'network-only',
  });
  const order = data?.Order;

  const getOrderTexts = (): { statusText: string; messageText: ReactNode } | undefined => {
    if (!order) return;
    return getTexts(
      {
        status: order.status,
        easyId: order.easyId,
        estimatedFinishTime: order.estimatedFinishTime,
        currentTime,
      },
      i18n,
      locale,
    );
  };
  const orderTexts = getOrderTexts();

  // ** UPDATE CURRENT TIME ** Update current time every 10 seconds
  useEffect(() => {
    const intervalId = setInterval(() => setCurrentTime(dayjs().format()), 10000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!order) return;
    setColor(colors[order.status]);
  }, [data]);

  const to =
    venueId && pathname.includes(`/order`)
      ? `/order/${venueId}/order/${orderId}`
      : `/orderstatus/${orderId}`;

  return (
    <Wrapper>
      {order && (
        <OrderWrapper to={to}>
          <OrderStatus
            borderWidth={0}
            shadow={'0px 1px 32px 0px rgba(0, 0, 0, 0.32)'}
            background={'#fff'}
            messageColor={'rgb(97, 97, 100)'}
            statusColor={color}
            iconColor={color}
            top={8}
            status={orderTexts ? orderTexts.statusText : ''}
            message={orderTexts ? orderTexts.messageText : ''}
          />
        </OrderWrapper>
      )}
    </Wrapper>
  );
};

export default Orders;
