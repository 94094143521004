import React from 'react';
import styled from 'styled-components';

import { ISocialMedia } from '../';

const Wrapper = styled.a<{ isInstagram?: boolean }>`
  ${({ isInstagram }) => {
    if (isInstagram) {
      return {
        '&:first-child': {
          width: '100%',
          height: 'calc(100vw - 47px)',
          'grid-area': 'big-image',
        },
        width: `calc((100vw - 47px) / 2)`,
        height: `calc((100vw - 47px) / 2)`,
      };
    }

    return {
      width: '100%',
      margin: '7px',
    };
  }};

  @media (min-width: 768px) {
    &:first-child {
      height: calc((100vw - 303px - 40px) / 2);
      grid-area: initial;
    }
    width: 100%;
    height: calc((100vw - 303px - 40px) / 2);
  }

  @media (min-width: calc(768px + 303px)) {
    &:first-child {
      height: calc((720px) / 3);
    }
    height: calc((720px) / 3);
  }

  @media (min-width: calc(992px + 303px)) {
    &:first-child {
      height: calc((960px) / 3);
    }
    height: calc((960px) / 3);
  }

  @media (min-width: calc(1200px + 303px)) {
    &:first-child {
      height: calc((1140px) / 3);
    }
    height: calc((1140px) / 3);
  }
`;

const Image = styled.div<{ mediaUrl: string }>`
  background-image: url(${({ mediaUrl }) => mediaUrl});
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
`;

interface Props {
  /**
   * item
   */
  item: ISocialMedia;
  /**
   * isInstagram
   */
  isInstagram?: boolean;
}

/**
 * SocialMedia Item component
 */

const Item = ({ item: { link, mediaUrl }, isInstagram }: Props) => {
  return (
    <Wrapper href={link} isInstagram={isInstagram}>
      {mediaUrl && <Image mediaUrl={mediaUrl} />}
    </Wrapper>
  );
};

export default Item;
