import { ReactNode } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useConfig } from 'Components/ConfigProvider';
import useI18n from 'i18n';

import { i18nProps } from 'Types';
import { ORDER_STATUS } from '../../../../__queries__/globalTypes';

type Props = (
  order: {
    status: ORDER_STATUS;
    estimatedFinishTime: string;
    easyId: string;
    currentTime: string;
  },
  i18n: i18nProps,
  locale: string,
) => {
  statusText: string;
  messageText: string | ReactNode;
};
const getTexts: Props = (order, i18n, locale) => {
  const { status, easyId, estimatedFinishTime, currentTime } = order;
  const orderStatus = status.toLowerCase();
  const { CUSTOM_READY_TEXT } = useConfig();

  dayjs.extend(relativeTime);

  const diff = dayjs(estimatedFinishTime || undefined).diff(dayjs(currentTime), 'minutes');

  const timeLeftTo =
    diff > 0
      ? dayjs(estimatedFinishTime || undefined)
          .locale(locale)
          .from(currentTime, true)
      : i18n('Orderstatus.Soon');

  switch (orderStatus) {
    case 'pending':
      return {
        statusText: i18n('Orderstatus.Pending.Title'),
        messageText: i18n('Orderstatus.Pending.Text'),
      };
    case 'accepted':
    case 'finalizing':
      return {
        statusText: i18n('Orderstatus.Preparing.Title'),
        messageText: (
          <>
            {i18n('Orderstatus.Preparing.Text')} <b>{timeLeftTo}</b>
          </>
        ),
      };
    case 'ready':
      return {
        statusText: i18n('Orderstatus.Ready.Title'),
        messageText: CUSTOM_READY_TEXT ? (
          <>
            {i18n(CUSTOM_READY_TEXT)} <b>{easyId}</b>
          </>
        ) : (
          <>
            {i18n('Orderstatus.Ready.Text.I')} <b>{easyId}</b> {i18n('OrderStatus.Ready.Text.II')}
          </>
        ),
      };
    case 'rejected':
      return {
        statusText: i18n('Order.Status.Rejected'),
        messageText: <>{i18n('OrderStatus.Rejected.Text')}</>,
      };
    case 'completed':
      return {
        statusText: i18n('Orderstatus.Completed.Title'),
        messageText: i18n('Orderstatus.Completed.Text'),
      };
    default:
      return {
        statusText: '',
        messageText: '',
      };
  }
};

export default getTexts;
