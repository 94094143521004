import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import { setAlert, setLoginScreen } from 'actions';
import { useBridgeApi, useDispatch, useLogger } from 'Hooks';
import useI18n from 'i18n';
import View from './view';
import { useStorage } from 'Components/Storage';

export const ACTIVATE_CODE = gql`
  mutation activate($code: String!) {
    activatePhone(code: $code) {
      error
    }
  }
`;

const RESEND_ACTIVATION_CODE = gql`
  mutation resendActivationCode {
    requestActivationCode {
      error
    }
  }
`;

const VerifyAccount = () => {
  const [{ temporaryAuthToken }, updateStorage] = useStorage();
  const { i18n } = useI18n();
  const dispatch = useDispatch();
  const api = useBridgeApi();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const logger = useLogger('verify-account');

  const [activatedCode] = useMutation(ACTIVATE_CODE, {
    variables: {
      code,
    },
  });
  const [resendCode] = useMutation(RESEND_ACTIVATION_CODE);

  useEffect(() => {
    setCode('');
    setErrorCode('');
  }, []);

  const handleVerify = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const isValid = handleValidate();

    if (!isValid) {
      return;
    }

    setLoading(true);

    try {
      const response = await activatedCode();

      const { activatePhone } = response.data;
      console.log(response);
      if (!activatePhone) {
        throw new Error('ERROR_FAIL_VERIFY_PHONE');
      }
      if (activatePhone.error) {
        throw new Error(activatePhone.error);
      }
      updateStorage({ authToken: temporaryAuthToken });

      api.vibrate('impactLight');
      setLoading(false);
      setCode('');

      dispatch(setLoginScreen(''));
    } catch (err) {
      console.log('handleVerify error', err);
      logger.warn('verify account failed!', {
        description: err.message,
      });
      dispatch(
        setAlert({
          title: 'Error.ValidationFailed',
          description: err.message,
        }),
      );
      setLoading(false);
    }
  };

  const handleChange = (value: string) => {
    setCode(value);
    setErrorCode('');
  };

  const handleValidate = () => {
    let message = '';
    if (!/^.{4,}$/.test(code)) {
      message = i18n('VerifyAccount.Error.InvalidCode');
    }

    setErrorCode(message);
    return !message;
  };

  const sendNewCode = async () => {
    setLoading(true);
    try {
      const response = await resendCode();
      const errorResent = response.data.requestActivationCode.error;
      if (errorResent) {
        throw new Error(errorResent);
      }
    } catch (err) {
      console.log('sendNewCode error', err);
      logger.warn('resending the validate-code failed.', {
        description: err.message,
      });
      dispatch(
        setAlert({
          title: 'Error.ValidationFailed',
          description: err.message,
        }),
      );
      setLoading(false);
    }

    setCode('');
    setLoading(false);
  };

  return (
    <View
      loading={loading}
      code={code}
      handleChange={handleChange}
      error={errorCode}
      sendNewCode={sendNewCode}
      handleVerify={handleVerify}
    />
  );
};

export default VerifyAccount;
