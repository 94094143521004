import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid rgb(224, 224, 227);
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin: 0 16px 0 0;
`;

const Icon = styled.div<{ active?: boolean }>`
  background: ${(props) => (props.active ? 'rgb(33, 33, 36)' : 'transparent')};
  height: 16px;
  width: 16px;
  border-radius: 8px;
`;

type Props = {
  /**
   * active
   */
  active: boolean | undefined;
};

/**
 * RadioIcon component
 */

const RadioIcon = ({ active }: Props) => {
  return (
    <Wrapper>
      <Icon active={active} />
    </Wrapper>
  );
};

export default RadioIcon;
