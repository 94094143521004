import React from 'react';
import styled from 'styled-components';
import SocialMedia from '../../../Components/SocialMedia';

const Wrapper = styled.div`
  padding: 16px 16px 88px;
  background-color: #f5f5f8;
`;

/**
 * InstagramSection component
 */
const InstagramSection = () => {
  return (
    <Wrapper>
      <SocialMedia />
    </Wrapper>
  );
};

export default InstagramSection;
