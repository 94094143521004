import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useI18n from 'i18n';

import useAuth from 'Hooks/useAuth';
import useVenueManager from 'Hooks/useVenueManager';
import SignInBottomModal from 'Components/SignInBottomModal';
import { useStorage } from 'Components/Storage';

import { ReactComponent as HomeIcon } from '../../Assets/Icons/home.svg';
import { ReactComponent as MenuIcon } from '../../Assets/Icons/cutlery.svg';
import { ReactComponent as CartIcon } from '../../Assets/Icons/shopping-basket.svg';
import { useShoppingCart, useShoppingCartApi } from 'Components/ShoppingCartUniverse';

import { useBridgeApi, useDispatch, useSelector } from '../../Hooks';
import Navbar from '../../Components/Navbar';
import Icon from '../../Components/Icon';
import { useConfig } from 'Components/ConfigProvider';
import * as actions from '../../actions';
import { setLoginModal } from '../../actions';

const Wrapper = styled.div`
  padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
`;

const InnerWrapper = styled.div``;
const Content = styled.div`
  height: 100%;
`;
const FooterTabs = styled.div<{
  hide: boolean;
  animate: boolean;
  offsetLeft: number;
}>`
  background: rgb(255, 255, 255);
  position: fixed;
  bottom: 0;
  box-sizing: content-box;
  width: 100%;
  z-index: 20;
`;

const TabsInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding-bottom: 8px;

  @media (min-width: 1200px) {
    max-width: 1140px;
    margin: 0 auto;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }
`;

const Tab = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: calc(100% / 3);
  text-decoration: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;
const Title = styled.span<{ active: boolean }>`
  color: ${({ active }) => (active ? '#212124' : '#9e9ea1')};
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.48px;
  line-height: 16px;
  text-transform: uppercase;
  transition: 0.3s color;
  animation: ${({ active }) => (active ? 'bounce-small .2s forwards' : 'none')};
`;
const IconWrapper = styled.div<{ active: boolean }>`
  position: relative;
  animation: ${({ active }) => (active ? 'bounce-small .2s forwards' : 'none')};

  & div {
    transition: 0.3s background-color;
  }
`;
const Badge = styled.div<{ bounce: boolean }>`
  position: absolute;
  z-index: 1;
  top: 0;
  right: -10.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background: #000;
  border-radius: 100%;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0;
  animation: ${({ bounce }) => (bounce ? 'bounce .2s forwards' : 'none')};
`;

const ActivatedOffer = styled.div`
  position: fixed;
  align-items: center;
  justify-content: space-between;
  bottom: 88px;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 99;
  font-size: 14px;
  display: flex;
  border-bottom: 1px solid #eeeef1;
`;

const OrderContent = styled.div`
  padding: 16px 0 16px 24px;
`;

const CancelOrderButton = styled.div`
  font-weight: 600;
  padding: 16px 24px;
`;

type Props = {
  children: React.ReactNode;
  path: string;
};

const StartPage = ({ children }: Props) => {
  const config = useConfig();
  const { cartId, qrOrder, tableId } = useSelector((state) => state);
  const [storage] = useStorage();
  const [bounceCart, setBounceCart] = useState(true);
  const params = useParams<{ venue_id?: string }>();
  const venueId = params?.venue_id || '';
  const { i18n } = useI18n();
  const history = useHistory();
  const { hasAuthToken } = useAuth();
  const isSignedIn = hasAuthToken();
  const shoppingCart = useShoppingCart();
  const { pathname } = useLocation();
  const api = useBridgeApi();
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isHome =
    pathname == `/order/${venueId}/cart` ||
    pathname == `/order/${venueId}/menu` ||
    pathname == `/order/${venueId}`;
  const withOfferPopUp = false; //basket.OfferTitle;
  useVenueManager();

  const tableIdFromParams = new URLSearchParams(window.location.search).get('t');

  const [scrollDirection, setScrollDirection] = useState<string | null>(null);
  const timeoutID = useRef<any>(null);
  const lastTouchY = useRef<any>(null);

  function onTouchMove(e: React.TouchEvent) {
    const y = e.changedTouches[0];

    const direction = y > lastTouchY.current ? 'DOWN' : 'UP';

    lastTouchY.current = y;

    setScrollDirection(direction);

    if (timeoutID.current) {
      clearTimeout(timeoutID.current);
    }

    timeoutID.current = setTimeout(() => {
      setScrollDirection(null);
      lastTouchY.current = 0;
    }, 510);
  }

  useEffect(() => {
    if (storage.qrOrder && !qrOrder) {
      dispatch(actions.setqrOrder(true));
    }
  }, [storage.qrOrder]);

  useEffect(() => {
    setTimeout(() => {
      setBounceCart(false);
    }, 200);
  }, [bounceCart]);

  useEffect(() => {
    setBounceCart(true);
  }, [shoppingCart.items?.length]);

  useEffect(() => {
    if (isHome && wrapperRef.current) {
      wrapperRef.current.style.transition = 'none';
      wrapperRef.current.style.transform = 'none';
    }
  }, [pathname]);

  const handleClick = (path: string) => {
    api.vibrate('impactLight');
    if (path === '#') {
      dispatch(setLoginModal(true));
    }
  };

  const handleCancelActivedOffer = () => {
    return;
    // Replace with shoppingCartApi method basket.clearOrderOffer();
  };

  const tabs = [
    {
      path: `/order/${venueId}`,
      title: i18n('Startpage.Button.Start'),
      icon: <HomeIcon />,
    },
    {
      path: `/order/${venueId}/menu`,
      title: i18n('Startpage.Button.Menu'),
      icon: <MenuIcon />,
    },
    {
      // If customers are allowed to do anonymous purchases, and there is a shoppingCart, take them to the cart.
      // If customers are not allowed to do anonymous purchases, check if they are signed in.
      path:
        (config.ANONYMOUS_PURCHASE && shoppingCart) ||
        (isSignedIn && shoppingCart) ||
        qrOrder ||
        storage.qrOrder
          ? `/order/${venueId}/cart`
          : '#',
      title: i18n('Startpage.Button.Cart'),
      icon: <CartIcon />,
      badge: true,
    },
  ];

  const navTitle = pathname == `/order/${venueId}/cart` ? i18n('Cart.Title') : i18n('Menu.Title');
  const showLogo = Boolean(!pathname.includes('menu') && !pathname.includes('cart'));

  return (
    <>
      <Wrapper>
        <InnerWrapper id="level-1" ref={wrapperRef}>
          <Navbar
            showLogo={showLogo}
            title={navTitle}
            /* If we have a tableId from state, show back button.
             If we don't have a tableId from state, check if we have the tableId in the URL Params.
             If we have it in the URL Params, show the back button.
             Otherwise, show the menu icon. */
            showMenuIcon={qrOrder ? false : tableIdFromParams ? false : true}
            showTrashcan={Boolean(pathname.includes('cart')) && shoppingCart.items.length > 0}
            onBack={() => {
              if (pathname.includes('menu')) {
                history.replace(`/order/${venueId}?type=EAT_HERE&cartId=${cartId}`);
              }
              history.goBack();
            }}
          />
          <Content onTouchMove={onTouchMove}>{children}</Content>
        </InnerWrapper>

        {withOfferPopUp && (
          <ActivatedOffer>
            <OrderContent>{i18n('' /*basket.OfferTitle*/)}</OrderContent>
            <CancelOrderButton onClick={handleCancelActivedOffer}>
              {i18n('Startpage.Offers.PopUpUndo')}
            </CancelOrderButton>
          </ActivatedOffer>
        )}

        <FooterTabs
          hide={!!scrollDirection}
          animate={!window.hasNativeWrapper}
          offsetLeft={window.sidebarWidth}>
          <TabsInner>
            {tabs.map(({ path, title, icon, badge }) => {
              const active = pathname === path;
              const cartCount = shoppingCart.items?.length ?? 0;

              return (
                <Tab key={path} to={path} onClick={() => handleClick(path)}>
                  <IconWrapper active={active}>
                    {badge && cartCount ? <Badge bounce={bounceCart}>{cartCount}</Badge> : null}
                    <Icon icon={icon} color={active ? '#212124' : '#9e9ea1'} size={32} />
                  </IconWrapper>

                  <Title active={active}>{title}</Title>
                </Tab>
              );
            })}
          </TabsInner>
        </FooterTabs>
      </Wrapper>
    </>
  );
};

export default StartPage;
