import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{
  bgColor: string | undefined;
  textColor: string | undefined;
}>`
  padding: 64px 24px;
  background-color: ${({ bgColor, theme }) => (bgColor ? bgColor : theme.main)};
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 32px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  p {
    color: ${({ textColor, theme }) => (textColor ? textColor : theme.contrastingColor)};
  }
`;

const Inner = styled.div`
  @media (min-width: 768px) {
    width: 435px;
    margin: 0 auto;
  }

  @media (min-width: 992px) {
    width: 500px;
    margin: 0 auto;
  }
`;

const Title = styled.h2<{
  textColor: string | undefined;
}>`
  font-size: 40px;
  font-weight: 600;
  line-height: 56px;
  margin-bottom: 8px;
  color: ${({ textColor, theme }) => (textColor ? textColor : theme.contrastingColor)};
`;

interface Props {
  /**
   * title
   */
  title: string;
  /**
   * bgColor
   */
  bgColor?: string;
  /**
   * textColor
   */
  textColor?: string;
}

/**
 * TextSection component
 */
const TextSection: React.FC<Props> = ({ title, bgColor, textColor, children }) => {
  return (
    <>
      {title && (
        <Wrapper bgColor={bgColor} textColor={textColor}>
          <Inner>
            <Title textColor={textColor}>{title}</Title>
            {children}
          </Inner>
        </Wrapper>
      )}
    </>
  );
};

export default TextSection;
