import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import { useDispatch, useSelector } from 'Hooks';
import useAuth from 'Hooks/useAuth';
import * as actions from 'actions';
import FullpageLoader from 'Components/FullpageLoader';
import View from './view';
import {
  allContents,
  allContentsVariables,
  getNextMembershipLevel,
  companyQuery,
  companyQueryVariables,
  companyQuery_allMembershipLevels,
  allContents_allContents,
} from './__queries__';
import { Language } from '../../Types';
import { useConfig } from 'Components/ConfigProvider';
import { useStorage } from 'Components/Storage';

export const GET_INTRO = gql`
  query allContents($companyId: ID!) {
    allContents(filter: { company: { id: $companyId }, deleted: false }) {
      id
      slug
      type
      updatedAt
      image {
        file {
          url
        }
      }
      title {
        id
        en
        se
        no
      }
      subTitle {
        id
        en
        se
        no
      }
      text {
        id
        en
        se
        no
      }
    }
  }
`;

export const GET_NEXT_MEMBERSHIP_LEVEL = gql`
  query getNextMembershipLevel {
    nextMembershipLevel {
      success
      nextLevel
      pointsNeeded
      message
    }
  }
`;

export const MEMBERSHIP_CARDS = gql`
  query companyQuery($id: ID!) {
    allMembershipLevels(filter: { company: { id: $id }, deleted: false }) {
      id
      title {
        id
        se
        en
      }
      pointsRequired
      primaryColor
      secondaryColor
      image {
        file {
          url
        }
      }
    }
  }
`;

/**
 * LandingPage scene
 */
const LandingPage = () => {
  const config = useConfig();
  const { hasAuthToken } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const isSignedIn = hasAuthToken();
  const [storage, updateStorage] = useStorage();
  const [content, setContent] = useState<allContents_allContents[]>();
  const [membershipCard, setMembershipCard] = useState<companyQuery_allMembershipLevels>();
  const [nextLevelPoints, setNextLevelPoints] = useState(0);
  const [modalLanguage, setModalLanguage] = useState<Language | null>(null);
  const [showLanguageDialog, setShowLanguageDialog] = useState<boolean>(false);
  const user = useSelector((state) => state.user);
  const userMembershipLevel = user?.membershipCard?.membershipLevel?.id ?? '';

  const { data } = useQuery<allContents, allContentsVariables>(GET_INTRO, {
    fetchPolicy: 'cache-and-network',
    variables: {
      companyId: config.COMPANY_ID,
    },
  });
  const { data: membershipCards } = useQuery<companyQuery, companyQueryVariables>(
    MEMBERSHIP_CARDS,
    {
      variables: {
        id: config.COMPANY_ID,
      },
    },
  );

  const { data: nextLevel } = useQuery<getNextMembershipLevel>(GET_NEXT_MEMBERSHIP_LEVEL, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const cardLevels = membershipCards?.allMembershipLevels;
    if (cardLevels && userMembershipLevel) {
      const card = cardLevels.find((item: any) => item.id == userMembershipLevel);
      setMembershipCard(card);
    } else if (cardLevels) {
      const sortedCards = [...cardLevels].sort(function (a: any, b: any) {
        return a.pointsRequired - b.pointsRequired;
      });

      setMembershipCard(sortedCards[1]);
    }
  }, [membershipCards, user]);

  useEffect(() => {
    if (nextLevel?.nextMembershipLevel?.nextLevel?.pointsRequired) {
      setNextLevelPoints(nextLevel?.nextMembershipLevel?.nextLevel?.pointsRequired);
    }
  }, [nextLevel]);

  useEffect(() => {
    if (data) {
      setContent(data.allContents);
    }
  }, [data]);

  const navigate = () => {
    history.push('/store-listing');
  };

  const handleShowLanguageDialog = () => {
    setShowLanguageDialog(true);
    setModalLanguage(storage.language);
  };

  const handleSelectLanguage = () => {
    updateStorage({
      language: modalLanguage,
    });
    setShowLanguageDialog(false);
  };

  const handleCloseLanguageDialog = () => {
    setShowLanguageDialog(false);
  };

  const nextLevelData = nextLevel?.nextMembershipLevel?.nextLevel;
  const nextDiscountPercentage = nextLevelData?.discountPercentage;
  const nextPointsRequired = nextLevelData?.pointsRequired;

  const hidePoints = nextDiscountPercentage > 50 || nextPointsRequired > 500000000;
  return (
    <>
      {!content ? (
        <FullpageLoader />
      ) : (
        <View
          data={content}
          onNavigate={navigate}
          isSignedIn={isSignedIn}
          membershipCard={membershipCard}
          nextLevelPoints={nextLevelPoints}
          modalLanguage={modalLanguage}
          setModalLanguage={setModalLanguage}
          showLanguageDialog={showLanguageDialog}
          onShowLanguageDialog={handleShowLanguageDialog}
          onSelectLanguage={handleSelectLanguage}
          handleCloseLanguageDialog={handleCloseLanguageDialog}
          hidePoints={hidePoints}
        />
      )}
    </>
  );
};

export default LandingPage;
