import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import useI18n from '../../../i18n';
import { SubCategoryWithData } from '../index';
import { DEVICE_SIZES, getDeviceSize } from '../../../utils/deviceSize';

const Wrapper = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 80px;
  left: 0;
  overflow-x: auto;
  background: #fff;
  box-shadow: inset 0 1px 0 #e0e0e3, inset 0px -1px 0px #e0e0e3;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 768px) {
    padding: 0 16px;
    height: 65px;
    overflow-x: initial;
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;

  @media (min-width: 768px) {
    padding: 0;
    width: 100%;
    overflow-x: auto;
    height: 65px;
  }
  @media (min-width: 992px) {
    margin: 0 auto;
    max-width: 960px;
  }
`;

const Item = styled.div<{ active: boolean; clicked: boolean }>`
  height: 40px;
  background: ${({ active }) => (active ? '#D5D7D8' : 'transparent')};
  transform: ${({ clicked }) => (clicked ? 'scale(0.9)' : 'none')};
  transition: background 0.3s ease 0s, transform 0.3s ease 0s;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.024em;
  white-space: nowrap;
  border-radius: 24px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

type Props = {
  /**
   * subCategories
   */
  subCategories: SubCategoryWithData[];
  /**
   * selectedId
   */
  selectedId: string | null;
  /**
   * onClick
   */
  onClick: (id: string) => void;
};

/**
 * SubCategoriesMenu component
 */
const SubCategoriesMenu = ({ subCategories, selectedId, onClick }: Props) => {
  const [clickedId, setClickedId] = useState<string | null>(null);
  const { i18n } = useI18n();
  const scrollRef = useRef<HTMLDivElement>(null);
  const categoryRef: any = useRef({});
  const deviceSize = getDeviceSize();
  const deviceSmall = deviceSize === DEVICE_SIZES.SMALL;

  if (subCategories.length === 0) {
    return null;
  }

  const getSizes = (id: string) => {
    const current = categoryRef.current[id];

    if (!current) {
      return null;
    }
    const { width } = current.getBoundingClientRect();
    const offsetLeft = current.offsetLeft;
    const itemPosition = offsetLeft - Math.round(width / 2);
    return { itemPosition, itemWidth: width };
  };

  useEffect(() => {
    if (selectedId) {
      const itemPosition = getSizes(selectedId)?.itemPosition;

      if (!itemPosition) {
        return;
      }

      scrollRef.current?.scrollTo({ left: itemPosition, behavior: 'smooth' });
    }
  }, [selectedId]);

  return (
    <Wrapper ref={deviceSmall ? scrollRef : null}>
      <Inner ref={!deviceSmall ? scrollRef : null}>
        {subCategories.map((category) => (
          <Item
            ref={(el) => (categoryRef.current[category.id] = el)}
            clicked={clickedId === category.id}
            onTouchStart={() => setClickedId(category.id)}
            onTouchEnd={() => setClickedId(null)}
            active={category.id === selectedId}
            onClick={() => onClick(category.id)}
            key={category.id}>
            <span>{i18n(category.nameLang)}</span>
          </Item>
        ))}
      </Inner>
    </Wrapper>
  );
};

export default SubCategoriesMenu;
