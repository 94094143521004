import useI18n from 'i18n';
import React from 'react';
import styled from 'styled-components';

import { ReactComponent as RestaurantIcon } from '../../Assets/Icons/restaurant.svg';
import Icon from '../Icon';

const Wrapper = styled.div<{
  background?: string;
  borderColor?: string;
  shadow?: string;
  borderWidth?: number;
  top?: number;
}>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  border-width: ${({ borderWidth }) => borderWidth ?? 1}px;
  border-style: solid;
  border-color: ${({ borderColor }) => borderColor || 'rgb(39, 103, 105)'};
  padding: 17px 19px 19px 19px;
  background-color: ${({ background }) => background || '#1c4b4c'};
  box-shadow: ${({ shadow }) => shadow || 'none'};
  margin-top: ${({ top }) => top || 0}px;
`;

const Info = styled.div``;

const Status = styled.strong<{ color?: string }>`
  display: block;
  color: ${({ color }) => color || 'rgb(255, 255, 255)'};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.23px;
  line-height: 18px;
`;

const Message = styled.p<{ color?: string }>`
  color: ${({ color }) => color || 'rgb(255, 255, 255)'};
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 16px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
`;

interface Props {
  /**
   * status
   */
  status: string | undefined;
  /**
   * message
   */
  message: string | undefined | React.ReactNode;
  /**
   * background
   */
  background?: string;
  /**
   * borderWidth
   */
  borderWidth?: number;
  /**
   * borderColor
   */
  borderColor?: string;
  /**
   * statusColor
   */
  statusColor?: string;
  /**
   * messageColor
   */
  messageColor?: string;
  /**
   * iconColor
   */
  iconColor?: string;
  /**
   * shadow
   */
  shadow?: string;
  /**
   * top
   */
  top?: number;
}

/**
 * OrderStatus component
 */

const OrderStatus: React.FunctionComponent<Props> = ({
  status,
  message,
  background,
  borderWidth,
  borderColor,
  statusColor,
  messageColor,
  iconColor,
  shadow,
  top,
}: Props) => {
  const { i18n } = useI18n();

  return (
    <Wrapper
      background={background}
      borderWidth={borderWidth}
      borderColor={borderColor}
      shadow={shadow}
      top={top}>
      <IconWrapper>
        <Icon icon={<RestaurantIcon />} color={iconColor} size={24} />
      </IconWrapper>
      <Info>
        {status && (
          <Status color={statusColor}>
            {i18n('Order.Status')}: {status}
          </Status>
        )}
        {message && <Message color={messageColor}>{message}</Message>}
      </Info>
    </Wrapper>
  );
};

export default OrderStatus;
