import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as LocationIcon } from '../../Assets/Icons/location.svg';
import VenueItem from './components/VenueItem';
import { VenuesWithDistance } from './index';
import useI18n from '../../i18n';
import Icon from '../../Components/Icon';
import DialogModal from 'Components/Dialog/Modal';
import Dialog from 'Components/Dialog';

const Wrapper = styled.div`
  background: #eeeef1;
  flex: 1;
  min-height: 100vh;
  padding: ${() => window.headerHeight}px 0 300px 0;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Position = styled.div`
  background: rgb(245, 245, 248);
  padding: 8px 24px;
`;

const PositionInner = styled.div`
  border-radius: 4px;
  border: 1px solid rgb(158, 158, 161);
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 16px;
  cursor: pointer;
`;
const PositionLabel = styled.div`
  color: rgb(33, 33, 36);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 20px;
  text-transform: uppercase;
`;

const ClosedText = styled.p`
  color: rgb(33, 33, 36);
  margin-top: 16px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 20px;
  text-align: center;
`;

const Venues = styled.div``;

interface Props {
  userLocationAllowed: boolean;
  activeVenue: string | null;
  setActiveVenue: (venueId: string | null) => void;
  venues: VenuesWithDistance[];
  onGetUserLocation: () => void;
  onSubmit: (venueId: string, serviceType: string) => void;
  deniedLocation: boolean;
  disableApp: boolean;
}

const View: React.FC<Props> = ({
  userLocationAllowed,
  activeVenue,
  setActiveVenue,
  venues,
  onGetUserLocation,
  onSubmit,
  deniedLocation,
  disableApp,
}) => {
  const [showDeniedModal, setShowDeniedModal] = useState(false);
  const { i18n } = useI18n();

  const handlePositionClick = () => {
    if (deniedLocation) {
      setShowDeniedModal(true);
    } else {
      onGetUserLocation();
    }
  };

  useEffect(() => {
    if (deniedLocation) {
      setShowDeniedModal(true);
    }
  }, [deniedLocation]);

  return (
    <>
      <Wrapper>
        <Position>
          <PositionInner onClick={handlePositionClick}>
            <PositionLabel>{i18n('StoreList.FindPosition')}</PositionLabel>
            <Icon
              icon={<LocationIcon />}
              size={24}
              color={userLocationAllowed ? '#212124' : '#bdbdc0'}
            />
          </PositionInner>
        </Position>
        <Venues>
          {disableApp ? (
            <ClosedText>{i18n('Store.Closed.Message')}</ClosedText>
          ) : (
            venues.map((venue) => (
              <VenueItem
                key={venue.id}
                venue={venue}
                activeVenue={activeVenue}
                setActiveVenue={setActiveVenue}
                onSubmit={onSubmit}
              />
            ))
          )}
        </Venues>
      </Wrapper>
      {showDeniedModal && (
        <Dialog onClose={() => setShowDeniedModal(false)} visible={showDeniedModal}>
          <DialogModal
            title={i18n('StoreList.Denied.Title')}
            text={i18n('StoreList.Denied.Text')}
            visible={showDeniedModal}
            buttonText={i18n('StoreList.Denied.ButtonText')}
            onButtonClick={() => setShowDeniedModal(false)}
            centerText
          />
        </Dialog>
      )}
    </>
  );
};

export default View;
