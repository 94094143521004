import React from 'react';
import styled from 'styled-components';
import { useStorage } from 'Components/Storage';
import dayjs from 'dayjs';
import Orders from './Orders';

const OrderList = styled.div<{ top?: number }>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: 8px;
  right: 8px;
  z-index: 3;

  & > div {
    margin-bottom: 8px;
  }
`;

interface Props {
  venueId?: string;
}

const AnonymousOrdersList = ({ venueId }: Props) => {
  const [storage, updateStorage] = useStorage();

  return (
    <OrderList top={window.headerHeight}>
      {storage.anonymousOrders.map((order) => {
        const timeDiff = dayjs(order.createdAt).diff(dayjs(), 'hours');
        if (timeDiff <= -1) {
          updateStorage({
            anonymousOrders: storage.anonymousOrders.filter((o) => order.id !== o.id),
          });
          return;
        } else {
          return <Orders orderId={order.id} venueId={venueId} anonymousOrder={order} />;
        }
      })}
    </OrderList>
  );
};

export default AnonymousOrdersList;
