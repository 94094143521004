import type { Language } from './Types';
import { OfferTokens } from 'Components/App/OfferManager';
import { venueQuery_venue } from 'Hooks/__queries__';
import { ShoppingCartEvent } from '@baemingo/shopping-cart-events';
import { SocialTableEvent } from '@baemingo/social-table-events';
import { CURRENCY } from '../__queries__';

export const DEFAULT_LOCALE = 'se';

export const TOGGLE_MAIN_MENU = 'TOGGLE_MAIN_MENU';
export const TOGGLE_ACTIVATE_PUSH = 'TOGGLE_ACTIVATE_PUSH';
export const SET_USER = 'SET_USER';
export const SET_USER_EASYID = 'SET_USER_EASYID';
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
export const SET_ALERT = 'SET_ALERT';
export const SET_INVENTORY = 'SET_INVENTORY';
export const SET_VENUE = 'SET_VENUE';
export const SET_MINIMUM_PICKUP_TIME = 'SET_MINIMUM_PICKUP_TIME';
export const LOGOUT = 'LOGOUT';
export const ADD_SHOPPING_CART_EVENT = 'ADD_SHOPPING_CART_EVENT';
export const ADD_SOCIAL_TABLE_EVENT = 'ADD_SOCIAL_TABLE_EVENT';
export const SET_REDIRECT_PATH = 'SET_REDIRECT_PATH';
export const SET_CART_ID = 'SET_CART_ID';
export const SET_TABLE_ID = 'SET_TABLE_ID';
export const SET_USER_MEMBERSHIP = 'SET_USER_MEMBERSHIP';
export const SET_COMPANY_HAS_MEMBERSHIP_CARDS = 'SET_COMPANY_HAS_MEMBERSHIP_CARDS';
export const SET_SHOW_NEWS_MENU_ITEM = 'SET_SHOW_NEWS_MENU_ITEM';
export const SET_CART_ERRORS = 'SET_CART_ERRORS';
export const SET_AVAILABLE_OFFERS = 'SET_AVAILABLE_OFFERS';
export const SET_DISABLE_APP = 'SET_DISABLE_APP';
export const SET_QR_ORDER = 'SET_QR_ORDER';
export const SET_COMPANY_CURRENCY = 'SET_COMPANY_CURRENCY';
export const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE';
export const SET_LOGIN_SCREEN = 'SET_LOGIN_SCREEN';
export const SET_LOGIN_POPUP = 'SET_LOGIN_POPUP';

type ToggleMainMenuAction = {
  type: typeof TOGGLE_MAIN_MENU;
  isShow: boolean;
};
export function toggleMainMenu(isShow: boolean) {
  return {
    type: TOGGLE_MAIN_MENU,
    isShow,
  };
}

type ToggleActivatePushAction = {
  type: typeof TOGGLE_ACTIVATE_PUSH;
  isShow: boolean;
};
export function toggleActivatePush(isShow: boolean) {
  return {
    type: TOGGLE_ACTIVATE_PUSH,
    isShow,
  };
}

type User = any | null;
type SetUserAction = {
  type: typeof SET_USER;
  user: User;
};
export function setUser(user: User) {
  return {
    type: SET_USER,
    user,
  };
}

type SetUserEasyIdAction = {
  type: typeof SET_USER_EASYID;
  easyId: string;
};
export function setUserEasyId(easyId: string) {
  return {
    type: SET_USER_EASYID,
    easyId,
  };
}

type SetConnectionStatusAction = {
  type: typeof SET_CONNECTION_STATUS;
  offline: boolean;
};
export function setConnectionStatus(offline: boolean) {
  return {
    type: SET_CONNECTION_STATUS,
    offline,
  };
}
export type AlertState = {
  title: string;
  description: string;
} | null;
type SetAlertAction = {
  type: typeof SET_ALERT;
  alert: AlertState;
};
export function setAlert(alert: AlertState) {
  return {
    type: SET_ALERT,
    alert,
  };
}

export type InventoryState = any;
type SetInventoryAction = {
  type: typeof SET_INVENTORY;
  inventory: InventoryState;
};
export function setInventory(inventory: InventoryState) {
  return {
    type: SET_INVENTORY,
    inventory,
  };
}

export type VenueState = venueQuery_venue | null;
type SetVenue = {
  type: typeof SET_VENUE;
  venue: VenueState;
};
export function setVenue(venue: VenueState) {
  return {
    type: SET_VENUE,
    venue,
  };
}

export type MinimumPickupTime = number;

type SetMinimumPickupTime = {
  type: typeof SET_MINIMUM_PICKUP_TIME;
  minimumPickupTime: number;
};

export function setMinimumPickupTime(minimumPickupTime: MinimumPickupTime) {
  return {
    type: SET_MINIMUM_PICKUP_TIME,
    minimumPickupTime,
  };
}

type LogoutAction = {
  type: typeof LOGOUT;
};
export function logout() {
  return {
    type: LOGOUT,
  };
}

export type RedirectPathState = string;
type RedirectPathAction = {
  type: typeof SET_REDIRECT_PATH;
  redirectPath: RedirectPathState;
};
export function setRedirectPath(redirectPath: RedirectPathState) {
  return {
    type: SET_REDIRECT_PATH,
    redirectPath,
  };
}

export type ShowNewsMenuItemState = boolean;
type ShowNewsMenuItemAction = {
  type: typeof SET_SHOW_NEWS_MENU_ITEM;
  showNewsMenuItem: ShowNewsMenuItemState;
};
export function setShowNewsMenuItem(showNewsMenuItem: ShowNewsMenuItemState) {
  return {
    type: SET_SHOW_NEWS_MENU_ITEM,
    showNewsMenuItem,
  };
}

export type ShoppingCartEventState = ShoppingCartEvent[];
type ShoppingCartEventAction = {
  type: typeof ADD_SHOPPING_CART_EVENT;
  event: ShoppingCartEvent;
};
export function addShoppingCartEvent(event: ShoppingCartEvent): ShoppingCartEventAction {
  return {
    type: ADD_SHOPPING_CART_EVENT,
    event,
  };
}

export type SocialTableEventState = SocialTableEvent[];
type SocialTableEventAction = {
  type: typeof ADD_SOCIAL_TABLE_EVENT;
  event: SocialTableEvent;
};
export function addSocialTableEvent(event: SocialTableEvent): SocialTableEventAction {
  return {
    type: ADD_SOCIAL_TABLE_EVENT,
    event,
  };
}

export type CartIdState = string | null;
type CartIdAction = {
  type: typeof SET_CART_ID;
  cartId: CartIdState;
};
export function setCartId(id: string | null): CartIdAction {
  return {
    type: SET_CART_ID,
    cartId: id,
  };
}

export type TableIdState = string | null;
type TableIdAction = {
  type: typeof SET_TABLE_ID;
  tableId: TableIdState;
};
export function setTableId(id: string | null): TableIdAction {
  return {
    type: SET_TABLE_ID,
    tableId: id,
  };
}

export type MembershipState = null | {
  title: string;
  rate: number;
};
type MembershipAction = {
  type: typeof SET_USER_MEMBERSHIP;
  membership: MembershipState;
};
export function setUserMembership(membership: MembershipState): MembershipAction {
  return {
    type: SET_USER_MEMBERSHIP,
    membership,
  };
}

export type CompanyHasMembershipCardsState = boolean;
type CompanyHasMembershipCardsAction = {
  type: typeof SET_COMPANY_HAS_MEMBERSHIP_CARDS;
  companyHasMembershipCards: CompanyHasMembershipCardsState;
};
export function setCompanyHasMembershipCards(
  companyHasMembershipCards: CompanyHasMembershipCardsState,
): CompanyHasMembershipCardsAction {
  return {
    type: SET_COMPANY_HAS_MEMBERSHIP_CARDS,
    companyHasMembershipCards,
  };
}

export type DefaultLanguageState = Language | null;
type SetDefaultLanguageAction = {
  type: typeof SET_DEFAULT_LANGUAGE;
  defaultLanguage: DefaultLanguageState;
};
export function setDefaultLanguage(
  defaultLanguage: DefaultLanguageState,
): SetDefaultLanguageAction {
  return {
    type: SET_DEFAULT_LANGUAGE,
    defaultLanguage,
  };
}

export type CompanyCurrencyState = keyof typeof CURRENCY;
type SetCompanyCurrencyAction = {
  type: typeof SET_COMPANY_CURRENCY;
  companyCurrency: CompanyCurrencyState;
};
export function setCompanyCurrency(
  companyCurrency: CompanyCurrencyState,
): SetCompanyCurrencyAction {
  return {
    type: SET_COMPANY_CURRENCY,
    companyCurrency,
  };
}

export type DisableAppState = boolean;
type DisableAppAction = {
  type: typeof SET_DISABLE_APP;
  disabled: DisableAppState;
};
export function setDisableApp(disabled: DisableAppState): DisableAppAction {
  return {
    type: SET_DISABLE_APP,
    disabled,
  };
}

export type qrOrderState = boolean;
type qrOrderAction = {
  type: typeof SET_QR_ORDER;
  qrOrder: qrOrderState;
};
export function setqrOrder(qrOrder: qrOrderState): qrOrderAction {
  return {
    type: SET_QR_ORDER,
    qrOrder,
  };
}

export type CartErrorAmountState = number;
type CartErrorAction = {
  type: typeof SET_CART_ERRORS;
  cartErrorAmount: CartErrorAmountState;
};
export function setCartErrors(cartErrorAmount: CartErrorAmountState): CartErrorAction {
  return {
    type: SET_CART_ERRORS,
    cartErrorAmount,
  };
}

export type AvailableOffersState = OfferTokens[];
type AvailableOfferAction = {
  type: typeof SET_AVAILABLE_OFFERS;
  availableOffers: AvailableOffersState;
};
export function setAvailableOffers(availableOffers: AvailableOffersState): AvailableOfferAction {
  return {
    type: SET_AVAILABLE_OFFERS,
    availableOffers,
  };
}

export type LoginScreenState = 'signin' | 'signup' | 'forgot-password' | 'verify-account' | '';
type LoginScreenAction = {
  type: typeof SET_LOGIN_SCREEN;
  screen: LoginScreenState;
};
export function setLoginScreen(screen: LoginScreenState) {
  return {
    type: SET_LOGIN_SCREEN,
    screen,
  };
}

type LoginModalAction = {
  type: typeof SET_LOGIN_POPUP;
  show: boolean;
};
export function setLoginModal(show: boolean) {
  return {
    type: SET_LOGIN_POPUP,
    show,
  };
}

export type Action =
  | SetUserAction
  | SetUserEasyIdAction
  | ToggleMainMenuAction
  | ToggleActivatePushAction
  | SetConnectionStatusAction
  | SetAlertAction
  | SetInventoryAction
  | SetVenue
  | SetMinimumPickupTime
  | LogoutAction
  | RedirectPathAction
  | ShoppingCartEventAction
  | CartIdAction
  | TableIdAction
  | ShowNewsMenuItemAction
  | CartErrorAction
  | AvailableOfferAction
  | DisableAppAction
  | qrOrderAction
  | SetCompanyCurrencyAction
  | SetDefaultLanguageAction
  | LoginScreenAction
  | LoginModalAction;
