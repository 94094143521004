import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as NavigateRightIcon } from '../../Assets/Icons/navigate-right.svg';
import Icon from '../Icon';

const Wrapper = styled(Link)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  color: #212124;
  text-transform: uppercase;
  margin: 31px 0 0 0;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    div {
      transform: translateX(3px);
    }
  }
`;

const Arrow = styled.div`
  margin-left: 7px;
  transition: transform 0.2s ease-in-out;
`;

interface Props {
  /**
   * text
   */
  text: string;
  /**
   * url
   */
  url: string;
}

/**
 * ArrowLink component
 */

const ArrowLink: React.FunctionComponent<Props> = ({ text, url }) => {
  return (
    <Wrapper to={url}>
      {text}
      <Arrow>
        <Icon icon={<NavigateRightIcon />} color={'#212124'} size={15} />
      </Arrow>
    </Wrapper>
  );
};

export default ArrowLink;
