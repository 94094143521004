import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid #eeeef1;
  :last-child {
    border: none;
  }
`;

const Variations = styled.div``;

const Variation = styled.p<{ removeVariation?: boolean }>`
  color: ${({ removeVariation }) => (removeVariation ? '#e3273b' : '#212124')};
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 20px;
  margin-top: 4px;
  span {
    width: 12px;
    margin-right: 4px;
    display: inline-block;
    text-align: center;
  }
`;

const Text = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.p`
  font-weight: 600;
`;

interface Variation {
  id: string;
  title: string;
  amount: number;
  groupId: string;
}
interface Props {
  itemId: string;
  title: string;
  variations: Variation[];
  removedVariations: string[];
}

const OutOfStockModalItem: React.FC<Props> = ({
  itemId,
  title,
  variations,
  removedVariations,
}) => {
  return (
    <Wrapper>
      <Text>
        <Title>{title}</Title>
      </Text>
      {Boolean(variations.length) && (
        <Variations>
          {variations.map((variation) => (
            <Variation key={`${itemId}_${variation.id}`}>
              <span>+</span>
              {variation.title}
            </Variation>
          ))}
        </Variations>
      )}
      {Boolean(removedVariations?.length) && (
        <Variations>
          {removedVariations.map((variationName) => (
            <Variation key={`${itemId}_${variationName}`} removeVariation>
              <span>-</span>
              {variationName}
            </Variation>
          ))}
        </Variations>
      )}
    </Wrapper>
  );
};

export default OutOfStockModalItem;
