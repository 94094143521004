import React from 'react';

import PrivacyPolicyView from './view';
import { useConfig } from 'Components/ConfigProvider';

const PrivacyPolicy = () => {
  const config = useConfig();
  const name = config.DISPLAY_NAME;
  return <PrivacyPolicyView name={name} />;
};

export default PrivacyPolicy;
