import { useSelector } from 'Hooks';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import OutOfStockOverlay from 'Components/OutOfStockOverlay';
import { checkOutOfStock } from 'utils/inventory';

// Styling shared between normal and disabled item
const ItemStyling = css`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  user-select: none;
  border-radius: 4px;
  overflow: hidden;
`;

const Item = styled(Link)<{ image: string }>`
  background: ${({ image, theme }) => (image ? `url(${image})` : `${theme.menuBackgroundColor}`)};
  ${ItemStyling}
  -webkit-tap-highlight-color: transparent;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  @media (min-width: 768px) {
    transition: transform 0.1s ease-in-out;
    &:hover {
      transform: scale(0.99);
    }
  }
`;

// Used when a category is out of stock
const DisabledItem = styled.span<{ image: string }>`
  background: ${({ image, theme }) => (image ? `url(${image})` : `${theme.menuBackgroundColor}`)};
  ${ItemStyling}
`;

const Title = styled.h2<{ fontSize: number; lineHeight: number; hasImage: boolean }>`
  color: ${({ hasImage, theme }) => (hasImage ? 'rgb(255, 255, 255)' : `${theme.menuTextColor}`)};
  font-size: ${(props) => `${props.fontSize}px`};
  font-weight: 600;
  line-height: ${(props) => `${props.lineHeight}px`};
  text-align: center;
`;

type Props = {
  id: string;
  image: string;
  name: string;
  venueId: string;
  isGrid?: boolean;
};

const maxGridCharacters = 11;
const smallFontSize = 16;
const smallLineHeight = 22;
const defaultFontSize = 24;
const defaultLineHeight = 32;

const MenuItem = ({ id, image, name, venueId, isGrid }: Props) => {
  // Get inventory, used to check if category is out of stock
  const inventory = useSelector((state) => state.inventory);
  // For categories we don't care about the count
  const categoryOutOfStock = checkOutOfStock(id, inventory, true);

  const fontStyle = useMemo(() => {
    if (isGrid) {
      const numberOfCharacters = name.length;
      if (numberOfCharacters > maxGridCharacters) {
        return { fontSize: smallFontSize, lineHeight: smallLineHeight };
      }
    }
    return { fontSize: defaultFontSize, lineHeight: defaultLineHeight };
  }, [isGrid]);

  if (categoryOutOfStock) {
    return (
      <DisabledItem image={image}>
        {categoryOutOfStock && <OutOfStockOverlay textPosition="topLeft" />}
        <Title fontSize={fontStyle.fontSize} lineHeight={fontStyle.lineHeight} hasImage={!!image}>
          {name}
        </Title>
      </DisabledItem>
    );
  }

  return (
    <Item to={`/order/${venueId}/menu/${id}`} image={image}>
      <Title fontSize={fontStyle.fontSize} lineHeight={fontStyle.lineHeight} hasImage={!!image}>
        {name}
      </Title>
    </Item>
  );
};

export default MenuItem;
