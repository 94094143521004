import React from 'react';
import styled from 'styled-components';
import Button from 'Components/Button';
import useI18n from '../../../../i18n';

const Wrapper = styled.div`
  border-radius: 24px;
  overflow: hidden;
`;

const Image = styled.div<{ image: any }>`
  background: ${({ image }) => `url(${image})`} no-repeat center;
  background-size: cover;
  border-radius: 24px 24px 0 0;
  height: 216px;
  width: 327px;
`;

const Content = styled.div`
  background: rgb(255, 255, 255);
  padding: 16px 24px 24px 24px;
`;

const Title = styled.h3`
  color: rgb(33, 33, 36);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  margin: 0 0 16px 0;
`;

interface Props {
  /**
   * onClose
   */
  onClose: () => void;
  /**
   * offerImage
   */
  offerImage: string;
}

/**
 * Home page StampCardConfirm component
 */
const StampCardConfirm = ({ onClose, offerImage }: Props) => {
  const { i18n } = useI18n();

  return (
    <Wrapper>
      <Image image={offerImage} />
      <Content>
        <Title>{i18n('StampCard.Finish')}</Title>
        <Button text={'OK'} handleClick={onClose} width="100%" />
      </Content>
    </Wrapper>
  );
};

export default StampCardConfirm;
