import { createStore, combineReducers, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import * as reducers from './reducers';

const logger = createLogger({
  //predicate: () => Boolean(window.NODE_ENV === 'development'),
  collapsed: (getState, action, logEntry) => !logEntry?.error,
});

/**
 * Saves compliance data to localstorage
 * TODO: Send this to app wrapper
 */
const saveComplianceData = (store: any) => (next: any) => (action: any) => {
  const actions = ['SET_COMPLIANCE_NAME', 'SET_COMPLIANCE_CCU_ID'];

  if (action.type === 'SET_COMPLIANCE_ID') {
    localStorage.setItem('taxComplianceId', action.id);
  }
  if (actions.includes(action.type)) {
    localStorage.setItem('taxComplianceName', action.name);
  }

  return next(action);
};

const reducer = combineReducers(reducers);
const store = createStore(reducer, applyMiddleware(logger, saveComplianceData));
export type Store = typeof store;

export default store;
