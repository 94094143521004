import { gql } from '@apollo/client';
import React from 'react';
import { useApolloClient } from '@apollo/client';
import EventsSynchronizer from 'Components/EventsSynchronizer';
import { useDispatch, useSelector } from 'Hooks';
import { GetShoppingCartEvents, GetShoppingCartEventsVariables } from './__queries__';
import { useSocket } from 'Components/SocketManager';

export const SHOPPING_CART_EVENTS_QUERY = gql`
  query GetShoppingCartEvents($cartId: String!) {
    shoppingCartEvents(cartId: $cartId) {
      lastKey
      events
    }
  }
`;

interface Props {
  cartId: string;
}

const ShoppingCartSynchronizer: React.FunctionComponent<Props> = ({ cartId }) => {
  const client = useApolloClient();
  const socket = useSocket();
  const dispatch = useDispatch();
  const venueId = useSelector((state) => state.venue?.id ?? undefined);

  return (
    <EventsSynchronizer
      venueId={venueId!}
      eventType="ADD_SHOPPING_CART_EVENT"
      manyEventsType="ADD_SHOPPING_CART_EVENTS"
      snapshotType="SET_SHOPPING_CART_SNAPSHOT"
      eventSelector={(state) => state.shoppingCartEvents}
      snapshotSelector={(state) => null}
      pollingInterval={7000}
      /* Polling query for new events */
      queryEvents={async (timestamp) => {
        const response = await client.query<GetShoppingCartEvents, GetShoppingCartEventsVariables>(
          {
            query: SHOPPING_CART_EVENTS_QUERY,
            variables: {
              cartId,
            },
            fetchPolicy: 'network-only',
          },
        );

        const events = response.data?.shoppingCartEvents?.events.items;
        const success = Boolean(events);
        /**
         * Register that we actually got data.
         * Doesn't matter if we got new items/events or not, just that we have
         * established a connection with the backend and it sent us data
         *
         * If this action isnt triggered in a while, we will show a warning
         */
        if (success) {
          dispatch({
            type: 'NEW_SHOPPING_CART_DATA',
          });
        }
        return {
          success,
          events: events,
          error: (response.errors && response.errors[0].message) || null,
        };
      }}
      /* Snapshot query */
      querySnapshot={async () => {
        return {
          success: true,
          snapshot: null,
          timestamp: new Date().toISOString(),
          error: null,
        };
      }}
      /* Send event mutation */
      addEventMutation={async () => {
        return {
          error: null,
          success: true,
        };
      }}
      sendSocketMessage={(event) => socket.send.shoppingCartEvent(event)}
    />
  );
};

export default ShoppingCartSynchronizer;
