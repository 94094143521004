import React from 'react';
import styled from 'styled-components';

import Button from '../../../Components/Button';
import Dialog from '../../../Components/Dialog';
import useI18n from '../../../i18n';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: #fff;
  border-radius: 24px;
`;

const Title = styled.h2`
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  margin: 0 0 15px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const CancelWrapper = styled.div`
  margin: 0 0 0 10px;
`;

type Props = {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const ClearCartAlert = ({ visible, onConfirm, onCancel }: Props) => {
  const { i18n } = useI18n();

  return (
    <Dialog visible={visible} onClose={onCancel} dontCloseOnClickAnywhere>
      <Wrapper>
        <Title>{i18n('Cart.Clear.Title')}</Title>
        <ButtonContainer>
          <Button text={i18n('Cart.Confirm')} handleClick={onConfirm} small />
          <CancelWrapper>
            <Button
              text={i18n('Cart.Cancel')}
              handleClick={onCancel}
              small
              background={'#818181'}
            />
          </CancelWrapper>
        </ButtonContainer>
      </Wrapper>
    </Dialog>
  );
};

export default ClearCartAlert;
