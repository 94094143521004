import { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { StorageData } from 'Components/Storage';

type VibrateTypes =
  | 'selection'
  | 'impactLight'
  | 'impactMedium'
  | 'impactHeavy'
  | 'notificationSuccess'
  | 'notificationWarning'
  | 'notificationError';

// , "clockTick"(Android only), "contextClick"(Android only), "keyboardPress"(Android only), "keyboardRelease"(Android only), "keyboardTap"(Android only), "longPress"(Android only), "textHandleMove"(Android only), "virtualKey"(Android only), "virtualKeyRelease"(Android only), (default: "selection")

export const useBridgeApi = () => {
  // Save interval IDs to prevent memory leaks
  const [intervalIds, setIntervalIds] = useState<number[]>([]);

  // Our hashmap of request IDs and data
  const requests = useRef<Record<string, any>>({});

  // Message handler
  function onMessage(message: any) {
    let data = message.data;

    try {
      data = JSON.parse(message.data);
    } catch (e) {} // eslint-disable-line

    // If this message contained a request ID, store the response
    if (data.requestId) {
      requests.current[data.requestId] = data.payload;
    }
  }

  function request(data: Object) {
    try {
      if (window?.webkit?.messageHandlers?.ReactNativeWebView) {
        window.webkit.messageHandlers.ReactNativeWebView.postMessage(JSON.stringify(data));
      } else if (window?.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function requestResponse(type: string, data: Object) {
    // Create a unique ID to use for this request
    const requestId = uuid();

    // Send the request to the app wrapper
    request({
      payload: data,
      type,
      isBridgeApi: true,
      requestId,
    });

    // Return a new promise which continually checks for a response from the wrapper
    return new Promise((resolve) => {
      const id = window.setInterval(() => {
        // If we got a response, resolve
        if (requests.current[requestId]) {
          resolve(requests.current[requestId]);
          clearInterval(id);

          // Remove the data from "storage" after we have resolved
          requests.current[requestId] = null;
        }
      }, 150);

      // Save this interval ID so we can remove it later if needed
      setIntervalIds((state) => [...state, id]);
    });
  }

  useEffect(() => {
    // Add the event listener for messages passed from app wrapper
    document.addEventListener('message', onMessage);
    window.addEventListener('message', onMessage);

    // To prevent memory leaks, remove all event listeners on demount
    return () => {
      document.removeEventListener('message', onMessage);
      window.removeEventListener('message', onMessage);

      // Clear all pending intervals
      intervalIds.forEach((id) => clearInterval(id));
    };
  }, []);

  return {
    /**
     *
     * The CAPITALIZED KEYS are hardcoded in the native wrapper.
     * If we add more native functionality, we add more keys here.
     *
     * For now, these are the exact native functions we have
     */
    vibrate: (type: VibrateTypes) => {
      return requestResponse('VIBRATE', type);
    },

    getStorageData: () => {
      return requestResponse('GET_STORAGE', {}) as Promise<StorageData>;
    },

    updateStorage: async (data: StorageData) => {
      return requestResponse('SET_STORAGE', data);
    },
    getUserLocation: async () => {
      return requestResponse('GET_USER_LOCATION', {});
    },
  };
};
