import React from 'react';
import { gql, useQuery } from '@apollo/client';
import useI18n from '../../i18n';
import View from './view';
import { useSelector } from 'Hooks';
import useAuth from 'Hooks/useAuth';
import { useConfig } from 'Components/ConfigProvider';

export const MEMBERSHIP_CARDS = gql`
  query companyQuery($id: ID!) {
    allMembershipLevels(
      filter: {
        company: { id: $id }
        deleted: false
        AND: [{ discountPercentage_lt: 50 }, { pointsRequired_lt: 500000000 }]
      }
    ) {
      id
      pointsRequired
      primaryColor
      secondaryColor
      image {
        file {
          url
        }
      }
      title {
        id
        en
        se
      }
      description {
        id
        en
        se
      }
      discountPercentage
    }
  }
`;

/**
 * Membership scene
 */
const Membership = () => {
  const config = useConfig();
  const user = useSelector((state) => state.user);
  const userEasyId = useSelector((state) => state.easyId);
  const { i18n } = useI18n();
  const { hasAuthToken } = useAuth();
  const isSignedIn = hasAuthToken();
  const userPoints = user?.membershipCard?.points ?? 0;
  const membershipLevel = user?.membershipCard?.membershipLevel?.title;

  const { data } = useQuery<any>(MEMBERSHIP_CARDS, {
    variables: {
      id: config.COMPANY_ID,
    },
  });

  const sortedCards = data?.allMembershipLevels
    ? [...data?.allMembershipLevels].sort((a: any, b: any) => {
        return a.pointsRequired - b.pointsRequired;
      })
    : [];

  return (
    <View
      isSignedIn={isSignedIn}
      allCards={sortedCards}
      userEasyId={userEasyId}
      userPoints={userPoints}
      membershipLevel={i18n(membershipLevel)}
    />
  );
};

export default Membership;
