import styled from 'styled-components';

export default styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    background-color: #ffffff80;
    top: 0;
    height: 100%;
    width: 100%;
    transition: 0.3s opacity;
    opacity: 0;
    border-radius: 4px;
    pointer-events: none;
  }

  &:active {
    &::after {
      opacity: 1;
    }
  }
`;
