import startCase from 'lodash/startCase';

import { i18nProps } from 'Types';
import { VenuesWithDistance } from '..';

export const formatDistance = (venue: VenuesWithDistance) => {
  const { distance } = venue;

  if (!distance && distance !== 0) {
    return null;
  }

  if (distance === 0) {
    return `0m`;
  }

  if (distance < 1000) {
    return `${distance}m`;
  }

  if (distance >= 1000) {
    return `${Math.round(distance / 1000)}km`;
  }

  return null;
};

type ServiceType = {
  id: string;
  name: string;
  description: string;
};
export const parseServiceType = (type: string, i18n: i18nProps): ServiceType => {
  switch (type) {
    case 'TAKE_AWAY':
      return {
        id: 'TAKE_AWAY',
        name: i18n('StoreList.Modal.TakeAway'),
        description: i18n('StoreList.Modal.TakeAway.Text'),
      };

    case 'EAT_HERE':
      return {
        id: 'EAT_HERE',
        name: i18n('StoreList.Modal.EatHere'),
        description: i18n('StoreList.Modal.EatHere.Text'),
      };
    default:
      return {
        id: type,
        name: startCase(type),
        description: '',
      };
  }
};
