export default function getImageUrlWithSize(
  url: string | undefined,
  width: number,
  height: number,
) {
  if (!url) {
    return '';
  }
  if (url.includes('graph.cool')) {
    const [project, secret] = url.split('/').slice(-2);
    return `https://images.graph.cool/v1/${project}/${secret}/${Math.round(width)}x${Math.round(
      height,
    )}`;
  } else {
    return `${url}/${Math.round(width)}x${Math.round(height)}`;
  }
}
