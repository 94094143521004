import ReactGA from 'react-ga4';

const threeDsSwitch = (status: string, logger: any): void => {
  switch (status) {
    // Successful 3DSecure
    case 'Y':
      logger.info('successful authentication through 3dsecure challenge', {
        transStatus: status,
      });
      ReactGA.event({
        category: 'Payment',
        action: 'payment_finished',
      });
      ReactGA.event({
        category: 'Payment',
        action: 'payment_auth_succeeded',
      });
      break;
    // Failed 3DSecure
    case 'N':
      logger.info('failed authentication through 3dsecure challenge', {
        transStatus: status,
      });
      ReactGA.event({
        category: 'Payment',
        action: 'payment_auth_failed',
      });
      break;
    // Cancelled 3DSecure
    case 'U':
      logger.info('cancelled authentication through 3dsecure challenge', {
        transStatus: status,
      });
      ReactGA.event({
        category: 'Payment',
        action: 'payment_auth_cancelled',
      });
      break;
    // We don't really care about this.
    case 'N/A':
      logger.info('response from 3dsecure challenge not available', {
        transStatus: status,
      });
      ReactGA.event({
        category: 'Payment',
        action: 'payment_auth_not_available',
      });
      break;
    // Warn about unhandled 3dsecure challenges in our logs.
    default:
      logger.warn('resonse from 3dsecure challenge unhandled', {
        transStatus: status,
      });
      break;
  }
};

export default threeDsSwitch;
