import React, { useState, useEffect, useContext, useRef } from 'react';

import { useBridgeApi } from 'Hooks';
import { Location } from '../../utils/location';
import { Language } from '../../Types';
import { DEFAULT_LOCALE } from '../../actions';
import Config from 'config';
import { useConfig } from 'Components/ConfigProvider';
const LOCALSTORAGE_KEY = 'platform-storage';

export type Device = { token: string; operatingSystem: string; registered: boolean } | null;

export type AnonymousOrder = { id: string; createdAt: string };

export interface StorageData {
  cookieConsent: boolean | null;
  analyticsConsent: boolean | null;
  authToken: string | null;
  temporaryAuthToken: string | null;
  selectedPaymentMethod: string | null;
  enableNotifications: boolean;
  device: Device;
  userLocation: Location | null;
  cartId: string | null;
  serviceType: string | null;
  anonymousOrders: AnonymousOrder[];
  language: Language | null;
  qrOrder: boolean;
  redirectPath: string;
}

/**
 * !!! WHEN ADDING A NEW STORAGE FIELD !!!
 * Also add default data to this constant so that we dont
 * have to do null-checks for missing data everywhere
 */
const DEFAULT_DATA: StorageData = {
  cookieConsent: null,
  analyticsConsent: null,
  authToken: null,
  temporaryAuthToken: null,
  selectedPaymentMethod: null,
  enableNotifications: true,
  device: null,
  userLocation: null,
  cartId: null,
  serviceType: null,
  anonymousOrders: [],
  language: null,
  qrOrder: false,
  redirectPath: '/',
};

type UpdateFunction = (data: Partial<StorageData>) => void;
type Loading = boolean;

// @ts-ignore Invalid initialization, so we can check for provider presence
const Context = React.createContext([] as [StorageData, UpdateFunction, Loading]);

interface Props {
  children: React.ReactNode;
  onChange?: (data: StorageData) => void;
}

export const StorageProvider: React.FunctionComponent<Props> = ({ children, onChange }) => {
  const config = useConfig();
  const [data, setData] = useState<StorageData>(DEFAULT_DATA);
  const [loading, setLoading] = useState(true);
  const api = useBridgeApi();

  const loaded = useRef(false);

  const localStorageKey = config.IS_CLOUD_CONFIG
    ? `${config.COMPANY_ID}+${LOCALSTORAGE_KEY}`
    : LOCALSTORAGE_KEY;

  useEffect(() => {
    if (window.hasNativeWrapper) {
      // If we are using a native wrapper, use native storage
      //api.getStorageData().then((newData) => setData((current) => ({ ...current, ...newData })));
      api.getStorageData().then((newData) => {
        setData({ ...DEFAULT_DATA, ...newData });
        setLoading(false);
      });
    } else {
      // Otherwise, use computer local storage
      const newData = localStorage.getItem(localStorageKey) || '{}';
      const parsedData = JSON.parse(newData);

      setData({ ...DEFAULT_DATA, ...parsedData });
      setLoading(false);
    }

    loaded.current = true;
  }, []);

  /**
   * Updates permanent storage with our settings data
   *
   * @param storageData One or more keys from the storage data interface
   */
  function update(storageData: Partial<StorageData>) {
    if (loading) {
      console.warn(
        '!!! WARNING !!! Trying to update storage before data loading is finished. Possibly overwriting with DEFAULT_DATA.',
      );
    }
    if (!loaded.current) {
      return;
    }

    const newData = {
      ...data,
      ...storageData,
    };

    setData((current) => ({ ...current, ...storageData }));

    if (window.hasNativeWrapper) {
      return api.updateStorage(newData);
    } else {
      /* Store data in local storage for development */
      return new Promise((resolve) => {
        localStorage.setItem(localStorageKey, JSON.stringify(newData));
        resolve(true);
      });
    }
  }

  useEffect(() => {
    if (onChange) {
      onChange(data);
    }
  }, [data]);

  return <Context.Provider value={[data, update, loading]}>{children}</Context.Provider>;
};

export const useStorage = () => {
  const value = useContext(Context);

  if (!value.length) {
    throw new Error('useStorage must be used within a StorageProvider');
  }

  return value;
};
