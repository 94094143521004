import { useEffect, useRef } from 'react';
import {
  useDispatch as useReduxDispatch,
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import { State } from '../reducers';

export { useCurrency } from './useCurrency';
export { useBridgeApi } from './useBridgeApi';
export { useLogger } from './useLogger';

export const useSelector: TypedUseSelectorHook<State> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<any>();

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }

    return () => {};
  }, [delay]);
}
