import styled from 'styled-components';
import { ReactComponent as NavigateRightIcon } from '../../../Assets/Icons/navigate-right.svg';
import TouchableOpacity from '../../../Components/OpacityTouchable';
import Icon from '../../../Components/Icon';

const Item = styled(TouchableOpacity)<{ small: boolean }>`
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 24%);
  width: ${({ small }) => (small ? 204 : 280)}px;
  margin-right: 8px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;
const Thumbnail = styled.div<{ small: boolean }>`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 0 0;
  height: ${({ small }) => (small ? 136 : 184)}px;
  width: auto;
  position: relative;
`;

const Title = styled.div`
  margin: 5px;
  background: rgba(0, 0, 0, 0.12);
  padding: 8px 50px;
`;
const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 24px 16px;
  color: rgb(33, 33, 36);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`;
const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #aaa;
  border-radius: 16px;
  font-weight: normal;
  color: rgb(255, 255, 255);
  font-size: 12px;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  white-space: nowrap;
  height: 32px;
  padding: 8px 12px 8px 12px;

  span:not(:last-child) {
    margin-right: 4px;
  }
`;

type Props = {
  /**
   * small
   */
  small?: boolean;
};

/**
 * Home page PlaceholderItem component
 */
const PlaceholderItem = ({ small = false }: Props) => {
  return (
    <Item small={small}>
      <Thumbnail small={small} />
      <Info>
        <Title />
        <Price>
          <span>----</span>
          <Icon icon={<NavigateRightIcon />} color={'#fff'} size={15} />
        </Price>
      </Info>
    </Item>
  );
};

export default PlaceholderItem;
