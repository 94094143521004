import { useMutation } from '@apollo/client';
import { useConfig } from 'Components/ConfigProvider';
import { useShoppingCart, useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { useStorage } from 'Components/Storage';
import { ACTIVATE_PROMOCODE } from 'Scenes/Cart';
import { ActivatePromoCode, ActivatePromoCodeVariables } from 'Scenes/Cart/__queries__';
import React, { useEffect, useState } from 'react';

/**
 * This component handles passing in promo codes from the URL.
 * Mostly for use in social media campaigns or ads.
 *
 * It stores the code, waits until we have a cart and then tries
 * to apply the code as if the user would do it.
 */
const PromoCodeManager: React.FC = () => {
  const config = useConfig();
  const [storage] = useStorage();
  const [promoCode, setPromoCode] = useState<string | null>(null);
  const cart = useShoppingCart();
  const cartApi = useShoppingCartApi();

  const [activatePromocode] = useMutation<ActivatePromoCode, ActivatePromoCodeVariables>(
    ACTIVATE_PROMOCODE,
  );

  /**
   * Gets promo code from URL
   */
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('p');

    if (code) {
      setPromoCode(code);
    }
  }, []);

  /**
   * Runs the promo code mutation when we have a promo code.
   */
  useEffect(() => {
    if (!storage.cartId || !cart.venueId || !promoCode) {
      return;
    }

    activatePromocode({
      variables: {
        cartId: storage.cartId,
        companyId: config.COMPANY_ID,
        venueId: cart.venueId,
        code: promoCode,
      },
    }).then((result) => {
      if (!result.data?.activatePromoCode?.promoCode) {
        return;
      }

      cartApi.setPromoCode({
        ...result.data.activatePromoCode.promoCode,
        offerType: 'ORDER_DISCOUNT',
      });
    });
  }, [promoCode, storage.cartId, cart.venueId]);

  return null;
};

export default PromoCodeManager;
