import React, { useState, Fragment } from 'react';
import useI18n from 'i18n';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { setAlert, setLoginScreen } from 'actions';
import { useDispatch, useLogger } from 'Hooks';
import TextField from '../../Components/TextField';
import Navbar from '../../Components/Navbar';
import Button from '../../Components/Button';
import { getDeviceSize, DEVICE_SIZES } from '../../utils/deviceSize';

import rules from './rules';
import { useConfig } from 'Components/ConfigProvider';

const Wrapper = styled.div`
  padding: 120px 24px 88px;
`;

const Inner = styled.div`
  @media (min-width: 768px) {
    width: 417px;
    margin: 0 auto;
  }
`;

export const RESET_PASSWORD = gql`
  mutation sendResetPassword($companyId: ID!, $phone: String!) {
    sendResetPassword(companyId: $companyId, phone: $phone) {
      success
      error
      message
    }
  }
`;

export const CONFIRM_RESET_PASSWORD = gql`
  mutation confirmResetPassword(
    $companyId: ID!
    $phone: String!
    $code: String!
    $password: String!
  ) {
    confirmResetPassword(companyId: $companyId, phone: $phone, code: $code, password: $password) {
      success
      error
      message
    }
  }
`;

const defaultFormData = {
  phone: '+46',
  code: '',
  password: '',
  confirmPassword: '',
};

const ForgotPassword = () => {
  const config = useConfig();
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<{ [key: string]: string }>(defaultFormData);
  const [errors, setErrors] = useState({
    phone: '',
    code: '',
    password: '',
    confirmPassword: '',
  });
  const { i18n } = useI18n();
  const logger = useLogger('forgot-password');
  const deviceSize = getDeviceSize();

  const [resetPass] = useMutation(RESET_PASSWORD, {
    variables: {
      companyId: config.COMPANY_ID,
      phone: formData.phone,
    },
  });
  const [confirmResetPass] = useMutation(CONFIRM_RESET_PASSWORD, {
    variables: {
      companyId: config.COMPANY_ID,
      phone: formData.phone,
      code: formData.code,
      password: formData.password,
    },
  });

  const handleGoBack = () => {
    if (currentStep) {
      return setCurrentStep((prev) => (prev ? prev - 1 : prev));
    }
    dispatch(setLoginScreen(''));
  };

  const onClose = () => {
    currentStep ? setCurrentStep(currentStep - 1) : dispatch(setLoginScreen(''));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const getButtonText = () => {
    let text = '';

    if (loading) {
      text = 'General.Loading';
    } else if (currentStep === 2) {
      text = 'Button.SubmitButton';
    } else {
      text = 'Button.Next';
    }
    return i18n(text);
  };

  const isValid = () => {
    const messages: { [key: string]: string } = { ...errors };

    if (rules[currentStep]) {
      rules[currentStep].forEach((field) => {
        let valid = false;
        if (field.name === 'phone') {
          const phoneNumber = parsePhoneNumberFromString(formData[field.name]);
          valid = Boolean(phoneNumber && phoneNumber.isValid());
        } else if (field.regexp) {
          valid = field.regexp.test(formData[field.name]);
        } else if (field.compareWith) {
          valid = formData[field.compareWith] === formData[field.name];
        }

        messages[field.name] = valid ? '' : i18n(field.message);
      });
    }

    setErrors((prev) => ({ ...prev, ...messages }));

    return Object.values(rules[currentStep] ?? []).every((el) => !messages[el.name]);
  };

  const getSubmit = async () => {
    if (!isValid()) {
      return;
    }
    setLoading(true);
    if (currentStep === 0) {
      try {
        const response = await resetPass();
        const { success, error } = response.data.sendResetPassword;
        if (success) {
          setCurrentStep(1);
        } else if (error) {
          logger.warn('ERROR from reset password', {
            error: error,
          });
          throw new Error(error);
        }
      } catch (error) {
        logger.warn('ERROR from reset password', {
          error: error,
        });
        dispatch(
          setAlert({
            title: 'ForgotPassword.Error.Failed',
            description: error.message,
          }),
        );
      }
    } else if (currentStep === 1) {
      setCurrentStep(2);
    } else {
      try {
        const response = await confirmResetPass();
        const { success, error } = response.data.confirmResetPassword;
        if (success) {
          dispatch(setLoginScreen('signin'));
        } else if (error) {
          logger.warn('ERROR from confirm reset password', {
            error: error,
          });
          throw new Error(error);
        }
      } catch (error) {
        logger.warn('ERROR from confirm reset password', {
          error: error,
        });
        dispatch(
          setAlert({
            title: 'ForgotPassword.Error.Failed',
            description: error.message,
          }),
        );
      }
    }
    setLoading(false);
  };

  return (
    <div>
      <Navbar
        title={i18n('ForgotPassword.Title', `${currentStep + 1}`, '3')}
        showClose
        onBack={handleGoBack}
        onClose={onClose}
      />
      <Wrapper>
        <Inner>
          {currentStep === 0 && (
            <TextField
              label={i18n('Input.Phone')}
              placeholder={i18n('Input.Phone.Placeholder')}
              onChange={handleChange}
              value={formData.phone}
              name="phone"
              error={errors.phone}
              type="tel"
            />
          )}
          {currentStep === 1 && (
            <TextField
              label={i18n('ForgotPassword.Code')}
              name="code"
              type="number"
              placeholder={i18n('ForgotPassword.Code.Placeholder')}
              onChange={handleChange}
              value={formData.code}
              error={errors.code}
              autoFocus
            />
          )}
          {currentStep === 2 && (
            <Fragment>
              <p>{i18n('ForgotPassword.NewPassword')}</p>
              <TextField
                type="password"
                label={`${i18n('Input.Password')}:`}
                placeholder={i18n('Input.Password.Placeholder')}
                value={formData.password}
                name="password"
                onChange={handleChange}
                error={errors.password}
                autoFocus
              />
              <TextField
                type="password"
                label={`${i18n('Input.PasswordAgain')}:`}
                placeholder={i18n('Input.PasswordAgain.Placeholder')}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                error={errors.confirmPassword}
              />
            </Fragment>
          )}
          <Button
            text={getButtonText()}
            handleClick={getSubmit}
            floating={deviceSize === DEVICE_SIZES.SMALL}
            width="100%"
          />
        </Inner>
      </Wrapper>
    </div>
  );
};

export default ForgotPassword;
