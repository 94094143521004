import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import useI18n from '../../i18n';
import getLocale from '../../i18n/getLocale';
import dayjs from 'dayjs';
import { allContents, allContentsVariables } from './__queries__';
import getImageUrlWithSize from '../../utils/getImageUrlWithSize';
import { DEVICE_SIZES, getDeviceSize } from '../../utils/deviceSize';

export const GET_ARTICLE = gql`
  query allContents($id: ID!) {
    allContents(filter: { id: $id, deleted: false }) {
      createdAt
      updatedAt
      image {
        file {
          url
        }
      }
      title {
        id
        en
        se
        no
      }
      text {
        id
        en
        se
        no
      }
    }
  }
`;

const Wrapper = styled.div`
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const HeadImageWrapper = styled.div`
  @media (min-width: 768px) {
    padding: 24px 24px 0 24px;
  }

  @media (min-width: 1400px) {
    padding: 24px 0 0 0;
  }
`;

const HeadImage = styled.div<{ image: any }>`
  height: 232px;
  width: 100%;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  position: relative;

  @media (min-width: 768px) {
    width: 436px;
    height: 290px;
  }
`;

const Date = styled.div`
  color: rgb(158, 158, 161);
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 16px;
  margin: 0 0 16px 0;
`;

const Inner = styled.div`
  padding: 24px 24px 56px;

  @media (min-width: 1400px) {
    padding: 24px 0 56px;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  letter-spacing: 0;
  line-height: 36px;
  margin: 0 0 24px 0;
`;

const Content = styled.div`
  h2 {
    font-size: 28px;
    line-height: 1.2;
    margin: 0 0 24px 0;
  }
  h3 {
    font-size: 26px;
    line-height: 1.2;
    margin: 0 0 24px 0;
  }
  h4 {
    font-size: 24px;
    line-height: 1.2;
    margin: 0 0 24px 0;
  }
  h5 {
    font-size: 22px;
    line-height: 1.2;
    margin: 0 0 24px 0;
  }
  h6 {
    font-size: 20px;
    line-height: 1.2;
    margin: 0 0 24px 0;
  }
  p {
    margin: 0 0 24px 0;
  }
`;

/**
 * NewsArticle scene
 */
const NewsArticle = () => {
  const { id } = useParams<{ id: string }>();
  const { i18n } = useI18n();
  const locale = getLocale();
  const deviceSize = getDeviceSize();
  let showUpdated = false;

  const { data } = useQuery<allContents, allContentsVariables>(GET_ARTICLE, {
    variables: {
      id: id!,
    },
  });

  if (!data) return null;

  const { createdAt, updatedAt, title, text, image } = data?.allContents[0];
  const createdDate = dayjs(createdAt).locale(locale);
  const updatedDate = dayjs(updatedAt).locale(locale);

  const diff = updatedDate.diff(createdDate) / 1000;
  if (diff > 43200) showUpdated = true;

  const localeText = i18n(text);
  const imageSize =
    deviceSize === DEVICE_SIZES.SMALL
      ? { width: window.innerWidth * 2, height: 232 * 2 }
      : { width: 436 * 2, height: 290 * 2 };

  return (
    <Wrapper>
      <HeadImageWrapper>
        <HeadImage
          image={getImageUrlWithSize(image.file.url, imageSize.width, imageSize.height)}
        />
      </HeadImageWrapper>
      <Inner>
        <Date>
          {createdDate.format('DD MMMM YYYY')}
          {showUpdated && ', ' + i18n('News.UpdatedAt') + ' ' + updatedDate.format('DD MMMM YYYY')}
        </Date>
        <Title>{i18n(title)}</Title>
        <Content dangerouslySetInnerHTML={{ __html: localeText }} />
      </Inner>
    </Wrapper>
  );
};

export default NewsArticle;
