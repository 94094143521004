import { useShoppingCart } from 'Components/ShoppingCartUniverse';
import useI18n from 'i18n';

const getServiceTypeText = () => {
  const shoppingCart = useShoppingCart();
  const { i18n } = useI18n();
  switch (shoppingCart.serviceType) {
    case 'EAT_HERE':
      return i18n('Cart.ServiceType.EatHere');
    case 'TAKE_AWAY':
      return i18n('Cart.ServiceType.TakeAway');
    default:
      return shoppingCart.serviceType ?? '-';
  }
};

export default getServiceTypeText;
