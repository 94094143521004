import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TextH2 } from 'Components/Text';
import { useSelector } from 'Hooks';
import useI18n from 'i18n';
import getImageUrlWithSize from 'utils/getImageUrlWithSize';
import { ReactComponent as OfferIcon } from 'Assets/Icons/tag.svg';
import { ReactComponent as NavigateRightIcon } from 'Assets/Icons/navigate-right.svg';
import FlatRows from 'Components/FlatRows';
import OfferDialog from './OfferDialog';
import Icon from '../../../Components/Icon';

import { getLoggedOutData_company_defaultOffers } from '../__queries__';
import { getVenue_venue } from '../__queries__';
import { useShoppingCart } from 'Components/ShoppingCartUniverse';
import { useStorage } from 'Components/Storage';

const Wrapper = styled.div`
  padding: 18px 0 0;
  margin-top: 6px;
  background: #f8f8fb;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Title = styled.div`
  color: rgb(117, 117, 120);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 0 24px 0px;

  span {
    padding-left: 8px;
  }
`;

export const Item = styled.div<{ isDisabled: boolean }>`
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: ${({ isDisabled }) => (isDisabled ? 'none' : '0px 2px 8px 0px rgb(0 0 0 / 24%)')};
  height: 72px;
  min-width: 312px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  padding: 8px 16px 8px 8px;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5 !important' : '1')};
  cursor: pointer;

  &:active {
    opacity: 0.3;
  }

  @media (min-width: 768px) {
    transition: transform 0.1s ease-in-out;

    &:hover {
      transform: scale(0.99);
    }
  }
`;

export const Image = styled.div<{ image: string }>`
  background: url('${({ image }) => image}') no-repeat center;
  background-size: cover;
  border-radius: 2px;
  height: 56px;
  width: 84px;
`;

export const Name = styled(TextH2)`
  font-weight: normal;
  margin: 0 8px;
  flex-grow: 1;
`;

const Action = styled.div`
  background: ${({ theme }) => theme.secondary};
  min-height: 32px;
  min-width: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  /**
   * defaultOffers
   */
  defaultOffers: getLoggedOutData_company_defaultOffers[];
  /**
   * venue
   */
  venue?: getVenue_venue;
  /**
   * homeRef
   */
  homeRef: any;
};

/**
 * Offers component
 */
const Offers = ({ defaultOffers, venue, homeRef }: Props) => {
  const [storage] = useStorage();
  const availableOffers = useSelector((state) => state.availableOffers);
  const { i18n } = useI18n();
  const shoppingCart = useShoppingCart();
  const [activeOffer, setActiveOffer] = useState<getLoggedOutData_company_defaultOffers | null>(
    null,
  );
  const [offers, setOffers] = useState<getLoggedOutData_company_defaultOffers[]>([]);
  const [disabled, setDisabled] = useState(false);
  const displayedOffers = storage.authToken ? availableOffers : offers;

  useEffect(() => {
    if (!defaultOffers) return;
    setOffers(
      defaultOffers
        .slice()
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()),
    );
  }, [defaultOffers]);

  const handleOfferClicked = (element: getLoggedOutData_company_defaultOffers) => {
    setActiveOffer(element);
    setDisabled(true);
  };

  useEffect(() => {
    if (!homeRef?.current) return;

    if (activeOffer) {
      homeRef.current.classList.add('modal-open');
    } else {
      setTimeout(() => {
        homeRef?.current?.classList.remove('modal-open');
      }, 400);
    }
  }, [activeOffer]);

  const handleOfferCancel = () => {
    setActiveOffer(null);
  };

  if (!displayedOffers.length) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <Title>
          <Icon icon={<OfferIcon />} color={'#9e9ea1'} size={32} />
          <span>
            {storage.authToken
              ? i18n('Startpage.Offers.LoggedIn.Title')
              : i18n('Startpage.Offers.Title')}
          </span>
        </Title>
        <FlatRows>
          {displayedOffers.map((item) => {
            return (
              <Item
                key={item.id}
                isDisabled={disabled}
                onClick={disabled ? () => null : () => handleOfferClicked(item)}>
                {item.image && (
                  <Image image={getImageUrlWithSize(item.image?.file?.url, 168, 112)} />
                )}
                <Name lines={3}>{i18n(item.nameLang)}</Name>
                {/* <Action>
                  <Icon icon={<NavigateRightIcon />} color={'#fff'} size={15} />
                </Action> */}
              </Item>
            );
          })}
        </FlatRows>
      </Wrapper>

      <OfferDialog activeOffer={activeOffer} onClose={handleOfferCancel} />
    </>
  );
};

export default Offers;
