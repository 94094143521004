import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const ResetURIToHome = () => {
  const history = useHistory();

  const handleMessage = (message: any) => {
    let data = message.data;

    try {
      data = typeof data === 'string' ? JSON.parse(data) : data;
      const uri = data.uri;
      if (uri === '/') {
        history.push('/');
      }
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  const onMessage = (message: any) => {
    if (!message?.data) {
      return;
    }
    handleMessage(message);
  };

  useEffect(() => {
    // Add the event listener for messages passed from app wrapper
    document.addEventListener('message', onMessage);
    window.addEventListener('message', onMessage);

    // To prevent memory leaks, remove all event listeners on demount
    return () => {
      document.removeEventListener('message', onMessage);
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return null;
};

export default ResetURIToHome;
