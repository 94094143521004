import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from '../../../Hooks';

const OrderHistoryNotification = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [message, setMessage] = useState<any>(null);

  const onMessage = (msg: any) => {
    setMessage(msg);
  };

  // handle message
  useEffect(() => {
    if (!message || !user) {
      return;
    }

    let data = message?.data;

    try {
      data = typeof data === 'string' ? JSON.parse(data) : data;
    } catch (e) {
      console.error(e);
    }

    if (data?.orderId) {
      setMessage(null);
      history.push(`/order-history/${data.orderId}`);
    }
  }, [message, user]);

  useEffect(() => {
    // Add the event listener for messages passed from app wrapper
    document.addEventListener('message', onMessage);
    window.addEventListener('message', onMessage);

    // To prevent memory leaks, remove all event listeners on demount
    return () => {
      document.removeEventListener('message', onMessage);
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return null;
};

export default OrderHistoryNotification;
