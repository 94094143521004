import { gql, useQuery } from '@apollo/client';
import { useConfig } from 'Components/ConfigProvider';
import { useShoppingCart } from 'Components/ShoppingCartUniverse';
import { useSelector } from 'Hooks';
import { useCallback, useMemo } from 'react';
import {
  getAppliedMenus,
  getAppliedMenus_getAppliedMenus_results_menu_menuEntries,
} from './__queries__';

export const MENU_QUERY = gql`
  query getAppliedMenus($venueId: String!, $companyId: String!, $serviceType: [SERVICE_TYPE!]!) {
    getAppliedMenus(venueId: $venueId, companyId: $companyId, serviceType: $serviceType) {
      results {
        id
        menu {
          id
          menuEntries {
            id
            price
            instant
            item {
              id
              vatRate
            }
          }
        }
      }
    }
  }
`;

const useEntryFinder = () => {
  const config = useConfig();
  const { serviceType } = useShoppingCart();
  const venue = useSelector((state) => state.venue);

  const menuQuery = useQuery<getAppliedMenus>(MENU_QUERY, {
    variables: {
      venueId: venue?.id,
      companyId: config.COMPANY_ID,
      serviceType: [serviceType],
    },
    skip: !venue,
  });

  const menus = menuQuery.data?.getAppliedMenus?.results?.map((result) => result.menu) || [];

  // Invert the menu for very fast lookup later
  const invertedMenu = useMemo(() => {
    const entries = menus.flatMap((menu) => menu.menuEntries);

    return entries.reduce((obj, entry) => {
      obj[entry.item.id] = entry;

      return obj;
    }, {} as Record<string, getAppliedMenus_getAppliedMenus_results_menu_menuEntries>);
  }, [menuQuery.data]);

  /**
   * Searches the currently active menu for an entry of the item you pass in
   *
   * Primarily used for finding the price of an item
   *
   * @param id The Item ID that you wish to find the price for
   * @returns A MenuEntry or null
   */
  const findEntryFromItem: (
    id: string,
  ) => getAppliedMenus_getAppliedMenus_results_menu_menuEntries | null = useCallback(
    (id: string) => {
      return invertedMenu[id] || null;
    },
    [menuQuery.data],
  );

  return findEntryFromItem;
};

export default useEntryFinder;
