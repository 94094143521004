import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import map from 'lodash/map';
import sum from 'lodash/sum';

import useI18n from 'i18n';
import getLocale from 'i18n/getLocale';
import { useCurrency } from 'Hooks';
import OrderStatus from 'Components/OrderStatus';
import getTexts from 'Scenes/OrderStatus/utils/getTexts';
import { getHistoryOrder_Order_items, getHistoryOrder_Order_venue } from './__queries__';
import { ORDER_STATUS } from '../../../__queries__/globalTypes';
import { VatRates } from '.';
import Button from '../../Components/Button';

const Wrapper = styled.div`
  flex: 1;
  min-height: 100vh;
  padding-bottom: 100px;
  box-sizing: content-box;
  background: #f8f8fb;
  padding-top: ${() => window.headerHeight}px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const StatusWrapper = styled.div`
  background: #212124;
  padding: 8px;
`;
const QRStatusWrapper = styled.div`
  background: #212124;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
`;

const StatusInner = styled.div`
  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const OrderIdWrapper = styled.div`
  padding: 27px 16px 12px 16px;
`;

const OrderIdInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const OrderIdTitle = styled.p`
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 16px;
`;
const QROrderIdTitle = styled.p`
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 16px;
  opacity: 0.6;
`;

const OrderId = styled.strong`
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  text-align: right;
`;
const QROrderId = styled.strong`
  color: rgb(255, 255, 255);
  font-size: 72px;
  font-weight: 600;
  line-height: 64px;
  text-align: right;
`;

const OrderInfo = styled.div`
  background: rgb(238, 238, 241);
  border-radius: 0;
  padding: 12px 24px 11px 24px;
`;

const OrderInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 4px 0;

  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const OrderInfoLabel = styled.p`
  color: rgb(33, 33, 36);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 16px;
`;

const OrderInfoValue = styled.p`
  color: rgb(97, 97, 100);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.24px;
  line-height: 16px;
  text-align: right;
`;

const Never = styled.div`
  flex: 1;
  color: rgb(227, 39, 59);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 16px;
  text-align: right;
`;

const Items = styled.div`
  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const Item = styled.div<{ bottomBorder: boolean }>`
  border-bottom: ${({ bottomBorder }) => (bottomBorder ? 'solid 1px rgb(238, 238, 241)' : 'none')};
  padding-bottom: ${({ bottomBorder }) => (bottomBorder ? '24px' : '0')};
  margin-bottom: ${({ bottomBorder }) => (bottomBorder ? '24px' : '0')};
`;

const ItemInner = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px 0;
`;

const ItemName = styled.h3`
  color: rgb(33, 33, 36);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 20px;
`;

const ItemPrice = styled.p`
  color: rgb(33, 33, 36);
  font-size: 16px;
  letter-spacing: 0.24px;
  line-height: 20px;
  text-align: right;
`;

const Variations = styled.div``;

const Variation = styled.p<{ removed?: boolean }>`
  color: ${({ removed }) => (removed ? '#e3273b' : 'rgb(33, 33, 36)')};
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 20px;
  margin: 0 0 4px 0;
`;

const Center = styled.div`
  padding: 24px 24px 48px 24px;
  background: #ffffff;
`;

const TotalContainer = styled.div`
  padding: 20px 0 0 0;

  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px 0;
`;

const TotalDiscountLabel = styled.strong`
  color: rgb(32, 153, 70);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 20px;
`;

const TotalDiscountValue = styled.p`
  color: rgb(32, 153, 70);
  font-size: 16px;
  letter-spacing: 0.24px;
  line-height: 20px;
  text-align: right;
`;

const TotalLabel = styled.strong`
  color: rgb(33, 33, 36);
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
`;

const TotalValue = styled.p`
  color: rgb(33, 33, 36);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.36px;
  line-height: 24px;
  text-align: right;
`;

const TotalTipsLabel = styled.strong`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 20px;
`;

const TotalTipsValue = styled.p`
  font-size: 16px;
  letter-spacing: 0.24px;
  line-height: 20px;
  text-align: right;
`;

const Divider = styled.div`
  height: 1px;
  margin: 24px 0 0 0;
  background: rgb(224, 224, 227);

  @media (min-width: 992px) {
    max-width: 960px;
    margin: 24px auto 0 auto;
  }
`;

const VatContainer = styled.div`
  padding: 7px 0 0 0;

  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const VatRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 4px 0;
`;

const VatLabel = styled.strong`
  display: block;
  color: rgb(33, 33, 36);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 16px;
`;

const VatValue = styled.p`
  color: rgb(97, 97, 100);
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 16px;
  text-align: right;
`;

const VenueContainer = styled.div`
  padding: 32px 24px 24px 24px;
  background: #f8f8fb;
`;

const VenueInner = styled.div`
  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const VenueName = styled.strong`
  display: block;
  color: rgb(33, 33, 36);
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 16px;
  margin: 0 0 8px 0;
`;

const VenueInfo = styled.div`
  margin: 0 0 24px 0;
`;

const VenueInfoRow = styled.p`
  color: rgb(97, 97, 100);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.24px;
  line-height: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    @media (min-width: 768px) {
      max-width: 400px;
    }
  }
`;

type Props = {
  easyId: string;
  status: ORDER_STATUS;
  estimatedFinishTime: string;
  createdAt: string;
  desiredTime: string;
  sortedOrderItems: { [key: string]: getHistoryOrder_Order_items };
  venue: getHistoryOrder_Order_venue;
  serviceType: string;
  vatRates: VatRates;
  discount: number;
  totalPrice: number;
  disabled: boolean;
  tip?: number;
  openModal: () => void;
  qrOrder: boolean;
};

const HistoryOrderView = ({
  easyId,
  status,
  createdAt,
  desiredTime,
  estimatedFinishTime,
  sortedOrderItems,
  venue,
  serviceType,
  vatRates,
  discount,
  totalPrice,
  openModal,
  disabled,
  qrOrder,
  tip = 0,
}: Props) => {
  const { i18n } = useI18n();
  const locale = getLocale();
  const [currentTime, setCurrentTime] = useState(dayjs().format());
  const { statusText, messageText } = getTexts(
    { status, easyId, estimatedFinishTime, currentTime },
    i18n,
    locale,
  );
  const showStatus = status !== 'REJECTED' && status !== 'COMPLETED';
  const rejected = status === 'REJECTED';
  const { formatAmount } = useCurrency();
  const urlParams = new URLSearchParams(window.location.search);
  const hasTableId = urlParams.has('t');

  // ** UPDATE CURRENT TIME ** Update current time every 10 seconds
  useEffect(() => {
    const intervalId = setInterval(() => setCurrentTime(dayjs().format()), 10000);
    return () => clearInterval(intervalId);
  }, []);

  const getServiceTypeLocale = () => {
    switch (serviceType) {
      case 'TAKE_AWAY':
        return 'Order.TakeAway';
      case 'EAT_HERE':
        return 'Order.EatHere';
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      {!qrOrder && (
        <StatusWrapper>
          <StatusInner>
            {showStatus && (
              <OrderStatus
                status={statusText}
                message={messageText}
                iconColor={'#fff'}
                borderColor="#616164"
                background="transparent"
              />
            )}
          </StatusInner>
          <OrderIdWrapper>
            <OrderIdInner>
              <OrderIdTitle>{i18n('OrderHistory.OrderId')}</OrderIdTitle>
              <OrderId>{easyId}</OrderId>
            </OrderIdInner>
          </OrderIdWrapper>
        </StatusWrapper>
      )}
      <OrderInfo>
        {qrOrder && !hasTableId && (
          <QRStatusWrapper>
            <QROrderIdTitle>{i18n('OrderHistory.OrderId')}</QROrderIdTitle>
            <QROrderId>{easyId}</QROrderId>
          </QRStatusWrapper>
        )}
        {qrOrder ? (
          <OrderInfoRow>
            <OrderInfoLabel>{i18n('Receipt.Restaurant')}</OrderInfoLabel>
            <OrderInfoValue>{venue.name}</OrderInfoValue>
          </OrderInfoRow>
        ) : (
          <OrderInfoRow>
            <OrderInfoLabel>{i18n('OrderHistory.ServiceType')}</OrderInfoLabel>
            <OrderInfoValue>{i18n(getServiceTypeLocale())}</OrderInfoValue>
          </OrderInfoRow>
        )}
        <OrderInfoRow>
          <OrderInfoLabel>{i18n('OrderHistory.Date')}</OrderInfoLabel>
          <OrderInfoValue>
            {dayjs(createdAt).locale(locale).format('YYYY-MM-DD | HH:mm')}
          </OrderInfoValue>
        </OrderInfoRow>
        {desiredTime && (
          <OrderInfoRow>
            <OrderInfoLabel>{i18n('OrderHistory.Date.Scheduled')}</OrderInfoLabel>
            <OrderInfoValue>
              {dayjs(desiredTime).locale(locale).format('YYYY-MM-DD | HH:mm')}
            </OrderInfoValue>
          </OrderInfoRow>
        )}
        {rejected && (
          <OrderInfoRow>
            <Never>{i18n('Order.Status.Rejected')}</Never>
          </OrderInfoRow>
        )}
      </OrderInfo>
      <Center>
        <Items>
          {map(sortedOrderItems, (item) => (
            <Item
              key={item.id}
              bottomBorder={!!item.variations.length || !!item.removedVariations.length}>
              <ItemInner>
                <ItemName>{i18n(item.entry.item.titleLang)}</ItemName>
                <ItemPrice>{formatAmount(item.price.amount)}</ItemPrice>
              </ItemInner>
              <Variations>
                {item.variations.map((variation) => (
                  <Variation key={variation.id}>
                    + {i18n(variation.nameLang || variation.name)}
                  </Variation>
                ))}
                {item.removedVariations.map((variation) => (
                  <Variation removed key={variation.id}>
                    - {i18n(variation.nameLang || variation.name)}
                  </Variation>
                ))}
              </Variations>
            </Item>
          ))}
        </Items>
        <Divider />
        <TotalContainer>
          {discount > 0 && (
            <TotalRow>
              <TotalDiscountLabel>{i18n('OrderHistory.Discount')}</TotalDiscountLabel>
              <TotalDiscountValue>-{formatAmount(discount)}</TotalDiscountValue>
            </TotalRow>
          )}
          {tip > 0 && (
            <TotalRow>
              <TotalTipsLabel>{i18n('OrderHistory.Tips')}</TotalTipsLabel>
              <TotalTipsValue>{formatAmount(tip)}</TotalTipsValue>
            </TotalRow>
          )}
          <TotalRow>
            <TotalLabel>{i18n('OrderHistory.Total')}</TotalLabel>
            <TotalValue>
              {formatAmount(tip ? totalPrice - discount + tip : totalPrice - discount)}
            </TotalValue>
          </TotalRow>
        </TotalContainer>
        <Divider />
        <VatContainer>
          {Object.keys(vatRates).map((rate) => (
            <VatRow key={rate}>
              <VatLabel>
                {rate} {i18n('OrderHistory.VAT')}
              </VatLabel>
              <VatValue>{formatAmount(vatRates[rate])}</VatValue>
            </VatRow>
          ))}
          <VatRow>
            <VatLabel>{i18n('OrderHistory.Total.VAT')}</VatLabel>
            <VatValue>{formatAmount(sum(Object.values(vatRates)))}</VatValue>
          </VatRow>
          <VatRow>
            <VatLabel>{i18n('OrderHistory.Total.Exclude.VAT')}</VatLabel>
            <VatValue>
              {formatAmount(totalPrice - discount - sum(Object.values(vatRates)))}
            </VatValue>
          </VatRow>
        </VatContainer>
      </Center>
      <VenueContainer>
        <VenueInner>
          <VenueName>{i18n('OrderHistory.Store')}</VenueName>
          <VenueInfo>
            <VenueInfoRow>{venue.name}</VenueInfoRow>
            <VenueInfoRow>{venue.phone}</VenueInfoRow>
            <VenueInfoRow>
              {venue.location.name} {venue.location.city}
            </VenueInfoRow>
          </VenueInfo>
          <VenueInfo>
            <VenueInfoRow>
              {i18n('OrderHistory.OrgNr')}: {venue.organizationId}
            </VenueInfoRow>
          </VenueInfo>
        </VenueInner>
      </VenueContainer>
      {!rejected && (
        <ButtonContainer>
          <Button
            handleClick={() => openModal()}
            text={disabled ? i18n('Receipt.Email.Sent') : i18n('Receipt.Email')}
            disabled={disabled}
            width="80%"
          />
        </ButtonContainer>
      )}
    </Wrapper>
  );
};

export default HistoryOrderView;
