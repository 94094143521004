import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import useI18n from '../../i18n';
import { allContents, allContentsVariables } from './__queries__';
import { useConfig } from 'Components/ConfigProvider';

const Wrapper = styled.div`
  padding: 128px 24px 80px 24px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Content = styled.div`
  h2 {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 36px;
    margin: 0 0 24px 0;
  }
  p {
    color: rgb(33, 33, 36);
    margin: 0 0 24px 0;
  }

  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

export const GET_SIDEBAR_CONTENT = gql`
  query allContents($companyId: ID!) {
    allContents(filter: { types: ["SIDEBAR"], company: { id: $companyId }, deleted: false }) {
      id
      slug
      title {
        id
        en
        se
        no
      }
      text {
        id
        en
        se
        no
      }
    }
  }
`;

const SidebarContent = () => {
  const { i18n } = useI18n();
  const { slug } = useParams<{ slug: string }>();
  const config = useConfig();

  const { data } = useQuery<allContents, allContentsVariables>(GET_SIDEBAR_CONTENT, {
    variables: {
      companyId: config.COMPANY_ID,
    },
  });

  const content = data?.allContents || [];
  const page = content.find((c) => c.slug === slug);
  const text = (page?.text && i18n(page.text)) || '';

  return (
    <Wrapper>
      <Content dangerouslySetInnerHTML={{ __html: text }} />
    </Wrapper>
  );
};

export default SidebarContent;
