import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckmarkIcon } from '../../../Assets/Icons/check.svg';
import Icon from '../../../Components/Icon';

const Wrapper = styled.div<{ active?: boolean }>`
  border: 1px solid ${(props) => (props.active ? '#212124' : 'rgb(224, 224, 227)')};
  height: 22px;
  width: 22px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? '#212124' : '#ffffff')};
  margin: 0 16px 0 0;
`;

type Props = {
  /**
   * active
   */
  active: boolean | undefined;
};

/**
 * CheckIcon component
 */
const CheckIcon = ({ active }: Props) => {
  return (
    <Wrapper active={active}>
      <Icon icon={<CheckmarkIcon />} color={'#fff'} size={18} />
    </Wrapper>
  );
};

export default CheckIcon;
