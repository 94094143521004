import React, { useMemo, useEffect } from 'react';
import Config from 'config';
import { gql, useQuery } from '@apollo/client';
import { GetCloudConfig, GetCloudConfig_orderingCloudConfiguration } from './__queries__';
import styled from 'styled-components';
import { datadogLogs } from '@datadog/browser-logs';

// Stylish red error box
const Error = styled.div`
  margin: 80px;
  font-size: 24px;
  color: #333;
  text-align: center;
  background-color: #99002233;
  border-radius: 4px;
  padding: 20px;
`;

const Context = React.createContext(Config);

const GET_CLOUD_CONFIG = gql`
  query GetCloudConfig($cloudConfigId: String, $slug: String) {
    orderingCloudConfiguration(id: $cloudConfigId, slug: $slug) {
      id
      marketplaceId
      userCompanyId
      marketplace {
        id
        venues {
          id
          name
        }
      }
      logoUrl
      theme
      landingPageBackgroundUrl
      enableAnonymousOrders
      enableInvoicing
      enableTipping
      disableComments
      disablePickuptime
      serviceTypes
      greetingText
      navigationInfoText
      showCategoriesInGridView
    }
  }
`;

function mapCloudConfigToLocalConfig(
  cloudConfig: GetCloudConfig_orderingCloudConfiguration,
): Partial<typeof Config> {
  return {
    COMPANY_ID: cloudConfig.userCompanyId,
    LOGO_URL: cloudConfig.logoUrl,
    MARKETPLACE_ID: cloudConfig.marketplaceId,
    MARKETPLACE_VENUES: cloudConfig.marketplace?.venues || [],
    THEME: cloudConfig.theme,
    LANDING_BACKGROUND_URL: cloudConfig.landingPageBackgroundUrl || null,
    ANONYMOUS_PURCHASE: cloudConfig.enableAnonymousOrders,
    USE_INVOICE_CUSTOMERS: cloudConfig.enableInvoicing,
    DISABLE_COMMENTS: cloudConfig.disableComments,
    ENABLE_TIPS: cloudConfig.enableTipping,
    DISABLE_PICKUP_TIME: cloudConfig.disablePickuptime,
    ALLOWED_SERVICE_TYPES: cloudConfig.serviceTypes,
    GREETING: cloudConfig.greetingText,
    NAVIGATION_INFOTEXT: cloudConfig.navigationInfoText,
    MENU_CATEGORIES_GRID: cloudConfig.showCategoriesInGridView,
    ENABLE_PROMOCODES: true,
    SYNC_ETA: true,
    IS_CLOUD_CONFIG: true,
  };
}

const ConfigProvider: React.FC = ({ children }) => {
  /**
   * Deployments can be hosted at either <brand>.shop.baemingo.se or as
   * paths on the main domain like this: shop.baemingo.se/<brand>.
   *
   * If we are hosted as a path, get the brand from the path.
   *
   * It can also be available as the "s" query parameter.
   */

  const slug = useMemo(() => {
    let brand: string | null = null;
    const path = window.location.pathname;
    if (path.startsWith('/')) {
      const parts = path.split('/');
      if (parts.length > 1) {
        brand = parts[1];
      }
    }

    const search = window.location.search;
    if (search.startsWith('?')) {
      const params = new URLSearchParams(search);
      brand = params.get('s') || brand;
    }

    return brand;
  }, [window.location.pathname, window.location.search]);

  /**
   * If we have either a cloud config ID or a pathname brand, fetch the
   * cloud config and update the configuration.
   */
  const hasCloudConfig = Config.CLOUD_CONFIG_ID || slug;
  const cloudQuery = useQuery<GetCloudConfig>(GET_CLOUD_CONFIG, {
    variables: {
      id: Config.CLOUD_CONFIG_ID,
      slug,
    },
    skip: !hasCloudConfig,
  });
  const cloudConfig = cloudQuery.data?.orderingCloudConfiguration;

  useEffect(() => {
    if (!cloudConfig) {
      return;
    }

    datadogLogs.addLoggerGlobalContext('company', Config.DISPLAY_NAME.toLowerCase());
  }, [cloudConfig]);

  // Merge the cloud config with the local config.
  const configuration = useMemo(() => {
    if (!cloudConfig) {
      return Config;
    }

    const merged = {
      ...Config,
      ...mapCloudConfigToLocalConfig(cloudConfig),
    };

    return merged;
  }, [cloudConfig]);

  if (hasCloudConfig && cloudConfig === null) {
    console.error(cloudQuery.error);

    return <Error>This domain has not been set up</Error>;
  }

  // TODO: Show a loading indicator while the cloud config is loading.
  if (hasCloudConfig && !cloudConfig) {
    return null;
  }

  return <Context.Provider value={configuration}>{children}</Context.Provider>;
};

export const useConfig = () => React.useContext(Context);

export default ConfigProvider;
