import useI18n from 'i18n';
import styled from 'styled-components';

import FlatRows from '../../../Components/FlatRows';
import { ReactComponent as ArrowIcon } from 'Assets/Icons/navigate-right.svg';

import OutOfStockOverlay from 'Components/OutOfStockOverlay';

const Wrapper = styled.div`
  background: #f8f8fb;
  margin-top: 6px;
  position: relative;
`;

const Title = styled.div<{ fullWidth?: boolean; isLink?: boolean }>`
  flex: ${({ fullWidth }) => (fullWidth ? 1 : 'none')};
  color: rgb(117, 117, 120);
  font-weight: 600;
  padding: 18px 24px 0;
  cursor: ${({ isLink }) => (isLink ? 'pointer' : 'default')};

  svg {
    width: 12px;
    height: 12px;
    margin-left: 5px;
    fill: rgb(117, 117, 120);
  }

  @media (min-width: 768px) {
    &:hover {
      text-decoration: ${({ isLink }) => (isLink ? 'underline' : 'none')};
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

type Props = {
  /**
   * children
   */
  children: React.ReactNode;
  /**
   * title
   */
  title: string;
  /**
   * categoryId
   */
  categoryId?: string;
  /**
   * toMenu
   */
  toMenu?: (categoryId: string) => void;
  /**
   * Category out of stock
   */
  outOfStock?: boolean;
};

/**
 * Home page row component
 */
const Row = ({ children, title, categoryId, toMenu, outOfStock }: Props) => {
  const { i18n } = useI18n();

  return (
    <Wrapper>
      {outOfStock && <OutOfStockOverlay />}
      <TitleContainer>
        <Title fullWidth>{title}</Title>
        {!outOfStock && categoryId && toMenu && (
          <Title onClick={() => toMenu(categoryId)} isLink={Boolean(toMenu)}>
            {i18n('Home.Categories.More')}
            <ArrowIcon />
          </Title>
        )}
      </TitleContainer>
      <FlatRows>{children}</FlatRows>
    </Wrapper>
  );
};

export default Row;
