import React from 'react';
import styled from 'styled-components';
import Loader from '../Loader';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.14);
  z-index: 100;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

/**
 * FullpageLoader component
 */

const FullpageLoader = () => {
  return (
    <Wrapper>
      <Loader size={80} />
    </Wrapper>
  );
};

export default FullpageLoader;
