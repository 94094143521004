import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import screen from '../../Assets/Images/screen.png';
import AnonymousOrdersList from './Components/AnonymousOrdersList';
import Button from 'Components/Button';
import { ReactComponent as LogoImg } from '../../Assets/Images/logo.svg';
import { ReactComponent as LanguageIcon } from '../../Assets/Icons/language.svg';
import { ServiceType } from 'Scenes/StoreListing';
import Dialog from 'Components/Dialog';
import DialogModal from 'Components/Dialog/Modal';
import useI18n from 'i18n';
import baemingoLogo from '../../Assets/Images/bae_logo.svg';
import { useSelector } from 'Hooks';
import { SOCKET_STATE, useSocket } from 'Components/SocketManager';
import LanguageSelector from './Components/LanguageSelector';
import { Language } from '../../Types';
import { useConfig } from 'Components/ConfigProvider';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div<{
  background: string;
}>`
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  position: relative;
  height: var(--height);
  transition: all 0.2s ease-in-out;

  @media (min-width: 768px) {
    width: 100%;
    background-size: cover;
  }
`;

const TopBar = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  width: 100vw;
  top: env(safe-area-inset-top);
  padding: env(safe-area-inset-top);

  @media (min-width: 768px) {
    width: 100%;
  }
`;

const TopBarInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  @media (min-width: 992px) {
    margin: 0 auto;
    max-width: 960px;
  }
`;

const Logo = styled.div`
  padding-left: 16px;
`;

const LanguageSelect = styled.div`
  width: 88px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;
`;

const LangText = styled.p`
  font-family: Eina 01;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  color: white;
`;

const TableNumberContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33%;
  left: 33%;
  top: 20%;
  width: 33%;
`;

const TableNumberBox = styled.div`
  display: flex;
  border: 2px solid #616164;
  box-sizing: border-box;
  border-radius: 16px;
  height: 96px;
  min-width: 112px;
  background: black;
  color: white;
  justify-content: center;
  align-items: center;
  padding: 14px 16px 18px;
`;

const TableText = styled.div`
  font-family: Eina 01;
  font-size: 54px;
  line-height: 30px;
  font-weight: 600;
`;

const Small = styled.div`
  font-family: Eina 01;
  margin-bottom: 16px;
  font-size: 16px;
  white-space: nowrap;
  color: white;
  font-style: normal;
  font-weight: 600;
  line-height: 128%;
  text-align: center;
  letter-spacing: 0.024em;
`;

const OrderList = styled.div<{ top?: number }>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: 8px;
  right: 8px;
  z-index: 3;

  & > div {
    margin-bottom: 8px;
  }
`;

const CheckoutCard = styled.div`
  background: white;
  position: absolute;
  width: 100vw;
  bottom: 0;
  box-shadow: inset 0px -1px 0px #e0e0e3;
  border-radius: 16px 16px 0 0;
  align-items: center;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  padding: 0 0 16px;
`;

const CardText = styled.div`
  margin-top: 12px;
  font-weight: 600;
  color: #000;
`;
const CardLink = styled.span`
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
`;

const CardFooter = styled.div`
  height: 48px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 24px 0;
`;

const CardFooterText = styled.div`
  font-weight: 300;
  font-size: 8px;
  line-height: 24px;
`;

const BaemingoLogo = styled.img`
  height: 40%;
  padding-left: 8px;
`;

interface Props {
  tableId: string | null;
  tableNumber: string | undefined;
  dark: boolean;
  createCartWithTable: () => void;
  createCartNoTable: (arg0: ServiceType) => void;
  redirectToLogin: () => void;
  serviceTypes: string[];
  modalLanguage: Language | null;
  setModalLanguage: React.Dispatch<React.SetStateAction<Language | null>>;
  showLanguageDialog: boolean;
  supportsPayLater?: boolean;
  onShowLanguageDialog: () => void;
  handleCloseLanguageDialog: () => void;
  onSelectLanguage: () => void;

  chequeIds: string[];
}

const TableLandingView = ({
  tableId,
  tableNumber,
  dark,
  createCartWithTable,
  createCartNoTable,
  redirectToLogin,
  serviceTypes,
  modalLanguage,
  setModalLanguage,
  showLanguageDialog,
  onShowLanguageDialog,
  handleCloseLanguageDialog,
  onSelectLanguage,
  chequeIds,
  supportsPayLater,
}: Props) => {
  const config = useConfig();
  const { socketState } = useSocket();
  const { i18n } = useI18n();
  const user = useSelector((state) => state.user);
  const history = useHistory();

  const disableStart = socketState !== SOCKET_STATE.OPEN;

  const background = config.LANDING_BACKGROUND_URL
    ? `${config.LANDING_BACKGROUND_URL}/${window.innerWidth * 2}x${window.innerHeight * 2}`
    : screen;

  return (
    <Wrapper background={background}>
      <TopBar>
        <TopBarInner>
          <Logo>
            {config.LOGO_URL ? (
              <img
                src={`${config.LOGO_URL}/200x100`}
                style={{
                  height: 50,
                  width: 100,
                }}
              />
            ) : (
              <LogoImg height="50" />
            )}
          </Logo>
          <LanguageSelect onClick={onShowLanguageDialog}>
            <LangText>{i18n('TableLandingPage.Language')}</LangText>
            <LanguageIcon />
          </LanguageSelect>
        </TopBarInner>
      </TopBar>
      {tableId ? (
        <TableNumberContainer>
          <Small>{i18n('TableLandingPage.TableNumber')}</Small>
          <TableNumberBox>
            <TableText>{tableNumber ?? '--'}</TableText>
          </TableNumberBox>
        </TableNumberContainer>
      ) : (
        <AnonymousOrdersList />
      )}
      <CheckoutCard>
        <CardBody>
          {tableId ? (
            <>
              {/*chequeIds.length > 0 && supportsPayLater && (
                <Button
                  text={i18n('PayCheque.SeeBill')}
                  withArrow={false}
                  width="327px"
                  handleClick={() => {
                    /*if (chequeIds.length > 1) {
                      history.push({
                        pathname: `/cheque/` + chequeIds[0] + '/checkout',
                      });
                    } else {
                    history.push({
                      pathname: `/cheque/` + chequeIds[0],
                    });
                    // }
                  }}
                  noTransform={true}
                />
              ) */}
              <Button
                text={i18n('TableLandingPage.Begin')}
                withArrow={false}
                width="327px"
                handleClick={() => createCartWithTable()}
                noTransform={true}
                disabled={disableStart}
              />
            </>
          ) : (
            <>
              {serviceTypes.map((serviceType) => {
                return (
                  <Button
                    key={serviceType}
                    text={
                      serviceType == ServiceType.EAT_HERE
                        ? i18n('StoreList.Modal.EatHere')
                        : i18n('StoreList.Modal.TakeAway')
                    }
                    handleClick={() => createCartNoTable(serviceType as ServiceType)}
                    width="327px"
                    withArrow={false}
                    disabled={disableStart}
                    noTransform={true}
                  />
                );
              })}
            </>
          )}
          {user ? (
            <CardText>
              {i18n('TableLandingPage.LoggedIn.Message')} {user.name}
            </CardText>
          ) : (
            <CardText>
              {i18n('TableLandingPage.Member')}{' '}
              <CardLink onClick={() => redirectToLogin()}>
                {i18n('TableLandingPage.Login')}
              </CardLink>
            </CardText>
          )}
        </CardBody>
        <CardFooter>
          <CardFooterText>Powered by</CardFooterText>
          <BaemingoLogo src={baemingoLogo} />
        </CardFooter>
      </CheckoutCard>
      <Dialog
        visible={showLanguageDialog}
        onClose={() => handleCloseLanguageDialog()}
        dontCloseOnClickAnywhere>
        <DialogModal
          title={i18n('TableLandingPage.SelectYourLanguage')}
          visible={showLanguageDialog}
          buttonText={i18n('TableLandingPage.SelectYourLanguageButton')}
          onButtonClick={onSelectLanguage}>
          <LanguageSelector setLanguage={setModalLanguage} language={modalLanguage} />
        </DialogModal>
      </Dialog>
    </Wrapper>
  );
};

export default TableLandingView;
