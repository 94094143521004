import { Theme, Language } from 'Types';
import config from './configs/current';
import { LanguageSelector } from 'Scenes/TableLandingPage/Components/LanguageSelector';

export interface Config {
  ANALYTICS_ID: string;
  SOCKET_URL: string;
  DISPLAY_NAME: string;
  IS_PRODUCTION: boolean;
  CURRENCY: string;
  ADYEN_CLIENT_TOKEN: string;
  DATADOG_CLIENT_TOKEN: string;
  BAMBORA_API_KEY: string;
  BAMBORA_API_TOKEN: string;
  USE_INVOICE_CUSTOMERS: boolean;
  CLOUD_CONFIG_ID: string | null;
  GREETING: {
    id: string;
    en: string;
    se: string;
  };
  MARKETPLACE_ID: string | null;
  MARKETPLACE_VENUES: Array<{
    id: string;
    name: string;
  }> | null;
  API_URL: string;
  COMPANY_ID: string;
  PACKAGE_SUFFIX: string;
  ANONYMOUS_PURCHASE: boolean;
  DISABLE_COMMENTS: boolean;
  ENABLE_TIPS: boolean;
  CUSTOM_CLIENT_ADYEN_TOKEN: string;
  NAVIGATION_INFOTEXT: {
    id: string;
    en: string;
    se: string;
    es: string;
  };
  ALLOWED_SERVICE_TYPES: string[];
  MENU_CATEGORIES_GRID: boolean;
  PHONE_COUNTRY_CODE: string | null;
  MAX_CATEGORIES: number;
  SYNC_ETA: boolean | null;
  DEFAULT_LANGUAGE: Language | null;
  LANGUAGE_OPTIONS: LanguageSelector[] | null;
  CUSTOM_READY_TEXT: {
    id: string;
    en: string;
    se: string;
    es?: string;
  };
  ENABLE_PROMOCODES: boolean;
  FORCE_3DS: boolean;

  // Design
  LOGO_URL: string | null;
  LANDING_BACKGROUND_URL: string | null;
  THEME: Theme | null;
  DISABLE_PICKUP_TIME: boolean;

  IS_CLOUD_CONFIG?: boolean;
}

const data: Config = JSON.parse(config);

export default data;
