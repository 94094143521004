import type { Theme } from 'Types';
import eggs from './eggs';
import ohpoke from './ohpoke';
import eighteen from './1889';
import baemingo from './baemingo';
import vmalmo from './vmalmo';
import scaniarinken from './scaniarinken';
import alvikstrand from './alvikstrand';
import pomflora from './pomflora';
import thon from './thon';
import vgbg from './vgbg';
import gronastugan from './gronastugan';
import crowdedhouse from './crowdedhouse';
import vbarcelona from './vbarcelona';
import vapiano from './vapiano';
import brogyllen from './brogyllen';
import ubsushi from './ubsushi';
import andysburgers from './andysburgers';
import fattonys from './fattonys';
import ilovepizza from './ilovepizza';
import saigon from './saigon';
import wrapsody from './wrapsody';
import reload from './reload';
import jos from './jos';
import togogo from './togogo';
import portavia from './portavia';
import maha from './maha';
import mittcafe from './mittcafe';
import nycburger from './nycburger';
import vide from './vide';
import fourcorners from './fourcorners';
import dumplingx from './dumplingx';
import dagmars from './dagmars';
import rosegarden from './rosegarden';
import olknacke from './olknacke';
import pascal from './pascal';
import tribowl from './tribowl';
import fabrica from './fabrica';
import viktorsbodega from './viktorsbodega';
import kamilo from './kamilo';
import umami from './umami';
import megazone from './megazone';

const companies: { [key: string]: Theme } = {
  eggs,
  ohpoke,
  1889: eighteen,
  baemingo,
  vmalmo,
  scaniarinken,
  alvikstrand,
  pomflora,
  thon,
  vgbg,
  gronastugan,
  crowdedhouse,
  vbarcelona,
  vapiano,
  brogyllen,
  ubsushi,
  andysburgers,
  fattonys,
  ilovepizza,
  saigon,
  wrapsody,
  reload,
  jos,
  togogo,
  portavia,
  maha,
  mittcafe,
  nycburger,
  vide,
  fourcorners,
  dumplingx,
  dagmars,
  rosegarden,
  olknacke,
  pascal,
  tribowl,
  fabrica,
  viktorsbodega,
  kamilo,
  umami,
  megazone,
};

export default companies;
