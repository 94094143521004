import { Item } from '@baemingo/shopping-cart-events/dist/types';
import { InventoryState } from 'actions';

const getInventoryItem = (itemId: string, inventory: InventoryState) => {
  return inventory.find((invItem: Item) => invItem.itemId === itemId);
};

// Quantity difference between inventory and cart
export const getDiffInventoryCartCount = (
  itemId: string,
  inventoryItemCount: number,
  cartItems: Item[],
) => {
  const cartItemQuantity = cartItems.filter((item) => item.itemId === itemId).length;
  return inventoryItemCount - cartItemQuantity;
};

/**
 *
 * @param itemId Id of the item to check for stock
 * @param inventory Inventory state
 * @param disregardCount Whether to take the count of the item into account
 * @param cartItems Items in shopping cart
 * @param isCart Item check or check items in cart
 * @returns boolean
 */
export const checkOutOfStock = (
  itemId: string,
  inventory: InventoryState,
  disregardCount = false,
  cartItems?: Item[],
  isCart?: boolean,
) => {
  const inventoryItem = getInventoryItem(itemId, inventory);

  if (disregardCount) {
    return inventoryItem ? inventoryItem.outOfStock : false;
  }

  if (inventoryItem?.outOfStock) {
    return true;
  }

  if (typeof inventoryItem?.count === 'number' && cartItems) {
    const diff = getDiffInventoryCartCount(itemId, inventoryItem.count, cartItems);

    if ((isCart && diff < 0) || (!isCart && diff === 0)) {
      return true;
    }
  }

  return false;
};

// The maximum number of items that a user can add based on inventory and cart quantities
export const getItemMaxQuantity = (
  itemId: string,
  inventory: InventoryState,
  cartItems: Item[],
) => {
  if (inventory.length === 0) {
    return undefined;
  }

  const inventoryItem = getInventoryItem(itemId, inventory);

  if (typeof inventoryItem?.count === 'number') {
    return getDiffInventoryCartCount(itemId, inventoryItem.count, cartItems);
  }

  return undefined;
};
