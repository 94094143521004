import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';

import { useSelector } from 'Hooks';
import OrderStatusView from './view';
import { getUserOrders, getUserOrdersVariables, getUserOrders_orders } from './__queries__';

export const ORDER_QUERY = gql`
  query getUserOrders($userId: ID!, $cutoffDate: DateTime!) {
    orders: allOrders(
      filter: {
        createdAt_gt: $cutoffDate
        OR: [
          { user: { id: $userId }, status_in: [PENDING, ACCEPTED, FINALIZING, READY] }
          { user: { id: $userId }, status: REJECTED, confirmed: false }
        ]
      }
    ) {
      id
      status
      estimatedFinishTime
      rejectReason
      easyId
      confirmed
      table {
        id
      }
      venue {
        id
        name
      }
    }
  }
`;

interface Props {
  /**
   * homeRef
   */
  homeRef?: React.RefObject<HTMLDivElement>;
  floating: boolean;
}

/**
 * OrderStatus scene
 */
const OrderStatus: React.FC<Props> = ({ homeRef, floating }) => {
  const [rejectedOrders, setRejectedOrders] = useState<getUserOrders_orders[]>([]);
  const user = useSelector((state) => state.user);
  const { data } = useQuery<getUserOrders, getUserOrdersVariables>(ORDER_QUERY, {
    variables: {
      userId: user?.id || '',
      cutoffDate: moment().subtract(1, 'day').startOf('day').toISOString(),
    },
    fetchPolicy: 'network-only',
    pollInterval: 5000,
  });

  const orders = data?.orders || [];
  const notRejectedOrders = orders.filter((order) => order.status !== 'REJECTED');
  const selectedOrders = user?.table ? notRejectedOrders.slice(-1) : notRejectedOrders;

  useEffect(() => {
    const rejected = orders
      .filter((order) => order.status === 'REJECTED' && !order.confirmed)
      .reverse();

    if (!rejected.length) {
      return;
    }
    setRejectedOrders((prev) => [...rejected, ...prev]);
  }, [orders]);

  const onClose = (orderId: string) => {
    setRejectedOrders((prev) => prev.filter((order) => order.id !== orderId));
  };

  return (
    <OrderStatusView
      homeRef={homeRef}
      orders={selectedOrders}
      rejectedOrders={rejectedOrders}
      onClose={onClose}
      floating={floating}
    />
  );
};

export default OrderStatus;
