import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0 24px;
  border-bottom: 1px solid #e0e0e3;
  height: 64px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  position: relative;
  background-color: #fff;
  cursor: pointer;
`;

const Title = styled.h4`
  font-size: 16px;
  letter-spacing: 0.24px;
  line-height: 20px;
`;

const Text = styled.p`
  font-size: 14px;
  color: #9e9ea1;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 82%;
  overflow: hidden;
`;

const Icon = styled.div`
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
`;

interface Props {
  /**
   * title
   */
  title: string;
  /**
   * text
   */
  text: string;
  /**
   * icon
   */
  icon: React.SVGProps<SVGSVGElement>;
  /**
   * onClick
   */
  onClick: () => void;
}

/**
 * Info component
 */

const Info: React.FC<Props> = ({ title, text, icon, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Title>{title}</Title>
      <Text>{text}</Text>
      <Icon>{icon}</Icon>
    </Wrapper>
  );
};

export default Info;
