import { ModalType } from '..';

const resultCodeSwitch = (
  resultCode: string,
  setShowModal: React.Dispatch<React.SetStateAction<ModalType>>,
  api: any,
  extra?: any,
) => {
  switch (resultCode) {
    case 'Authorised':
      api.vibrate('impactHeavy');
      setShowModal(ModalType.PaymentComplete);
      break;
    case 'ApiError':
      api.vibrate('impactHeavy');
      setShowModal(ModalType.Error);
      break;
    case 'Refused':
      api.vibrate('impactHeavy');
      setShowModal(ModalType.PaymentRefused);
      break;
    case 'Received':
      api.vibrate('impactLight');
      setShowModal(ModalType.PaymentComplete);
      break;
    default:
      api.vibrate('impactHeavy');
      setShowModal(ModalType.PaymentError);
      break;
  }
};

export default resultCodeSwitch;
