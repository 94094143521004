import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import { useBridgeApi, useDispatch, useLogger, useSelector } from 'Hooks';
import * as actions from 'actions';
import { setLoginScreen } from 'actions';
import { useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { HOMEPAGE_QUERY } from 'Scenes/Home';
import { ServiceType } from 'Scenes/StoreListing';
import { useStorage } from 'Components/Storage';

import { GetTableNumber, GetTableNumberVariables } from './__queries__';
import TableLandingView from './view';
import { Language } from '../../Types';
import { useConfig } from 'Components/ConfigProvider';

interface Props {
  tableId: string | null;
  venueId: string;
}

export const TABLE_QUERY = gql`
  query GetTableNumber($tableId: ID!) {
    Table(id: $tableId) {
      id
      code
      supportsPayLater

      cheques {
        id
      }
    }
  }
`;

const TableLandingPage = ({ venueId, tableId }: Props) => {
  const config = useConfig();
  const logger = useLogger('table-landing-page');
  const api = useShoppingCartApi();
  const bridgeApi = useBridgeApi();
  const [storage, updateStorage] = useStorage();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data } = useQuery<GetTableNumber, GetTableNumberVariables>(TABLE_QUERY, {
    variables: {
      tableId: tableId ?? '',
    },
    skip: !tableId,
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
  });
  const chequeIds = data?.Table?.cheques.map((cheque) => cheque.id) || [];
  const tableNumber = data?.Table?.code;
  const serviceTypes = config.ALLOWED_SERVICE_TYPES;
  const [modalLanguage, setModalLanguage] = useState<Language | null>(null);
  const [showLanguageDialog, setShowLanguageDialog] = useState<boolean>(false);

  const createCartWithTable = () => {
    if (!venueId) {
      logger.warn('No venueId found in TableLandingPage', {
        venueId,
        queryParams: window.location.search,
      });
    }
    // Set QR order to true in global state
    dispatch(actions.setqrOrder(true));
    // Set up our cart with tableId as deliverytargetId
    const usePayLater = Boolean(data?.Table.supportsPayLater);
    const id = api.createCart(venueId, 'EAT_HERE', tableId, usePayLater);
    logger.info('Created cart with Table Id', {
      tableId,
      tableNumber,
      venueId,
      cartId: id,
    });

    bridgeApi.vibrate('selection');
    updateStorage({
      serviceType: 'EAT_HERE',
      qrOrder: true,
      cartId: id,
    });

    history.push({
      pathname: `/order/${venueId}`,
      search: `?type=EAT_HERE&cartId=${id}`,
    });
  };

  const redirectToLogin = () => dispatch(setLoginScreen('signin'));

  const createCartNoTable = (serviceType: ServiceType) => {
    if (!venueId) {
      logger.warn('No venueId found in TableLandingPage', {
        venueId,
        queryParams: window.location.search,
      });
    }
    // Set up our cart
    const id = api.createCart(venueId, serviceType, null, false);
    logger.log('Created cart without Table Id', {
      venueId,
      cartId: id,
    });

    updateStorage({
      serviceType,
      qrOrder: true,
      cartId: id,
    });

    history.push({ pathname: `/order/${venueId}`, search: `?type=${serviceType}` });
  };

  // Preload the homepage query
  useQuery(HOMEPAGE_QUERY, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!tableId) {
      return;
    }

    dispatch(actions.setTableId(tableId));
  }, [tableId]);

  const handleShowLanguageDialog = () => {
    setShowLanguageDialog(true);
    setModalLanguage(storage.language);
  };

  const handleSelectLanguage = () => {
    if (storage.language !== modalLanguage) {
      updateStorage({
        language: modalLanguage,
      });
    }

    setShowLanguageDialog(false);
  };

  const handleCloseLanguageDialog = () => {
    setShowLanguageDialog(false);
  };

  return (
    <TableLandingView
      tableId={tableId}
      tableNumber={tableNumber}
      supportsPayLater={data?.Table?.supportsPayLater}
      dark={true}
      createCartWithTable={createCartWithTable}
      createCartNoTable={createCartNoTable}
      redirectToLogin={redirectToLogin}
      serviceTypes={serviceTypes}
      modalLanguage={modalLanguage}
      setModalLanguage={setModalLanguage}
      onSelectLanguage={handleSelectLanguage}
      showLanguageDialog={showLanguageDialog}
      onShowLanguageDialog={handleShowLanguageDialog}
      handleCloseLanguageDialog={handleCloseLanguageDialog}
      chequeIds={chequeIds}
    />
  );
};

export default TableLandingPage;
