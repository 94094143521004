import React from 'react';
import useI18n from '../../i18n';
import styled from 'styled-components';
import NewsItem from '../../Components/NewsItem';

const Wrapper = styled.div`
  padding-top: ${() => window.headerHeight}px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Listing = styled.div`
  padding: 16px 16px 76px;
`;

const ListingInner = styled.div`
  display: flex;
  flex-flow: row wrap;

  a:first-child {
    margin-top: 0;
  }

  a {
    @media (min-width: 992px) {
      margin: 0;
    }

    img {
      @media (min-width: 1200px) {
        width: 100%;
        height: 314px;
      }
    }
  }

  @media (min-width: 992px) {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    max-width: 960px;
  }
`;

interface Props {
  news: any[];
}

const NewsListingView: React.FC<Props> = ({ news }) => {
  const { i18n } = useI18n();

  return (
    <Wrapper>
      <Listing>
        <ListingInner>
          {news.map((item) => (
            <NewsItem
              key={item.id}
              id={item.id}
              image={item.image.file.url}
              title={i18n(item.title)}
              date={item.updatedAt}
            />
          ))}
        </ListingInner>
      </Listing>
    </Wrapper>
  );
};

export default NewsListingView;
