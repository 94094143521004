import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import Button from 'Components/Button';
import { useShoppingCart, useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { useCurrency, useLogger, useSelector } from 'Hooks';
import useI18n from '../../../i18n';

const PaymentWrapper = styled.div<{ withOffer: boolean }>`
  width: 100%;
  padding: 16px;
  padding-bottom: ${({ withOffer }) => (withOffer ? '70px' : '24px')};

  button {
    width: 100%;
  }

  @media (min-width: 992px) {
    margin: 0 auto;
    max-width: 400px;
  }
`;

const ClosedText = styled.p`
  font-size: 14px;
  margin: 24px;

  @media (min-width: 1400px) {
    margin: 24px 0;
  }
`;

interface Props {
  isVenueOpen: {
    loading: boolean;
    open: boolean;
  };
  gettingSession: boolean;
  buttonHandler?: () => void;
  tips: number;
}
const PaymentButton = ({ isVenueOpen, buttonHandler, gettingSession, tips }: Props) => {
  const { loading, open } = isVenueOpen;
  const { isReadyForCheckout, total, items, isFullyPaid } = useShoppingCart();
  const [disabled, setDisabled] = useState(loading);
  const { i18n } = useI18n();
  const { formatAmount } = useCurrency();
  const shoppingCart = useShoppingCart();
  const shoppingCartApi = useShoppingCartApi();
  const userMembership = useSelector((state) => state.userMembership);

  /**
   * For Timeout logger START
   */
  const disabledRef = useRef(false);
  const loadingRef = useRef(true);
  const isReadyForCheckoutRef = useRef(false);
  const gettingSessionRef = useRef(true);
  const openRef = useRef(false);
  const logger = useLogger('payment-button');

  // Update ref for timeout to check if button is still disabled.
  disabledRef.current = disabled;
  isReadyForCheckoutRef.current = isReadyForCheckout;
  gettingSessionRef.current = gettingSession;
  loadingRef.current = loading;
  openRef.current = open;
  /**
   * For Timeout logger END
   */

  useEffect(() => {
    if (!isReadyForCheckout) {
      setDisabled(true);
    } else if (loading || gettingSession) {
      setDisabled(true);
    } else if (open) {
      setDisabled(false);
    }
  }, [isReadyForCheckout, open, loading, gettingSession]);

  // Force consolidate when user enters the cart.
  useEffect(() => {
    const forceConsolidate = setTimeout(() => {
      // Conditions that needs to be met after 5 seconds to force consolidate.
      const shouldForceConsolidate =
        disabledRef.current && openRef.current && !gettingSessionRef.current;
      if (shouldForceConsolidate) {
        // Force consolidate.
        logger.info('Forcing consolidate after 5 seconds.');
        shoppingCartApi.setMembershipDiscount(userMembership?.rate ?? 0);
      }
    }, 5000);
    return () => clearTimeout(forceConsolidate);
  }, []);

  // Check if button is still disabled after 10 seconds.
  useEffect(() => {
    const stillDisabled = setTimeout(() => {
      if (disabledRef.current) {
        logger.warn('Payment button still disabled after 10 seconds', {
          isReadyForCheckout: isReadyForCheckoutRef.current,
          open: openRef.current,
          loading: loadingRef.current,
          gettingSession: gettingSessionRef.current,
        });
      }
    }, 10000);
    return () => clearTimeout(stillDisabled);
  }, []);

  if (!open && !loading) {
    return <ClosedText>{i18n('Cart.Modal.Error.DesiredTime')}</ClosedText>;
  }
  return (
    <>
      {items?.length > 0 && (
        <PaymentWrapper withOffer={false}>
          <Button
            disabled={disabled}
            text={
              disabled
                ? i18n('Cart.Button.Loading')
                : shoppingCart.isPayLaterOrder
                ? i18n('Cart.Button.PayLater')
                : `${i18n('Cart.Button.Pay')} - ${formatAmount(total + tips)}`
            }
            handleClick={buttonHandler}
          />
        </PaymentWrapper>
      )}
    </>
  );
};

export default PaymentButton;
