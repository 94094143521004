import React, { useEffect, useMemo, useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import useI18n from 'i18n';
import MenuView from './view';
import {
  getCategoryInfo,
  getCategoryInfo_category_items,
  getCategoryInfoVariables,
  getSubCategories,
  getSubCategoriesVariables,
  getSubCategories_allCategories_nameLang,
  getAllCategories,
  getAllCategoriesVariables,
  getSubCategories_allCategories_items,
} from './__queries__';
import useEntryFinder from 'Hooks/useEntryFinder';
import { useStorage } from 'Components/Storage';
import { useSelector } from 'Hooks';
import { useConfig } from 'Components/ConfigProvider';

// All company category ids. This is necessary to get subcategories.
export const SHORT_CATEGORIES_INFO_QUERY = gql`
  query getAllCategories($companyId: ID!) {
    allCategories(orderBy: order_DESC, filter: { company: { id: $companyId }, deleted: false }) {
      id
      parentId
    }
  }
`;

// Active parent category data (hero image, title, description, etc) and items if we will display parent category
export const MAIN_CATEGORY_QUERY = gql`
  query getCategoryInfo($categoryId: ID!) {
    category: Category(id: $categoryId) {
      id
      nameLang {
        id
        se
        en
        no
      }
      descriptionLang {
        id
        se
        en
        no
      }
      image {
        id
        file {
          id
          url
        }
      }
      items(filter: { deleted: false }) {
        id
        title
        titleLang {
          id
          se
          en
          es
        }
        order
        defaultPrice
        image {
          id
          file {
            id
            url
          }
        }
      }
    }
  }
`;

// All subcategories for the active parent category
export const SUBCATEGORIES_QUERY = gql`
  query getSubCategories($ids: [ID!]) {
    allCategories(orderBy: order_DESC, filter: { id_in: $ids, deleted: false }) {
      id
      parentId
      nameLang {
        id
        se
        en
        no
      }
      items(filter: { deleted: false }) {
        id
        title
        titleLang {
          id
          se
          en
          no
          es
        }
        order
        defaultPrice
        image {
          id
          file {
            id
            url
          }
        }
      }
    }
  }
`;

export type CategoryItem = {
  item: getCategoryInfo_category_items;
  id: string;
  price: number;
  instant: boolean;
};

export type SubCategoryItem = {
  item: getSubCategories_allCategories_items;
  id: string;
  price: number;
  instant: boolean;
};

export type SubCategoryWithData = {
  id: string;
  isInstant: boolean;
  items: SubCategoryItem[];
  nameLang: getSubCategories_allCategories_nameLang;
  parentId: string;
};

/**
 * Menu scene
 */
const Menu = (props: any) => {
  const params = useParams<{ venue_id?: string }>();
  const venueId = params?.venue_id || '';
  const config = useConfig();
  const { tableId, qrOrder } = useSelector((state) => state);
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { i18n } = useI18n();
  const [storage, _, storageLoading] = useStorage();
  const categoryId = id!;
  const [activeSubCategoryId, setActiveSubCategoryId] = useState<string | null>(null);

  const findEntryFromItem = useEntryFinder();

  useEffect(() => {
    if (storageLoading) {
      return;
    }
    if (storage.serviceType) {
      return;
    }
    if (tableId) {
      push(`/?v=${venueId}&t=${tableId}`);
    } else if (!tableId && qrOrder) {
      push(`/?v=${venueId}`);
    } else {
      push(`/`);
    }
  }, [storageLoading]);

  useEffect(() => {
    if (!categoryId) {
      push(`/order/${venueId}/menu`);
    }
  }, [categoryId]);

  // Get all subcategories for the active parent category
  const [getSubCategories, { data: subCategoriesData }] = useLazyQuery<
    getSubCategories,
    getSubCategoriesVariables
  >(SUBCATEGORIES_QUERY);

  // Get active parent category data (hero image, title, description, etc) and items if we will display parent category
  const { data: categoryInfoData } = useQuery<getCategoryInfo, getCategoryInfoVariables>(
    MAIN_CATEGORY_QUERY,
    {
      variables: {
        categoryId,
      },
    },
  );

  // Get all company category ids. This is necessary to get subcategories.
  const { data: allCategoriesData, loading: allCategoriesLoading } = useQuery<
    getAllCategories,
    getAllCategoriesVariables
  >(SHORT_CATEGORIES_INFO_QUERY, {
    variables: {
      companyId: config.COMPANY_ID,
    },
  });

  const subCategories = useMemo(() => {
    return allCategoriesData?.allCategories.filter((category) => category.parentId === categoryId);
  }, [allCategoriesData]);

  useEffect(() => {
    if (props && categoryInfoData?.category?.nameLang) {
      const title = i18n(categoryInfoData.category.nameLang);
      props.changeTitle(title);
    }
  }, [props, categoryInfoData]);

  useEffect(() => {
    if (subCategories && subCategories.length > 0) {
      setActiveSubCategoryId(subCategories[0].id);
    }
  }, [subCategories]);

  useEffect(() => {
    if (allCategoriesLoading) {
      return;
    }

    if (subCategories && subCategories.length > 0) {
      const ids = subCategories.map((subCategory) => subCategory.id);

      getSubCategories({
        variables: {
          ids,
        },
      });
    }
  }, [subCategories, allCategoriesLoading]);

  const categoryItems: CategoryItem[] = useMemo(() => {
    // Get the full item contents, and filter any items which aren't in this menu
    return (categoryInfoData?.category?.items || [])
      .filter((item) => findEntryFromItem(item.id))
      .map((item) => ({
        ...findEntryFromItem(item.id)!,
        item,
      }))
      .sort((a, b) => {
        if (i18n(a.item.titleLang) === i18n(b.item.titleLang)) {
          return 0;
        }
        return i18n(a.item.titleLang) > i18n(b.item.titleLang) ? 1 : -1;
      })
      .sort((a, b) => {
        if (!a.item.order && !b.item.order) {
          return 0;
        }
        return a.item.order < b.item.order ? 1 : -1;
      });
  }, [categoryInfoData]);

  // Data for displaying subcategories
  const subCategoriesWithData: SubCategoryWithData[] | undefined = useMemo(() => {
    return subCategoriesData?.allCategories
      .map((category) => {
        const items = category.items
          .filter((item) => findEntryFromItem(item.id))
          .map((item) => ({
            ...findEntryFromItem(item.id)!,
            item,
          }))
          .sort((a, b) => {
            if (i18n(a.item.titleLang) === i18n(b.item.titleLang)) {
              return 0;
            }
            return i18n(a.item.titleLang) > i18n(b.item.titleLang) ? 1 : -1;
          })
          .sort((a, b) => {
            if (!a.item.order && !b.item.order) {
              return 0;
            }
            return a.item.order < b.item.order ? 1 : -1;
          });
        return {
          ...category,
          isInstant: items.some((item) => item.instant),
          items,
        };
      })
      .filter((category) => category.items.length > 0);
  }, [subCategoriesData]);

  if (!categoryInfoData?.category) {
    return null;
  }

  return (
    <MenuView
      categoryInfo={categoryInfoData?.category}
      categoryId={categoryId!}
      activeSubCategoryId={activeSubCategoryId}
      setActiveSubCategoryId={setActiveSubCategoryId}
      items={categoryItems}
      subCategoriesWithData={subCategoriesWithData}
    />
  );
};

export default Menu;
