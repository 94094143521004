import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';
import useI18n from '../../i18n';
import { ReactComponent as NavigateRightIcon } from '../../Assets/Icons/navigate-right.svg';
import { Order } from './index';
import { useCurrency } from 'Hooks';
import getLocale from '../../i18n/getLocale';
import Icon from '../../Components/Icon';

const Wrapper = styled.div`
  background: #f8f8fb;
  padding-top: ${() => window.headerHeight}px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  flex: 1;
`;

const Inner = styled.div`
  @media (min-width: 992px) {
    margin: 0 auto;
    max-width: 960px;
  }
`;

const Item = styled(Link)`
  display: flex;
  align-items: center;
  border-bottom: solid 1px #e0e0e3;
  padding: 12px 24px 15px 24px;
  background: #ffffff;
  -webkit-tap-highlight-color: transparent;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Info = styled.div`
  flex: 1;
  padding: 0 10px 0 0;
`;

const Title = styled.h2`
  color: rgb(33, 33, 36);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 20px;

  @media (min-width: 1200px) {
    font-size: 17px;
    line-height: 21px;
  }
`;

const Date = styled.div`
  color: rgb(158, 158, 161);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.24px;
  line-height: 16px;
`;

const Price = styled.div`
  color: #212124;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.24px;
  line-height: 20px;
  text-align: right;

  @media (min-width: 1200px) {
    font-size: 17px;
    line-height: 21px;
  }
`;

const Rejected = styled.span`
  color: rgb(227, 39, 59);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.24px;
  line-height: 20px;
  text-align: right;

  @media (min-width: 1200px) {
    font-size: 17px;
    line-height: 21px;
  }
`;

const IconWrapper = styled.div`
  margin-left: 8px;
`;

type Props = {
  orders: Order[];
};

const HistoryListingView = ({ orders }: Props) => {
  const { i18n } = useI18n();
  const locale = getLocale();
  const location = useLocation();
  const { formatAmount } = useCurrency();

  return (
    <Wrapper>
      <Inner>
        {orders.map((order: any) => (
          <Item
            to={{
              pathname: `/order-history/${order.id}/`,
              state: { background: location },
            }}
            key={order.id}
            onClick={() => {}}>
            <Info>
              <Title>{order.title}</Title>
              <Date>{dayjs(order.date).locale(locale).format('MMM DD, YYYY, HH:mm')}</Date>
            </Info>
            {order.status === 'REJECTED' ? (
              <Rejected>{i18n('Order.Status.Rejected')}</Rejected>
            ) : (
              <Price>{formatAmount(order.amount)}</Price>
            )}
            <IconWrapper>
              <Icon icon={<NavigateRightIcon />} color={'#212124'} size={24} />
            </IconWrapper>
          </Item>
        ))}
      </Inner>
    </Wrapper>
  );
};

export default HistoryListingView;
