import React from 'react';
import styled from 'styled-components';
import Button from 'Components/Button';
import TextField from 'Components/TextField';

const Wrapper = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: #fff;
  border-radius: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 150px;
`;

const Title = styled.h2`
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 32px;
  text-align: center;
`;
const Description = styled.p<{ centerText: boolean }>`
  margin: 16px 0;
  text-align: ${({ centerText }) => (centerText ? 'center' : 'left')};
`;

interface Props {
  /**
   * title
   */
  title: string;
  /**
   * text
   */
  text?: string;
  /**
   * buttonText
   */
  buttonText: string;
  /**
   * visible
   */
  visible: boolean;
  /**
   * onButtonClick
   */
  onButtonClick: (e: any) => void;
  /**
   * centerText
   */
  centerText?: boolean;
  /**
   * extraButton
   */
  extraButton?: boolean;
  /**
   * extraButtonText
   */
  extraButtonText?: string;
  /**
   * input
   */
  input?: boolean;
  /**
   * val
   */
  val?: string;
  /**
   * onChange
   */
  onChange?: (arg0: any) => void;
  /**
   * error
   */
  error?: string;
  /**
   * children
   */
  children?: React.ReactNode;
}

/**
 * DialogModal component
 */

const DialogModal: React.FC<Props> = ({
  title,
  text,
  buttonText,
  visible,
  onButtonClick,
  centerText,
  extraButton,
  extraButtonText,
  input,
  val,
  onChange,
  error,
  children,
}) => {
  return (
    <Wrapper visible={visible}>
      <Title>{title}</Title>
      {children ? (
        children
      ) : input && onChange ? (
        <TextField
          error={error}
          value={val}
          onChange={onChange}
          type="email"
          height="12px"
          marginTop="16px"
        />
      ) : (
        <Description centerText={Boolean(centerText)}>{text}</Description>
      )}
      {extraButton ? (
        <ButtonContainer>
          <Button text={buttonText} handleClick={onButtonClick} />
          <Button text={extraButtonText ?? ''} handleClick={onButtonClick} />
        </ButtonContainer>
      ) : (
        <Button width="80%" text={buttonText} handleClick={onButtonClick} />
      )}
    </Wrapper>
  );
};

export default DialogModal;
