import styled from 'styled-components';

export const TextH2 = styled.h2<{ lines?: number }>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines = 1 }) => lines || 1};
  -webkit-box-orient: vertical;

  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 17px;
`;

export const TextSpan = styled.span<{ lines?: number }>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines = 1 }) => lines || 1};
  -webkit-box-orient: vertical;
`;
