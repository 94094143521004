const translations: {
  [key: string]: {
    en: string | any;
    se: string | any;
    es: string | any;
  };
} = {
  'Landingpage.OrderFood': {
    en: 'Order food',
    se: 'Beställ mat',
    es: 'Ver menu y pedir',
  },
  'Landingpage.Membership': {
    en: 'Membership',
    se: 'Medlemskap',
    es: 'Membresía',
  },
  'Landingpage.MemberCard.Title': {
    en: (company: string) => `${company} membership card`,
    se: (company: string) => `${company} medlemskort`,
    es: (company: string) => `${company} tarjeta de membresía`,
  },
  'Landingpage.MemberCard.Text': {
    en: 'Receive points and get offers',
    se: 'Samla poäng och få erbjudanden',
    es: 'Recibe puntos y obtén ofertas',
  },
  'Landingpage.MemberCard.Hello': {
    en: (name: string) => `Hello, ${name}`,
    se: (name: string) => `Hej, ${name}`,
    es: (name: string) => `Hola, ${name}`,
  },
  'Landingpage.Membership.Level': {
    en: 'Your membership level',
    se: 'Din medlemsnivå',
    es: 'Tu nivel de membresía',
  },
  'Landingpage.Membership.Text': {
    en: 'Now you can order in our app and receive points.',
    se: 'Nu kan du handla i vår app och samla poäng.',
    es: 'Ahora puedes ordenar en nuestra app y recibir puntos.',
  },
  'Landingpage.Readmore': {
    en: (topic: string) => `Read more about ${topic}`,
    se: (topic: string) => `Läs mer om ${topic}`,
    es: (topic: string) => `Leer más sobre ${topic}`,
  },
  'Landingpage.News': {
    en: 'News',
    se: 'Nyheter',
    es: 'Noticia',
  },
  'Landingpage.FindOnInstagram': {
    en: 'We are on Instagram, follow us',
    se: 'Vi finns på instagram, följ oss',
    es: 'Estamos en Instagram, síguenos',
  },
  'TableLandingPage.Language': {
    en: 'English',
    se: 'Svenska',
    es: 'Español',
  },
  'TableLandingPage.TableNumber': {
    en: 'Table no.',
    se: 'Bordsnummer',
    es: 'N° de mesa',
  },
  'TableLandingPage.LoggedIn.Message': {
    en: 'Welcome, ',
    se: 'Välkommen,',
    es: 'Bienvenido, ',
  },
  'TableLandingPage.Member': {
    en: 'Already a regular?',
    se: 'Redan en Stammis?',
    es: '¿Ya eres cliente?',
  },
  'TableLandingPage.Login': {
    en: 'Log in here!',
    se: 'Logga in här!',
    es: 'Inicia tu sesión',
  },
  'TableLandingPage.Welcome': {
    en: 'Welcome!',
    se: 'Välkommen!',
    es: 'Bienvenido',
  },
  'TableLandingPage.Begin': {
    en: 'Start ordering',
    se: 'Starta beställning',
    es: 'Comenzar pedido',
  },
  'TableLandingPage.SelectYourLanguage': {
    en: 'Choose language',
    se: 'Välj språk',
    es: 'Elige tu idioma',
  },
  'TableLandingPage.SelectYourLanguageButton': {
    en: 'Choose',
    se: 'Välj',
    es: 'Seleccione',
  },
  'Startpage.Offers.Title': {
    en: 'Offers',
    se: 'Erbjudanden',
    es: 'Ofertas',
  },
  'Startpage.Offers.LoggedIn.Title': {
    en: 'Active Offers',
    se: 'Aktiva Erbjudanden',
    es: 'Ofertas Activos',
  },
  'Startpage.Offers.PopUpUndo': {
    en: 'Undo',
    se: 'Ångra',
    es: 'Deshacer',
  },
  'Startpage.LatestOrder.Title': {
    en: 'Your latest orders',
    se: 'Dina senaste beställningar',
    es: 'Tus últimos pedidos',
  },
  'Startpage.Popular.Title': {
    en: 'Popular',
    se: 'Populära',
    es: 'Popular',
  },
  'Startpage.Button.Start': {
    en: 'Start',
    se: 'Start',
    es: 'Empezar',
  },
  'Startpage.Button.Menu': {
    en: 'Menu',
    se: 'Meny',
    es: 'Menú',
  },
  'Startpage.Button.Cart': {
    en: 'Cart',
    se: 'Varukorg',
    es: 'Tu Pedido',
  },
  'Startpage.Offer.Modal.Button': {
    en: 'Continue',
    se: 'Gå vidare',
    es: 'Continuar',
  },
  'Startpage.ServiceType.Modal.Title': {
    en: 'Select Service Type',
    se: 'Välj Servicetyp',
    es: 'Seleccione el tipo de servicio',
  },
  'Startpage.ServiceType.Modal.Text': {
    en: 'Do you want to eat here or take away?',
    se: 'Vill du äta här eller ta med?',
    es: '¿Quieres comer aquí o para llevar?',
  },
  'StampCard.Details.Description': {
    en: 'The discount is automatically deducted at checkout when you have filled in all stamps.',
    se: 'Rabatten dras av automatiskt i kassan när du har fyllt i alla stämplar.',
    es:
      'El descuento se deduce automáticamente al finalizar la compra cuando haya completado todos los sellos.',
  },
  'StampCard.Finish': {
    en: 'Congratulations! You’ve unlocked a new offer!',
    se: 'Grattis! Du har låst upp ett nytt erbjudande!',
    es: '¡Felicidades! ¡Has desbloqueado una nueva oferta!',
  },
  'Push.Title': {
    en: 'Do not miss out!',
    se: 'Missa inget!',
    es: '¡No te lo pierdas!',
  },
  'Push.Text': {
    en: 'Enable push notifications so you never miss an offer or news.',
    se: 'Aktivera pushnotiser så att du aldrig går mist om ett erbjudande eller en nyhet.',
    es: 'Habilite las notificaciones push para que nunca te pierdas una oferta o noticia.',
  },
  'Push.Button': {
    en: 'Ok',
    se: 'Ok',
    es: 'Bien',
  },
  'Navigation.Welome': {
    en: 'Welcome.',
    se: 'Välkommen.',
    es: 'Bienvenido.',
  },
  'Navigation.InfoText': {
    en: (company: string) => `Sign in or create an account for ${company}.`,
    se: (company: string) => `Logga in eller skapa ett ${company} konto.`,
    es: (company: string) => `Iniciar sesión o crear una cuenta para ${company}.`,
  },
  'Navigation.MembershipLevel': {
    en: 'Membership level',
    se: 'Medlemsnivå',
    es: 'Nivel de membresía',
  },
  'Navigation.CreateAccount': {
    en: 'Create account',
    se: 'Skapa konto',
    es: 'Crear cuenta',
  },
  'Navigation.Signin': {
    en: 'Sign in',
    se: 'Logga in',
    es: 'Inicia sesión',
  },
  'Navigation.Home': {
    en: 'Home',
    se: 'Hem',
    es: 'Home',
  },
  'Navigation.OrderFood': {
    en: 'Order food',
    se: 'Beställ mat',
    es: 'Pedir comida',
  },
  'Navigation.OrderHistory': {
    en: 'Order history',
    se: 'Orderhistorik',
    es: 'Historial de pedidos',
  },
  'Navigation.InvoiceCustomer': {
    en: 'Sign up for invoicing',
    se: 'Bli företagskund',
    es: 'Registrarse para facturación',
  },
  'Navigation.PaymentMethods': {
    en: 'Payment methods',
    se: 'Betalmedel',
    es: 'Forma de pago',
  },
  'Navigation.MembershipCard': {
    en: 'Membership',
    se: 'Medlemskort',
    es: 'Membresía',
  },
  'Navigation.News': {
    en: 'News',
    se: 'Nyheter',
    es: 'Noticias',
  },
  'Navigation.ActivateCode': {
    en: 'Activate code',
    se: 'Aktivera kod',
    es: 'Activar código',
  },
  'Navigation.Support': {
    en: 'Support',
    se: 'Support',
    es: 'Soporte',
  },
  'Navigation.Signout': {
    en: 'Sign out',
    se: 'Logga ut',
    es: 'Cerrar sesión',
  },
  'Navigation.Version': {
    en: 'Version',
    se: 'Version',
    es: 'Versión',
  },
  'Session.Error': {
    en: 'Something went wrong, please try again.',
    se: 'Något har gått fel, vänligen försök igen.',
    es: 'Algo salió mal, por favor inténtalo de nuevo.',
  },
  'Signup.Title': {
    en: 'Create account',
    se: 'Skapa konto',
    es: 'Crear cuenta',
  },
  'Signup.Step': {
    en: '/',
    se: 'av',
    es: 'de',
  },
  'Signup.Name': {
    en: 'Name',
    se: 'Namn',
    es: 'Nombre',
  },
  'Signup.Name.Placeholder': {
    en: 'Enter your name',
    se: 'Fyll i ditt namn här',
    es: 'Introduce tu nombre',
  },
  'Signup.Phone': {
    en: 'Phone number',
    se: 'Mobilnummer',
    es: 'Número de móvil',
  },
  'Signup.Phone.Placeholder': {
    en: 'Validation of your account',
    se: 'Validering av ditt konto',
    es: 'Validación de su cuenta',
  },
  'Signup.Email': {
    en: 'Email',
    se: 'Mailadress',
    es: 'Correo electrónico',
  },
  'Signup.Email.Placeholder': {
    en: 'Name@email.com',
    se: 'Namn@email.se',
    es: 'Nombre@email.es',
  },
  'Button.Next': {
    en: 'Next',
    se: 'Nästa',
    es: 'Próximo',
  },
  'Button.SubmitButton': {
    en: 'Complete',
    se: 'Slutför',
    es: 'Terminar',
  },
  'Signup.Discount': {
    en: 'Discount code',
    se: 'Rabattkod',
    es: 'Código de descuento',
  },
  'Signup.Discount.Placeholder': {
    en: 'Enter discount code',
    se: 'Ange eventuell rabattkod',
    es: 'Introduzca el código de descuento',
  },
  'Signup.Discount.Infotext': {
    en: 'Here you can enter a discount code for exclusive memberships. Not required.',
    se: 'Här kan du ange rabattkod för exklusiva medlemskap. Ej obligatoriskt.',
    es: 'Aquí puede ingresar un código de descuento para membresías exclusivas. No es necesario.',
  },
  'SignUp.Error.RegistrationFailed': {
    en: 'Registration failed',
    se: 'Registrering misslyckades',
    es: 'Error de registro',
  },
  'SignUp.Step.Title': {
    en: (step: string, nrOfSteps: string) => `Create account ${step} of ${nrOfSteps}`,
    se: (step: string, nrOfSteps: string) => `Skapa konto ${step} av ${nrOfSteps}`,
    es: (step: string, nrOfSteps: string) => `Crear cuenta ${step} de ${nrOfSteps}`,
  },
  'VerifyAccount.Title': {
    en: 'Verify your account',
    se: 'Verifiera ditt konto',
    es: 'Verifica tu cuenta',
  },
  'VerifyAccount.Text': {
    en: 'We have sent an SMS to your phone number with a code. Enter the code here:',
    se: 'Vi har skickat ett SMS till ditt mobilnummer med en kod. Skriv in koden här:',
    es: 'Hemos enviado un SMS a su número de teléfono con un código. Ingrese el código aquí:',
  },
  'General.Loading': {
    en: 'Loading...',
    se: 'Laddar...',
    es: 'Cargando...',
  },
  'General.OutOfStock': {
    en: 'Out of stock',
    se: 'Slut för dagen',
    es: 'Fuera de stock',
  },
  'General.LimitStock': {
    en: "We don't have more in stock",
    se: 'Vi har inte mer i lager',
    es: 'No tenemos más en stock',
  },
  'VerifyAccount.ButtonText': {
    en: 'Verify',
    se: 'Verifiera',
    es: 'Verificar',
  },
  'VerifyAccount.NewCode': {
    en: 'Send a new code',
    se: 'Skicka en ny kod',
    es: 'Enviar un nuevo código',
  },
  'VerifyAccount.Modal.CodeSent': {
    en: 'Code sent',
    se: 'Kod skickad',
    es: 'Código enviado',
  },
  'VerifyAccount.Modal.Text': {
    en: 'A new code has been sent to your phone.',
    se: 'En ny kod har skickats till din telefon.',
    es: 'Se ha enviado un nuevo código a tu teléfono.',
  },
  'VerifyAccount.Modal.ButtonText': {
    en: 'Ok',
    se: 'Ok',
    es: 'De acuerdo',
  },
  'VerifyAccount.Error.InvalidCode': {
    en: 'Invalid activation code',
    se: 'Ogiltig aktiveringskod',
    es: 'Código de activación no válido',
  },
  'InvoiceSignup.Title': {
    en: 'Business Account',
    se: 'Företagskonto',
    es: 'Cuenta empresarial',
  },
  'InvoiceSignup.RegisterFirst': {
    en:
      'In order to apply for a business account you must first register. Click the button below to continue.',
    se:
      'För att ansöka om ett företagskonto måste du först registrera dig. Klicka på knappen nedan för att fortsätta.',
    es:
      'Para solicitar una cuenta empresarial, primero debe registrarse. Haga clic en el botón a continuación para continuar.',
  },
  'InvoiceSignup.Terms': {
    se: `VILLKOR FÖR FAKTURERING
Genom att skicka din ansökan godkänner du följande villkor för fakturabetalning. Sedvanlig kreditprövning kan göras.

Vi skickar en samlingsfaktura 1 gång per vecka. Det innebär att vi varje måndag sammanställer en faktura som innehåller alla köp gjorda föregående vecka. Betalningsvillkor är 30 dagar.

Vi förbehåller oss rätten att stänga kontot och möjlighet till fakturering om krediten missbrukas.`,
    en: `TERMS FOR INVOICING
By submitting your application you accept the following terms for invoicing. Standard credit checks may be made.

We send a consolidated invoice once a week. This means that we compile an invoice every Monday that contains all purchases made the previous week. Payment terms are 30 days.

We reserve the right to close the account and the possibility of invoicing if the credit is misused.`,

    es: `TÉRMINOS PARA FACTURACIÓN

Al enviar su solicitud, acepta los siguientes términos para la facturación. Se pueden realizar controles de crédito estándar.

Enviamos una factura consolidada una vez por semana. Esto significa que compilamos una factura todos los lunes que contienen todas las compras realizadas la semana anterior. Los términos de pago son de 30 días.

Nos reservamos el derecho de cerrar la cuenta y la posibilidad de facturación si el crédito se utiliza de manera indebida.`,
  },
  'InvoiceSignup.InfoText': {
    en:
      'Fill out the form below to apply for a business account. We will respond with a confirmation after we have verified your details.',
    se:
      'Fyll i formuläret nedan för att ansöka om ett företagskonto. Vi kommer att svara med en bekräftelse efter att vi har verifierat dina uppgifter.',
    es:
      'Complete el siguiente formulario para solicitar una cuenta empresarial. Le responderemos con una confirmación después de haber verificado sus datos.',
  },
  'Error.ERROR_FAIL_VERIFY_PHONE': {
    en: 'The code you provided was not correct. Please try again.',
    se: 'Koden du angav var inte korrekt. Var vänlig försök igen.',
    es: 'El código que proporcionaste no es correcto. Inténtelo de nuevo.',
  },
  'Error.ERROR_USER_IS_REQUIRED': {
    en: 'You have to be logged in to perform this action.',
    se: 'Du måste vara inloggad för att genomföra detta',
    es: 'Debes haber iniciado sesión para realizar esta acción.',
  },
  'Error.ERROR_INVALID_CREDENTIALS': {
    en: 'The phone number or password you provided is not correct',
    se: 'Telefonnumret eller lösenordet du angivit stämmer inte',
    es: 'El número de teléfono o la contraseña que proporcionó no es correcto',
  },
  'Error.USER_ALREADY_EXISTS': {
    en: 'This user already exists. Please try again with another phone number.',
    se: 'Användaren existerar redan. Vänligen försök igen med ett annat telefonnummer.',
    es: 'Este usuario ya existe. Inténtelo de nuevo con otro número de teléfono.',
  },
  'Error.UNEXPECTED_ERROR_OCCURRED': {
    en: 'Something went wrong, please try logging in again',
    se: 'Något har gått fel, vänligen försök logga in igen',
    es: 'Algo salió mal, intenta iniciar sesión de nuevo',
  },
  'Error.ERROR_USER_ALREADY_EXISTS': {
    en: 'This user already exists. Please try again with another phone number.',
    se: 'Användaren existerar redan, försök igen med ett annat telefonnummer',
    es: 'Este usuario ya existe. Inténtelo de nuevo con otro número de teléfono.',
  },
  'Error.ERROR_NOT_VALID_PHONE_NUMBER': {
    en:
      'The phone number you entered is not valid. Please entered a valid phone number. It should start with a "+" and a country code.',
    se:
      'Telefonnumret du angivit är inte giltigt. Det måste börja med ett plustecken och din landskod (t.ex. +46)',
    es:
      'El número de teléfono que ingresó no es válido. Introduzca un número de teléfono válido. Debe comenzar con un "+" y un código de país.',
  },
  'Error.PAYMENT_DECLINED_CREDIT_CARD_FUNDS': {
    en: 'We could not handle your payment. Please verify your balance.',
    se: 'Medges Ej. Vänligen försök igen med en annan betalningsmetod.',
    es: 'No pudimos aceptar su pago. Por favor, verifique su saldo.',
  },
  'Error.HANDLE_ORDER_AFTER_PAYMENT_ERROR': {
    en:
      'An error occurred, the money that has been withdrawn will be refunded within 3 banking days. Please try again.',
    se:
      'Ett oväntat fel inträffade. Pengar som har dragits kommer återbetalas inom 3 arbetsdagar. Vänligen försök igen.',
    es:
      'Se ha producido un error, el dinero que ha sido retirado será reembolsado dentro de los siguientes 3 días hábiles. Por favor inténtalo de nuevo.',
  },
  'Error.INTERNAL_SERVER_ERROR': {
    en: 'Something went wrong. Please try again later.',
    se: 'Något gick fel. Var god och prova igen senare.',
    es: 'Algo salió mal. Por favor inténtelo de nuevo más tarde.',
  },
  'Error.ERROR_USER_NOT_FOUND': {
    en: 'We do not recognize this email address. Check if it may be misspelled and try again.',
    se:
      'Vi känner inte igen den här e-postadressen. Kontrollera om den kan vara felstavad och försök igen.',
    es:
      'No reconocemos esta dirección de correo electrónico. Compruebe si puede estar mal escrito y vuelva a intentarlo.',
  },
  'Error.ValidationFailed': {
    en: 'Oops!',
    se: 'Oops!',
    es: 'Ups!',
  },
  'SignUp.Error.Name': {
    en: 'First and last name required',
    se: 'Vänligen skriv in både för och efternamn',
    es: 'Nombre y apellido requeridos',
  },
  'SignUp.Error.Phone': {
    en: 'The number should begin with +',
    se: 'Numret måste börja med +',
    es: 'El número debe comenzar con +',
  },
  'SignUp.Error.Email': {
    en: 'The email address is incorrect',
    se: 'Mailadressen är inte korrekt',
    es: 'La dirección de correo electrónico es incorrecta',
  },
  'SignUp.Error.Password': {
    en: 'Password has to be at least 4 characters',
    se: 'Lösenordet måste vara minst 4 bokstäver',
    es: 'La contraseña debe tener al menos 4 caracteres',
  },
  'SignUp.Error.PasswordMatch': {
    en: 'Passwords do not match',
    se: 'Lösenorden matchar inte',
    es: 'Las contraseñas no coinciden',
  },
  'SignUp.Error.UnexpectedErrorOccurred': {
    en: 'An unexpected error occurred. Please try again',
    se: 'Ett oväntat fel inträffade. Försök gärna igen.',
    es: 'Se ha producido un error inesperado. Inténtelo de nuevo.',
  },
  'Signin.Title': {
    en: 'Log in',
    se: 'Logga in',
    es: 'Inicia sesión',
  },
  'Signin.Password': {
    en: 'Password',
    se: 'Lösenord',
    es: 'Contraseña',
  },
  'Signin.Password.Placeholder': {
    en: 'Enter your password',
    se: 'Fyll i ditt lösenord',
    es: 'Introduzca su contraseña',
  },
  'Signin.Next': {
    en: 'Next',
    se: 'Nästa',
    es: 'Siguiente',
  },
  'Signin.ForgotPassword': {
    en: 'Forgot password',
    se: 'Glömt lösenord',
    es: 'Olvidé mi contraseña',
  },
  'Signin.Modal.Title': {
    en: 'Sign in or create an account',
    se: 'Logga in eller skapa konto',
    es: 'Iniciar sesión o crear una cuenta',
  },
  'Signin.Modal.Button.Create': {
    en: 'Create account',
    se: 'Skapa konto',
    es: 'Crear cuenta',
  },
  'Signin.Modal.Button.Signin': {
    en: 'Log in',
    se: 'Logga in',
    es: 'Inicia sesión',
  },
  'SignIn.Error.Failed': {
    en: 'Login failed',
    se: 'Inloggning misslyckades',
    es: 'Error de inicio de sesión',
  },
  'Consent.Banner.Title': {
    en: 'We use cookies',
    se: 'Vi använder kakor',
    es: 'Nosotros usamos cookies',
  },
  'Consent.Banner.ReadMore': {
    en: 'Read more and configure.',
    se: 'Läs mer och konfigurera.',
    es: 'Leer más y configurar',
  },
  'Consent.Banner.Button.Agree': {
    en: 'Agree',
    se: 'Acceptera',
    es: 'Aceptar',
  },
  'Consent.Banner.Button.Deny': {
    en: 'Deny',
    se: 'Neka',
    es: 'Negar',
  },
  'ForgotPassword.Text': {
    en: 'Forgot password',
    se: 'Glömt lösenord',
    es: 'Olvidé mi contraseña',
  },
  'ForgotPassword.Title': {
    en: (step: string, nrOfSteps: string) => `Reset password ${step} of ${nrOfSteps}`,
    se: (step: string, nrOfSteps: string) => `Återställ lösenord ${step} av ${nrOfSteps}`,
    es: (step: string, nrOfSteps: string) => `Restablecer contraseña ${step} of ${nrOfSteps}`,
  },
  'ForgotPassword.NewPassword': {
    en: 'Enter new password',
    se: 'Skriv in nytt lösenord',
    es: 'Introduzca una nueva contraseña',
  },
  'ForgotPassword.Code': {
    en: 'We have sent an SMS to your phone number with a code. Enter the code here:',
    se: 'Vi har skickat ett SMS till ditt mobilnummer med en kod. Skriv in koden här:',
    es: 'Hemos enviado un SMS a su número de teléfono con un código. Ingrese el código aquí:',
  },
  'ForgotPassword.Code.Placeholder': {
    en: 'Input your code',
    se: 'Fyll din kod',
    es: 'Introducir en el código',
  },
  'ForgotPassword.Error.Code': {
    en: 'The code is wrong',
    se: 'Koden är fel',
    es: 'El código es incorrecto',
  },
  'ForgotPassword.Error.Failed': {
    en: 'Reset password failed',
    se: 'Återställ lösenord misslyckades',
    es: 'Error al restablecer la contraseña',
  },
  'Home.Categories.More': {
    en: 'View All',
    se: 'Visa fler',
    es: 'Ver más',
  },
  'Home.SeeMenu': {
    en: 'View the whole menu',
    se: 'Se hela menyn',
    es: 'Ver el menú completo',
  },

  'OrderHistory.Title': {
    en: 'Order history',
    se: 'Orderhistorik',
    es: 'Historial de pedidos',
  },
  'OrderHistory.OrderId': {
    en: 'Order#',
    se: 'Order#',
    es: 'Número de pedido',
  },
  'OrderHistory.ServiceType': {
    en: 'Service type',
    se: 'Servicetyp',
    es: 'Tipo de servicio',
  },
  'OrderHistory.Date': {
    en: 'Date',
    se: 'Datum',
    es: 'Fecha',
  },
  'OrderHistory.Date.Scheduled': {
    en: 'Scheduled time',
    se: 'Tid för upphämtning',
    es: 'Hora programada',
  },
  'OrderHistory.Total': {
    en: 'Total',
    se: 'Total',
    es: 'Total',
  },
  'OrderHistory.Tips': {
    en: 'Tips',
    se: 'Dricks',
    es: 'Propina',
  },
  'OrderHistory.VAT': {
    en: 'VAT',
    se: 'moms',
    es: 'IVA',
  },
  'OrderHistory.Total.VAT': {
    en: 'Total VAT',
    se: 'Totalsumma moms',
    es: 'IVA total',
  },
  'OrderHistory.Total.Exclude.VAT': {
    en: 'Total excluding VAT',
    se: 'Pris exklusive moms',
    es: 'Total sin IVA',
  },
  'OrderHistory.Store': {
    en: 'Store',
    se: 'Butik',
    es: 'Tienda',
  },
  'OrderHistory.OrgNr': {
    en: 'Organisation number',
    se: 'Orgnummer',
    es: 'Número de organización',
  },
  'OrderHistory.Discount': {
    en: 'Discount',
    se: 'Rabatt',
    es: 'Descuento',
  },
  'Order.Status': {
    en: 'Status',
    se: 'Status',
    es: 'Estado',
  },
  'Order.Status.Rejected': {
    en: 'Rejected',
    se: 'Nekad',
    es: 'Rechazado',
  },
  'Order.Status.Accepted': {
    en: 'Accepted',
    se: 'Accepterad',
    es: 'Aceptado',
  },
  'Order.Status.Ready': {
    en: 'Ready',
    se: 'Redo',
    es: 'Listo',
  },
  'Orderstatus.Pending.Title': {
    en: 'Order received',
    se: 'Order mottagen',
    es: 'Pedido recibido',
  },
  'Orderstatus.Pending.Text': {
    en: 'We will get back to you soon',
    se: 'Vi besvarar dig inom kort',
    es: 'Nos pondremos en contacto con usted pronto',
  },
  'Orderstatus.Preparing.Title': {
    en: 'Preparing',
    se: 'Förbereder',
    es: 'Preparando',
  },
  'Orderstatus.Preparing.Text': {
    en: 'Estimated time is',
    se: 'Uppskattad tid',
    es: 'El tiempo estimado es',
  },
  'Orderstatus.Ready.Title': {
    en: 'Ready for pickup',
    se: 'Redo för upphämtning',
    es: 'Listo para recoger',
  },
  'Orderstatus.Ready.Text.I': {
    en: 'Enter order number',
    se: 'Ange ordernummer',
    es: 'Introduzca el número de pedido',
  },
  'OrderStatus.Ready.Text.II': {
    en: 'at checkout',
    se: 'i kassan',
    es: 'al finalizar la compra',
  },
  'Orderstatus.Completed.Title': {
    en: 'Completed',
    se: 'Färdigställd',
    es: 'Completado',
  },
  'Orderstatus.Completed.Text': {
    en: 'Click here for your receipt',
    se: 'Klicka här för ditt kvitto',
    es: 'Haga clic aquí para su recibo',
  },
  'Orderstatus.Rejected.Title': {
    en: 'Sorry, we can not accept your order.',
    se: 'Tyvärr kunde vi inte ta emot din order.',
    es: 'Lo sentimos, no podemos aceptar su pedido.',
  },
  'Orderstatus.Rejected.Closed': {
    en: 'We are closing soon and can not complete your order on time. Try ordering tomorrow.',
    se: 'Vi stänger snart och hinner inte slutföra din order i tid. Prova beställa imorgon.',
    es:
      'Estamos cerrando pronto y no podemos completar su pedido a tiempo. Intente ordenar mañana.',
  },
  'OrderStatus.Rejected.Text': {
    en: 'We apologise but the restaurant did not respond in time. Try again.',
    se: 'Vi ber om ursäkt men restaurangen svarade inte i tid. Prova igen.',
    es: 'Lo sentimos, el restaurante no respondió a tiempo. Vuelve a intentarlo.',
  },
  'Orderstatus.Rejected.BestRegards': {
    en: (company: string) => `Best regards ${company}.`,
    se: (company: string) => `Vänliga hälsningar ${company}.`,
    es: (company: string) => `Saludos ${company}.`,
  },
  'Orderstatus.Soon': {
    en: 'soon',
    se: 'snart',
    es: 'pronto',
  },
  'Paymentmethods.Title': {
    en: 'Payment methods',
    se: 'Betalmedel',
    es: 'Formas de pago',
  },
  'Paymentmethods.Add': {
    en: 'Add payment method',
    se: 'Lägg till betalningsmedel',
    es: 'Añadir método de pago',
  },
  'Membership.Title': {
    en: 'Membership',
    se: 'Medlemskort',
    es: 'Membresía',
  },
  'Membership.Points': {
    en: 'Your points',
    se: 'Dina poäng',
    es: 'Tus puntos',
  },
  'Membership.Level.Title': {
    en: 'Membership level',
    se: 'Medlemsnivå',
    es: 'Nivel de membresía',
  },
  'Membership.Number': {
    en: 'Membership number',
    se: 'Medlemsnummer',
    es: 'Número de membresía',
  },
  'Membership.Benefits': {
    en: 'membership benefits',
    se: 'medlemsförmåner',
    es: 'beneficios de membresía',
  },
  'Membership.Infotext': {
    // maybe this should not be hardcoded
    en: '1 krona spent means one point in the membership. Below we have our membership levels.',
    se: '1 spenderad krona innebär en poäng i medlemskapet. Här under har vi  våra medlemsnivåer.',
    es:
      '1 Euro significan diez puntos en la membresía. A continuación tenemos nuestros niveles de membresía.',
  },
  'Membership.Level': {
    en: (step: string, nrOfSteps: string) => `Level ${step} / ${nrOfSteps}`,
    se: (step: string, nrOfSteps: string) => `Nivå ${step} av ${nrOfSteps}`,
    es: (step: string, nrOfSteps: string) => `Nivel ${step} de ${nrOfSteps}`,
  },
  'Membership.discount': {
    en: 'Discount on the food check',
    se: 'Rabatt på matnotan',
    es: 'Descuento en la cuenta de alimentos',
  },
  'Membership.TotalPoints': {
    en: 'Points',
    se: 'Antal poäng',
    es: 'Puntos',
  },
  'Membership.Member': {
    en: 'Member',
    se: 'Medlem',
    es: 'Miembro',
  },
  'News.Title': {
    en: 'News',
    se: 'Nyheter',
    es: 'Noticias',
  },
  'News.Readmore': {
    en: 'Read more',
    se: 'Läs mer',
    es: 'Leer más',
  },
  'News.UpdatedAt': {
    en: 'updated at',
    se: 'uppdaterad',
    es: 'actualizado en',
  },
  'ActivateCode.Title': {
    en: 'Activate code',
    se: 'Aktivera kod',
    es: 'Activar código',
  },
  'ActivateCode.Heading': {
    en: 'Activate the code by filling out the form below.',
    se: 'Aktivera koden genom fylla i formuläret nedan.',
    es: 'Activa el código rellenando el siguiente formulario.',
  },
  'ActivateCode.Text': {
    en:
      'We offer all our members discounts or offers. Unlock membership levels or other discounts.',
    se:
      'Vi erbjuder alla våra medlemmar rabatter eller erbjudanden. Lås upp medlemsnivåer eller andra rabatter.',
    es:
      'Ofrecemos a todos nuestros miembros ofertas o descuentos. Desbloquea niveles de membresía.',
  },
  'ActivateCode.Code': {
    en: 'Code',
    se: 'Kod',
    es: 'Código',
  },
  'ActivateCode.Placeholder': {
    en: 'Enter your code',
    se: 'Fyll i din kod',
    es: 'Introduce tu código',
  },
  'ActivateCode.ButtonText': {
    en: 'Activate',
    se: 'Aktivera',
    es: 'Activar',
  },
  'Terms.Title': {
    en: 'Terms',
    se: 'Villkor',
    es: 'Términos',
  },
  'Terms.EmailButton': {
    en: 'Email us',
    se: 'Maila oss',
    es: 'Envíanos un correo electrónico',
  },
  'StoreList.Title': {
    en: 'Choose a restaurant',
    se: 'Välj restaurang',
    es: 'Elige un restaurante',
  },
  'StoreList.FindPosition': {
    en: 'Find my location',
    se: 'Hitta min position',
    es: 'Encuentra mi ubicación',
  },
  'StoreList.Denied.Title': {
    en: 'Location access denied',
    se: 'Platsdelning nekad',
    es: 'Acceso a la ubicación denegado',
  },
  'StoreList.Denied.Text': {
    en:
      'You need to accept the location access to see the closest restaurant. Go to the app in the phone settings to change this.',
    se:
      'Du behöver godkänna platsåtkomst för att kunna se närmsta restaurang. Gå till appen under telefonens inställningar för att ändra detta.',
    es:
      'Debe aceptar el acceso a la ubicación para ver el restaurante más cercano. Vaya a la aplicación en la configuración del teléfono para cambiar esto.',
  },
  'StoreList.Denied.ButtonText': {
    en: 'OK',
    se: 'OK',
    es: 'Bien',
  },
  'StoreList.Today': {
    en: 'Today',
    se: 'Idag',
    es: 'Hoy',
  },
  'StoreList.Modal.TakeAway': {
    en: 'Take away',
    se: 'Avhämtning',
    es: 'Recoger',
  },
  'StoreList.Modal.EatHere': {
    en: 'Eat here',
    se: 'Äta här',
    es: 'Comer aquí',
  },
  'StoreList.Modal.TakeAway.Text': {
    en: 'Skip the line and collect your order',
    se: 'Gå förbi kön och hämta ut din mat.',
    es: 'Sáltate la cola y recoge tu pedido.',
  },
  'StoreList.Modal.EatHere.Text': {
    en: 'Order here and enjoy it in the restaurant.',
    se: 'Beställ här och njut på restuarangen. ',
    es: 'Ordene aquí y disfrútelo en el restaurante. ',
  },
  'Menu.Title': {
    en: 'Menu',
    se: 'Meny',
    es: 'Menú',
  },
  'Menu.Choose': {
    en: 'Choose',
    se: 'Välj',
    es: 'Elegir',
  },
  'Item.ChooseOption': {
    en: 'Choose an option',
    se: 'Välj ett alternativ',
    es: 'Hazlo a tu gusto',
  },
  'Item.Allergens': {
    en: 'Allergens',
    se: 'Allergener',
    es: 'Alérgenos',
  },
  'Item.ChooseOption.Multiple': {
    en: 'Choose multiple options',
    se: 'Välj flera alternativ',
    es: 'Elija varias opciones',
  },
  'Item.NoExtra': {
    en: 'No extras',
    se: 'Inget tillbehör',
    es: 'Sin extras',
  },
  'Item.Button.Add': {
    en: 'Add',
    se: 'Lägg till',
    es: 'Añadir al pedido',
  },
  'Item.Button.RequiredVariations': {
    en: 'Finalize your order',
    se: 'Välj Tillbehör',
    es: 'Elige tus opciones',
  },
  'Item.Button.Signin': {
    en: 'Sign in',
    se: 'Logga in',
    es: 'Inicia sesión',
  },
  'Item.Button.Error': {
    en: 'Error',
    se: 'Fel',
    es: 'Error',
  },
  'Item.Next': {
    en: 'Next step',
    se: 'Nästa steg',
    es: 'Siguiente paso',
  },
  'Item.Allergen.CELERY': {
    en: 'Celery allergy',
    se: 'Selleriallergi',
    es: 'Alergia a la cebolla',
  },
  'Item.Allergen.CRUSTACEANS': {
    en: 'Crustaceans allergy',
    se: 'Kräftdjursallergi',
    es: 'Alergia a los crustáceos',
  },
  'Item.Allergen.EGGS': {
    en: 'Egg allergy',
    se: 'Äggallergi',
    es: 'Alergia a los huevos',
  },
  'Item.Allergen.FISH': {
    en: 'Fish allergy',
    se: 'Fiskallergi',
    es: 'Alergia al pescado',
  },
  'Item.Allergen.GLUTEN': {
    en: 'Gluten allergy',
    se: 'Glutenallergi',
    es: 'Alergia al gluten',
  },
  'Item.Allergen.LUPIN': {
    en: 'Lupin allergy',
    se: 'Lupinallergi',
    es: 'Alergia al lupino',
  },
  'Item.Allergen.MILK': {
    en: 'Milk allergy',
    se: 'Mjölkallergi',
    es: 'Alergia a la leche',
  },
  'Item.Allergen.MOLLUSCS': {
    en: 'Molluscs allergy',
    se: 'Blötdjursallergi',
    es: 'Alergia a los moluscos',
  },
  'Item.Allergen.MUSTARD': {
    en: 'Mustard allergy',
    se: 'Senapsallergi',
    es: 'Alergia a la mostaza',
  },
  'Item.Allergen.NUTS': {
    en: 'Nut allergy',
    se: 'Nötallergi',
    es: 'Alergia a los frutos secos',
  },
  'Item.Allergen.PEANUTS': {
    en: 'Peanut allergy',
    se: 'Jordnötsallergi',
    es: 'Alergia a los cacahuetes',
  },
  'Item.Allergen.SESAME': {
    en: 'Sesame allergy',
    se: 'Sesamallergi',
    es: 'Alergia al sésamo',
  },
  'Item.Allergen.SOYBEANS': {
    en: 'Soybeans allergy',
    se: 'Sojabönor allergi',
    es: 'Alergia a los frijoles',
  },
  'Item.Allergen.SULPHITES': {
    en: 'Sulphites allergy',
    se: 'Sulfiter allergi',
    es: 'Alergia a los sulfitos',
  },
  'Item.Allergen.SULPHUR_DIOXIDE': {
    en: 'Sulphur dioxide allergy',
    se: 'Svaveldioxidallergi',
    es: 'Alergia al dióxido de azufre',
  },
  'Toast.Add': {
    en: (item: string) => `${item} added to cart!`,
    se: (item: string) => `${item} tillagd i varukorgen!`,
    es: (item: string) => `${item} agregar algo al carrito!`,
  },
  'Cart.Title': {
    en: 'Cart',
    se: 'Varukorg',
    es: 'Carrito',
  },
  'Cart.Restaurant': {
    en: 'Restaurant',
    se: 'Restaurang',
    es: 'Restaurante',
  },
  'Cart.ServiceType': {
    en: 'Service type',
    se: 'Servicetyp',
    es: 'Tipo de servicio',
  },
  'Cart.ServiceType.TakeAway': {
    en: 'Take away',
    se: 'Avhämtning',
    es: 'A domicilio',
  },
  'Cart.ServiceType.EatHere': {
    en: 'Eat here',
    se: 'Äta här',
    es: 'Comer aquí',
  },
  'Cart.ServiceType.Delivery': {
    en: 'Delivery',
    se: 'Leverans',
    es: 'Entrega',
  },
  'Cart.Discount': {
    en: 'Total Discount',
    se: 'Total Rabatt',
    es: 'Descuento',
  },
  'Cart.MembershipDiscount': {
    en: 'Membership Discount',
    se: 'Medlemskaps rabatt',
    es: 'Descuento de membresía',
  },
  'Cart.Total': {
    en: 'Total',
    se: 'Totalt',
    es: 'Total',
  },
  'Cart.VAT': {
    en: 'VAT',
    se: 'moms',
    es: 'IVA',
    //repeated
  },
  'Cart.VAT.Total': {
    en: 'Total VAT',
    se: 'Totalsumma moms',
    es: 'IVA total',
    //repeated
  },
  'Cart.VAT.TotalExcluding': {
    en: 'Price excluding VAT',
    se: 'Pris exlusive moms',
    es: 'Precio sin IVA',
    //repeated
  },
  'Cart.ChangePickuptime': {
    en: 'Change pickup time',
    se: 'Ändra upphämtningstid',
    es: 'Cambiar la hora de recogida',
  },
  'Cart.Pickuptime.ASAP': {
    en: 'ASAP - As soon as possible',
    se: 'ASAP - As soon as possible',
    es: 'ASAP - Tan pronto como sea posible',
  },
  'Cart.Pickuptime.Today': {
    en: 'Today',
    se: 'Idag',
    es: 'Hoy',
  },
  'Cart.AddComment': {
    en: 'Add a comment',
    se: 'Lägg till kommentar',
    es: 'Añadir un comentario',
  },
  'Cart.NoComment': {
    en: 'No comments',
    se: 'Inga kommentarer',
    es: 'Sin comentarios',
  },
  'Cart.SelectCard': {
    en: 'Select card',
    se: 'Välj betalkort',
    es: 'Seleccionar tarjeta',
  },
  'Cart.AddDiscount': {
    en: 'Add discount code',
    se: 'Lägg till rabattkod',
    es: 'Añadir código de descuento',
  },
  'Cart.Button.Pay': {
    en: 'Pay',
    se: 'Betala',
    es: 'Pagar',
  },
  'Cart.Button.PayLater': {
    en: 'Order to table',
    se: 'Beställ till bordet',
    es: 'Ordenar a la mesa',
  },
  'Cart.Button.Loading': {
    en: 'Loading...',
    se: 'Vänligen vänta...',
    es: 'Cargando...',
  },
  'Cart.Confirm': {
    en: 'Confirm',
    se: 'Bekräfta',
    es: 'Confirmar',
  },
  'Cart.Cancel': {
    en: 'Cancel',
    se: 'Avbryt',
    es: 'Cancelar',
  },
  'Cart.Clear.Title': {
    en: 'Cart will be cleared',
    se: 'Varukorgen kommer att tömmas',
    es: 'El carrito se borrará',
  },
  'Cart.Cleared': {
    en: 'Cart is cleared',
    se: 'Varukorgen är tömd',
    es: 'El carrito está vacío',
  },
  'Cart.Tips.Description': {
    en: 'Say thanks with a tip!',
    se: 'Säg tack med dricks!',
    es: '¿Propina?',
  },
  'Cart.Modal.SendComment': {
    en: 'Send a comment',
    se: 'Skicka med en kommentar',
    es: 'Enviar un comentario',
  },
  'Cart.Modal.SendComment.Placeholder': {
    en: 'Your comment here',
    se: 'Din kommentar här',
    es: 'Tu comentario aquí',
  },
  'Cart.Modal.SendComment.Button': {
    en: 'Ok',
    se: 'Ok',
    es: 'Ok',
  },
  'Cart.Modal.DiscountCode.Title': {
    en: 'Discount code',
    se: 'Rabattkod',
    es: 'Código de descuento',
  },
  'Cart.Modal.DiscountCode.Placeholder': {
    en: 'Enter your code',
    se: 'Fyll i din kod här',
    es: 'Introduce tu código',
  },
  'Cart.Modal.OutOfStock': {
    en:
      'These items are out of stock and was removed from your cart, please select something else',
    es: 'Este artículo está agotado y eliminado de su carrito, seleccione otra cosa',
    se:
      'Dessa produkter är slut i lager och har tagits bort från din varukorg, vänligen välj något annat',
  },
  NOUSER: {
    en: 'This promocode requires you to log in',
    se: 'Denna rabattkod kräver att du loggar in',
    es: 'Este código de descuento requiere que inicie sesión',
  },

  INVALIDVENUE: {
    en: 'This promocode is not valid at this restaurant',
    se: 'Denna rabattkod är inte giltig på denna restaurang',
    es: 'Este código de descuento no es válido en este restaurante',
  },

  NOTACTIVEYET: {
    en: 'This promocode is not active yet',
    se: 'Denna rabattkod är inte aktiv än',
    es: 'Este código de descuento aún no está activo',
  },

  INACTIVE: {
    en: 'This promocode is not active',
    se: 'Denna rabattkod är inte aktiv',
    es: 'Este código de descuento no está activo',
  },

  EXPIRED: {
    en: 'This promocode has expired',
    se: 'Denna rabattkod har gått ut',
    es: 'Este código de descuento ha caducado',
  },

  USELIMIT: {
    en: 'This promocode has reached its use limit',
    se: 'Denna rabattkod har nått sin användningsgräns',
    es: 'Este código de descuento ha alcanzado su límite de uso',
  },

  OUTOFHOURS: {
    en: 'This promocode is not active at this time',
    se: 'Denna rabattkod är inte aktiv just nu',
    es: 'Este código de descuento no está activo en este momento',
  },

  NOTFOUND: {
    en: 'This promocode does not exist',
    se: 'Denna rabattkod finns inte',
    es: 'Este código de descuento no existe',
  },

  'Cart.Modal.DiscountCode.Button': {
    en: 'Activate',
    se: 'Aktivera',
    es: 'Activar',
  },
  'Cart.Modal.Payment.Title': {
    en: 'Choose payment method',
    se: 'Välj betalningsmedel',
    es: 'Elija el método de pago',
  },
  'Cart.Modal.Payment.Add': {
    en: 'Add payment card',
    se: 'Lägg till betalkort',
    es: 'Añadir tarjeta de pago',
  },
  'Cart.Modal.Payment.Button': {
    en: 'Ok',
    se: 'Ok',
    es: 'Ok',
  },
  'Cart.Modal.PaymentError.Title': {
    en: 'Payment Error',
    se: 'Betalningsfel',
    es: 'Error de pago',
  },
  'Cart.Modal.PaymentError.Text': {
    en: 'Something went wrong with your payment, please try again',
    se: 'Något gick fel med din betalning, vänligen försök igen',
    es: 'Algo salió mal con su pago, inténtelo de nuevo',
  },
  'Cart.Modal.AfterPaymentError.Text': {
    en: 'An error occurred, please try again.',
    se: 'Ett oväntat fel inträffade. Vänligen försök igen.',
    es: 'Se ha producido un error, inténtelo de nuevo.',
  },
  'Cart.Modal.PaymentError.Button': {
    en: 'Try Again',
    se: 'Försök igen',
    es: 'Vuelve a intentarlo',
  },
  'Cart.Modal.OrderPayment.Title': {
    en: 'Payment verification',
    se: 'Betalningsverifiering',
    es: 'Verificación de pagos',
  },
  'Cart.Modal.Error.Title': {
    en: 'Something went wrong',
    se: 'Något gick fel',
    es: 'Algo salió mal',
  },
  'Cart.Modal.Error.Text': {
    en: 'An error occurred, please try again.',
    se: 'Ett oväntat fel inträffade. Försök gärna igen.',
    es: 'Se ha producido un error, inténtelo de nuevo.',
  },
  'Cart.Modal.Error.DesiredTime': {
    en: 'We are closed but you can create a pre-order by choosing a desired pick-up time',
    se: 'Vi har stängt, men du kan förbeställa genom att ändra upphämtningstid',
    es:
      'Estamos cerrados, pero puede crear un pedido anticipado eligiendo la hora de recogida deseada',
  },
  'Cart.Modal.Error.Button': {
    en: 'Ok',
    se: 'Ok',
    es: 'Ok',
  },

  'Cart.Modal.PaymentRefused.Title': {
    en: 'Payment Refused',
    se: 'Medges Ej',
    es: 'Pago rechazado',
  },
  'Cart.Modal.PaymentRefused.Text': {
    en: 'Your payment was refused, please try another payment method.',
    se: 'Betalningen nekades, vänligen försök igen med en annan betalningsmetod.',
    es: 'Su pago fue rechazado, pruebe otro método de pago.',
  },
  'Cart.Modal.PaymentComplete.Title.First': {
    en: 'Processing Payment',
    se: 'Betalning genomförd',
    es: 'Procesando el pago',
  },
  'Cart.Modal.PaymentComplete.Title.Second': {
    en: 'Creating Order',
    se: 'Skapar Order',
    es: 'Creación de orden',
  },
  'Cart.Modal.PaymentComplete.Text.First': {
    en: 'Payment processing, please wait...',
    se: 'Betalningen behandlas! Vänligen vänta...',
    es: 'Procesamiento de pago, por favor espere...',
  },
  'Cart.Modal.PaymentComplete.Text.Second': {
    en: 'Creating order...',
    se: 'Skapar order...',
    es: 'Creando orden...',
  },
  'Cart.Modal.PayLaterPending.Title': {
    en: 'Sending order',
    se: 'Skickar beställning',
    es: 'Enviando orden',
  },
  'Cart.Modal.PayLaterPending.Text': {
    en: 'Your order is being sent to the kitchen...',
    se: 'Din beställning skickas till köket...',
    es: 'Tu pedido está siendo enviado a la cocina...',
  },
  'Cart.Modal.PayLaterComplete.Title': {
    en: 'Order sent!',
    se: 'Beställning skickad!',
    es: '¡Orden enviada!',
  },
  'Cart.Modal.PayLaterComplete.Text': {
    en: 'Your order has been sent to the kitchen! You will now be redirected to the start page.',
    se: 'Din beställning har skickats till köket! Du kommer nu att återgå till startsidan.',
    es: '¡Tu pedido ha sido enviado a la cocina! Ahora será redirigido a la página de inicio.',
  },
  'Cart.DropIn.Card': {
    en: 'Credit card',
    se: 'Kort',
    es: 'Tarjeta de crédito',
  },
  'Cart.DropIn.Language': {
    en: 'en-US',
    se: 'sv-SE',
    es: 'es-ES',
  },
  'Cart.DropIn.Invoice': {
    en: 'Pay with invoice',
    se: 'Betala med faktura',
    es: 'Pagar con factura',
  },
  'Cart.SignUpReminder.Title': {
    en: 'Come here often? Become our regular!',
    se: 'Är du här ofta? Bli vår stammis!',
    es: '¿Vienes a menudo? ¡Guarda tus datos para la próxima visita!',
  },
  'Cart.SignUpReminder.Row.First': {
    en: 'Save your payment information',
    se: 'Spara din betalningsinformation',
    es: 'Guarde tus datos de pago',
  },
  'Cart.SignUpReminder.Row.Second': {
    en: 'Earn points on every order',
    se: 'Tjäna poäng på varje beställning',
    es: 'Gana puntos en cada pedido',
  },
  'Cart.SignUpReminder.Row.Third': {
    en: 'Recieve unique offers',
    se: 'Få unika erbjudanden',
    es: 'Recibe ofertas únicas',
  },
  'CookieSettings.Nav.Title': {
    en: 'Cookie settings',
    se: 'Cookie settings',
    es: 'Privacidad',
  },
  'CookieSettings.Title': {
    en: 'Inställningar kakor',
    se: 'Cookie settings',
    es: 'configuración de cookies',
  },
  'CookieSettings.Cookies.Title': {
    en: 'Cookies',
    se: 'Kakor',
    es: 'Cookies',
  },
  'CookieSettings.Cookies.Text': {
    en:
      'To improve your customer experience and enable full functionality of our service, we save temporary files in the form of so-called cookies. These are stored on your device and contain, among other things, settings you have made and your shopping basket.',
    se:
      'För att förbättra din kundupplevelse och möjliggöra full funktion av vår tjänst sparar vi temporära filer i form av så kallade kakor. Dessa lagras på din enhet och innehåller bland annat inställningar du har gjort samt din kundkorg.',
    es:
      'Para mejorar su experiencia como cliente y habilitar la funcionalidad completa de nuestro servicio, guardamos archivos temporales en forma de las llamadas cookies. Estos se almacenan en su dispositivo y contienen, entre otras cosas, la configuración que ha realizado y su cesta de la compra.',
  },
  'CookieSettings.Tracking.Title': {
    en: 'Tracking',
    se: 'Spårning',
    es: 'Galletas',
  },
  'CookieSettings.Tracking.Text': {
    en:
      'With the aim of further developing our product, we collect anonymous information about visits to the app. This information cannot be directly connected to you as an individual and helps us understand our customers as a group.',
    se:
      'Med ändamålet att vidareutveckla vår produkt samlar vi in anyonym information om besök i appen. Denna information går alltså inte att härleda till dig som person och hjälper oss förstå våra kunder som grupp.',
    es:
      'Con el objetivo de seguir desarrollando nuestro producto, recopilamos información anónima sobre las visitas a la aplicación. Esta información no se puede relacionar directamente con usted como individuo y nos ayuda a comprender a nuestros clientes como grupo.', // Needs to be proof read
  },
  'Support.Nav.Title': {
    en: 'Support',
    se: 'Support',
    es: 'Soporte',
  },
  'Support.Title': {
    en: 'Contact us',
    se: 'Kontakta Support',
    es: 'Contáctenos',
  },
  'Support.Text': {
    en:
      'We can be reached fastest by email. By clicking the link below your email client will open.',
    se: 'Vi kan nås snabbast via email. Tryck på länken nedan för att öppna din emailklient.',
    es:
      'Podemos ser contactados más rápido por correo electrónico. Al hacer clic en el enlace de abajo, su cliente de correo electrónico se abrirá.',
  },
  'Support.AccountDeletion': {
    en: 'Do you want to have your account deleted? ',
    se: 'Vill du få ditt konto borttaget? ',
    es: '¿Quieres que eliminen tu cuenta? ',
  },
  'Support.Button.Text': {
    en: 'Contact us',
    se: 'Kontakta oss',
    es: 'Contáctenos',
  },
  'Support.DeleteButton.Text': {
    en: 'Delete account',
    se: 'Ta bort konto',
    es: 'Eliminar cuenta',
  },
  'Support.DeleteButton.Error': {
    en: 'Something went wrong, please contact our support',
    se: 'Något gick fel, vänligen kontakta vår support',
    es: 'Algo salió mal, por favor contáctenos',
  },
  'Support.DeleteButton.Cancel': {
    en: 'Cancel',
    se: 'Avbryt',
    es: 'Cancelar',
  },
  'Support.DeleteModal.Confirm': {
    en: 'Are you sure you want to delete your account? This action is irreversible.',
    se: 'Är du säker på att du vill ta bort ditt konto? Denna åtgärd är oåterkallelig.',
    es: '¿Estás seguro de que quieres eliminar tu cuenta? Esta acción es irreversible.',
  },
  'Date.PickADate': {
    en: 'Pick a date and time',
    se: 'Välj datum och tid',
    es: 'Elige una fecha y hora',
  },
  'Date.PickADate.OK': {
    en: 'Ok',
    se: 'Ok',
    es: 'Ok',
  },
  'Date.Mon': {
    en: 'Mon',
    se: 'Mån',
    es: 'Lun',
  },
  'Date.Tue': {
    en: 'Tue',
    se: 'Tis',
    es: 'Mar',
  },
  'Date.Wed': {
    en: 'Wed',
    se: 'Ons',
    es: 'Mie',
  },
  'Date.Thu': {
    en: 'Thu',
    se: 'Tor',
    es: 'Jue',
  },
  'Date.Fri': {
    en: 'Fri',
    se: 'Fre',
    es: 'Vie',
  },
  'Date.Sat': {
    en: 'Sat',
    se: 'Lör',
    es: 'Sab',
  },
  'Date.Sun': {
    en: 'Sun',
    se: 'Sön',
    es: 'Dom',
  },
  'Date.Jan': {
    en: 'jan',
    se: 'jan',
    es: 'ene',
  },
  'Date.Feb': {
    en: 'feb',
    se: 'feb',
    es: 'feb',
  },
  'Date.Mars': {
    en: 'mar',
    se: 'mar',
    es: 'mar',
  },
  'Date.Apr': {
    en: 'apr',
    se: 'apr',
    es: 'abr',
  },
  'Date.May': {
    en: 'may',
    se: 'maj',
    es: 'may',
  },
  'Date.June': {
    en: 'jun',
    se: 'jun',
    es: 'jun',
  },
  'Date.July': {
    en: 'jul',
    se: 'jul',
    es: 'jul',
  },
  'Date.Aug': {
    en: 'aug',
    se: 'aug',
    es: 'ago',
  },
  'Date.Sep': {
    en: 'sep',
    se: 'sep',
    es: 'sep',
  },
  'Date.Oct': {
    en: 'oct',
    se: 'okt',
    es: 'oct',
  },
  'Date.Nov': {
    en: 'nov',
    se: 'nov',
    es: 'nov',
  },
  'Date.Dec': {
    en: 'dec',
    se: 'dec',
    es: 'dic',
  },
  'General.More': {
    en: 'more...',
    se: 'mer...',
    es: 'más...',
  },
  'Order.EatHere': {
    en: 'Eat Here',
    se: 'Äta här',
    es: 'Comer aquí',
  },
  'Order.TakeAway': {
    en: 'Take Away',
    se: 'Avhämtning',
    es: 'A domicilio',
  },
  'Store.Closed': {
    en: 'Closed',
    se: 'Stängt',
    es: 'Cerrado',
  },
  'Store.Closed.Message': {
    en: 'The app is currently closed.',
    se: 'Appen är för tillfället nedstängd.',
    es: 'La aplicación está actualmente cerrada.',
  },
  'Input.Phone': {
    en: 'Phone number',
    se: 'Telefonnummer',
    es: 'Número de teléfono',
  },
  'Input.Phone.Placeholder': {
    en: 'Enter your mobile number',
    se: 'Fyll i ditt mobilnummer',
    es: 'Introduce tu número de móvil',
  },
  'Input.Password': {
    en: 'Password',
    se: 'Lösenord',
    es: 'Contraseña',
  },
  'Input.PasswordAgain': {
    en: 'Password again',
    se: 'Lösenord igen',
    es: 'Contraseña de nuevo',
  },
  'Input.Password.Placeholder': {
    en: 'Choose your password',
    se: 'Välj ditt lösenord',
    es: 'Choose your password',
  },
  'Input.PasswordAgain.Placeholder': {
    en: 'Repeat password',
    se: 'Upprepa lösenord',
    es: 'Repetir contraseña',
  },
  'Receipt.Prompt': {
    en: 'Press here for your receipt',
    se: 'Tryck här för ditt kvitto',
    es: 'Presione aquí para su recibo',
  },
  'Receipt.Email': {
    en: 'Send Receipt to Email',
    se: 'Skicka kvitto till min mail',
    es: 'Enviar recibo a e-mail',
  },
  'Receipt.Email.Sent': {
    en: 'Your receipt has been sent!',
    se: 'Ditt kvitto har skickats!',
    es: '¡Tu recibo ha sido enviado!',
  },
  'Receipt.Email.Input': {
    en: 'Email:',
    se: 'Email:',
    es: 'Email:',
  },
  'Receipt.Email.Send': {
    en: 'Send',
    se: 'Skicka',
    es: 'Enviar',
  },
  'Receipt.Restaurant': {
    en: 'Restaurant:',
    se: 'Restaurang:',
    es: 'Restaurante:',
  },
  'Receipt.Accepted': {
    en: 'Your order has been accepted.',
    se: 'Din beställning har tagits emot.',
    es: 'Su pedido ha sido aceptado',
  },
  'Receipt.Finalizing': {
    en: 'The Kitchen is preparing your order!',
    se: 'Köket förbereder din beställning!',
    es: '¡La Cocina está preparando tu pedido!',
  },
  'Receipt.Ready': {
    en: 'Bringing the food to your table!',
    se: 'Maten är påväg till ditt bord!',
    es: '¡Llevando la comida a tu mesa!',
  },
  'Receipt.HeaderText': {
    en: 'Order Confirmed!',
    se: 'Order bekräftad!',
    es: '¡Orden confirmada!',
  },
  'Receipt.OrderNumberText': {
    en: 'Your Order Number:',
    se: 'Ditt ordernummer:',
    es: 'Su número de orden:',
  },
  'PayCheque.SeeBill': {
    en: 'See your bill',
    se: 'Se din nota',
    es: 'Ver tu pedido',
  },
  'PayCheque.EntireBill': {
    en: 'Pay entire bill',
    se: 'Betala hela notan',
    es: 'Pagar cuenta total',
  },
  'PayCheque.Or': {
    en: 'or',
    se: 'eller',
    es: 'o',
  },
  'PayCheque.SelectItems': {
    en: 'Select items to pay for',
    se: 'Välj vilka du vill betala',
    es: 'Seleccionar productos a pagar',
  },
  'PayCheque.PaySelected': {
    en: 'Pay for selected items',
    se: 'Betala valda produkter',
    es: 'Pagar productos seleccionados',
  },
  'PayCheque.YourBill': {
    en: 'Your bill',
    se: 'Din nota',
    es: 'Tu cuenta',
  },
  'PayCheque.PayOrSplit': {
    en: 'pay or split bill',
    se: 'betala eller dela notan',
    es: 'pagar o dividir cuenta',
  },
};

export default translations;
