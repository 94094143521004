import React from 'react';
import styled from 'styled-components';
import useI18n from '../../../i18n';
import { useCurrency } from 'Hooks';

const Wrapper = styled.div`
  padding: 16px 24px 24px;
  border-bottom: 1px solid #e0e0e3;
  background-color: #fff;
`;

const Discount = styled.p`
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  margin-bottom: 8px;
  color: #209946;
`;

const TotalText = styled.p`
  font-size: 24px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.36px;
  line-height: 24px;
`;

interface Props {
  /**
   * price
   */
  price: string;
  /**
   * discount
   */
  discount: number;
}

/**
 * Total component
 */

const Total: React.FC<Props> = ({ price, discount }) => {
  const { i18n } = useI18n();
  const { formatAmount } = useCurrency();

  return (
    <Wrapper>
      {discount > 0 && (
        <Discount>
          <strong>{i18n('Cart.Discount')}</strong>-{formatAmount(discount)}
        </Discount>
      )}
      <TotalText>
        <strong>{i18n('Cart.Total')}</strong>
        {price}
      </TotalText>
    </Wrapper>
  );
};

export default Total;
