import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import Navbar from '../Navbar';
import Draggable from 'react-draggable';

const OuterWrapper = styled.div<{ isBackground: boolean }>`
  transform: ${({ isBackground }) => (isBackground ? 'translateX(-10%)' : 'translateX(0)')};
  transition: transform 0.2s ease-in-out;
`;

const Wrapper = styled.div<{ isDragging: boolean; showFooterTabs: boolean; offsetLeft: number }>`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: ${({ showFooterTabs }) =>
    showFooterTabs ? `calc(var(--height) - 80px)` : 'var(--height)'};
  overflow: hidden;
  transition: ${({ isDragging }) => (isDragging ? 'none' : 'transform 0.2s ease-in-out')};
`;

const Background = styled.div<{
  slideOut: boolean;
  slideIn: boolean;
  showFooterTabs: boolean;
}>`
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: ${({ showFooterTabs }) =>
    showFooterTabs
      ? `calc(var(--height) - ${window.hasNativeWrapper ? 88 : 80}px)`
      : 'var(--height)'};
  background-color: rgb(0, 0, 0, 0.2);
  animation: ${({ slideOut, slideIn }) =>
    (slideOut && 'fade-out .3s forwards') || (slideIn && 'fade-in .3s forwards') || 'none'};
`;

const InnerWrapper = styled.div<{
  slideOut: boolean;
  slideIn: boolean;
  backgroundColor: string | undefined;
}>`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#fff')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--height);
  animation: ${({ slideOut, slideIn }) =>
    (slideOut && 'slide-out-screen .3s forwards') ||
    (slideIn && 'slide-in-screen .3s forwards') ||
    'none'};
`;

const Content = styled.div`
  overflow-y: auto;
  height: var(--height);
`;

const Handle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: var(--height);
  width: 20px;
`;

interface Props {
  showFooterTabs?: boolean;
  navTitle?: string;
  navShowOnScroll?: number | false;
  backgroundColor?: string;
  backToHome?: boolean;
  showLogo?: boolean;
  showMenuIcon?: boolean;
  useSlugName?: boolean;
  slideIn?: boolean;
  hideNav?: boolean;
  isBackground?: boolean;
  level?: number;
  noSwipe?: boolean;
  scrollClass?: string;
  children: any;
}

const Stack: React.FC<Props> = ({
  showFooterTabs = false,
  navTitle,
  navShowOnScroll,
  backgroundColor,
  backToHome,
  showLogo,
  showMenuIcon,
  useSlugName,
  slideIn,
  hideNav,
  isBackground,
  level = 0,
  noSwipe = false,
  scrollClass,
  children,
}) => {
  const [title, setTitle] = useState('');
  const [showOnScroll, setShowOnScroll] = useState<number | false>(false);
  const [onBackFunc, setOnBackFunc] = useState<(() => void) | undefined>();
  const [opacity, setOpacity] = useState(100);
  const [isDragging, setIsDragging] = useState(false);
  const [slideOut, setSlideOut] = useState(false);
  const history = useHistory();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const ref = useRef(null);
  const bgElement = document.getElementById('level-' + (level - 1));

  const { slug } = useParams<{ slug: string }>();
  const formatSlug = slug ? slug.replace('-', ' ') : '';

  useEffect(() => {
    if (outerRef.current) {
      outerRef.current.style.transition = 'none';
      outerRef.current.style.transform = 'none';
    }

    if (bgElement) {
      bgElement.style.transition = 'transform .2s ease-in-out';
      bgElement.style.transform = 'translateX(-10%)';
    }
  }, [isBackground]);

  useEffect(() => {
    if (useSlugName) {
      setTitle(formatSlug.charAt(0).toUpperCase() + formatSlug.slice(1));
    } else if (navTitle) {
      setTitle(navTitle);
    }
  }, [navTitle, useSlugName]);

  useEffect(() => {
    setShowOnScroll(navShowOnScroll || false);
  }, [navShowOnScroll]);

  const slideBack = () => {
    setSlideOut(true);
    if (bgElement) {
      bgElement.style.transition = 'transform .2s ease-in-out';
      bgElement.style.transform = 'translateX(0%)';
    }

    setTimeout(() => {
      if (backToHome) {
        history.push('/');
      } else {
        history.goBack();
      }
    }, 300);

    return () => {
      setSlideOut(false);
    };
  };

  const onDragStop = (e: any, data: any) => {
    setIsDragging(false);
    if (bgElement) {
      bgElement.style.transition = 'transform .2s ease-in-out';
    }
    if (!wrapperRef.current) return;

    if (data.x > 100) {
      if (bgElement) {
        bgElement.style.transform = 'translateX(0%)';
      }
      slideBack();
    } else {
      setOpacity(100);
      wrapperRef.current.style.transform = 'translateX(0)';
      if (bgElement) {
        bgElement.style.transform = 'translateX(-10%)';
      }
    }
  };

  const handleDragStart = () => {
    if (bgElement) {
      bgElement.style.transition = 'none';
    }
    setIsDragging(true);
  };

  const handleDrag = (data: any) => {
    const opacityValue = Math.round(100 - 0.5 * data.x);

    if (opacityValue >= 0) {
      setOpacity(opacityValue);
    }

    if (level && data.x > 0) {
      const transformValue = 10 - (10 / 320) * Math.round(data.x);
      if (bgElement) {
        bgElement.style.transform = `translateX(-${transformValue}%)`;
      }
    }
  };

  const changeTitle = (navbarTitle: string) => {
    setTitle(navbarTitle);
  };

  const changeShowOnScroll = (scrollSize: number | false) => {
    setShowOnScroll(scrollSize);
  };

  const changeOnBackFunc = (callback: (() => void) | undefined) => {
    setOnBackFunc(() => callback);
  };

  const childrenWithExtraProp = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      changeTitle,
      changeShowOnScroll,
      changeOnBackFunc,
      contentRef: ref,
    }),
  );

  return (
    <OuterWrapper
      ref={outerRef}
      isBackground={isBackground !== undefined ? isBackground : false}
      id={'level-' + level}>
      <Background slideOut={slideOut} slideIn={Boolean(slideIn)} showFooterTabs={showFooterTabs} />
      <Draggable
        axis="x"
        bounds={{ left: 0 }}
        handle=".handle"
        nodeRef={wrapperRef}
        onStart={handleDragStart}
        disabled={noSwipe}
        onDrag={(e, data) => handleDrag(data)}
        onStop={(e, data) => onDragStop(e, data)}>
        <Wrapper
          ref={wrapperRef}
          isDragging={isDragging}
          showFooterTabs={showFooterTabs}
          offsetLeft={window.sidebarWidth}>
          <InnerWrapper
            slideIn={Boolean(slideIn)}
            slideOut={slideOut}
            backgroundColor={backgroundColor}>
            {!hideNav && (
              <Navbar
                title={title ?? ''}
                showOnScroll={showOnScroll}
                onBack={onBackFunc || slideBack}
                forwardRef={ref}
                slideOut={slideOut}
                opacity={opacity}
                showMenuIcon={showMenuIcon}
                showLogo={Boolean(showLogo)}
              />
            )}
            <Content ref={ref} id="stack-content" className={scrollClass ? scrollClass : ''}>
              {childrenWithExtraProp}
            </Content>
          </InnerWrapper>
          <Handle className="handle" />
        </Wrapper>
      </Draggable>
    </OuterWrapper>
  );
};

export default Stack;
