import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useDispatch } from 'Hooks';
import * as actions from 'actions';
import { Language } from 'Types';
import useI18n from 'i18n';

import Intro from './Components/IntroSection';
import MembershipSection from './Components/MembershipSection';
import NewsSection from './Components/NewsSection';
import InstagramSection from './Components/InstagramSection';
import OrderStatus from '../OrderStatus';
import LanguageSelector from 'Scenes/TableLandingPage/Components/LanguageSelector';
import { allContents_allContents, companyQuery_allMembershipLevels } from './__queries__';
import Dialog from 'Components/Dialog';
import DialogModal from 'Components/Dialog/Modal';
import { ReactComponent as LanguageIcon } from '../../Assets/Icons/language.svg';
import AnonymousOrdersList from '../TableLandingPage/Components/AnonymousOrdersList';

const Wrapper = styled.div``;

const InnerWrapper = styled.div`
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const OrderStatusWrapper = styled.div`
  @media (min-width: calc(768px + 303px)) {
    max-width: 720px;
    margin: 0 auto;
  }

  @media (min-width: calc(992px + 303px)) {
    max-width: 960px;
  }

  @media (min-width: calc(1200px + 303px)) {
    max-width: 1140px;
  }
`;

const LanguageSelect = styled.div`
  position: absolute;
  top: ${() => (window.hasNativeWrapper ? (!window.hasSmallViewport ? 48 : 24) : 24)}px;
  right: 24px;
  width: 88px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  @media (min-width: calc(992px + 303px)) {
    width: 160px;
  }
`;

const LangText = styled.p`
  font-family: Eina 01;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  color: white;
  @media (min-width: calc(992px + 303px)) {
    font-size: 24px;
  }
`;

interface Props {
  data: allContents_allContents[];
  onNavigate: () => void;
  isSignedIn: boolean;
  membershipCard?: companyQuery_allMembershipLevels;
  nextLevelPoints: number;
  modalLanguage: Language | null;
  setModalLanguage: React.Dispatch<React.SetStateAction<Language | null>>;
  showLanguageDialog: boolean;
  onShowLanguageDialog: () => void;
  handleCloseLanguageDialog: () => void;
  onSelectLanguage: () => void;
  hidePoints: boolean;
}
const LandingView: React.FC<Props> = ({
  data,
  onNavigate,
  isSignedIn,
  membershipCard,
  nextLevelPoints,
  setModalLanguage,
  modalLanguage,
  showLanguageDialog,
  onShowLanguageDialog,
  handleCloseLanguageDialog,
  onSelectLanguage,
  hidePoints,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { i18n } = useI18n();

  const introContent = data.find((item: any) => item.slug === 'HOME_INTRO');
  const membershipContent = data.find((item: any) => item.slug === 'MEMBERSHIP');
  const newsletterContent = data
    .filter((item: any) => item.type.find((t: any) => t === 'NEWSLETTER'))
    .sort((a: any, b: any) => Number(new Date(b.updatedAt)) - Number(new Date(a.updatedAt)))[0];

  useEffect(() => {
    dispatch(actions.setShowNewsMenuItem(Boolean(newsletterContent)));
  }, [newsletterContent]);

  return (
    <Wrapper ref={ref}>
      <InnerWrapper ref={ref}>
        <LanguageSelect onClick={onShowLanguageDialog}>
          <LangText>{i18n('TableLandingPage.Language')}</LangText>
          <LanguageIcon />
        </LanguageSelect>
        <OrderStatusWrapper>
          <OrderStatus floating={true} />
          <AnonymousOrdersList />
        </OrderStatusWrapper>
        <Intro content={introContent} onNavigate={onNavigate} isSignedIn={isSignedIn} />
        {membershipContent && (
          <MembershipSection
            content={membershipContent}
            membershipCard={membershipCard}
            nextLevelPoints={nextLevelPoints}
            isSignedIn={isSignedIn}
            hidePoints={hidePoints}
          />
        )}
        {newsletterContent && <NewsSection content={newsletterContent} />}
        <InstagramSection />
      </InnerWrapper>
      <Dialog
        visible={showLanguageDialog}
        onClose={() => handleCloseLanguageDialog()}
        dontCloseOnClickAnywhere>
        <DialogModal
          title={i18n('TableLandingPage.SelectYourLanguage')}
          visible={showLanguageDialog}
          buttonText={i18n('TableLandingPage.SelectYourLanguageButton')}
          onButtonClick={onSelectLanguage}>
          <LanguageSelector setLanguage={setModalLanguage} language={modalLanguage} />
        </DialogModal>
      </Dialog>
    </Wrapper>
  );
};

export default LandingView;
