import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useLogger } from 'Hooks';

import * as actions from 'actions';
import { PutEventsInputEvent } from '../EventsSynchronizer';
import Config from '../../config';
import dayjs from 'dayjs';

interface SocketAPI {
  send: {
    shoppingCartEvent: (event: PutEventsInputEvent) => void;
    socialTableEvent: (event: PutEventsInputEvent) => void;
  };
  socketState: SOCKET_STATE | null;
}

export enum SOCKET_STATE {
  CONNECTING = 'CONNECTING',
  OPEN = 'OPEN',
  CLOSING = 'CLOSING',
  CLOSED = 'CLOSED',
  ERROR = 'ERROR',
}

const Context = React.createContext<SocketAPI>({
  send: {
    shoppingCartEvent: () => {},
    socialTableEvent: () => {},
  },
  socketState: null,
});

const URL = Config.SOCKET_URL;

const createSocket = () => new WebSocket(URL);

const initialSocket = createSocket();

type SocketAction =
  | 'put-shopping-cart-event'
  | 'put-social-table-event'
  | 'ping'
  | 'broadcast-venue-shopping-cart'
  | 'broadcast-venue-social-table';

const SocketProvider: React.FunctionComponent = ({ children }) => {
  const eventBuffer = useRef<
    Array<{
      action: any;
      data?: any;
    }>
  >([]);
  const socket = useRef<WebSocket | null>(null);
  const logger = useLogger('socket-manager');
  const timestampofLastPong = useRef(0);
  const dispatch = useDispatch();
  const venueId = useSelector((state) => state?.venue?.id);
  const cartId = useSelector((state) => state.cartId);
  const tableId = useSelector((state) => state.tableId);
  const [retryAttempt, setRetryAttempt] = useState(0);
  const [socketState, setSocketState] = useState<SOCKET_STATE>(SOCKET_STATE.CONNECTING);

  // Used to keep track of the last pong timestamp
  const pingPongTimeoutRef = useRef<any>(0);

  /* ==================================
      SEND HANDLERS
     ================================== */

  function sendMessage(action: SocketAction, data?: object) {
    if (!socket.current || socket.current.readyState !== WebSocket.OPEN) {
      logger.error('Socket is not initialized', {
        action,
        data,
      });

      eventBuffer.current.push({ action, data });
      return;
    }

    if (action !== 'ping') {
      logger.info(`Sending message (${action})`, {
        action,
        data,
      });
    }

    socket.current.send(
      JSON.stringify({
        action,
        data,
      }),
    );
  }

  /**
   * Sends a shopping cart event to the backend
   */
  const shoppingCartEvent = useCallback((event: PutEventsInputEvent) => {
    sendMessage('put-shopping-cart-event', event);
  }, []);

  /**
   * Sends a social table event to the backend
   */
  const socialTableEvent = useCallback((event: PutEventsInputEvent) => {
    sendMessage('put-social-table-event', event);
  }, []);

  function closeListener(event: CloseEvent) {
    setSocketState(SOCKET_STATE.CLOSED);

    logger.info(`Socket closed. Code: ${event.code}`);
  }

  function openListener() {
    /**
     * Ping the socket once on boot to ensure theres a connection
     */
    triggerPing();

    /**
     * Send any buffered events
     */
    for (const event of eventBuffer.current) {
      sendMessage(event.action, event.data);
    }
    eventBuffer.current = [];

    setSocketState(SOCKET_STATE.OPEN);
  }

  function errorListener(event: Event) {
    logger.warn('Socket error, closing...');
    socket.current!.close();
  }

  function messageListener(event: MessageEvent) {
    try {
      // If this is our keep-alive pingpong, ignore
      if (event.data === 'pong') {
        timestampofLastPong.current = new Date().getTime();
        return;
      }

      const message = JSON.parse(event.data);
      const { message: errorMessage, domain, payload } = message;

      if (errorMessage) {
        logger.error(errorMessage, {
          message,
        });
        return;
      }

      if (!domain) {
        logger.error('Missing domain in socket message', {
          message,
        });
      }

      switch (domain) {
        case 'SHOPPING_CART_EVENT':
          dispatch(actions.addShoppingCartEvent(payload));
          logger.info('Received shopping cart event', { payload });
          break;
        case 'SOCIAL_TABLE_EVENT':
          dispatch(actions.addSocialTableEvent(payload));
          break;
        case 'EVENT_CONFIRMED':
          logger.info('Event confirmed', { payload });
          break;
        default:
          logger.warn('Unknown socket event domain', {
            message,
          });
      }
    } catch (e: any) {
      logger.warn(e.message, { event });
    }
  }

  /* ==================================
      LIFECYCLE FUNCTIONS
     ================================== */

  function triggerPing() {
    sendMessage('ping');

    // Ensure we get a pong back within 5 seconds
    pingPongTimeoutRef.current = setTimeout(() => {
      const diff = dayjs().diff(timestampofLastPong.current, 'seconds');

      if (diff > 5) {
        logger.warn('No pong received within 5 seconds');
        logger.warn('Restarting socket connection...');

        // Not sure if this try / catch is needed but lets stay safe
        try {
          socket.current!.close();
        } catch (e) {
          logger.error((e as Error).message);
        }

        reconnect();
      } else if (socket.current?.readyState !== WebSocket.OPEN) {
        /**
         * If we are not connected, try again as soon as this ping/pong
         * cycle is complete.
         */
        console.log('trigger ping');
        triggerPing();
      }
    }, 5000);
  }

  function reconnect() {
    // And trigger a re-run of the event listener setup effect
    setRetryAttempt((r) => r + 1);
  }

  function broadcastVenue() {
    if (tableId) {
      sendMessage('broadcast-venue-social-table', { tableId });
    }

    // Let the shopping cart service know we want shopping cart events
    sendMessage('broadcast-venue-shopping-cart', { cartId });
  }
  /* ==================================
      SOCKET STATE HANDLING
     ================================== */

  useEffect(() => {
    if (socket.current) {
      //  Clear all event listeners from previous connection
      socket.current.removeEventListener('close', closeListener);
      socket.current.removeEventListener('open', openListener);
      socket.current.removeEventListener('message', messageListener);
      socket.current.removeEventListener('error', errorListener);
      socket.current.close();
    }

    // Create a new socket instance
    socket.current = createSocket();
    setSocketState(SOCKET_STATE.CONNECTING);

    // Set up event listeners
    socket.current.addEventListener('open', openListener);
    socket.current.addEventListener('close', closeListener);
    socket.current.addEventListener('error', errorListener);
    socket.current.addEventListener('message', messageListener);

    /* ==================================
      EFFECTS AND MORE LIFECYCLE
     ================================== */

    /**
     * Ping the socket with regular intervals to keep it alive
     * API Gateway timeout is 10 minutes, but 1 minute feels... better
     */
    const id = setInterval(triggerPing, 6000);
    triggerPing();

    // Refresh the connection every 90 minutes.
    // AWS API Gateway session timeout is 2 hours but lets have some margin
    const refreshId = setTimeout(() => {
      socket.current!.close();
    }, 60 * 90 * 1000);

    return () => {
      clearTimeout(refreshId);
      clearInterval(id);
      clearInterval(pingPongTimeoutRef.current);
    };
  }, [retryAttempt]);

  // Always have the socket state as context to all logs
  useEffect(() => {
    logger.setContext({
      socketState,
    });

    logger.info(`Socket state: ${socketState}`);
  }, [socketState]);

  useEffect(() => {
    if (!venueId || socketState !== SOCKET_STATE.OPEN) {
      return;
    }

    // Broadcast our connection to start accepting new events
    broadcastVenue();
  }, [socketState, venueId, cartId, tableId]);

  const api: SocketAPI = {
    send: {
      shoppingCartEvent,
      socialTableEvent,
    },
    socketState,
  };

  return <Context.Provider value={api}>{children}</Context.Provider>;
};

export const useSocket = () => {
  return useContext(Context);
};

export default SocketProvider;
