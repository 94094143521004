import React from 'react';
import styled from 'styled-components';
import { ReactComponent as NavigateRightIcon } from '../../Assets/Icons/navigate-right.svg';
import Icon from '../Icon';

const Wrapper = styled.button<{
  background: string | undefined;
  textColor: string | undefined;
  disabled: boolean;
  width: string | undefined;
  small: boolean;
  withArrow: boolean;
  floating: boolean;
  noTransform: boolean;
  ghost: boolean;
  disableTransform?: boolean;
}>`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-radius: 4px;
  border: ${({ ghost, theme }) => (ghost ? `2px solid black` : 'none')};
  background-color: ${({ background, theme }) => background || theme.secondary};
  color: ${({ textColor }) => textColor || '#fff'};
  padding: ${({ small }) => (small ? '12px 16px' : '24px 16px')};
  font-size: ${({ small }) => (small ? '12px' : '16px')};
  font-weight: 600;
  text-transform: ${({ noTransform }) => (noTransform ? 'none' : 'uppercase')};
  letter-spacing: 0.24px;
  line-height: 16px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  width: ${({ width }) => width || 'auto'};
  box-sizing: border-box;
  transition: opacity 0.2s ease-in-out;
  margin-bottom: 10px;
  outline: none;
  justify-content: ${({ withArrow }) => (withArrow ? 'space-between' : 'center')};
  cursor: pointer;

  ${({ floating }) =>
    floating &&
    `
      position: fixed;
      text-align: center;
      margin-bottom: calc(16px + env(safe-area-inset-bottom));
      bottom: 0;
      left: 0;
      width: 100%;
      height: 64px;
      border-radius: 0;
    `};

  @media (min-width: 768px) {
    transition: transform 0.1s ease-in-out;

    &:hover {
      transform: ${({ disableTransform }) => (disableTransform ? 'none' : 'scale(0.99)')};
    }
  }
`;

const Arrow = styled.div`
  margin: -9px 0 -9px auto;
`;

interface Props {
  /**
   * Button text
   */
  text: string;
  /**
   * Button handle click
   */
  handleClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * Button background
   */
  background?: string;
  /**
   * Button text color
   */
  textColor?: string;
  /**
   * Button disabled
   */
  disabled?: boolean;
  /**
   * Button width
   */
  width?: string;
  /**
   * Button is small
   */
  small?: boolean;
  /**
   * Button with arrow
   */
  withArrow?: boolean;
  /**
   * Button type
   */
  type?: 'button' | 'submit' | 'reset';
  /**
   * Button floating
   */
  floating?: boolean;
  /**
   * Button no transform
   */
  noTransform?: boolean;
  /**
   * Button ghost
   */
  ghost?: boolean;
  /**
   * disableTransform
   */
  disableTransform?: boolean;
}

/**
 * Button component
 */
const Button: React.FunctionComponent<Props> = ({
  text,
  handleClick,
  background,
  textColor,
  disabled,
  width,
  small,
  withArrow,
  type = 'button',
  floating,
  noTransform,
  ghost,
  disableTransform,
}: Props) => {
  return (
    <Wrapper
      onClick={!disabled ? handleClick : undefined}
      background={background}
      width={width}
      textColor={textColor}
      disabled={Boolean(disabled)}
      small={Boolean(small)}
      type={type}
      withArrow={Boolean(withArrow)}
      floating={Boolean(floating)}
      noTransform={Boolean(noTransform)}
      ghost={Boolean(ghost)}
      disableTransform={disableTransform}>
      {text}
      {withArrow && (
        <Arrow>
          <Icon icon={<NavigateRightIcon />} color={'#fff'} size={32} />
        </Arrow>
      )}
    </Wrapper>
  );
};

export default Button;
