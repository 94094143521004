import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: block;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
`;

const Title = styled.h1`
  margin-top: 10px;
  font-family: 'Eina01';
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  color: #000000;
  text-align: center;
`;

const List = styled.ul`
  list-style: disc;
`;

const ListItem = styled.li`
  margin-top: 5px;
  font-family: 'Eina01';
  font-size: 14px;
  line-height: 1.5;
  color: #000000;
  text-align: left;
`;

const Paragraph = styled.p`
  margin-top: 10px;
  font-family: 'Eina01';
  font-size: 14px;
  line-height: 1.5;
  color: #000000;
  text-align: left;
`;

interface Props {
  name: string;
}

const PrivacyPolicyView = ({ name }: Props) => {
  return (
    <Container>
      <Title>Privacy Policy</Title>
      <Paragraph>
        {name} (”Vi” i någon böjningsform såsom ”Oss”, ”Vår” etc.) är Personuppgiftsansvarig för de
        Personuppgifter som Vi får del av när Du skapar ett användarkonto för denna applikation som
        tillhandahålls av Oss och som hädanefter benämns ”Tjänsten”.
      </Paragraph>
      <br />
      <p>1.PERSONUPPGIFTER SOM BEHANDLAS</p>
      <List>
        <ListItem>Namn</ListItem>
        <ListItem>E-postadress</ListItem>
        <ListItem>Telefonnummer</ListItem>
        <ListItem>IP-adress</ListItem>
        <ListItem>Geografisk position</ListItem>
        <ListItem>Kreditkortsuppgifter</ListItem>
        <ListItem>Device/Enhets-ID</ListItem>
        <ListItem>Köphistorik; produkt, pris och tillfälle.</ListItem>
      </List>
      <Paragraph>
        1.2 Om Du besöker Tjänsten (utan att skapa ett användarkonto): Vi kan komma att samla in,
        använda och lagra särskild, begränsad information som rör Din användning av Tjänsten,
        utöver vad som nämnts ovan, i syfte att bättre förstå den typ av besökare som besöker
        Tjänsten och för att förbättra Vårt erbjudande. Information som Vi samlar in om besökare i
        Tjänsten ger inte möjlighet till att identifiera enskilda personer och behandlas endast i
        anonym, aggregerad form, och kommer endast användas för att analysera Tjänsten.
      </Paragraph>
      <Paragraph>
        1.3 När Du skapar ett användarkonto i Tjänsten kommer även viss information inhämtas
        automatiskt av Oss. Automatiskt insamlade Personuppgifter är följande:
      </Paragraph>
      <List>
        <ListItem>Information om hur Du använder Tjänsten.</ListItem>
        <ListItem>
          Teknisk information, innefattande bl.a. Ditt unika enhetsnummer (unique device ID), språk
          och information om identifiering samt nätverksprestanda.
        </ListItem>
        <ListItem>Lokaliseringsuppgifter</ListItem>
      </List>
      <Paragraph>
        1.4 Vi kan förutom Personuppgifter komma att samla in uppgifter genom tekniska lösningar
        för analys.
      </Paragraph>
      <Paragraph>2. ÄNDAMÅLEN</Paragraph>
      <Paragraph>
        2.1 Att leverera Tjänsten: Vi behandlar de insamlade Personuppgifterna för flera ändamål.
        Framförallt används Personuppgifterna för att tillhandahålla Tjänsten till Dig, men också
        för att hantera, utveckla och anpassa Tjänsten och dess funktioner samt för att uppfylla
        säkerhetskrav och andra lagstadgade krav som ställs på Oss.
      </Paragraph>
      <Paragraph>
        2.2 Att kunna förbättra, utveckla och analysera Tjänsten: Vi behandlar även de insamlade
        Personuppgifterna för att kunna genomföra kundundersökningar samt för att kunna analysera,
        arbeta med och förbättra Din användarupplevelse av Tjänsten samt säkerställa dess tekniska
        funktionalitet. Vi kan också komma att behandla Personuppgifter för att fullgöra en
        rättslig förpliktelse.
      </Paragraph>
      <Paragraph>
        2.3 Att kunna kommunicera med Dig och ge Dig erbjudanden: Vi behandlar vidare de insamlade
        Personuppgifterna för att kunna kommunicera med Dig som användare, profilera Dina intressen
        och kunna ge rekommendationer på innehåll som intresserar Dig, och för att kunna skicka Dig
        information om Oss via email. Detta innefattar marknadsföring av Våra varor och tjänster, i
        t.ex. nyhetsbrev och annan direktmarknadsföring. Skulle Du inte vilja att Vi behandlar Dina
        Personuppgifter för direktmarknadsföring inbegripet profilering så kan Du alltid kontakta
        Oss och avanmäla Dig från framtida utskick.
      </Paragraph>
      <Paragraph>
        2.4 Att kunna utföra analys av historisk och aggregerad anonym användardata: Vi utför
        analys på historisk och aggregerad anonym användardata för att förbättra Vår förståelse för
        Våra kunder och för att därmed kunna förbättra Tjänsten och Våra erbjudanden.
      </Paragraph>
      <Paragraph>3.HUR VI DELAR INFORMATION</Paragraph>
      3.1 Vi kan komma att dela Dina Personuppgifter och annan information till underleverantörer
      för att t.ex. kunna:
      <List>
        <ListItem>3.1.1 Tillhandahålla Tjänsten till Dig;</ListItem>
        <ListItem>3.1.2 Tillhandahålla supporttjänster till Dig;</ListItem>
        <ListItem>3.1.3 Hantera datalagring på externa servrar;</ListItem>
        <ListItem>
          3.1.4 Kommunicera med Dig för att svara på eventuella frågor Du har om oss och om
          Tjänsten;
        </ListItem>
        <ListItem>
          3.1.5 Genomföra kundanalyser och analysera kundbeteende, användarupplevelse och
          användning av Tjänsten;
        </ListItem>
      </List>
      <Paragraph>
        3.2 Dessa underleverantörer agerar som Personuppgiftsbiträden för Dina Personuppgifter:
        Mingo AB tillhandahåller mjukvaran till Tjänsten.
      </Paragraph>
      <Paragraph>4.YTTERLIGARE INFORMATION OM HUR VI BEHANDLAR PERSONUPPGIFTER</Paragraph>
      <Paragraph>
        4.1 Du kommer att informeras om eventuella ändringar nästa gång Du loggar in på Tjänsten.
        Om ändringarna är väsentliga, kommer Vi även meddela Dig på ett sätt som är lämpligt för
        omständigheterna, t.ex. via e-post till den e-postadress Du uppgivit till Oss.
      </Paragraph>
      <Paragraph>
        4.2 För mer information om hur Vi behandlar och skyddar personuppgifter kan Du besöka eller
        kontakta Oss.
      </Paragraph>
      <Paragraph>5.INFORMATION OM LEVERANS OCH UTBUD</Paragraph>
      <Paragraph>
        5.1 Du som gäst är ansvarig för att din beställning hämtas vid köpstället, då leverans inte
        erbjuds inom ramarna för denna tjänst.
      </Paragraph>
      <Paragraph>
        5.2 Vissa produkter, såsom alkoholhaltiga drycker, kan vara olagliga eller olämpliga att
        sälja i syfte att bäras ut från restaurangen. Vidare kan det finnas andra skäl att begränsa
        utbudet baserat på individ och situationens beskaffenhet. Vi förbehåller oss därför rätten
        att begränsa utbudet - och därmed utlämning av beställning - i enlighet med hur vi tolkar
        situationen och utifrån gällande bestämmelser.
      </Paragraph>
      <Paragraph>6.RETUR- OCH ÅTERBETALNINGSPOLICY</Paragraph>
      <Paragraph>
        6.1 Grundregeln att konsumenter har rätt till 14 dagars ångerfrist gäller ej färskvaror
        eller specialtillverkade produkter, varför köp av mat och dryck via denna tjänst inte per
        automatik kan ångras. 6.2 Eventuella återköp och reklamationer sköts direkt med en
        representant för köpstället, separat i samband med uthämtning av Din beställning.
      </Paragraph>
      <Paragraph>7.BESKRIVNING AV PRODUKT OCH TJÄNST</Paragraph>
      <Paragraph>
        7.1 Genom denna tjänst ges Du som konsument möjligheten att välja och köpa mat samt dryck i
        enlighet med det utbud som av {name} presenteras.
      </Paragraph>
      <br />
      <br />
      <br />
    </Container>
  );
};

export default PrivacyPolicyView;
