import translations from '../i18n/translations';
import type { useI18nProps, i18nProps } from '../Types';
import { useStorage } from 'Components/Storage';

const useI18n: useI18nProps = () => {
  const [storage] = useStorage();
  const language = storage.language;

  const getTranslate = (translatedString: any) => {
    if (language && translatedString[language]) {
      return translatedString[language];
    }

    const keys = Object.keys(translatedString);
    for (let i = 0; i < keys.length; i += 1) {
      if (translatedString[keys[i]] && keys[i] !== 'id' && keys[i] !== '__typename') {
        return translatedString[keys[i]];
      }
    }
    return '';
  };

  const i18n: i18nProps = (obj, ...params) => {
    if (!obj || !language) {
      return '';
    }

    if (typeof obj === 'object') {
      return getTranslate(obj);
    }

    if (!translations[obj]) {
      return obj;
    }

    const text = translations[obj][language];
    if (text) {
      if (typeof text === 'string') {
        return text;
      }
      const formattedText = text(...params);
      return formattedText.includes(undefined) ? '' : formattedText;
    }
    console.log('Missing translation', obj);
    return `Missing translation: [${obj}]`;
  };

  const validate = (key: string) => {
    return Boolean(translations[key]);
  };

  return { i18n, language, validate };
};

export default useI18n;
