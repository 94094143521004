import React from 'react';
import styled from 'styled-components';
const defaultSize = 15;
const defaultColor = '#212124';

const Image = styled.div<{
  width: number;
  height: number;
  color: string;
  cursorPointer: boolean;
}>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  display: flex;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'inherit')};

  svg {
    max-height: 100%;
    max-width: 100%;
    fill: ${({ color }) => color};
  }
`;

type Props = {
  icon: any;
  size?: number;
  width?: number;
  height?: number;
  color?: string;
  onClick?: () => void;
  contain?: boolean;
};

const Icon = ({ icon, size, width, height, color, onClick }: Props) => {
  const styles = { width: defaultSize, height: defaultSize, color: defaultColor };
  if (size) {
    styles.width = size;
    styles.height = size;
  }
  if (width && height) {
    styles.width = width;
    styles.height = height;
  }

  if (color) {
    styles.color = color;
  }

  return (
    <Image
      onClick={onClick}
      width={styles.width}
      height={styles.height}
      color={styles.color}
      cursorPointer={Boolean(onClick)}>
      {icon}
    </Image>
  );
};

export default Icon;
