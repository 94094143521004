import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../Components/Button';
import useI18n from 'i18n';
import { DEVICE_SIZES, getDeviceSize } from '../../utils/deviceSize';

const Wrapper = styled.div`
  padding: 120px 24px 88px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Inner = styled.div`
  @media (min-width: 768px) {
    width: 417px;
    margin: 0 auto;
  }
`;

const HiddenInput = styled.input`
  opacity: 0;
  position: absolute;
  appearance: none;
  pointer-events: none;
  user-select: text;
`;

const Text = styled.p`
  font-size: 14px;
  text-align: center;
  margin-bottom: 24px;
`;

const NewCode = styled.div`
  appearance: none;
  border: none;
  text-align: center;
  background-color: transparent;
  color: #212124;
  text-decoration: underline;
  padding: 0;
  margin: 0 auto;
  display: block;
  margin-top: 24px;
  font-size: 16px;
  letter-spacing: 0.24px;
  line-height: 20px;
  outline: none;
  cursor: pointer;

  @media (min-width: 768px) {
    margin-bottom: 24px;
  }
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 37px;
`;
const Error = styled.p`
  font-size: 14px;
  color: #e3273b;
  text-align: center;
`;

const InputsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CodeInput = styled.div<{ active: boolean; error: boolean }>`
  height: 64px;
  width: 48px;
  border: 1px solid;
  border-color: ${({ active, error }) => (error ? '#e3273b' : active ? '#9e9ea1' : '#bdbdc0')};
  border-width: ${({ active }) => (active ? '2px' : '1px')};
  border-radius: 4px;
  margin: 0 8px;
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  position: relative;
  transition: border-color 0.2s ease-in-out;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: 3px;
    width: 30px;
    height: 2px;
    background-color: #9e9ea1;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: ${({ active }) => (active ? '1' : '0')};
    transition: opacity 0.2s ease-in-out;
  }
`;

interface Props {
  loading: boolean;
  code: string;
  handleChange: (val: string) => void;
  error: string;
  sendNewCode: () => void;
  handleVerify: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const VerifyAccount: React.FC<Props> = ({
  loading,
  code,
  handleChange,
  error,
  sendNewCode,
  handleVerify,
}) => {
  const { i18n } = useI18n();
  const codeLength = 4;
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const deviceSize = getDeviceSize();

  const focusInput = () => {
    if (inputRef.current) {
      handleChange('');
      inputRef.current.focus();
    }
  };

  useEffect(() => focusInput(), []);

  const CodeInputs = () => {
    const inputs = [];
    for (let i = 0; i < codeLength; i++) {
      inputs.push(
        <CodeInput active={inputFocused && code.length == i} key={i} error={Boolean(error)}>
          <span>{code.charAt(i)}</span>
        </CodeInput>,
      );
    }
    return <>{inputs}</>;
  };

  return (
    <Wrapper>
      <Inner>
        <Text>{i18n('VerifyAccount.Text')}</Text>
        <form>
          <HiddenInput
            name="code"
            ref={inputRef}
            type="number"
            value={code}
            onBlur={() => setInputFocused(false)}
            onFocus={() => setInputFocused(true)}
            onClick={() => setInputFocused(true)}
            onChange={(e) => handleChange(e.target.value)}
            autoFocus
          />
          <InputsWrapper onClick={focusInput}>
            <CodeInputs />
          </InputsWrapper>
          <ErrorWrapper>{error && <Error>{error}</Error>}</ErrorWrapper>
          <NewCode onClick={sendNewCode}>{i18n('VerifyAccount.NewCode')}</NewCode>
          <Button
            handleClick={handleVerify}
            text={loading ? i18n('General.Loading') : i18n('VerifyAccount.ButtonText')}
            floating={deviceSize === DEVICE_SIZES.SMALL}
            width="100%"
            type={'submit'}
            disabled={loading}
          />
        </form>
      </Inner>
    </Wrapper>
  );
};

export default VerifyAccount;
