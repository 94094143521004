import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';
import useI18n from 'i18n';

import { getOffers, getOffers_user_tokens_offer } from './__queries__';
import { useShoppingCartApi, useShoppingCart } from 'Components/ShoppingCartUniverse';
import { useStorage } from 'Components/Storage';
import { useLogger, useSelector, useDispatch } from 'Hooks';
import * as actions from 'actions';

enum OFFER_TYPE {
  BUNDLE = 'BUNDLE',
  ITEM_DISCOUNT = 'ITEM_DISCOUNT',
  ORDER_DISCOUNT = 'ORDER_DISCOUNT',
  STAGED_BUNDLE = 'STAGED_BUNDLE',
  STAGED_INDIVIDUAL = 'STAGED_INDIVIDUAL',
  VISUAL = 'VISUAL',
}

const USER_OFFER_QUERY = gql`
  query getOffers {
    user {
      id
      tokens(
        orderBy: createdAt_DESC
        filter: { used: false, expired: false, offer: { deleted: false, enabled: true } }
      ) {
        id
        singleUse
        offer {
          id
          context
          stages {
            id
            discountRate
            discountType
            items {
              id
            }
          }
          createdAt
          type
          discountRate
          discountType
          price
          items {
            id
          }
          nameLang {
            id
            se
            en
            no
          }
          venues {
            id
          }
          descriptionLang {
            id
            se
            en
            no
          }
          image {
            id
            file {
              id
              url
            }
          }
        }
      }
    }
  }
`;
export interface OfferTokens extends getOffers_user_tokens_offer {
  tokenId: string;
}

const OfferManager = () => {
  const logger = useLogger('Offermanager');
  const dispatch = useDispatch();
  const cartId = useSelector((state) => state.cartId);
  const { i18n } = useI18n();
  const [storage] = useStorage();
  const { authToken } = storage;
  const [tokens, setTokens] = useState<OfferTokens[]>([]);
  const shoppingCartApi = useShoppingCartApi();
  const shoppingCart = useShoppingCart();
  const { data } = useQuery<getOffers>(USER_OFFER_QUERY, { fetchPolicy: 'network-only' });

  useEffect(() => {
    if (!tokens) {
      return;
    }
    dispatch(actions.setAvailableOffers(tokens));
  }, [tokens]);

  useEffect(() => {
    // Check if the user is logged in and then get their offers
    if (!authToken || !data?.user) {
      return;
    }

    const tokenList = data.user.tokens
      .map((token) => ({ ...token.offer, tokenId: token.id }))
      .filter((token) => token.context !== 'POS')
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    setTokens(uniqBy(tokenList, ({ id }) => id));
  }, [authToken, data?.user]);

  useEffect(() => {
    if (!cartId || !tokens) {
      return;
    }
    if (shoppingCart.promoCode) {
      return;
    }

    // Apply available offers to shoppingCart:
    // Go through all tokens to add an offer on.
    tokens.forEach((token) => {
      // If we have a ADD_OFFER event already, and the tokenID of this event matches the current token ID or if the shoppingcart already has an offer with the same id as the token "quick" return.
      if (
        shoppingCart.availableItemOffers.some((offer) => offer.tokenId === token.tokenId) ||
        shoppingCart.offer?.id === token.id
      ) {
        logger.info('Offer: ' + token.descriptionLang.se + ', already in cart, skipping', {
          shoppingCart,
        });
        return;
      }

      // If this is another order offer, and we don't have it in our cart, dispatch SET_ORDER_OFFER if the conditions are met.
      if (token.type === OFFER_TYPE.ORDER_DISCOUNT) {
        // This is needed to run the discount comparison in the if statement below.
        // If we have a set discount rate on the cart, check towards that discount rate, otherwise check towards 0 instead of undefined.
        const currentCartDiscount = shoppingCart.offer?.discountRate ?? 0;

        if (
          token.id &&
          token.discountRate &&
          token.discountType &&
          // If the incoming offers discount rate is higher than the cart offer discount rate, replace it.
          token.discountRate > currentCartDiscount &&
          // Make sure the token id is not the same as the one already in the cart
          token.id !== shoppingCart.offer?.id
        ) {
          // Log that we're adding the order offer.
          logger.info(`Applying order offer: ${token.descriptionLang.se}`, {
            shoppingCart,
            offer: token,
          });

          return shoppingCartApi.setOrderOffer(
            token.id,
            i18n(token.nameLang),
            token.discountRate,
            token.discountType,
          );
        } else {
          // If the offer type is "ORDER_DISCOUNT", and it doesn't fulfill the above requirements
          // (i.e) discountRate is higher, then we want to exit. Else it will continue and send a "ADD_OFFER" event.
          // Which we only use for staged offers. Order offers uses "SET_ORDER_OFFER" event.
          return;
        }
      }

      // Log that we're adding the staged offer.
      logger.info(`Applying staged offer: ${token.descriptionLang.se}`, {
        shoppingCart,
        offer: token,
      });

      // If this is another staged offer, and we don't have it in our cart, dispatch ADD_OFFER.
      return shoppingCartApi.addAvailableOffer({
        itemIds: token.items.map((item) => item.id),
        offerType: token.type,
        title: i18n(token.nameLang),
        tokenId: token.tokenId,
        discountRate: token.discountRate,
        discountType: token.discountType,
        id: token.id,
        stages: token.stages.map(({ discountRate, discountType, id, items }) => ({
          discountRate,
          discountType,
          id,
          itemIds: items.map((item) => item.id),
          title: '',
        })),
      });
    });
  }, [cartId, tokens, shoppingCart]);

  return null;
};

export default OfferManager;
