import * as actions from 'actions';
import { useStorage } from 'Components/Storage';
import { useSelector, useDispatch } from 'Hooks';
import jwtDecode from 'utils/jwtDecode';
import { gql, useMutation } from '@apollo/client';
import { removeDeviceToken, removeDeviceTokenVariables } from './__queries__';
import { useShoppingCartApi } from 'Components/ShoppingCartUniverse';

const REMOVE_DEVICE_TOKEN = gql`
  mutation removeDeviceToken($token: String!) {
    removeDeviceToken(token: $token) {
      success
      message
    }
  }
`;

const useAuth = () => {
  const [{ authToken, device }, updateStorage] = useStorage();
  const shoppingCartApi = useShoppingCartApi();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [removeDeviceToken] = useMutation<removeDeviceToken, removeDeviceTokenVariables>(
    REMOVE_DEVICE_TOKEN,
  );

  const setLocalAuthToken = (token: string, callback?: () => void) => {
    if (token !== authToken) {
      updateStorage({ authToken: token });
      if (callback) {
        callback();
      }
    }
  };

  const unregisterDeviceToken = () => {
    if (!device) {
      return;
    }

    const { token } = device;

    removeDeviceToken({
      variables: { token },
    });

    updateStorage({ device: { ...device, registered: false } });
  };

  const logout = () => {
    unregisterDeviceToken();
    updateStorage({ authToken: null, temporaryAuthToken: null, cartId: null });
    shoppingCartApi.clearPromoCode();
    shoppingCartApi.clearCart();
    dispatch(actions.setCartId(null));
    dispatch(actions.logout());
  };

  const hasAuthToken = () => {
    if (!authToken) {
      return false;
    }
    const parsedToken = jwtDecode(authToken);

    if (authToken && parsedToken && parsedToken.exp && parsedToken.exp > Date.now() / 1000) {
      return true;
    }

    return false;
  };

  const checkUserValid = () => {
    if (!user) {
      logout();
    }
  };

  return { logout, setLocalAuthToken, checkUserValid, hasAuthToken };
};

export default useAuth;
