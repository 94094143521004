import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import useI18n from 'i18n';
import { useBridgeApi, useDispatch, useLogger } from 'Hooks';
import ReactGA from 'react-ga4';
import { setAlert, setLoginScreen } from 'actions';
import TextField from '../../Components/TextField';
import Button from '../../Components/Button';
import rules from './rules';
import { useStorage } from 'Components/Storage';
import Navbar from '../../Components/Navbar';
import { CheckUser, Register, RegisterVariables } from './__queries__';
import { DEVICE_SIZES, getDeviceSize } from '../../utils/deviceSize';
import { useConfig } from 'Components/ConfigProvider';

const Wrapper = styled.div`
  padding: 120px 24px 88px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Inner = styled.div`
  @media (min-width: 768px) {
    width: 417px;
    margin: 0 auto;
  }
`;

const Discount = styled.div`
  @media (min-width: 768px) {
    margin: 0 0 24px 0;
  }
`;

export const SIGN_UP = gql`
  mutation Register(
    $companyId: ID!
    $name: String!
    $phone: String!
    $password: String!
    $email: String
    $referralCode: String
  ) {
    register(
      companyId: $companyId
      name: $name
      phone: $phone
      referral: $referralCode
      password: $password
      email: $email
    ) {
      error
      token
    }
  }
`;

const CHECK_USER_QUERY = gql`
  query CheckUser {
    user {
      id
      activated
    }
  }
`;

const SignUp = () => {
  const config = useConfig();
  const defaultFormData = {
    name: '',
    phone: config.PHONE_COUNTRY_CODE ?? '+46',
    password: '',
    password2: '',
    discountCode: '',
  };

  const [, updateStorage] = useStorage();
  const { i18n } = useI18n();
  const api = useBridgeApi();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<{ [key: string]: string }>(defaultFormData);
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: '',
    password: '',
    password2: '',
  });
  const deviceSize = getDeviceSize();

  const logger = useLogger('user-register');

  const [signUp] = useMutation<Register, RegisterVariables>(SIGN_UP, {
    variables: {
      companyId: config.COMPANY_ID,
      name: formData.name,
      phone: formData.phone.replace(/\s/g, ''),
      email: formData.email,
      password: formData.password,
      referralCode: formData.discountCode,
    },
  });

  const [getUser] = useLazyQuery<CheckUser>(CHECK_USER_QUERY, {
    fetchPolicy: 'network-only',
  });

  const submit = step === 2;
  const nrOfSteps = 3;

  const handleSignUp = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const isValid = handleValidate();
    if (!isValid) {
      return;
    }

    setLoading(true);

    try {
      // Iniitiate the redirect path which will change based on a few confitions below.
      const response = await signUp();
      if (response.data) {
        const { error, token } = response.data.register;

        if (error === 'ERROR_VALID_USER') {
          // If the user already existed.
          updateStorage({ temporaryAuthToken: token });
          const user = await getUser();
          const activated = user?.data?.user.activated;

          if (activated) {
            // If the user is already activated. Log them in and redirect them to start.
            updateStorage({ authToken: token });
            dispatch(setLoginScreen(''));
          } else {
            // If the user is not activated, set the redirect to verify account.
            dispatch(setLoginScreen('verify-account'));
          }
        } else if (error) {
          // If we got an unexpected error.
          throw new Error(error);
        } else {
          // If the signup went fine. Set the redirect path to verify account.
          updateStorage({ temporaryAuthToken: token });
          dispatch(setLoginScreen('verify-account'));
        }
      }
      api.vibrate('impactLight');
      setLoading(false);

      logger.info('user registered.');

      // Trigger GA event that a user has registered an account.
      ReactGA.event({
        category: 'User',
        action: 'user_registered',
      });
    } catch (err: any) {
      console.log('handleSignUp error', err);
      logger.warn('user registration failed', {
        description: i18n(`Error.${err.message}`),
      });
      dispatch(
        setAlert({
          title: 'SignIn.Error.Failed',
          description: i18n(`Error.${err.message}`),
        }),
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    setFormData(defaultFormData);
    setErrors({
      name: '',
      phone: '',
      email: '',
      password: '',
      password2: '',
    });
  }, []);

  const handleNext = () => {
    const isValid = handleValidate();
    if (!isValid) {
      return;
    }
    setStep((prev) => prev + 1);
  };

  const handleGoBack = () => {
    if (step) {
      return setStep((prev) => (prev ? prev - 1 : prev));
    }
    dispatch(setLoginScreen(''));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleValidate = () => {
    const messages: { [key: string]: string } = { ...errors };

    if (rules[step]) {
      rules[step].forEach((field) => {
        let valid = false;
        if (field.name === 'phone') {
          const phoneNumber = parsePhoneNumberFromString(formData[field.name]);
          valid = Boolean(phoneNumber && phoneNumber.isValid());
        } else if (field.regexp) {
          valid = field.regexp.test(formData[field.name]);
        } else if (field.compareWith) {
          valid = formData[field.compareWith] === formData[field.name];
        }

        messages[field.name] = valid ? '' : i18n(field.message);
      });
    }

    setErrors((prev) => ({ ...prev, ...messages }));

    return Object.values(rules[step] ?? []).every((el) => !messages[el.name]);
  };

  const getButtonText = () => {
    let text = '';

    if (loading) {
      text = 'General.Loading';
    } else if (submit) {
      text = 'Button.SubmitButton';
    } else {
      text = 'Button.Next';
    }
    return i18n(text);
  };

  const onClose = () => {
    step ? setStep(step - 1) : dispatch(setLoginScreen(''));
  };

  return (
    <>
      <Navbar
        title={i18n('SignUp.Step.Title', `${step + 1}`, `${nrOfSteps}`)}
        showClose
        onBack={handleGoBack}
        onClose={onClose}
      />
      <Wrapper>
        <Inner>
          {step === 0 && (
            <>
              <TextField
                label={i18n('Signup.Name')}
                placeholder={i18n('Signup.Name.Placeholder')}
                onChange={handleChange}
                value={formData.name}
                name={'name'}
                error={errors.name}
              />
              <TextField
                label={i18n('Input.Phone')}
                placeholder={i18n('Signup.Phone.Placeholder')}
                onChange={handleChange}
                value={formData.phone}
                name={'phone'}
                error={errors.phone}
                type={'tel'}
              />
              <TextField
                label={i18n('Signup.Email')}
                placeholder={i18n('Signup.Email.Placeholder')}
                onChange={handleChange}
                value={formData.email}
                name={'email'}
                error={errors.email}
                type={'email'}
              />
            </>
          )}
          {step === 1 && (
            <>
              <TextField
                label={i18n('Input.Password')}
                placeholder={i18n('Input.Password.Placeholder')}
                onChange={handleChange}
                value={formData.password}
                name={'password'}
                error={errors.password}
                type={'password'}
              />
              <TextField
                label={i18n('Input.PasswordAgain')}
                placeholder={i18n('Input.PasswordAgain.Placeholder')}
                onChange={handleChange}
                value={formData.password2}
                name={'password2'}
                error={errors.password2}
                type={'password'}
              />
            </>
          )}
          {submit && (
            <Discount>
              <TextField
                label={i18n('Signup.Discount')}
                placeholder={i18n('Signup.Discount.Placeholder')}
                onChange={handleChange}
                value={formData.discountCode}
                name={'discountCode'}
                marginBottom="16px"
              />
              <p>{i18n('Signup.Discount.Infotext')}</p>
            </Discount>
          )}
          <Button
            text={getButtonText()}
            handleClick={submit ? handleSignUp : handleNext}
            floating={deviceSize === DEVICE_SIZES.SMALL}
            width="100%"
            disabled={loading}
          />
        </Inner>
      </Wrapper>
    </>
  );
};

export default SignUp;
