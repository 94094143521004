import React from 'react';
import styled from 'styled-components';
import useI18n from 'i18n';

import { useCurrency } from 'Hooks';
import { useShoppingCart } from 'Components/ShoppingCartUniverse';
import OpacityTouchable from 'Components/OpacityTouchable';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 28px;
  margin-top: 16px;
  padding: 0 16px;
`;

const TipButton = styled.div<{ isActive: boolean }>`
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.16), 0 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 64px;
  padding: 12px;
  background-color: ${({ isActive }) => (isActive ? '#06d6a0' : 'transparent')};
`;

const InfoText = styled.div`
  font-size: 11px;
  font-weight: 400;
  font-family: Eina01;
`;

const Description = styled.h4`
  margin-top: 12px;
  text-align: center;
  font-family: Eina01;
`;

interface Props {
  tips: number;
  setTips: React.Dispatch<React.SetStateAction<number>>;
}

const Tips: React.FC<Props> = ({ tips, setTips }) => {
  const { total } = useShoppingCart();
  const { formatAmount } = useCurrency();
  const { i18n } = useI18n();
  const tipAmounts = [
    { value: 0.05, text: '5%' },
    { value: 0.1, text: '10%' },
    { value: 0.15, text: '15%' },
    { value: 0.2, text: '20%' },
  ];

  const tipHandler = (value: number) => {
    if (tips === Math.round(total * value)) {
      setTips(0);
    } else {
      setTips(Math.round(total * value));
    }
  };

  return (
    <div>
      <Description>{i18n('Cart.Tips.Description')}</Description>
      <ButtonContainer>
        {tipAmounts.map((amount) => (
          <OpacityTouchable>
            <TipButton
              isActive={Boolean(tips === Math.round(total * amount.value))}
              onClick={() => tipHandler(amount.value)}>
              <b>{amount.text}</b>
              <InfoText>{formatAmount(Math.round(total * amount.value))}</InfoText>
            </TipButton>
          </OpacityTouchable>
        ))}
      </ButtonContainer>
    </div>
  );
};

export default Tips;
