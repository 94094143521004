import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ExpandMoreIcon } from '../../../Assets/Icons/navigate-down.svg';
import Icon from '../../../Components/Icon';

const Header = styled.div<{ collapsible?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -24px;
  padding: 12px 24px 14px 24px;
  background: rgb(245, 245, 248);
  border-bottom: solid 1px #ffffff;
  //position: sticky;
  top: ${() => window.headerHeight}px;
  z-index: 2;
  cursor: ${({ collapsible }) => (collapsible ? 'pointer' : 'initial')};

  @media (min-width: 992px) {
    margin: 0;
  }
`;

const HeaderText = styled.div``;

const Title = styled.h3`
  color: rgb(33, 33, 36);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 20px;
  margin: 0 0 2px 0;
`;

const Description = styled.p`
  color: rgb(117, 117, 120);
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 16px;
`;

const IconWrapper = styled.div<{ opened: boolean }>`
  background: rgb(255, 255, 255);
  border: 1px solid rgb(224, 224, 227);
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${(props) => (props.opened ? 'rotate(-180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s ease-in-out;
`;

type Props = {
  /**
   * onSelect
   */
  onSelect: () => void;
  /**
   * title
   */
  title: string;
  /**
   * description
   */
  description: string;
  /**
   * opened
   */
  opened: boolean;
  /**
   * collapsible
   */
  collapsible?: boolean;
};

/**
 * GroupHeader component
 */
const GroupHeader = ({ onSelect, title, description, opened, collapsible }: Props) => {
  return (
    <Header onClick={collapsible ? onSelect : undefined} collapsible={collapsible}>
      <HeaderText id="groupHeader">
        <Title>{title}</Title>
        <Description>{description}</Description>
      </HeaderText>
      {collapsible && (
        <IconWrapper opened={opened}>
          <Icon icon={<ExpandMoreIcon />} color={'#212124'} size={17} />
        </IconWrapper>
      )}
    </Header>
  );
};

export default GroupHeader;
