import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ size: number }>`
  height: ${(props) => props.size + 16}px;
  width: ${(props) => props.size + 16}px;
  background-color: #fff;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.16);
  margin-top: 12px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const InnerWrapper = styled.div<{ size: number }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  text-align: center;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

const Points = styled.strong`
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: #212124;
`;

const TotalPoints = styled.p`
  font-size: 10px;
  font-weight: 600;
  color: #212124;
`;

const Circle = styled.div<{ size: number }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  position: absolute;
  top: 0;
  left: 0;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  svg:last-child {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    transform: rotate(-90deg);
  }
`;

interface Props {
  points: number | undefined;
  nextLevelPoints: number | undefined;
  strokeColor?: string;
}

/**
 * PointsCircle component
 */

const PointsCircle: React.FC<Props> = ({ points, nextLevelPoints, strokeColor = '#46bbbf' }) => {
  const circleSize = 88;
  const circleStrokeWidth = 10;
  const circleRadius = circleSize / 2 - circleStrokeWidth / 2;
  const circleCircumference = 2 * Math.PI * circleRadius;

  const circlePoints =
    points !== undefined && nextLevelPoints
      ? ((100 - (points / nextLevelPoints) * 100) / 100) * circleCircumference
      : 0;

  return (
    <Wrapper size={circleSize}>
      <InnerWrapper size={circleSize}>
        <Points>{points}</Points>
        <TotalPoints>/{nextLevelPoints}</TotalPoints>
        <Circle size={circleSize}>
          <svg height={circleSize} width={circleSize}>
            <circle
              cx={circleSize / 2}
              cy={circleSize / 2}
              r={circleRadius}
              stroke="#e0e0e3"
              strokeWidth={circleStrokeWidth - 3}
              fillOpacity="0"
            />
          </svg>
          <svg height={circleSize} width={circleSize}>
            <circle
              cx={circleSize / 2}
              cy={circleSize / 2}
              r={circleRadius}
              stroke={strokeColor}
              strokeWidth={circleStrokeWidth}
              strokeLinecap="round"
              fillOpacity="0"
              strokeDasharray={circleCircumference}
              strokeDashoffset={circlePoints}
            />
          </svg>
        </Circle>
      </InnerWrapper>
    </Wrapper>
  );
};

export default PointsCircle;
