export enum DEVICE_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
  EXTRA_EXTRA_LARGE = 'EXTRA_EXTRA_LARGE',
}

export const getDeviceSize = () => {
  const windowWidth = window.innerWidth;

  if (windowWidth >= 768 && windowWidth < 992) {
    return DEVICE_SIZES.MEDIUM;
  }

  if (window.innerWidth >= 992 && windowWidth < 1200) {
    return DEVICE_SIZES.LARGE;
  }

  if (window.innerWidth >= 1200 && windowWidth < 1400) {
    return DEVICE_SIZES.EXTRA_LARGE;
  }

  if (window.innerWidth >= 1400) {
    return DEVICE_SIZES.EXTRA_EXTRA_LARGE;
  }

  return DEVICE_SIZES.SMALL;
};
