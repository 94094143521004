import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import useI18n from 'i18n';
import BottomModal from 'Components/BottomModal';
import Button from 'Components/Button';
import { useDispatch } from '../../Hooks';
import { setLoginModal, setLoginScreen } from '../../actions';

export const ModalContent = styled.div`
  padding: 24px 24px 88px;

  @media (min-width: 768px) {
    padding: 24px 24px 58px;
  }
`;
export const ButtonWrapper = styled(Link)`
  display: block;
  margin: 0 0 8px 0;
`;

interface Props {
  /**
   * show
   */
  show: boolean;
}

/**
 * SignInBottomModal component
 */

const SignInBottomModal = ({ show }: Props) => {
  const { i18n } = useI18n();
  const dispatch = useDispatch();

  const handleHide = () => dispatch(setLoginModal(false));

  if (!show) {
    return null;
  }

  return (
    <BottomModal title={i18n('Signin.Modal.Title')} onClose={handleHide}>
      <ModalContent>
        <ButtonWrapper
          to={'#'}
          onClick={() => {
            handleHide();
            dispatch(setLoginScreen('signup'));
          }}>
          <Button text={i18n('Signin.Modal.Button.Create')} width="100%" />
        </ButtonWrapper>
        <ButtonWrapper
          to={'#'}
          onClick={() => {
            handleHide();
            dispatch(setLoginScreen('signin'));
          }}>
          <Button text={i18n('Signin.Modal.Button.Signin')} width="100%" />
        </ButtonWrapper>
      </ModalContent>
    </BottomModal>
  );
};

export default SignInBottomModal;
