import { setAlert } from 'actions';
import { useDispatch, useSelector } from 'Hooks';
import useI18n from 'i18n';
import Dialog from '.';
import DialogModal from './Modal';

/**
 * Dialog Alert component
 */

const Alert = () => {
  const { i18n, validate } = useI18n();
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const show = Boolean(alert);

  if (!alert) {
    return null;
  }

  let title = alert.title;
  let description = alert.description;

  if (validate(title)) {
    title = i18n(title);
  }
  if (validate(description)) {
    description = i18n(description);
  }

  const hideDialog = () => {
    dispatch(setAlert(null));
  };

  return (
    <Dialog onClose={hideDialog} visible={show}>
      <DialogModal
        title={title}
        text={description}
        visible={show}
        buttonText={i18n('VerifyAccount.Modal.ButtonText')}
        onButtonClick={hideDialog}
        centerText
      />
    </Dialog>
  );
};

export default Alert;
