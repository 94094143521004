import styled from 'styled-components';
import defaultHeroImage from 'Assets/Images/Hero.png';
import getImageUrlWithSize from 'utils/getImageUrlWithSize';

const Wrapper = styled.div`
  height: 232px;
  position: relative;

  @media (min-width: 992px) {
    display: none;
  }
`;
const ImageBackground = styled.div<{ image: string }>`
  background: url(${({ image }) => image || defaultHeroImage}) no-repeat center;
  background-size: cover;
  height: 232px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

interface Props {
  /**
   * Venue image
   */
  venueImage: string;
}

/**
 * Home page hero component
 */

const Hero = ({ venueImage }: Props) => {
  return (
    <Wrapper>
      <ImageBackground image={getImageUrlWithSize(venueImage, 750 / 5, 464 / 5)} />
      <ImageBackground image={getImageUrlWithSize(venueImage, 750, 464)} />
    </Wrapper>
  );
};

export default Hero;
