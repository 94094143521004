import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import { toast } from 'react-toastify';

import { useBridgeApi, useDispatch } from '../../Hooks';
import * as actions from '../../actions';
import { ReactComponent as ArrowIcon } from '../../Assets/Icons/arrow_back.svg';
import { ReactComponent as BurgerIcon } from '../../Assets/Icons/burger_menu.svg';
import { ReactComponent as LogoImg } from '../../Assets/Images/logo.svg';
import { ReactComponent as DeleteIcon } from '../../Assets/Icons/trash.svg';
import { ReactComponent as CloseIcon } from '../../Assets/Icons/close.svg';
import Icon from '../Icon';
import { Link, useHistory } from 'react-router-dom';
import { useConfig } from 'Components/ConfigProvider';
import getTheme from 'utils/getTheme';
import ClearCartAlert from '../../Scenes/Cart/Components/ClearCartAlert';
import useClearCartAlert from '../../Hooks/useClearCartAlert';
import { useShoppingCart, useShoppingCartApi } from '../ShoppingCartUniverse';
import useI18n from '../../i18n';
import { useStorage } from '../Storage';

const Wrapper = styled.div<{ offsetLeft: number }>`
  position: fixed;
  width: 100vw;
  z-index: 999;

  @media (min-width: 768px) {
    width: ${({ offsetLeft }) => `calc(100vw - ${offsetLeft}px)`};
  }
`;

const InnerWrapper = styled.div<{ showOnScroll: boolean; animate: boolean }>`
  width: 100%;
  height: ${() => window.headerHeight}px;
  padding-top: ${() => (window.hasNativeWrapper ? (!window.hasSmallViewport ? 50 : 24) : 16)}px;
  background-color: ${({ showOnScroll, theme }) => (showOnScroll ? 'transparent' : theme.main)};
  z-index: 10;
  transform: ${({ showOnScroll }) => (showOnScroll ? 'translateY(-150%)' : 'translateY(0%)')};
  transition: ${({ showOnScroll, animate }) =>
    !animate
      ? 'none'
      : showOnScroll
      ? 'background-color 0.2s ease-in-out 0.4s, transform 0.4s ease-in-out'
      : 'background-color 0.2s ease-in-out, transform 0.4s ease-in-out'};
`;

const IconWrapper = styled.div<{
  right?: boolean;
  roundArrowIcon?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${() => (window.hasNativeWrapper ? (!window.hasSmallViewport ? 48 : 24) : 16)}px;
  left: ${({ right }) => (right ? 'auto' : '16px')};
  right: ${({ right }) => (right ? '16px' : 'auto')};
  height: 40px;
  width: 40px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  border-radius: 50%;
  background-color: ${({ roundArrowIcon }) => (roundArrowIcon ? '#fff' : 'transparent')};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  cursor: pointer;

  & div {
    transition: fill 0.2s ease-in-out;
  }
`;

const Logo = styled(Link)`
  display: flex;
  justify-content: center;
  max-height: 80%;
  padding-top: 4px;
`;

const LogoWrapper = styled.svg`
  max-width: 130px;
  max-height: 100%;
`;

const Title = styled.h1<{ opacity: number; slideOut: boolean }>`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 24px;
  padding: 4px 50px 0;
  color: ${({ theme }) => theme.contrastingColor};
  opacity: ${({ opacity }) => opacity}%;
  animation: ${({ slideOut }) => (slideOut ? 'fade-out .2s forwards' : 'none')};
`;

const LeftIconWrapper = styled.div<{ opacity: number; slideOut: boolean }>`
  z-index: 1;
  position: absolute;
  opacity: ${({ opacity }) => opacity}%;
  animation: ${({ slideOut }) => (slideOut ? 'fade-out .2s forwards' : 'none')};
`;

interface Props {
  /**
   * title
   */
  title?: string;
  /**
   * showOnScroll
   */
  showOnScroll?: number | false;
  /**
   * showLogo
   */
  showLogo?: boolean;
  /**
   * showMenuIcon
   */
  showMenuIcon?: boolean;
  /**
   * showClose
   */
  showClose?: boolean;
  /**
   * showTrashcan
   */
  showTrashcan?: boolean;
  /**
   * opacity
   */
  opacity?: number;
  /**
   * slideOut
   */
  slideOut?: boolean;
  /**
   * onBack
   */
  onBack?: () => void;
  /**
   * onClose
   */
  onClose?: () => void;
  /**
   * forwardRef
   */
  forwardRef?: any;
}

/**
 * Navbar component
 */

const Navbar: React.FC<Props> = ({
  title = '',
  showLogo = false,
  showOnScroll = false,
  showMenuIcon = false,
  showClose = false,
  showTrashcan = false,
  opacity = 100,
  slideOut = false,
  onClose,
  onBack,
  forwardRef,
}) => {
  const config = useConfig();
  const [scrollPosition, setScrollPosition] = useState(0);
  const dispatch = useDispatch();
  const api = useBridgeApi();
  const shoppingCart = useShoppingCart();
  const shoppingCartApi = useShoppingCartApi();
  const { replace } = useHistory();
  const { i18n } = useI18n();
  const [, updateStorage] = useStorage();

  const { contrastingColor } = getTheme();

  const onScroll = throttle(() => {
    if (forwardRef?.current) {
      setScrollPosition(forwardRef.current.scrollTop);
    } else {
      setScrollPosition(window.pageYOffset);
    }
  }, 20);

  useEffect(() => {
    if (forwardRef?.current) {
      forwardRef.current.addEventListener('scroll', onScroll);
    } else {
      window.addEventListener('scroll', onScroll);
    }
    return () => {
      if (forwardRef?.current) {
        forwardRef.current.removeEventListener('scroll', onScroll);
      } else {
        window.removeEventListener('scroll', onScroll);
      }
    };
  }, [scrollPosition]);

  const useRoundBackIcon = showOnScroll !== false && scrollPosition < showOnScroll;
  const color = useRoundBackIcon ? '#000000' : contrastingColor;
  const shouldAnimate = Boolean(showOnScroll && scrollPosition !== 0);
  const screenWidth = window.innerWidth;
  const showLeftIcon = screenWidth < 768 || (screenWidth >= 768 && !showMenuIcon);

  const toggleMenu = () => {
    dispatch(actions.toggleMainMenu(true));
    api.vibrate('impactLight');
  };

  const onClearCart = () => {
    api.vibrate('impactLight');
    shoppingCartApi.clearPromoCode();
    shoppingCartApi.clearCart();
  };

  const onReplace = (path: string) => {
    onClearCart();

    replace(path);
  };

  const onClickLogo = (e: React.MouseEvent<HTMLElement>) => {
    if (shoppingCart.items?.length > 0) {
      e.preventDefault();
      onShowClearCartAlert(() => onReplace('/'));
    }
  };

  const [
    clearCartAlertVisible,
    onShowClearCartAlert,
    onCancelClearCartAlert,
    onConfirmClearCartAlert,
  ] = useClearCartAlert();

  const onClearCartIcon = () => {
    onClearCart();
    toast(i18n('Cart.Cleared'), {
      position: 'bottom-center',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  return (
    <Wrapper offsetLeft={window.sidebarWidth}>
      <LeftIconWrapper opacity={opacity} slideOut={slideOut}>
        {showLeftIcon && (
          <IconWrapper
            onClick={showMenuIcon ? toggleMenu : onBack}
            roundArrowIcon={useRoundBackIcon}>
            <Icon icon={showMenuIcon ? <BurgerIcon /> : <ArrowIcon />} color={color} size={32} />
          </IconWrapper>
        )}
      </LeftIconWrapper>
      <InnerWrapper
        showOnScroll={showOnScroll !== false && scrollPosition <= showOnScroll}
        animate={shouldAnimate}>
        {showLogo ? (
          <Logo to={`/`} onClick={onClickLogo}>
            {config.LOGO_URL ? (
              <img
                style={{
                  height: 50,
                }}
                src={config.LOGO_URL}
              />
            ) : (
              <LogoWrapper>
                <LogoImg />
              </LogoWrapper>
            )}
          </Logo>
        ) : (
          <Title opacity={opacity} slideOut={slideOut}>
            {title}
          </Title>
        )}
        {showTrashcan && (
          <IconWrapper onClick={onClearCartIcon} right>
            <Icon icon={<DeleteIcon />} color={color} size={32} />
          </IconWrapper>
        )}
      </InnerWrapper>

      {showClose && (
        <IconWrapper onClick={onClose} right>
          <Icon icon={<CloseIcon />} color={color} size={27} />
        </IconWrapper>
      )}
      <ClearCartAlert
        visible={clearCartAlertVisible}
        onConfirm={onConfirmClearCartAlert}
        onCancel={onCancelClearCartAlert}
      />
    </Wrapper>
  );
};

export default Navbar;
