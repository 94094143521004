const rules = [
  [
    {
      name: 'name',
      regexp: /(\w\s).+/,
      message: 'SignUp.Error.Name',
    },
    {
      name: 'phone',
      regexp: /(\w\s).+/,
      message: 'SignUp.Error.Phone',
    },
    {
      name: 'email',
      regexp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'SignUp.Error.Email',
    },
  ],
  [
    {
      name: 'password',
      regexp: /^.{4,}$/,
      message: 'SignUp.Error.Password',
    },
    {
      name: 'password2',
      compareWith: 'password',
      message: 'SignUp.Error.PasswordMatch',
    },
  ],
];

export default rules;
