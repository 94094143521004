import React from 'react';
import styled from 'styled-components';
import useI18n from '../../../i18n';
import Button from '../../../Components/Button';
import TextSection from '../../../Components/TextSection';
import getImageUrlWithSize from '../../../utils/getImageUrlWithSize';
import { DEVICE_SIZES, getDeviceSize } from '../../../utils/deviceSize';

const Wrapper = styled.div``;

const Hero = styled.div`
  height: 660px;
  padding: 104px 24px 48px;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-flow: column nowrap;
  background-color: #f5f5f8;
  z-index: 1;
  position: relative;
`;

const HeroImage = styled.div<{ image: any }>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: ${({ image }) => (image ? '1' : '0')};
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Title = styled.h1`
  font-size: 56px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 56px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 24px;
  word-break: break-word;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media (min-width: 992px) {
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: auto;

  button {
    width: 100%;

    @media (min-width: 768px) {
      width: 435px;
      margin: auto auto 0 auto;
    }

    @media (min-width: 992px) {
      width: 500px;
    }
  }
`;

type Props = {
  /**
   * content
   */
  content: any; // needs to be changed in the backend
  /**
   * onNavigate
   */
  onNavigate: () => void;
  /**
   * isSignedIn
   */
  isSignedIn: boolean;
};

/**
 * IntroSection component
 */
const IntroSection = ({ content, isSignedIn, onNavigate }: Props) => {
  if (!content) {
    return null;
  }

  const { i18n } = useI18n();
  const title = content && content.title?.length > 1 ? i18n(content.title) : '';
  const subtitle = content ? i18n(content.subTitle) : '';
  const text = content ? i18n(content.text) : '';
  const image = content ? content.image.file.url : '';
  const deviceSize = getDeviceSize();
  const heroImage =
    deviceSize === DEVICE_SIZES.SMALL
      ? getImageUrlWithSize(image, window.innerWidth * 2, 1320)
      : image;

  return (
    <Wrapper>
      <Hero>
        <HeroImage image={heroImage} />
        <Title>{title}</Title>
        <ButtonWrapper>
          <Button handleClick={onNavigate} text={i18n('Landingpage.OrderFood')} withArrow={true} />
        </ButtonWrapper>
      </Hero>
      {!isSignedIn && (
        <TextSection title={subtitle}>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </TextSection>
      )}
    </Wrapper>
  );
};

export default IntroSection;
