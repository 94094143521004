import React from 'react';
import styled from 'styled-components';
import useI18n from '../../../i18n';

const Wrapper = styled.div`
  background-color: #eeeef1;
  padding: 14px 24px;

  p {
    display: flex;
    justify-content: space-between;
    color: #616164;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  strong {
    color: #212124;
    margin-right: 8px;
  }
`;

const Inner = styled.div`
  @media (min-width: 992px) {
    margin: 0 auto;
    max-width: 960px;
  }
`;

interface Props {
  /**
   * restaurant
   */
  restaurant: string;
  /**
   * serviceType
   */
  serviceType: string;
}

/**
 * Header component
 */

const Header: React.FC<Props> = ({ restaurant, serviceType }) => {
  const { i18n } = useI18n();

  return (
    <Wrapper>
      <Inner>
        <p>
          <strong>{i18n('Cart.Restaurant')}</strong>
          {restaurant}
        </p>
        <p>
          <strong>{i18n('Cart.ServiceType')}</strong>
          {serviceType}
        </p>
      </Inner>
    </Wrapper>
  );
};

export default Header;
