import React, { useState } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';
import useI18n from '../../i18n';
import { useStorage } from 'Components/Storage';
import Button from 'Components/Button';
import Toggle from 'Components/Toggle';
import { useConfig } from 'Components/ConfigProvider';

const Wrapper = styled.div`
  padding: 120px 24px 80px 24px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const ToggleTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  h3 {
    margin: 0;
  }
`;

const Content = styled.div`
  h2 {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 36px;
    margin: 0 0 24px 0;
  }
  h3 {
    margin: 0 0 14px 0;
  }
  p {
    color: rgb(33, 33, 36);
    margin: 0 0 24px 0;
  }
`;

const CookieSettings = () => {
  const config = useConfig();
  const [storage, updateStorage] = useStorage();
  const { i18n } = useI18n();
  const [analyticsEnabled, setAnalyticsEnabled] = useState<boolean>(
    storage.analyticsConsent != false,
  );
  const [settingsChanged, setSettingsChanged] = useState<boolean>(false);

  const removeGA = () => {
    // Set the disable property on G.
    // @ts-ignore: Cannot index window using string
    window[`ga-disable-${config.ANALYTICS_ID}`] = true;
    // Remove the default tracker.
    // @ts-ignore: ga is not part of default window
    if (window.ga) window.ga('remove');
    // Remove the default cookies
    // _ga is used to distinguish users.
    Cookies.remove('_ga', { path: '/', domain: document.domain });
    // _gid is used to distinguish users.
    Cookies.remove('_gid', { path: '/', domain: document.domain });
    // _gat is used to throttle request rate.
    Cookies.remove('_gat', { path: '/', domain: document.domain });
  };

  const saveSettings = () => {
    setSettingsChanged(true);
    updateStorage({ cookieConsent: true, analyticsConsent: analyticsEnabled });
    if (analyticsEnabled) {
      ReactGA._gtag('consent', 'update', {
        analytics_storage: 'granted',
      });
    } else {
      removeGA();
    }
  };

  return (
    <Wrapper>
      <Content>
        <h2>{i18n('CookieSettings.Title')}</h2>
        <ToggleTitleWrapper>
          <h3>{i18n('CookieSettings.Cookies.Title')}</h3>
          <Toggle name="dummy" checked disabled />
        </ToggleTitleWrapper>
        <p>{i18n('CookieSettings.Cookies.Text')}</p>
        <ToggleTitleWrapper>
          <h3>{i18n('CookieSettings.Tracking.Title')}</h3>
          <Toggle
            name="analytics"
            checked={analyticsEnabled}
            onChange={(e) => setAnalyticsEnabled(e.target.checked)}
          />
        </ToggleTitleWrapper>
        <p>{i18n('CookieSettings.Tracking.Text')}</p>
        <Button
          width="100%"
          handleClick={saveSettings}
          text={settingsChanged ? 'Dina val sparades' : 'Spara mina val'}
          disabled={settingsChanged}
        />
      </Content>
    </Wrapper>
  );
};

export default CookieSettings;
