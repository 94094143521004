import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { gql, useMutation } from '@apollo/client';
import StampCardConfirm from './StampCardConfirm';
import Dialog from '../../../../Components/Dialog';
import { homepageUserQuery_user_stamps } from '../../__queries__';

const Wrapper = styled.div`
  padding: 0 24px 24px 24px;
`;

const Inner = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.24);
  cursor: pointer;

  @media (min-width: 768px) {
    transition: transform 0.1s ease-in-out;

    &:hover {
      transform: scale(0.99);
    }
  }
`;

const Current = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.main};
  height: 56px;
  min-width: 56px;
  border-radius: 50%;
  color: ${({ theme }) => theme.contrastingColor};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 16px;
`;

const Info = styled.div`
  padding: 0 0 0 16px;
`;

const Title = styled.h3`
  color: rgb(33, 33, 36);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.23px;
  line-height: 18px;
  margin: 0 0 4px 0;
`;

const Description = styled.p`
  color: rgb(33, 33, 36);
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 16px;
  margin: 0;
`;

interface Props {
  /**
   * cardId
   */
  cardId: string;
  /**
   * name
   */
  name: string;
  /**
   * description
   */
  description: string;
  /**
   * offerImage
   */
  offerImage: string;
  /**
   * stamps
   */
  stamps: homepageUserQuery_user_stamps[];
  /**
   * requiredCount
   */
  requiredCount: number;
  /**
   * onSetActiveStampCardId
   */
  onSetActiveStampCardId: (id: string) => void;
}

const MARK_STAMP_SEEN = gql`
  mutation markStampSeen($id: ID!) {
    updateStamp(id: $id, seen: true) {
      id
      seen
    }
  }
`;

/**
 * Home page StampCard component
 */
const StampCard = ({
  cardId,
  name,
  description,
  offerImage,
  requiredCount,
  stamps,
  onSetActiveStampCardId,
}: Props) => {
  const [markStampSeen] = useMutation(MARK_STAMP_SEEN);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const doneCount = stamps.length > requiredCount ? requiredCount : stamps.length;

  useEffect(() => {
    if (showDialog) {
      return;
    }
    if (doneCount >= requiredCount) {
      setShowDialog(true);
    }
  }, [doneCount, requiredCount]);

  const onConfirm = () => {
    stamps.forEach((stamp) => {
      markStampSeen({
        variables: {
          id: stamp.id,
        },
      });
    });

    setShowDialog(false);
  };

  return (
    <Wrapper>
      <Inner onClick={() => onSetActiveStampCardId(cardId)}>
        <Current>
          <span>
            {doneCount}/{requiredCount}
          </span>
        </Current>
        <Info>
          <Title>{name}</Title>
          <Description>{description}</Description>
        </Info>
      </Inner>
      <Dialog visible={showDialog} onClose={() => {}}>
        <StampCardConfirm onClose={onConfirm} offerImage={offerImage} />
      </Dialog>
    </Wrapper>
  );
};

export default StampCard;
