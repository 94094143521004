import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import useI18n from '../../i18n';

import Dialog from 'Components/Dialog';
import Modal from 'Components/Dialog/Modal';
import View from './view';
import {
  getHistoryOrder,
  getHistoryOrderVariables,
  getHistoryOrder_Order_items,
} from './__queries__';
import { useSelector } from 'Hooks';

export const SEND_ORDER_EMAIL = gql`
  mutation sendOrderEmail($orderId: ID!, $email: String) {
    sendOrderEmail(orderId: $orderId, email: $email) {
      success
      error
      message
    }
  }
`;

export const HISTORY_ORDER_QUERY = gql`
  query getHistoryOrder($orderId: ID!) {
    Order(id: $orderId) {
      id
      desiredTime
      easyId
      status
      estimatedFinishTime
      payment {
        id
        tip
      }
      venue {
        id
        name
        phone
        organizationId
        location {
          id
          name
          city
        }
        availabilities {
          id
          dayOfWeek
          timeRange {
            id
            start
            end
          }
        }
      }
      type
      createdAt
      fees {
        id
        name
        price {
          id
          discount
          amount
          subtotal
          percentageVAT
        }
      }
      items {
        id
        bundleId
        offer {
          id
          name
          type
          nameLang {
            id
            en
            se
            no
          }
        }
        price {
          id
          amount
          percentageVAT
          discount
          subtotal
        }
        variations {
          id
          name
          nameLang {
            id
            en
            se
            no
          }
          price
        }
        removedVariations {
          id
          name
          nameLang {
            id
            en
            se
            no
          }
        }
        entry {
          id
          price
          selectedVariations {
            id
          }
          item {
            id
            defaultPrice
            titleLang {
              id
              se
              en
              es
            }
          }
        }
      }
    }
  }
`;

export type VatRates = { [key: string]: number };

interface Props {
  tableId: string | null;
}

/**
 * HistoryOrder scene
 */
const HistoryOrder = ({ tableId }: Props) => {
  const { i18n } = useI18n();
  const { id } = useParams<{ id: string }>();
  const [email, setEmail] = useState<string>('');
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const qrOrder = useSelector((state) => state.qrOrder);
  const [sendOrderEmail] = useMutation(SEND_ORDER_EMAIL);

  const validateEmail = (email: string) => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = regexp.test(email);
    return valid;
  };

  const onSubmitEmail = () => {
    if (!email) return;
    const isValidEmail = validateEmail(email);
    if (isValidEmail) {
      sendReceipt();
      setShowEmailInput(false);
      setDisabled(true);
      setEmail('');
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const openModal = () => setShowEmailInput(!showEmailInput);

  const onChangeText = (e: any) => setEmail(e.target.value);

  const sendReceipt = async () => {
    try {
      const result = await sendOrderEmail({
        variables: {
          orderId: id,
          email,
        },
      });

      console.log(result);
    } catch (e) {
      console.log(e);
    }
  };

  const { data, loading } = useQuery<getHistoryOrder, getHistoryOrderVariables>(
    HISTORY_ORDER_QUERY,
    {
      variables: {
        orderId: id!,
      },
      pollInterval: 5000,
      fetchPolicy: 'network-only',
    },
  );

  if (!data) {
    return null;
  }

  const {
    venue,
    fees,
    items,
    easyId,
    status,
    createdAt,
    desiredTime,
    type,
    estimatedFinishTime,
    payment,
  } = data.Order;

  const totalPrice = items.reduce(
    (sum, item) => {
      if (!item.price) {
        return sum;
      }

      return sum + item.price.amount;
    },
    fees.reduce((sum, fee) => {
      return sum + fee.price.amount;
    }, 0),
  );

  const totalDiscount = items.reduce((sum, item) => {
    if (!item.price) {
      return sum;
    }

    return sum + item.price.discount;
  }, 0);

  let vatRates: any = {};
  data.Order.items.map((item) => {
    const vatRate = item.price.percentageVAT;
    const price = item.price.amount - item.price.discount;

    const exists = vatRate + '%' in vatRates;
    const VAT = price - price / (1 + vatRate / 100);

    if (exists) {
      vatRates[vatRate + '%'] = vatRates[vatRate + '%'] + VAT;
    } else {
      vatRates = { ...vatRates, [vatRate + '%']: VAT };
    }
  });

  const sortedOrderItems = items.reduce(
    (result: { [key: string]: getHistoryOrder_Order_items }, item) => {
      return {
        ...result,
        [item.id]: item,
      };
    },
    {},
  );

  return (
    <>
      <View
        easyId={easyId}
        status={status}
        createdAt={createdAt}
        desiredTime={desiredTime}
        sortedOrderItems={sortedOrderItems}
        venue={venue}
        serviceType={type}
        vatRates={vatRates}
        discount={totalDiscount}
        totalPrice={totalPrice}
        estimatedFinishTime={estimatedFinishTime}
        tip={payment.tip}
        openModal={openModal}
        disabled={disabled}
        qrOrder={qrOrder}
      />
      <Dialog visible={showEmailInput} onClose={() => {}}>
        <Modal
          title={i18n('Receipt.Email.Input')}
          text={''}
          visible={showEmailInput}
          buttonText={i18n('Receipt.Email.Send')}
          onButtonClick={() => onSubmitEmail()}
          input
          val={email}
          onChange={onChangeText}
          error={emailError ? i18n('SignUp.Error.Email') : ''}
        />
      </Dialog>
    </>
  );
};

export default HistoryOrder;
