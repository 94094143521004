import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import { useBridgeApi, useDispatch, useInterval, useSelector } from 'Hooks';
import HomeView from './view';
import {
  getLoggedOutData,
  getLoggedOutDataVariables,
  getVenue,
  getVenueVariables,
  homepageUserQuery,
} from './__queries__';
import { useStorage } from 'Components/Storage';
import { useShoppingCart, useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { useHistory } from 'react-router-dom';
import { useConfig } from 'Components/ConfigProvider';
import useClearCartAlert from '../../Hooks/useClearCartAlert';
import * as actions from '../../actions';

export const HOMEPAGE_QUERY = gql`
  query homepageUserQuery {
    user {
      id
      name
      stamps(filter: { seen: false }) {
        id
        stampCard {
          id
        }
      }
      stampCards(filter: { deleted: false }) {
        id
        count
        nameLang {
          id
          en
          se
        }
        descriptionLang {
          id
          se
          en
        }
        details {
          id
          en
          se
        }
        icon
        venues {
          id
        }
        offer {
          id
          image {
            file {
              url
            }
          }
        }
      }
      company {
        id
      }
    }
  }
`;

export const LOGGED_OUT_DATA_QUERY = gql`
  query getLoggedOutData($companyId: ID!) {
    company: Company(id: $companyId) {
      id
      defaultOffers: offers(context: [APP], filter: { default: true, deleted: false }) {
        id
        createdAt
        type
        discountRate
        discountType
        price
        nameLang {
          id
          se
          en
          no
        }
        descriptionLang {
          id
          se
          en
          no
        }
        venues {
          id
        }
        image {
          id
          file {
            id
            url
          }
        }
      }
    }
  }
`;

export const VENUE_QUERY = gql`
  query getVenue($venueId: ID!) {
    venue: Venue(id: $venueId) {
      id
      name
      location {
        id
        name
      }
      image {
        id
        file {
          id
          url
        }
      }
    }
  }
`;

const POLLING_INTERVAL = 30000;
const INVENTORY_POLLING_INTERVAL = 120000;

/**
 * Home scene
 */
const Home = () => {
  const config = useConfig();

  const { push } = useHistory();
  const [storage, updateStorage, storageLoading] = useStorage();

  const { tableId, cartId, qrOrder } = useSelector((state) => state);
  const authToken = storage.authToken;
  const shoppingCartApi = useShoppingCartApi();
  const api = useBridgeApi();
  const shoppingCart = useShoppingCart();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user?.id);
  const isLoggedIn = !!authToken;
  const venueId = useSelector((state) => state.venue?.id ?? undefined);
  const { refetch: refetchUserData, data: userData } = useQuery<homepageUserQuery>(
    HOMEPAGE_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const { data: loggedOutData } = useQuery<getLoggedOutData, getLoggedOutDataVariables>(
    LOGGED_OUT_DATA_QUERY,
    {
      variables: {
        companyId: config.COMPANY_ID,
      },
      fetchPolicy: 'network-only',
    },
  );
  const { data: venueData } = useQuery<getVenue, getVenueVariables>(VENUE_QUERY, {
    variables: {
      venueId: venueId!,
    },
    skip: !venueId,
    pollInterval: INVENTORY_POLLING_INTERVAL,
  });
  const user = userId ? userData?.user : undefined;
  const venue = venueData?.venue;
  const venueImage = venue?.image?.file?.url || '';
  const company = loggedOutData?.company;
  const loading = authToken ? !user?.id : false;

  useInterval(
    () => {
      refresh();
    },
    userId ? POLLING_INTERVAL : null,
  );

  useEffect(() => {
    refresh();
  }, [userId, venueId]);

  useEffect(() => {
    if (storageLoading) {
      return;
    }
    if (storage.serviceType) {
      return;
    }
    if (tableId) {
      push(`/?v=${venueId}&t=${tableId}`);
    } else if (!tableId && qrOrder) {
      push(`/?v=${venueId}`);
    } else {
      push(`/`);
    }
  }, [storageLoading, storage.serviceType]);

  const refresh = () => {
    refetchUserData();
  };

  if (!company || loading) {
    // return <FullpageLoader />;
  }

  if (isLoggedIn && !userId) {
    // return <FullpageLoader />;
  }

  const onClearCart = () => {
    api.vibrate('impactLight');
    shoppingCartApi.clearPromoCode();
    shoppingCartApi.clearCart();
    dispatch(actions.setCartId(null));
    updateStorage({ cartId: null });
  };

  const navigateToSelectVenue = () => {
    onClearCart();

    api.vibrate('impactLight');
    push(`/store-listing`);
  };

  const onSelectVenue = () => {
    if (shoppingCart.items?.length > 0) {
      onShowClearCartAlert(navigateToSelectVenue);
    } else {
      navigateToSelectVenue();
    }
  };

  const [
    clearCartAlertVisible,
    onShowClearCartAlert,
    onCancelClearCartAlert,
    onConfirmClearCartAlert,
  ] = useClearCartAlert();

  return (
    <>
      <HomeView
        user={user}
        venue={venue}
        venueImage={venueImage}
        company={company}
        clearCartAlertVisible={clearCartAlertVisible}
        onShowClearCartAlert={onShowClearCartAlert}
        onCancelClearCartAlert={onCancelClearCartAlert}
        onConfirmClearCartAlert={onConfirmClearCartAlert}
        onSelectVenue={onSelectVenue}
      />
    </>
  );
};

export default Home;
