import React from 'react';
import styled from 'styled-components';

import { ReactComponent as LocationIcon } from '../../../Assets/Icons/location.svg';
import { getVenue_venue } from '../__queries__';
import getServiceTypeText from '../../../utils/getServiceTypeText';
import Icon from '../../../Components/Icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #f8f8fb;
  cursor: pointer;

  @media (min-width: 768px) {
    &:hover span {
      text-decoration: none;
    }
  }
`;

const RestaurantName = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.span`
  color: rgb(33, 33, 36);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: underline;
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const Address = styled.span`
  color: rgb(97, 97, 100);
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.32px;
  line-height: 16px;
`;

const Collection = styled.div`
  margin-left: auto;
  color: rgb(97, 97, 100);
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.32px;
  line-height: 16px;
`;

const LocationIconWrapper = styled.div`
  padding-right: 13px;
`;

type Props = {
  /**
   * venue
   */
  venue?: getVenue_venue;
  /**
   * onSelectVenue
   */
  onSelectVenue: () => void;
};

/**
 * Home page Restaurant component
 */
const Restaurant = ({ venue, onSelectVenue }: Props) => {
  const { name, location } = venue || {};

  return (
    <Wrapper onClick={onSelectVenue}>
      <LocationIconWrapper>
        <Icon icon={<LocationIcon />} color={'#212124'} size={24} />
      </LocationIconWrapper>
      <RestaurantName>
        <Name>{name ?? 'Laddar...'}</Name>
        <Address>{location?.name ?? '-'}</Address>
      </RestaurantName>
      <Collection>{getServiceTypeText()}</Collection>
    </Wrapper>
  );
};

export default Restaurant;
