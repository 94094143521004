import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isString from 'lodash/isString';
import { getVenues_venues_availabilities } from '../Scenes/StoreListing/__queries__';

export function getBoundariesFromAvailability(
  availability: getVenues_venues_availabilities | undefined | null,
  selectedTime?: string,
) {
  if (!availability) {
    return 'Store.Closed';
  }

  dayjs.extend(utc);
  const { timeRange } = availability;
  const { start, end } = timeRange;

  const selectedDate = selectedTime && selectedTime === 'ASAP' ? dayjs() : dayjs(selectedTime);

  const venueOpenAtNight = () => {
    const endLocalTime = dayjs()
      .utc()
      .set('hour', Number(end.split(':')[0]))
      .set('minute', 0)
      .set('seconds', 0)
      .local();

    const endLocalHours = endLocalTime.get('hours');

    return isNight(endLocalHours);
  };

  let from = selectedDate
    .utc()
    .set('hour', Number(start.split(':')[0]))
    .set('minute', Number(start.split(':')[1]))
    .local();

  // when we set the hours - dayjs can change the day (we need to make sure that doesn't happen)
  // related to the night time functionality

  const selectedDateDay = selectedDate.get('date');
  const fromDay = from.get('date');

  if (selectedDateDay != fromDay) {
    from = from.set('date', selectedDateDay);
  }

  // related to the night time functionality
  if (venueOpenAtNight() && isNight(selectedDate.hour())) {
    from = from.subtract(1, 'day');
  }

  let to = from
    .utc()
    .set('hour', Number(end.split(':')[0]))
    .set('minute', Number(end.split(':')[1]))
    .local();

  // when we set the hours - dayjs can change the day (we need to make sure that 'To' is after 'FROM')
  // related to the night time functionality
  if (to.isBefore(from)) {
    to = to.add(1, 'day');
  }

  return {
    from,
    to,
  };
}

export function openingHoursFormatTime(
  availabilities: getVenues_venues_availabilities | undefined | null,
) {
  const ranges = getBoundariesFromAvailability(availabilities);

  if (isString(ranges)) {
    return ranges;
  }

  return `${ranges.from.format('HH:mm')} - ${ranges.to.format('HH:mm')}`;
}

// for nighttime 00:00 - 04:00
export function isNight(hours: number) {
  return hours >= 0 && hours < 5;
}

export default openingHoursFormatTime;
