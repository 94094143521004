import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Icon from 'Components/Icon';
import useI18n from 'i18n';

const Wrapper = styled(Link)<{ color: string }>`
  color: ${({ color }) => color};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  text-decoration: none;
  margin-bottom: 32px;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    @media (min-width: 768px) {
      text-decoration: underline;
    }
  }
`;
const IconWrapper = styled.div`
  margin-right: 16px;
`;

interface Props {
  /**
   * path
   */
  path: string;
  /**
   * onClick
   */
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  /**
   * icon
   */
  icon: any;
  /**
   * title
   */
  title: string;
  /**
   * disabled
   */
  disabled?: boolean;
}

/**
 * MenuItem component
 */

const MenuItem = ({ path, onClick, icon, title, disabled = false }: Props) => {
  const { i18n } = useI18n();
  const { pathname } = useLocation();

  return (
    <Wrapper
      to={disabled ? pathname : path}
      onClick={disabled ? undefined : onClick}
      color={disabled ? '#bdbdc0' : '#212124'}>
      <IconWrapper>
        <Icon icon={icon} color={disabled ? '#bdbdc0' : '#212124'} size={24} />
      </IconWrapper>
      {i18n(title)}
    </Wrapper>
  );
};

export default MenuItem;
