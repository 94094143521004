export const getShortTextFromHTML = (str: string, count: number): string => {
  if (str.length === 0) {
    return '';
  }
  const div = document.createElement('div');
  div.innerHTML = str;
  const text = div.textContent || div.innerText || '';

  return text.slice(0, count) + '...';
};
