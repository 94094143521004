export type Location = {
  lat: number;
  long: number;
};

export const getDistance = (a: Location, b: Location) => {
  const asin = Math.asin;
  const cos = Math.cos;
  const sin = Math.sin;
  const sqrt = Math.sqrt;
  const PI = Math.PI;

  // equatorial mean radius of Earth (in meters)
  const R = 6378137;

  const squared = (x: any) => {
    return x * x;
  }
  const toRad = (x: any) => {
    return (x * PI) / 180.0;
  }
  const hav = (x: any) => {
    return squared(sin(x / 2));
  }

  const aLat = toRad(Array.isArray(a) ? a[1] : a.lat);
  const bLat = toRad(Array.isArray(b) ? b[1] : b.lat);
  const aLng = toRad(Array.isArray(a) ? a[0] : a.long);
  const bLng = toRad(Array.isArray(b) ? b[0] : b.long);

  const ht = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLng - aLng);
  return 2 * R * asin(sqrt(ht));
}
