import React, { useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector, useInterval } from 'Hooks';
import { State, InventorySnapshotState, OrderStatusSnapshotState } from 'reducers';
import { ShoppingCartEvent } from '@baemingo/shopping-cart-events';
import { InventoryEvent } from '@baemingo/inventory-events';
import moment from 'moment';
import { SocialTableEvent } from '@baemingo/social-table-events';
import { useConfig } from 'Components/ConfigProvider';

interface EventInput {
  [key: string]: any;
}

export interface PutEventsInputEvent extends EventInput {
  eventId: string;
  venueId: string;
  companyId: string;
  createdAt: string;
  sentAt: string;
}

type Event = ShoppingCartEvent | InventoryEvent | SocialTableEvent;

type Snapshot = InventorySnapshotState | OrderStatusSnapshotState;

interface EventsQueryResponse {
  events: Event[];
  success: boolean;
  error: null | string;
}

interface SnapshotQueryResponse {
  snapshot: any;
  timestamp: string;
  error: null | string;
}

interface Props {
  venueId: string;
  eventType: string;
  manyEventsType?: string;
  snapshotType: string;
  eventSelector: (state: State) => Event[];
  snapshotSelector?: (state: State) => Snapshot | null;
  queryEvents: (timestamp: string) => Promise<EventsQueryResponse>;
  querySnapshot?: () => Promise<SnapshotQueryResponse> | null;
  addEventMutation: (
    events: PutEventsInputEvent[],
  ) => Promise<{
    success: boolean;
    error: string | null;
  }>;
  sendSocketMessage?: (event: PutEventsInputEvent) => void;
  pollingInterval?: number;
}

const DEFAULT_TIMESTAMP = moment().startOf('day').add(4, 'hours').toISOString();

/*
 * When we get a new event we need to check if we already
 * have this event locally before adding it to state.
 * This can be a VERY slow process. So we save each ID in a
 * hash map to make event lookup way faster.
 */

const eventIdHash: {
  [key: string]: boolean;
} = {};

const EventsSynchronizer: React.FunctionComponent<Props> = ({
  queryEvents,
  querySnapshot,
  eventSelector,
  snapshotSelector,
  eventType,
  manyEventsType,
  snapshotType,
  addEventMutation,
  sendSocketMessage,
  venueId,
  pollingInterval = 3000,
}) => {
  const config = useConfig();

  // This is null on first mount, while we're loading
  const [eventsData, setEventsData] = useState<Event[] | null>(null);
  // Get dispatch
  const dispatch = useDispatch();
  // Get company ID
  const companyId = config.COMPANY_ID;

  // Get local events that have not yet been sent
  const events = useSelector(eventSelector);

  const snapshot = snapshotSelector ? useSelector(snapshotSelector) : null;

  // Store the timestamp of the last event we got from the backend to use as a filter for getting new events
  const [lastSeenEvent, setLastSeenEvent] = useState<string>(DEFAULT_TIMESTAMP);

  useInterval(() => {
    if (snapshot?.loading) {
      return;
    }

    queryEvents(lastSeenEvent).then((response) => {
      if (!response.success) {
        console.error(response.error);
        return;
      }

      setEventsData(response.events);
    });
  }, pollingInterval);

  useEffect(() => {
    if (!querySnapshot) {
      return;
    }
    querySnapshot()!
      .then((response) => {
        if (response.error) {
          console.error(response.error);

          return;
        }

        const timestamp = response.timestamp || DEFAULT_TIMESTAMP;

        setLastSeenEvent(timestamp);

        queryEvents(timestamp).then((eventsResponse) => {
          if (!eventsResponse.success) {
            console.error(eventsResponse.error);
            return;
          }

          setEventsData(eventsResponse.events);
        });

        dispatch({
          type: snapshotType,
          snapshot: response.snapshot,
        });
      })
      .catch(() => {
        dispatch({
          type: snapshotType,
          timestamp: new Date().toISOString(),
          snapshot: null,
        });
      });
  }, []);

  function prepareEventForSend(event: Event): PutEventsInputEvent {
    return {
      companyId,
      ...event,
      sentAt: new Date().toISOString(),
    };
  }

  /**
   * This function handles converting the events to the proper backend format and sending them off.
   * It's debounced so we dont trigger hundreds of mutations if the user does a batch action
   */
  const handleNewShoppingCartEvents = useCallback(
    debounce(async (eventsToSync: Event[]) => {
      // Dont run mutation if there's no events
      if (eventsToSync.length === 0) {
        return;
      }

      // Mark the events as sent BEFORE we sent the mutation so we dont accidentally send them twice
      // when someone performs an action during the mutation
      dispatch({
        type: 'MARK_EVENTS_SENT',
        ids: eventsToSync.map((event) => event.eventId),
        sentAt: new Date().toISOString(),
      });

      // Format the events into the correct type
      const eventsToSend: PutEventsInputEvent[] = eventsToSync.map(prepareEventForSend);

      try {
        const result = await addEventMutation(eventsToSend);

        if (result.success) {
          // Operation success, do nothing?
        } else {
          console.error(result.error);
          throw new Error(result.error!);
        }
      } catch (e) {
        // Un-mark the events and try again later
        dispatch({
          type: 'MARK_EVENTS_SENT',
          ids: eventsToSync.map((event) => event.eventId),
          sentAt: null,
        });
      }
    }, 750),
    [],
  );

  /**
   * This effect triggers when we get new events from backend.
   * It takes the events, dispatches them and then finally updates the "last seen" timestamp of the most
   * recent event for use in future queries.
   */
  useEffect(() => {
    if (!eventsData || eventsData.length === 0) {
      return;
    }

    let timeOfLastEvent = lastSeenEvent;

    const eventsToDispatch = eventsData.filter((e) => {
      return eventIdHash[e.eventId] !== true;
    });

    // If we dont already have this event, dispatch it
    if (manyEventsType) {
      dispatch({
        type: manyEventsType,
        events: eventsToDispatch,
      });
    } else {
      eventsToDispatch.forEach((event) => {
        dispatch({
          type: eventType,
          event,
        });
      });
    }

    eventsToDispatch.forEach((e) => {
      eventIdHash[e.eventId] = true;
    });

    timeOfLastEvent = eventsData[eventsData.length - 1].sentAt!;

    setLastSeenEvent(timeOfLastEvent);
  }, [eventsData]);

  useEffect(() => {
    if (events.length === 0) {
      return;
    }

    const newEvents = events.filter((event) => event.sentAt === null);

    if (sendSocketMessage) {
      newEvents.forEach((event) => {
        if (eventIdHash[event.eventId]) {
          return;
        }

        sendSocketMessage(prepareEventForSend(event));
      });
    }

    newEvents.forEach((e) => {
      eventIdHash[e.eventId] = true;
    });

    handleNewShoppingCartEvents(newEvents.slice(0, 1000));
  }, [events.length]);

  if (!eventsData) {
    return null; // <LoadingScreen />;
  } else {
    return null;
  }
};

export default EventsSynchronizer;
