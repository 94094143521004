import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useI18n from 'i18n';
import getImageUrlWithSize from 'utils/getImageUrlWithSize';
import { getAllMenuCategories_allCategories } from '../__queries__';
import TouchableOpacity from '../../../Components/OpacityTouchable';
import { useBridgeApi } from '../../../Hooks';
import MenuItem from '../Components/MenuItem';

const Wrapper = styled.div`
  background: #f8f8fb;
  padding: ${() => window.headerHeight + 16}px 16px 16px;
  overflow: auto;
  height: calc(var(--height) - ${window.hasNativeWrapper ? 88 : 80}px);
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const ItemWrapper = styled.div`
  margin: 0 0 8px 0;
`;

type Props = {
  categories: getAllMenuCategories_allCategories[];
};

const DefaultView = ({ categories }: Props) => {
  const params = useParams<{ venue_id?: string }>();
  const venueId = params?.venue_id || '';
  const { i18n } = useI18n();
  const api = useBridgeApi();

  return (
    <Wrapper>
      {categories.map(({ id, nameLang, image }) => {
        return (
          <ItemWrapper key={id}>
            <TouchableOpacity onClick={() => api.vibrate('impactLight')}>
              <MenuItem
                id={id}
                venueId={venueId}
                image={getImageUrlWithSize(image?.file?.url, 686, 336)}
                name={i18n(nameLang)}
              />
            </TouchableOpacity>
          </ItemWrapper>
        );
      })}
    </Wrapper>
  );
};

export default DefaultView;
