import React from 'react';
import { ThemeProvider } from 'styled-components';

import getTheme from 'utils/getTheme';
import { useConfig } from './ConfigProvider';

interface Props {
  children: React.ReactNode;
}
const ThemeProviderWrapper = ({ children }: Props) => {
  const config = useConfig();
  const theme = getTheme();

  const safeAreaInsetTop = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue('--sat'),
  );

  const safeAreaInsetBottom = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue('--sab'),
  );

  return (
    <ThemeProvider
      theme={{
        ...theme,
        ...config.THEME,
        safeAreaInsetBottom,
        safeAreaInsetTop,
      }}>
      {children}
    </ThemeProvider>
  );
};

export default ThemeProviderWrapper;
