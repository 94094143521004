import { useEffect } from 'react';
import { useSelector, useDispatch, useLogger } from 'Hooks';
import { datadogLogs } from '@datadog/browser-logs';
import { useStorage } from 'Components/Storage';
import * as actions from '../../actions';

const CartManager = () => {
  const cartId = useSelector((state) => state.cartId);

  useEffect(() => {
    datadogLogs.addLoggerGlobalContext('cart', cartId);
  }, [cartId]);

  return null;
};

export default CartManager;

// The Cart Checker Component is a component that checks if we have cartId and tableId in state and dispatches them to the store from localstorage and query params if they are not there.

export const CartChecker = () => {
  const logger = useLogger('CartChecker');
  const { cartId, tableId } = useSelector((state) => state);
  const [storage] = useStorage();
  const dispatch = useDispatch();
  const tableIdFromParams = new URLSearchParams(window.location.search).get('t');

  useEffect(() => {
    if (!cartId && storage.cartId) {
      logger.info('Dispatching cartId from storage', {
        newCartId: storage.cartId,
      });
      dispatch(actions.setCartId(storage.cartId));
    }
    if (!tableId && tableIdFromParams) {
      logger.info('Dispatching tableId from params', {
        newTableId: tableIdFromParams,
      });
      dispatch(actions.setTableId(tableIdFromParams));
    }
  }, [cartId, tableId, storage.cartId]);

  return null;
};
