import React from 'react';
import styled from 'styled-components';

import MemberCard from 'Components/MemberCard';
import ArrowLink from 'Components/ArrowLink';
import useI18n from 'i18n';
import { useSelector } from 'Hooks';
import { allContents_allContents, companyQuery_allMembershipLevels } from '../__queries__';

const Wrapper = styled.div`
  padding: 24px 16px 32px;
  background-color: #f5f5f8;
  z-index: 0;
  position: relative;
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media (min-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
  }
`;

const Heading = styled.h3`
  font-size: 16px;
  line-height: 24px;
  color: #757578;
  letter-spacing: 0.24px;
  margin-bottom: 24px;
`;

const Title = styled.h4`
  font-size: 24px;
  line-height: 32px;
  margin-top: 16px;
`;

const CardWrapper = styled.div`
  margin-bottom: 32px;

  @media (min-width: 576px) {
    width: 543px;
    height: 339px;
  }

  @media (min-width: 768px) {
    width: 435px;
    height: 272px;
  }

  @media (min-width: 992px) {
    width: 500px;
    height: 312px;
  }
`;

interface Props {
  /**
   * isSignedIn
   */
  isSignedIn: boolean;
  /**
   * content
   */
  content: allContents_allContents;
  /**
   * membershipCard
   */
  membershipCard?: companyQuery_allMembershipLevels;
  /**
   * nextLevelPoints
   */
  nextLevelPoints: number;
  /**
   * hidePoints
   */
  hidePoints: boolean;
}

/**
 * MembershipSection component
 */
const MembershipSection: React.FC<Props> = ({
  isSignedIn,
  content,
  membershipCard,
  nextLevelPoints,
  hidePoints,
}) => {
  const { i18n } = useI18n();
  const user = useSelector((state) => state.user);
  const userEasyId = useSelector((state) => state.easyId);

  const userPoints = user?.membershipCard?.points ?? 0;
  const title = content ? i18n(content.title) : '';
  const subTitle = content ? i18n(content.subTitle) : '';
  const text = content ? i18n(content?.text) : '';
  const cardTitle = i18n(membershipCard?.title) ?? '';
  const primaryColor = membershipCard?.primaryColor ?? '';
  const secondaryColor = membershipCard?.secondaryColor ?? '';
  const cardImage = membershipCard?.image?.file?.url ?? '';

  return (
    <Wrapper>
      {!isSignedIn && <Heading>{i18n('Landingpage.Membership')}</Heading>}
      <CardWrapper>
        {isSignedIn && cardImage ? (
          <MemberCard
            title={i18n('Landingpage.MemberCard.Hello', user?.name)}
            text={`${i18n('Landingpage.Membership.Level')}: ` + cardTitle}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            background={cardImage}
            memberNumber={userEasyId}
            points={userPoints > nextLevelPoints ? nextLevelPoints : userPoints}
            nextLevelPoints={nextLevelPoints}
            hasCircle
            hidePoints={hidePoints}
          />
        ) : (
          cardImage && (
            <MemberCard
              title={title}
              text={subTitle}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              background={cardImage}
            />
          )
        )}
      </CardWrapper>
      {!isSignedIn && <Title dangerouslySetInnerHTML={{ __html: text }} />}
      <ArrowLink url="/membership" text={i18n('Landingpage.Readmore', 'medlemskap')} />
    </Wrapper>
  );
};

export default MembershipSection;
