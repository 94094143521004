import React from 'react';
import styled from 'styled-components';
import useI18n from 'i18n';

import { ReactComponent as ArrowIcon } from 'Assets/Icons/navigate-right.svg';

const Wrapper = styled.div`
  background: #f8f8fb;
  height: 64px;
  margin-top: 6px;
  margin-bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  p {
    font-family: Eina01;
    font-size: 18px;
    font-weight: 600;
    color: rgb(117, 117, 120);
  }
  svg {
    width: 18px;
    padding-top: 2px;
    height: 17px;
    margin-left: 3px;
    fill: rgb(117, 117, 120);
  }

  @media (min-width: 768px) {
    &:hover p {
      text-decoration: underline};
    }
  }
`;

interface Props {
  toMenu: (categoryId?: string) => void;
}

const MenuShortcut = ({ toMenu }: Props) => {
  const { i18n } = useI18n();

  return (
    <Wrapper onClick={() => toMenu()}>
      <p>{i18n('Home.SeeMenu')}</p>
      <ArrowIcon />
    </Wrapper>
  );
};

export default MenuShortcut;
