import Button from 'Components/Button';
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-mobile-datepicker';
import dayjs from 'dayjs';
import useI18n from '../../i18n';

interface Props {
  /**
   * time
   */
  time: string;
  /**
   * minimumPickupWaitTime
   */
  minimumPickupWaitTime: number;
  /**
   * onSubmit
   */
  onSubmit: (val: Date) => void;
}

/**
 * DateTmePicker component
 */

const DateTimePicker: React.FC<Props> = ({ onSubmit, time, minimumPickupWaitTime }) => {
  const { i18n } = useI18n();
  const minTime = dayjs()
    .add(minimumPickupWaitTime, 'minutes')
    .second(0)
    .millisecond(0)
    .toISOString();
  const currentDate = new Date(minTime);
  const [chosenDate, setChosenDate] = useState(currentDate);
  // Current date + 30 days is the maximum
  const newDate = new Date();
  const maxDate = newDate.setDate(newDate.getDate() + 30);

  useEffect(() => {
    setChosenDate(time === 'ASAP' ? currentDate : new Date(time));
  }, []);

  const months: { [key: string]: string } = {
    '1': i18n('Date.Jan'),
    '2': i18n('Date.Feb'),
    '3': i18n('Date.Mars'),
    '4': i18n('Date.Apr'),
    '5': i18n('Date.May'),
    '6': i18n('Date.June'),
    '7': i18n('Date.July'),
    '8': i18n('Date.Aug'),
    '9': i18n('Date.Sep'),
    '10': i18n('Date.Oct'),
    '11': i18n('Date.Nov'),
    '12': i18n('Date.Dec'),
  };

  const days = [
    i18n('Date.Sun'),
    i18n('Date.Mon'),
    i18n('Date.Tue'),
    i18n('Date.Wed'),
    i18n('Date.Thu'),
    i18n('Date.Fri'),
    i18n('Date.Sat'),
  ];

  const datePickerConfig = {
    date: {
      format: (value: Date) =>
        days[value.getDay()] + ' ' + value.getDate() + ' ' + months[value.getMonth() + 1] + '.',
      caption: 'Day',
      step: 1,
    },
    hour: {
      format: 'hh',
      caption: 'Hour',
      step: 1,
    },
    minute: {
      format: 'mm',
      caption: 'Min',
      step: 1,
    },
  };

  const submitDate = dayjs(chosenDate).isBefore(minTime) ? currentDate : chosenDate;

  return (
    <>
      <div>
        <DatePicker
          isPopup={false}
          showHeader={false}
          showFooter={false}
          theme="ios"
          dateConfig={datePickerConfig}
          min={currentDate}
          max={new Date(maxDate)}
          value={chosenDate}
          onChange={(val: Date) => setChosenDate(val)}
        />
      </div>
      <Button text="Ok" handleClick={() => onSubmit(submitDate)} disableTransform />
    </>
  );
};

export default DateTimePicker;
