import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, CHEQUE_QUERY } from '.';
import { useQuery } from '@apollo/client';
import { GetCheque } from './__queries__';
import { useCurrency } from 'Hooks';
import Loader from 'Components/Loader';
import CheckIcon from 'Scenes/Item/Components/CheckIcon';
import Navbar from 'Components/Navbar';
import useI18n from 'i18n';

const Wrapper = styled.div`
  padding: 10px;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 70px);
`;

const Heading = styled.div`
  margin: 10px;
  text-align: center;
  margin-top: 20px;
`;

const ItemsWrapper = styled.div`
  flex: 1;
  background-color: #fff;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
`;

const Item = styled.div`
  padding: 8px 4px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
  }
`;

const Line = styled.div`
  border-bottom: 1px solid #ddd;
  text-align: center;
  position: relative;
  margin: 0 10px;
  margin-top: 15px;
`;

const LineText = styled.div`
  background-color: #fff;
  position: absolute;
  top: -12px;
  left: 45%;
  padding: 0 10px;
  color: #999;
`;

const PayOrSplit = () => {
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
  const currency = useCurrency();
  const params = useParams();
  const chequeId = params.cheque_id!;
  const chequeQuery = useQuery<GetCheque>(CHEQUE_QUERY, {
    variables: {
      id: chequeId,
    },
  });

  const { i18n } = useI18n();

  const history = useHistory();

  const cheque = chequeQuery.data?.Cheque;
  const items = cheque?.items || [];

  useEffect(() => {
    if (items.length === 1) {
      history.replace('checkout');
    }
  }, [items.length]);

  const [mode, setMode] = React.useState<'SPLIT' | 'ITEMS' | null>('ITEMS');

  if (mode === 'SPLIT') {
    return <Wrapper>Split</Wrapper>;
  } else if (mode === 'ITEMS') {
    return (
      <>
        <Navbar title={i18n('PayCheque.YourBill')} onBack={() => history.goBack()} />
        <Wrapper>
          <Button
            onClick={() => {
              history.push('checkout');
            }}>
            {i18n('PayCheque.EntireBill')}
          </Button>
          <Line>
            <LineText>{i18n('PayCheque.Or')}</LineText>
          </Line>
          <Heading>{i18n('PayCheque.SelectItems')}</Heading>
          <ItemsWrapper>
            {items.map((item) => (
              <Item
                key={item.id}
                onClick={() => {
                  if (selectedItems.includes(item.id)) {
                    setSelectedItems(selectedItems.filter((i) => i !== item.id));
                  } else {
                    setSelectedItems([...selectedItems, item.id]);
                  }
                }}>
                <CheckIcon active={selectedItems.includes(item.id)} />
                <span
                  style={{
                    flex: 1,
                  }}>
                  {item.title}
                </span>
                <span>{currency.formatAmount(item.amount - item.discount)}</span>
              </Item>
            ))}

            {!cheque && (
              <div style={{ position: 'relative', marginTop: 200 }}>
                <Loader color="#bbb" />
              </div>
            )}
          </ItemsWrapper>
          <Button
            disabled={!selectedItems.length}
            onClick={() => {
              history.push({
                pathname: `/cheque/${cheque?.id}/checkout`,
                search: `?items=${selectedItems.join(',')}`,
              });
            }}>
            {i18n('PayCheque.PaySelected')}
          </Button>
        </Wrapper>
      </>
    );
  }

  return (
    <>
      <Navbar title={i18n('PayCheque.YourBill')} onBack={() => history.goBack()} />
    </>
  );
};

export default PayOrSplit;
