import { gql, useQuery } from '@apollo/client';
import { useConfig } from 'Components/ConfigProvider';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 20px;
`;

const VenueList = styled.div`
  display: flex;
`;

const Venue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const GET_CLOUD_CONFIG = gql`
  query GetCloudConfig($cloudConfigId: String, $slug: String) {
    orderingCloudConfiguration(id: $cloudConfigId, slug: $slug) {
      id
      marketplace {
        id
        venues {
          id
        }
      }
    }
  }
`;
const MarketplaceLandingPage = () => {
  const config = useConfig();

  return (
    <Wrapper>
      <h1> Marketplace</h1>
      <VenueList>
        {config.MARKETPLACE_VENUES?.map((v) => (
          <Venue key={v.id}>
            <div>{v.name}</div>
          </Venue>
        ))}
      </VenueList>
    </Wrapper>
  );
};

export default MarketplaceLandingPage;
