import React from 'react';
import styled from 'styled-components';
import NewsItem from '../../../Components/NewsItem';
import useI18n from '../../../i18n';
import { getShortTextFromHTML } from '../../../utils/text';

const Wrapper = styled.div`
  padding: 24px 16px 32px;

  @media (min-width: calc(768px + 303px)) {
    max-width: 720px;
    margin: 0 auto;
    padding: 24px 0 32px;
  }

  @media (min-width: calc(992px + 303px)) {
    max-width: 960px;
  }

  @media (min-width: calc(1200px + 303px)) {
    max-width: 1140px;
  }
`;

const Heading = styled.h3`
  font-size: 16px;
  line-height: 24px;
  color: #757578;
  letter-spacing: 0.24px;
  margin-bottom: 24px;
`;

type Props = {
  /**
   * content
   */
  content: any; // needs to be changed in the backend
};

/**
 * NewsSection component
 */
const NewsSection = ({ content }: Props) => {
  if (!content) {
    return null;
  }

  const { i18n } = useI18n();
  const title = i18n(content.title);
  const text = i18n(content.text);
  const image = content.image.file.url;
  const shortText = getShortTextFromHTML(text, 100);
  const date = content.updatedAt;
  const { id } = content;

  return (
    <Wrapper>
      <Heading>{i18n('Landingpage.News')}</Heading>
      <NewsItem id={id} image={image} title={title} text={shortText} date={date} />
    </Wrapper>
  );
};

export default NewsSection;
