import { gql, useQuery } from '@apollo/client';
import { useCurrency, useDispatch } from 'Hooks';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { GetCheque } from './__queries__';
import Loader from 'Components/Loader';
import useVenueManager from 'Hooks/useVenueManager';
import { useStorage } from 'Components/Storage';
import { setCartId } from 'actions';
import Navbar from 'Components/Navbar';
import * as actions from 'actions';
import useI18n from 'i18n';

const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
  background-color: #f5f5f5;
  padding-top: 80px;
`;

const Header = styled.div`
  padding: 10px;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
`;

const ItemsWrapper = styled.div`
  flex: 1;
  background-color: #fff;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  overflow-y: scroll;
`;

const Item = styled.div`
  padding: 8px 4px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
  }
`;

const Summary = styled.div`
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
`;

const BottomWrapper = styled.div`
  flex: 0 0 100px;
`;

export const Button = styled.div<{
  disabled?: boolean;
}>`
  padding: 10px;
  margin: 10px;
  border: 1px solid #000;
  cursor: pointer;
  display: inline-block;
  width: calc(100% - 20px);
  text-align: center;
  border-radius: 10px;
  background-color: #000;
  color: #fff;
  font-weight: 600;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  &:hover {
    background-color: #333;
  }
`;

export const CHEQUE_QUERY = gql`
  query GetCheque($id: String!) {
    Cheque(id: $id) {
      id
      amount
      discount
      paidAmount
      createdAt
      items {
        id
        itemId
        title
        amount
        discount
        categoryId
        variations
        richVariations
        disableMembershipDiscount
      }
    }
  }
`;

interface Props {
  hasManyCheques: boolean;
}

const Cheque: React.FC<Props> = ({ hasManyCheques }) => {
  const [storage, updateStorage] = useStorage();
  const currency = useCurrency();
  const history = useHistory();
  const params = useParams();
  const chequeId = params.cheque_id!;
  const dispatch = useDispatch();
  const { i18n } = useI18n();

  const chequeQuery = useQuery<GetCheque>(CHEQUE_QUERY, {
    variables: {
      id: chequeId,
    },
    fetchPolicy: 'network-only',
  });

  const cheque = chequeQuery.data?.Cheque;
  const items = cheque?.items || [];

  /** Ensure no old leftover cart IDs are hanging around */
  useEffect(() => {
    setCartId(null);
    dispatch(actions.setCartId(null));
  }, []);

  return (
    <>
      <Navbar
        title={i18n('PayCheque.YourBill')}
        onBack={() => {
          history.goBack();
        }}
      />
      <Wrapper>
        <ItemsWrapper>
          {items.map((item) => (
            <Item key={item.id}>
              <span>{item.title}</span>
              <span>{currency.formatAmount(item.amount - item.discount)}</span>
            </Item>
          ))}

          {!cheque && (
            <div style={{ position: 'relative', marginTop: 200 }}>
              <Loader color="#bbb" />
            </div>
          )}
        </ItemsWrapper>

        <Summary>
          <SummaryRow>
            <span>{i18n('OrderHistory.Discount')}</span>
            <span>{cheque ? currency.formatAmount(cheque.discount) : '--'}</span>
          </SummaryRow>
          <SummaryRow>
            <span>{i18n('Cart.Total')}</span>
            <b>
              <span>{cheque ? currency.formatAmount(cheque.amount - cheque.discount) : '--'}</span>
            </b>
          </SummaryRow>
        </Summary>

        <BottomWrapper>
          <Button
            disabled={!cheque}
            onClick={() => {
              history.push(`/cheque/${cheque?.id}/pay_or_split`);
            }}>
            {i18n('PayCheque.PayOrSplit')}
          </Button>
        </BottomWrapper>
      </Wrapper>
    </>
  );
};

export default Cheque;
