import React from 'react';
import styled from 'styled-components';
import useI18n from 'i18n';
import Button from 'Components/Button';
import { ReactComponent as CheckIcon } from '../../../Assets/Icons/check.svg';
import { useDispatch, useSelector } from '../../../Hooks';
import { setLoginScreen } from '../../../actions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 212px;
  width: 327px;
  margin: 4px 24px 80px 24px;
`;

const Title = styled.h4`
  font-family: Eina 01;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 128%;
  margin-bottom: 9px;
  text-align: center;
`;

const TextRow = styled.div`
  display: flex;
  align-items: center;
  margin: 7px 0;
`;

const Text = styled.p`
  white-space: nowrap;
  margin-left: 16px;
  text-overflow: ellipsis;
  max-width: 82%;
  overflow: hidden;
  font-family: Eina 01;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
`;
const Spacing = styled.div`
  margin: 12px 0;
  height: 24px;
`;

/**
 * SignUpReminder component
 */

const SignUpReminder = () => {
  const { i18n } = useI18n();
  const dispatch = useDispatch();

  const companyHasMembershipCards = useSelector((state) => state.companyHasMembershipCards);

  const redirectToSignup = () => {
    dispatch(setLoginScreen('signup'));
  };

  return (
    <Wrapper>
      <Title>{i18n('Cart.SignUpReminder.Title')}</Title>
      <TextRow>
        <CheckIcon fill="#209946" height={24} width={24} />
        <Text>{i18n('Cart.SignUpReminder.Row.First')}</Text>
      </TextRow>
      {companyHasMembershipCards && (
        <>
          <TextRow>
            <CheckIcon fill="#209946" height={24} width={24} />
            <Text>{i18n('Cart.SignUpReminder.Row.Second')}</Text>
          </TextRow>
          <TextRow>
            <CheckIcon fill="#209946" height={24} width={24} />
            <Text>{i18n('Cart.SignUpReminder.Row.Third')}</Text>
          </TextRow>
        </>
      )}
      <Spacing />
      <Button
        text={i18n('Signup.Title')}
        background="transparent"
        textColor="#000"
        ghost={true}
        handleClick={() => redirectToSignup()}
      />
    </Wrapper>
  );
};

export default SignUpReminder;
