import React from 'react';
import styled from 'styled-components';
import Button from 'Components/Button';
import { ReactComponent as LogoImg } from '../../../../Assets/Images/logo.svg';
import Icon from '../../../../Components/Icon';
import useI18n from '../../../../i18n';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255, 255, 255);
  border-radius: 24px;
  padding: 24px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
`;

const Title = styled.h3`
  color: rgb(33, 33, 36);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin: 0 0 16px 0;
`;

const Description = styled.p`
  color: rgb(33, 33, 36);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.24px;
  line-height: 24px;
  text-align: center;
`;

const Stamps = styled.div`
  width: 240px;
  display: flex;
  flex-wrap: wrap;
  padding: 32px 0 16px 0;
`;

const Stamp = styled.div<{ active: boolean }>`
  background-color: ${({ active, theme }) => (active ? theme.main : 'rgb(238, 238, 241)')};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  width: 64px;
  margin: 0 8px 16px 8px;
  border-radius: 50%;
  color: rgb(158, 158, 161);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
`;

interface Props {
  /**
   * name
   */
  name: string;
  /**
   * description
   */
  description: string;
  /**
   * onSetActiveStampCardId
   */
  onSetActiveStampCardId: (id: string) => void;
  /**
   * stamps
   */
  stamps: boolean[];
}

/**
 * Home page StampCardDetails component
 */
const StampCardDetails = ({ name, description, stamps, onSetActiveStampCardId }: Props) => {
  const { i18n } = useI18n();
  return (
    <Wrapper>
      <Title>{name}</Title>
      <Description>
        {description} <br /> {i18n('StampCard.Details.Description')}
      </Description>
      <Stamps>
        {stamps.map((activeStamp, key) => (
          <Stamp key={key} active={activeStamp}>
            <span>
              {activeStamp ? (
                <Icon icon={<LogoImg />} color={'#fff'} width={46} height={13} />
              ) : (
                key + 1
              )}
            </span>
          </Stamp>
        ))}
      </Stamps>
      <Button text={'OK'} handleClick={() => onSetActiveStampCardId('')} width="100%" />
    </Wrapper>
  );
};

export default StampCardDetails;
