import useI18n from 'i18n';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import PointsCircle from '../PointsCircle';

const Wrapper = styled.div<{
  hasCircle: boolean;
  background: string | undefined;
  autoHeight: boolean;
}>`
  padding: 16px 0 ${(props) => (props.hasCircle ? '26px' : '50px')} 0;
  background-image: url(${(props) => (props.background ? props.background : 'none')});
  background-size: cover;
  background-position: center;
  background-color: #f5f5f8;
  border-radius: 8px;
  box-shadow: 1px 1px 1px 0 rgb(117, 117, 120), 0 1px 3px 1px rgba(0, 0, 0, 0.48);
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: ${(props) => (props.hasCircle ? 'flex-start' : 'center')};
  min-height: 216px;
  height: ${({ autoHeight }) => (autoHeight ? 'auto' : '57.6vw')};
  position: relative;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media (min-width: 576px) {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.h3<{
  color: string;
  smallerFont: boolean;
}>`
  font-size: ${({ smallerFont }) => (smallerFont ? 16 : 24)}px;
  font-weight: 600;
  line-height: ${({ smallerFont }) => (smallerFont ? 24 : 32)}px;
  color: ${(props) => props.color};
`;

const Text = styled.h4<{
  color: string;
}>`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.24px;
  color: ${(props) => props.color};
`;

const MemberNumber = styled.div`
  background-color: #fff;
  min-width: 48px;
  text-align: center;
  border-radius: 8px;
  font-size: 8px;
  font-weight: 600;
  padding: 6px;
  color: #9e9ea1;
  line-height: 10px;
  position: absolute;
  left: 16px;
  bottom: 16px;
`;

const Nr = styled.span`
  color: #757578;
  display: block;
  font-size: 10px;
  height: 12px;
  line-height: 12px;
`;

interface Props {
  title: string;
  text: string;
  primaryColor: string;
  secondaryColor: string;
  points?: number;
  nextLevelPoints?: number;
  memberNumber?: string;
  background?: string;
  hasCircle?: boolean;
  hidePoints?: boolean;
}

const MemberCard: React.FC<Props> = ({
  title,
  text,
  primaryColor,
  secondaryColor,
  points,
  nextLevelPoints = 0,
  memberNumber,
  background,
  hasCircle = false,
  hidePoints,
}) => {
  const { i18n } = useI18n();
  const titleRef = useRef<HTMLHRElement>(null);
  const [smallerFont, setSmallerFont] = useState(false);
  const [autoHeight, setAutoHeight] = useState(false);

  useEffect(() => {
    const height = titleRef.current?.getBoundingClientRect().height;
    if (!height) return;

    if (height >= 96) {
      setAutoHeight(true);
      setSmallerFont(true);
    } else if (height > 32) {
      setSmallerFont(true);
    }
  }, [title]);

  return (
    <Wrapper hasCircle={hasCircle} background={background} autoHeight={autoHeight}>
      <Title color={primaryColor} ref={titleRef} smallerFont={smallerFont}>
        {title}
      </Title>
      <Text color={secondaryColor}>{text}</Text>
      {points !== undefined && nextLevelPoints > 0 && !hidePoints && (
        <PointsCircle
          points={Math.round(points / 100)}
          nextLevelPoints={nextLevelPoints / 100}
          strokeColor={'#46bbbf'}
        />
      )}
      {memberNumber && (
        <MemberNumber>
          {i18n('Membership.Member')}
          <Nr>{memberNumber}</Nr>
        </MemberNumber>
      )}
    </Wrapper>
  );
};

export default MemberCard;
