import React, { useRef } from 'react';
import styled from 'styled-components';
import OrderStatus from '../OrderStatus';
import Restaurant from './Components/Restaurant';
import Recommendations from './Components/Recommendations';
import Offers from './Components/Offers';
import Categories from './Components/Categories';
import Hero from './Components/Hero';
import StampCards from './Components/StampCards';
import { getLoggedOutData_company, getVenue_venue, homepageUserQuery_user } from './__queries__';
import { useStorage } from 'Components/Storage';
import { DEVICE_SIZES, getDeviceSize } from '../../utils/deviceSize';
import ClearCartAlert from '../Cart/Components/ClearCartAlert';
import {
  ClearCartAlertVisible,
  OnCancelClearCartAlert,
  OnConfirmClearCartAlert,
  OnShowClearCartAlert,
} from '../../Hooks/useClearCartAlert';
import AnonymousOrdersList from '../TableLandingPage/Components/AnonymousOrdersList';

const Wrapper = styled.div`
  background: rgb(238, 238, 241);
  height: var(--height);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: ${() => window.headerHeight}px;
  padding-bottom: 88px;

  &.modal-open {
    -webkit-overflow-scrolling: auto;
  }
`;

type Props = {
  venueImage: string;
  user?: homepageUserQuery_user;
  company?: getLoggedOutData_company;
  venue?: getVenue_venue;
  clearCartAlertVisible: ClearCartAlertVisible;
  onShowClearCartAlert: OnShowClearCartAlert;
  onCancelClearCartAlert: OnCancelClearCartAlert;
  onConfirmClearCartAlert: OnConfirmClearCartAlert;
  onSelectVenue: () => void;
};

const HomeView = ({
  venueImage,
  user,
  company,
  venue,
  clearCartAlertVisible,
  onCancelClearCartAlert,
  onConfirmClearCartAlert,
  onSelectVenue,
}: Props) => {
  const [storage] = useStorage();
  const qrOrder = storage.qrOrder;
  const homeRef = useRef(null);
  const deviceSize = getDeviceSize();

  return (
    <Wrapper ref={homeRef}>
      {venue && <AnonymousOrdersList venueId={venue.id} />}
      {!qrOrder && (
        <>
          <OrderStatus homeRef={homeRef} floating={deviceSize === DEVICE_SIZES.SMALL} />
          <Restaurant venue={venue} onSelectVenue={onSelectVenue} />
          <Hero key="hero" venueImage={venueImage} />
        </>
      )}
      <StampCards homeRef={homeRef} user={user} />
      <Offers homeRef={homeRef} defaultOffers={company?.defaultOffers ?? []} venue={venue} />
      <Recommendations />
      <Categories />
      <ClearCartAlert
        visible={clearCartAlertVisible}
        onConfirm={onConfirmClearCartAlert}
        onCancel={onCancelClearCartAlert}
      />
    </Wrapper>
  );
};

export default HomeView;
