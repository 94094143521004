import { useQuery } from '@apollo/client';
import { useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { useStorage } from 'Components/Storage';
import Cart from 'Scenes/Cart';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { CHEQUE_QUERY } from '.';
import { GetCheque } from './__queries__';
import useEntryFinder from 'Hooks/useEntryFinder';
import useVenueManager from 'Hooks/useVenueManager';
import Navbar from 'Components/Navbar';
import useI18n from 'i18n';

const Checkout = () => {
  const [storage, updateStorage] = useStorage();
  const params = useParams();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const chequeId = params.cheque_id!;
  const selectedItems = search.get('items')?.split(',') || [];
  const findEntry = useEntryFinder();
  const urlParams = new URLSearchParams(window.location.search);
  const venueId = urlParams.get('v')!;
  const hasAddedItems = useRef(false);
  const history = useHistory();
  const { i18n } = useI18n();

  useVenueManager({
    noRedirect: true,
    venueId,
  });

  const chequeQuery = useQuery<GetCheque>(CHEQUE_QUERY, {
    variables: {
      id: chequeId,
    },
  });

  const cheque = chequeQuery.data?.Cheque;
  const items = cheque?.items || [];

  const shoppingCartApi = useShoppingCartApi();
  const [cartId, setCartId] = useState<string | null>(null);

  useEffect(() => {
    const tableId = urlParams.get('t')!;

    const id = shoppingCartApi.createCart(venueId, 'EAT_HERE', tableId, false);
    setCartId(id);

    // Joar: Not 100% sure if this is needed but based on reading some code it might be
    updateStorage({
      serviceType: 'EAT_HERE',
    });
  }, []);

  useEffect(() => {
    if (hasAddedItems.current) {
      return;
    }

    if (!cartId) {
      return;
    }

    if (!cheque) {
      return;
    }

    shoppingCartApi.connectToCheque(chequeId);

    // Ensure all items are in the menu. Solves menu loading race condition
    for (const item of items) {
      const entry = findEntry(item.itemId);
      if (!entry) {
        return;
      }
    }

    for (const item of items) {
      const entry = findEntry(item.itemId)!;

      // If we have selected items from the previous screen, only add those
      if (selectedItems.length && !selectedItems.includes(item.id)) {
        continue;
      }

      shoppingCartApi.addItem(
        item.title,
        item.itemId,
        entry.id,
        item.richVariations?.map((rv: any) => ({
          id: rv.id,
          amount: rv.price,
          title: rv.name,
          groupId: '',
        })),
        [],
        item.amount,
        item.disableMembershipDiscount,
        item.categoryId,
        entry.item.vatRate,
        item.id,
        item.discount,
      );
    }

    hasAddedItems.current = true;
  }, [cartId, items, findEntry]);

  return (
    <>
      <Navbar title={i18n('PayCheque.YourBill')} onBack={() => history.goBack()} />
      {cartId && <Cart venueId={venueId} />}
    </>
  );
};

export default Checkout;
