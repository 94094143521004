import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import * as actions from 'actions';
import { useDispatch, useLogger, useSelector } from 'Hooks';
import useI18n from '../../i18n';
import useAuth from 'Hooks/useAuth';
import { userQuery } from './__queries__';
import { useStorage } from 'Components/Storage';
import { useShoppingCart, useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { datadogLogs } from '@datadog/browser-logs';

const USER_QUERY = gql`
  query userQuery {
    userEasyId {
      easyId
    }
    user {
      id
      name
      email
      deleted
      invoiceTargetId
      creditCards {
        id
        provider
        digits
        token
      }

      membershipCard {
        id
        points

        membershipLevel {
          id
          title {
            id
            se
            en
          }
          discountPercentage
        }
      }

      membershipLevel {
        id
        title {
          id
          se
          en
        }
        discountPercentage
      }
    }
  }
`;

const UserManager = () => {
  const [storageData, updateStorage] = useStorage();
  const dispatch = useDispatch();
  const authToken = storageData.authToken;
  const shoppingCartApi = useShoppingCartApi();
  const shoppingCart = useShoppingCart();
  const { i18n } = useI18n();
  const { setLocalAuthToken } = useAuth();
  const logger = useLogger('user-manager');
  const userMembership = useSelector((state) => state.userMembership);

  const { data } = useQuery<userQuery>(USER_QUERY, {
    fetchPolicy: 'network-only',
  });

  // Sets local states to trigger crucial updates for the cart.
  useEffect(() => {
    if (!authToken) {
      return;
    }
    setLocalAuthToken(authToken);
    if (!data?.user) {
      return;
    }
    // Add datadog global context for users.
    datadogLogs.addLoggerGlobalContext('user', data.user);

    if (data.user.deleted) {
      dispatch(actions.logout());
      updateStorage({ authToken: null, temporaryAuthToken: null });
      logger.info('User is deleted, logging out and clearng auth tokens');
      return;
    }

    dispatch(actions.setUser(data.user));
    dispatch(actions.setUserEasyId(data.userEasyId.easyId));

    // Fetch membership level from different places if the company uses membershipCards or not.
    const userMembership = data.user.membershipCard?.membershipLevel ?? data.user.membershipLevel;

    // Set user membership in global state.
    dispatch(
      actions.setUserMembership({
        title: i18n(userMembership.title),
        rate: userMembership.discountPercentage,
      }),
    );

    // If we already have a shopping-cart, update it with the logged in user.
    if (!shoppingCart) {
      return;
    }

    logger.info('checking if we need to connect the user to the cart.', {
      shoppingCart: shoppingCart,
    });

    // If the user is already connected to the current cart, return.
    if (shoppingCart.userId == data.user.id) return;

    // Otherwise, connect the user to the cart.
    logger.info('updating shopping-cart with user details.', {
      shoppingCart: shoppingCart,
      user: data.user,
    });

    // Dispatch action to update the cart with the user ID.
    shoppingCartApi.updateCartUser(data.user.id);
  }, [authToken, data]);

  // Set discount to cart.
  useEffect(() => {
    if (!userMembership) return;
    // Dispatch action to update the cart with the new user membership discount.
    shoppingCartApi.setMembershipDiscount(userMembership.rate);
  }, [userMembership]);

  return null;
};

export default UserManager;
