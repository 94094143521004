export const prettify = (value: string) => {
  let text = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  if (text.substr(text.length - 3) == '.00') {
    text = text.slice(0, -3);
  }
  return text;
};

export const formatPoints = (points: number) => {
  let base = points / 100;
  base = Math.round((base + Number.EPSILON) * 100) / 100;
  return prettify(base.toFixed());
};
