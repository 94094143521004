import React from 'react';
import styled from 'styled-components';
import { Language } from '../../../Types';
import { ReactComponent as CheckIcon } from 'Assets/Icons/check_white.svg';
import { useConfig } from 'Components/ConfigProvider';

const Wrapper = styled.div`
  padding: 20px 0;
  width: 100%;
  font-family: 'Eina01';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 128%;
  letter-spacing: 0.024em;
`;
const Item = styled.div<{ active: boolean }>`
  padding: 5px 10px;
  background: ${({ active }) => (active ? '#000' : 'transparent')};
  color: ${({ active }) => (active ? '#fff' : '#000')};
  border-radius: 4px;
  transition: 0.3s background;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
  svg {
    visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  }
`;

export type LanguageSelector = {
  code: Language;
  title: string;
};
const languages: LanguageSelector[] = [
  {
    code: 'se',
    title: 'Svenska',
  },
  {
    code: 'en',
    title: 'English',
  },
  {
    code: 'es',
    title: 'Español',
  },
];

type Props = {
  setLanguage: any;
  language: any;
};

const LanguageSelector = ({ setLanguage, language }: Props) => {
  const config = useConfig();
  const mappedLanguages = config.LANGUAGE_OPTIONS || languages;

  return (
    <Wrapper>
      {mappedLanguages.map((lang) => (
        <Item active={language === lang.code} onClick={() => setLanguage(lang.code)}>
          {lang.title}
          <CheckIcon />
        </Item>
      ))}
    </Wrapper>
  );
};

export default LanguageSelector;
