import { gql } from '@apollo/client';

export const ITEM_QUERY = gql`
  query GetItem($id: ID!) {
    item: Item(id: $id) {
      id
      allergens
      ageRestricted
      title
      vatRate
      disableMembershipDiscount
      categories {
        id
      }
      titleLang {
        id
        se
        en
        es
      }
      description
      descriptionLang {
        id
        se
        en
        es
      }
      defaultVariations {
        id
        price
        name
        nameLang {
          id
          se
          en
          es
        }

        # This is not actually used, but makes both variation
        # fragments have the same schema which makes life easier
        asItem {
          id
        }

        image {
          id
          file {
            id
            url
          }
        }
      }
      appliedVariations {
        id
        default
        required
        variation {
          id
          name
          price
        }
        expandedByDefault

        group {
          id
          type
          title {
            id
            en
            se
            es
          }
          required
          preselectedVariations {
            id
          }
          variations {
            id
            name
            nameLang {
              id
              en
              se
              es
            }
            price
            absolutePrice
            asItem {
              id
            }
            image {
              id
              file {
                id
                url
              }
            }
          }
        }
      }
      sizes {
        id
        type
        variations {
          id
          price
          absolutePrice
          name
        }
      }
      image {
        id
        file {
          id
          url
        }
      }
      upsales {
        id
        name {
          id
          se
          en
        }
        items {
          id
          disableMembershipDiscount
          vatRate
          titleLang {
            id
            se
            en
            es
          }
          defaultPrice
          image {
            id
            file {
              id
              url
            }
          }
          categories {
            id
            name
            deleted
          }
        }
      }
    }
  }
`;
