import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useI18n from 'i18n';
import { useBridgeApi, useSelector } from 'Hooks';
import Row from './Row';
import CategoryItem from './CategoryItem';
import { checkOutOfStock } from '../../../utils/inventory';

import { useShoppingCart, useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { gql, useQuery } from '@apollo/client';
import {
  GetRecommendations,
  GetRecommendations_user_recommendations_item as Item,
} from './__queries__';
import useEntryFinder from 'Hooks/useEntryFinder';

export const QUERY = gql`
  query GetRecommendations {
    user {
      id
      recommendations {
        id
        item {
          id
          disableMembershipDiscount
          categories {
            id
          }
          descriptionLang {
            id
            se
            en
            es
          }
          titleLang {
            id
            se
            en
            es
          }
          defaultPrice
          image {
            id
            file {
              id
              url
            }
          }
        }
      }
    }
  }
`;
const Recommendations = () => {
  const params = useParams<{ venue_id?: string }>();
  const venueId = params?.venue_id || '';
  const { i18n } = useI18n();
  const { push } = useHistory();
  const shoppingCartApi = useShoppingCartApi();
  const shoppingCart = useShoppingCart();
  const api = useBridgeApi();
  const venue = useSelector((state) => state.venue);
  const inventory = useSelector((state) => state.inventory ?? []);
  const findEntryFromItem = useEntryFinder();

  const { data } = useQuery<GetRecommendations>(QUERY);

  const recommendedItems = useMemo(() => {
    const items = data?.user?.recommendations || [];

    return items.filter((recommendation) => findEntryFromItem(recommendation.item.id));
  }, [data, venue, findEntryFromItem]);

  const handleClick = (item: Item, categoryId: string) => {
    const entry = findEntryFromItem(item.id);

    if (!entry) {
      return;
    }

    if (entry.instant) {
      toast(i18n('Toast.Add', i18n(item.titleLang)), {
        position: 'bottom-center',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      api.vibrate('impactMedium');
      shoppingCartApi.addItem(
        i18n(item.titleLang),
        item.id,
        entry.id,
        [],
        [],
        entry.price,
        item.disableMembershipDiscount,
        categoryId,
      );
    } else {
      push(`/order/${venueId}/item/${item.id}`);
    }
  };

  if (!recommendedItems.length) {
    return null;
  }

  return (
    <Row title={i18n('Startpage.LatestOrder.Title')}>
      {recommendedItems.map(({ id, item }) => {
        // Check if any parent category is out of stock
        const parentCategoryOutOfStock = item.categories.some((category) =>
          checkOutOfStock(category.id, inventory, true),
        );
        return (
          <CategoryItem
            key={id}
            title={item.titleLang}
            image={item?.image?.file?.url}
            outOfStock={{
              item:
                checkOutOfStock(item.id, inventory, false, shoppingCart.items) ||
                parentCategoryOutOfStock,
            }}
            onClick={() => handleClick(item, item.categories[0].id)}
            small
          />
        );
      })}
    </Row>
  );
};

export default Recommendations;
