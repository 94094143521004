import { datadogLogs, Logger, StatusType } from '@datadog/browser-logs';
import { useMemo } from 'react';

type DisallowKeys<T, U extends keyof any> = T & { [P in U]?: never };

// These keys break datadog functionality, so prevent them
type MessageContext = DisallowKeys<{ [key: string]: any }, 'error' | 'message' | 'context'>;
interface DatadogLogger extends Logger {
  log(message: string, messageContext?: MessageContext, status?: StatusType): void;
  debug(message: string, messageContext?: MessageContext): void;
  info(message: string, messageContext?: MessageContext): void;
  warn(message: string, messageContext?: MessageContext): void;
  error(message: string, messageContext?: MessageContext): void;
}

const createCustomLogger = (name: string) => {
  const logger = datadogLogs.createLogger(name, {
    level: window.__DEV__ ? 'debug' : 'info',
    handler: window.__DEV__ ? 'console' : ['console', 'http'],
  });

  // The backend logs all use "service" as the context, so we'll do the same
  // for frontend logs. This is the datadog default, and some dashboards use
  // the service context to show where most logs are coming from.
  logger.addContext('service', name);

  return logger;
};

export const getLogger = (name?: string): DatadogLogger => {
  /**
   * If we dont specify a name, return the default logger
   */
  if (!name) {
    return datadogLogs.logger;
  }

  // We've specified a name, so try to get that logger
  const ddLogger = datadogLogs.getLogger(name);

  // If logger was not found, create it
  if (!ddLogger) {
    return createCustomLogger(name);
  }

  // Return the logger
  return ddLogger;
};

/**
 * Creates or gets a logger
 *
 * This will log both to datadog and to console.
 * Debug logs are OK to keep with this logger and will not
 * appear when not in DEV mode.
 *
 * You send in a name when you want to log for a specific context.
 * For example: Pay at table.
 * You would call `useLogger('pay-at-table')` and then in datadog
 * you can filter for only logs inside pay at table when debugging.
 */
export const useLogger = (name: string): DatadogLogger => {
  const logger = useMemo<Logger>(() => {
    return getLogger(name);
  }, [name]);

  return logger;
};
