import { useLogger } from 'Hooks';
import { useEffect } from 'react';
import useAuth from '../../Hooks/useAuth';

const UpdateAuthTokenFromOld = () => {
  const { setLocalAuthToken } = useAuth();
  const logger = useLogger('old-auth-token');

  const updateToken = (message: any) => {
    let data = message.data;

    try {
      data = typeof data === 'string' ? JSON.parse(data) : data;

      if (!data.oldAuthToken) {
        return;
      }

      logger.info('old authtoken found, adding it as local authtoken');
      setLocalAuthToken(data.oldAuthToken, removeOldAuthToken);
    } catch (e) {
      logger.error('Error when trying to find old authtoken', {
        error: e,
      });
      console.error('Error: ', e);
    }
  };

  const onMessage = (message: any) => {
    if (!message?.data) {
      return;
    }
    updateToken(message);
  };

  const removeOldAuthToken = () => {
    const data = { type: 'REMOVE_OLD_AUTH_TOKEN' };
    try {
      if (window?.webkit?.messageHandlers?.ReactNativeWebView) {
        window.webkit.messageHandlers.ReactNativeWebView.postMessage(JSON.stringify(data));
      } else if (window?.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
      }
    } catch (e) {
      logger.error('Error when removing the old authtoken', {
        error: e,
      });
      console.error(e);
    }
  };

  useEffect(() => {
    document.addEventListener('message', onMessage);
    window.addEventListener('message', onMessage);

    return () => {
      document.removeEventListener('message', onMessage);
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return null;
};

export default UpdateAuthTokenFromOld;
