import React from 'react';
import styled from 'styled-components';

const InnerList = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  padding-left: 24px;
  padding-top: 20px;
  padding-bottom: 25px;
`;
const List = styled.div`
  display: inline-flex;

  & > div:last-child {
    margin-right: 24px;
  }
`;

type Props = {
  children: React.ReactNode;
};
const FlatRows = ({ children }: Props) => {
  return (
    <InnerList>
      <List>{children}</List>
    </InnerList>
  );
};

export default FlatRows;
