import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from 'Components/Button';
import Dialog from 'Components/Dialog';

import { getLoggedOutData_company_defaultOffers } from '../__queries__';
import useI18n from 'i18n';
import { TextH2 } from 'Components/Text';
import getImageUrlWithSize from 'utils/getImageUrlWithSize';
import SignInBottomModal from 'Components/SignInBottomModal';
import { useStorage } from 'Components/Storage';
import { setLoginModal } from '../../../actions';
import { useDispatch } from '../../../Hooks';

const Wrapper = styled.div<{ visible: boolean }>`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 24px;
  min-width: 327px;
`;
const Image = styled.div<{ image?: string | null }>`
  background: url(${({ image }) => image || ''}) no-repeat center;
  background-size: cover;
  border-radius: 24px 24px 0 0;
  min-height: 216px;
`;
const Title = styled(TextH2)`
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin-bottom: 16px;
  font-size: 24px;
`;
const Info = styled.div`
  background: #fff;
  padding: 16px 24px 24px;
  border-radius: 0 0 24px 24px;
  display: flex;
  flex-direction: column;
`;

type Props = {
  /**
   * onClose
   */
  onClose: () => void;
  /**
   * activeOffer
   */
  activeOffer: getLoggedOutData_company_defaultOffers | null;
  /**
   * tokenId
   */
  tokenId?: string;
};

/**
 * OfferDialog component
 */
const OfferDialog = ({ activeOffer, onClose, tokenId }: Props) => {
  const { i18n } = useI18n();
  const [visible, setVisible] = useState(false);
  const [storage] = useStorage();
  const { nameLang, image } = activeOffer || {};
  const dispatch = useDispatch();

  useEffect(() => {
    setVisible(Boolean(activeOffer));
  }, [activeOffer]);

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  const handleApplyOffer = () => {
    if (!storage.authToken) {
      dispatch(setLoginModal(true));
      return;
    }
  };

  return (
    <>
      <Dialog visible={visible} onClose={handleClose}>
        <Wrapper visible={visible}>
          <Image image={getImageUrlWithSize(image?.file?.url, 654, 432)} />
          <Info>
            <Title lines={4}>{i18n(nameLang)}</Title>
            <Button text={i18n('Startpage.Offer.Modal.Button')} handleClick={handleApplyOffer} />
          </Info>
        </Wrapper>
      </Dialog>
    </>
  );
};

export default OfferDialog;
