import React from 'react';
import useI18n from 'i18n';
import styled, { CSSProp, css } from 'styled-components';

const positionStyles: Record<TextPosition, CSSProp> = {
  center: css`
    align-items: center;
    justify-content: center;
  `,
  topLeft: css`
    align-items: flex-start;
    justify-content: flex-start;
  `,
};

const Overlay = styled.div<OutOfStockOverlayProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(66, 66, 69, 0.5);
  display: flex;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.27px;
  text-transform: uppercase;
  z-index: 14;
  pointer-events: none;
  touch-action: none;
  padding: 12px;
  border-radius: 4px;
  ${({ textPosition }) => positionStyles[textPosition!]}
`;

Overlay.defaultProps = {
  textPosition: 'center',
};

type TextPosition = 'center' | 'topLeft';

interface OutOfStockOverlayProps {
  textPosition?: TextPosition;
}

/**
 * A gray overlay marking an item/category as out of stock
 */
export default function OutOfStockOverlay({ textPosition = 'center' }: OutOfStockOverlayProps) {
  const { i18n } = useI18n();
  return <Overlay textPosition={textPosition}>{i18n('General.OutOfStock')}</Overlay>;
}
