import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  margin: 0 0 10px 0;
  font-size: 14px;
`;
const Selected = styled.div<{ active: boolean }>`
  width: 100%;
  border: 1px solid ${({ active }) => (active ? '#000' : '#dbdbdb')};
  height: 48px;
  line-height: 48px;
  border-radius: 8px;
  padding: 0 16px;
  position: relative;
`;

const Arrow = styled.div<{ active: boolean }>`
  position: absolute;
  right: 15px;
  top: 20px;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: ${({ active }) => (active ? 'rotate(-135deg)' : 'rotate(45deg)')};
`;

const SelectedText = styled.span``;

const Items = styled.div`
  position: absolute;
  width: 100%;

  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.12);
  max-height: 250px;
  overflow-y: auto;
  z-index: 1;
`;

const Item = styled.div<{ active: boolean }>`
  box-sizing: border-box;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  color: ${({ active }) => (active ? 'red' : '#000')};

  &:hover {
    background: #faf9f6;
  }
`;

type TItem = {
  label: string;
  value: any;
};

interface Props {
  /**
   * items
   */
  items: TItem[];
  /**
   * onSelect
   */
  onChange: (val: any) => void;
  /**
   * formattedValue
   */
  formattedValue: string;

  /**
   * findSelectedItem
   */
  findSelectedItem: (val: any) => boolean;
}

/**
 * DateTimePicker Select component
 */

const Select = ({ items, onChange, formattedValue, findSelectedItem }: Props) => {
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = ({ target }: MouseEvent) => {
      if (ref.current && !ref.current.contains(target as Node)) {
        handleClickOutside();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);

  useEffect(() => {
    if (active) {
      scroll();
    }
  }, [active]);

  const onClick = (currentValue: any) => {
    onChange(currentValue);
    setActive(false);
  };

  const handleClickOutside = () => {
    setActive(false);
  };

  const onShow = () => {
    setActive((prevValue) => !prevValue);
  };

  const scroll = () => {
    const dropdown = document.getElementById('items');
    const item = document.getElementById('time-active');
    if (item && dropdown) {
      dropdown.scrollTop = item.offsetTop - 48;
    }
  };

  return (
    <Wrapper ref={ref}>
      <Selected onClick={() => onShow()} active={active}>
        <SelectedText>{formattedValue}</SelectedText>
        <Arrow active={active} />
      </Selected>
      {active && (
        <Items id={'items'}>
          {items.map((item, index) => {
            const isActive = findSelectedItem(item.value);
            return (
              <Item
                onClick={() => onClick(item.value)}
                key={item.label + index}
                active={isActive}
                id={isActive ? 'time-active' : `item-${index}`}>
                {item.label}
              </Item>
            );
          })}
        </Items>
      )}
    </Wrapper>
  );
};

export default Select;
