import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';

import { useDispatch, useSelector } from 'Hooks';
import useAuth from 'Hooks/useAuth';
import * as actions from 'actions';

import {
  deleteNode,
  deleteNodeVariables,
  getCompanyEmail,
  getCompanyEmailVariables,
} from './__queries__';
import Button from '../../Components/Button';
import useI18n from '../../i18n';
import { useState } from 'react';
import Dialog from 'Components/Dialog';
import { useConfig } from 'Components/ConfigProvider';

const DELETE_USER = gql`
  mutation deleteNode($userId: ID!) {
    result: deleteNode(id: $userId, force: true) {
      success
      message
    }
  }
`;

const Wrapper = styled.div`
  padding: 120px 24px 80px 24px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Modal = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  p {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 24px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 12px;
  left: 18px;
  font-size: 16px;
  cursor: pointer;
  color: #8f8c8c;
`;

const DeleteWrapper = styled.div`
  display: block;
  margin-top: 64px;
  @media (min-width: 768px) {
    width: 327px;
  }
`;

const GhostButton = styled.button`
  background: none;
  border: none;
  color: #8f8c8c;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  padding: 18px;
  margin-top: 16px;
  border: 1px solid #8f8c8c;
  border-radius: 4px;
`;

const Content = styled.div`
  h2 {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 36px;
    margin: 0 0 24px 0;
  }
  h3 {
    color: red;
  }
  p {
    color: rgb(33, 33, 36);
    margin: 0 0 24px 0;
  }
`;

const EmailLink = styled.a`
  display: block;

  @media (min-width: 768px) {
    width: 327px;
  }
`;

export const GET_COMPANY_EMAIL = gql`
  query getCompanyEmail($companyId: ID!) {
    Company(id: $companyId) {
      id
      supportEmail
    }
  }
`;

const Support = () => {
  const config = useConfig();
  const { i18n } = useI18n();
  const userId = useSelector((state) => state.user?.id);
  const [error, setError] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { logout, hasAuthToken } = useAuth();
  const isSignedIn = hasAuthToken();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const [deleteUser, { loading }] = useMutation<deleteNode, deleteNodeVariables>(DELETE_USER, {
    variables: {
      userId: userId,
    },
  });

  const { data } = useQuery<getCompanyEmail, getCompanyEmailVariables>(GET_COMPANY_EMAIL, {
    variables: {
      companyId: config.COMPANY_ID,
    },
  });

  const email = data?.Company?.supportEmail;

  const handleDelete = async () => {
    // Reset error state
    setError(false);

    const response = await deleteUser();

    if (response?.data?.result?.success) {
      // Clear the users availableOffers from state
      dispatch(actions.setAvailableOffers([]));
      logout();
      // We are routing the user back to the landing page when the user logs out.
      // Otherwise, if they log out in the supportpage, they are given a corrupt cart.
      push('/');
    } else {
      setError(true);
    }
    return;
  };

  return (
    <Wrapper>
      <Content>
        <h2>{i18n('Support.Title')}</h2>
        <p>{i18n('Support.Text')}</p>
      </Content>
      {email && (
        <EmailLink href={`mailto:${email}`}>
          <Button text={i18n('Support.Button.Text')} width="100%" />
        </EmailLink>
      )}
      {isSignedIn && (
        <DeleteWrapper>
          <Content>
            <p>{i18n('Support.AccountDeletion')}</p>
            <Button
              text={loading ? i18n('General.Loading') : i18n('Support.DeleteButton.Text')}
              width="100%"
              handleClick={() => setShowDialog(true)}
              disabled={error || loading}
            />
            {error && <h3>{i18n('Support.DeleteButton.Error')}</h3>}
          </Content>
        </DeleteWrapper>
      )}
      <Dialog visible={showDialog} onClose={() => setShowDialog(false)}>
        <Modal>
          <CloseButton onClick={() => setShowDialog(false)}>X</CloseButton>
          <p>{i18n('Support.DeleteModal.Confirm')}</p>
          <Button
            text={i18n('Support.DeleteButton.Text')}
            width="100%"
            handleClick={handleDelete}
            disabled={error || loading}
            background="red"
          />
          <GhostButton onClick={() => setShowDialog(false)}>
            {i18n('Support.DeleteButton.Cancel')}
          </GhostButton>
        </Modal>
      </Dialog>
    </Wrapper>
  );
};

export default Support;
