import { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { addDeviceToken, addDeviceTokenVariables } from './__queries__';
import useAuth from '../../Hooks/useAuth';
import { useStorage, Device } from '../Storage';

const ADD_DEVICE_TOKEN = gql`
  mutation addDeviceToken($operatingSystem: String!, $token: String!) {
    addDeviceToken(operatingSystem: $operatingSystem, token: $token, useNewSenderID: true) {
      success
      message
    }
  }
`;

const DeviceToken = () => {
  const [storage, updateStorage] = useStorage();
  const { hasAuthToken } = useAuth();
  const isSignedIn = hasAuthToken();
  const [addToken] = useMutation<addDeviceToken, addDeviceTokenVariables>(ADD_DEVICE_TOKEN);

  const registerToken = (device: Device) => {
    if (!device) {
      return;
    }
    addToken({
      variables: device,
    });

    updateStorage({ device: { ...device, registered: true } });
  };

  const init = (message: any) => {
    let data = message.data;

    try {
      data = typeof data === 'string' ? JSON.parse(data) : data;
      const token = data.token;
      const OS = data?.OS;

      if (!token || !OS) {
        return;
      }

      if (token === storage.device?.token) {
        return;
      }

      const device = {
        token,
        operatingSystem: OS === 'ios' ? 'IOS' : 'ANDROID',
        registered: false,
      };

      updateStorage({ device });

      if (!isSignedIn) {
        return;
      }
      registerToken(device);
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  const onMessage = (message: any) => {
    if (!message?.data) {
      return;
    }
    init(message);
  };

  useEffect(() => {
    // Add the event listener for messages passed from app wrapper
    document.addEventListener('message', onMessage);
    window.addEventListener('message', onMessage);

    // To prevent memory leaks, remove all event listeners on demount
    return () => {
      document.removeEventListener('message', onMessage);
      window.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    if (isSignedIn && storage.device && !storage.device.registered) {
      registerToken(storage.device);
    }
  }, [isSignedIn, storage.device]);

  return null;
};

export default DeviceToken;
