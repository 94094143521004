import { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';

import RejectedOrder from 'Components/RejectedOrder';
import { useConfig } from 'Components/ConfigProvider';

export const MARK_ORDER_AS_CONFIRMED = gql`
  mutation markOrderAsConfirmed($orderId: ID!) {
    updateOrder(id: $orderId, confirmed: true) {
      id
      confirmed
    }
  }
`;

type Props = {
  /**
   * orderId
   */
  orderId: string;
  /**
   * onClose
   */
  onClose: () => void;
};

/**
 * Rejected component
 */
const Rejected = ({ orderId, onClose }: Props) => {
  const config = useConfig();

  const [mutate] = useMutation(MARK_ORDER_AS_CONFIRMED, {
    variables: { orderId },
    optimisticResponse: {
      __typename: 'Mutation',
      updateOrder: {
        id: orderId,
        __typename: 'Order',
        confirmed: true,
      },
    },
  });

  useEffect(() => {
    mutate();
  }, []);

  return (
    <RejectedOrder
      onClose={onClose}
      title="Orderstatus.Rejected.Title"
      description="OrderStatus.Rejected.Text"
      company={config.DISPLAY_NAME}
    />
  );
};

export default Rejected;
