import React from 'react';
import styled from 'styled-components';

import Button from '../../../../Components/Button';
import Dialog from '../../../../Components/Dialog';
import Item from './Item';
import useI18n from '../../../../i18n';
import { useQuery } from '@apollo/client';
import { GetRemovedVariations, GetRemovedVariationsVariables } from '../__queries__';
import { REMOVED_VARIATIONS_QUERY } from '../CartItems';
import { Item as CartItem } from '@baemingo/shopping-cart-events/dist/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #fff;
  border-radius: 24px;
`;

const Title = styled.h2`
  font-size: 16px;
  letter-spacing: 0.24px;
  line-height: 24px;
`;

const Items = styled.div`
  max-height: 350px;
  overflow: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 5px 0 0 0;
`;

type Props = {
  visible: boolean;
  items: CartItem[];
  onConfirm: () => void;
};

const OutOfStockModal = ({ visible, items, onConfirm }: Props) => {
  const { i18n } = useI18n();
  const removedVariationIds = items.flatMap((item) => item.removedVariations);
  const { data } = useQuery<GetRemovedVariations, GetRemovedVariationsVariables>(
    REMOVED_VARIATIONS_QUERY,
    {
      variables: {
        removedVariationIds,
      },
    },
  );

  const removedVariationsList = data?.allVariations;

  return (
    <Dialog visible={visible} onClose={onConfirm} dontCloseOnClickAnywhere>
      <Wrapper>
        <Title>{i18n('Cart.Modal.OutOfStock')}</Title>
        <Items>
          {items.map((item) => (
            <Item
              itemId={item.id}
              key={`out_of_stock_item_${item.id}`}
              title={item.title}
              variations={item.variations}
              removedVariations={
                removedVariationsList
                  ?.filter((rv) => item.removedVariations.includes(rv.id))
                  .map((rv) => rv.name) ?? []
              }
            />
          ))}
        </Items>
        <ButtonContainer>
          <Button text={i18n('Cart.Confirm')} handleClick={onConfirm} width="100%" />
        </ButtonContainer>
      </Wrapper>
    </Dialog>
  );
};

export default OutOfStockModal;
