import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'Hooks';
import useI18n from 'i18n';
import times from 'lodash/times';
import StampCard from './StampCard';
import StampCardDetails from './StampCardDetails';
import Dialog from '../../../../Components/Dialog';
import {
  homepageUserQuery_user,
  homepageUserQuery_user_stampCards,
  homepageUserQuery_user_stamps,
} from '../../__queries__';

const Wrapper = styled.div`
  padding: 24px 0 0 0;
  background: #f8f8fb;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Inner = styled.div`
  @media (min-width: 920px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

interface Props {
  /**
   * user
   */
  user: homepageUserQuery_user | undefined;
  /**
   * homeRef
   */
  homeRef: any;
}

/**
 * Home page StampCard Index component
 */
const StampCardsIndex = ({ user, homeRef }: Props) => {
  const { i18n } = useI18n();
  const [stampCards, setStampCards] = useState<homepageUserQuery_user_stampCards[]>([]);
  const [stamps, setStamps] = useState<homepageUserQuery_user_stamps[]>([]);
  const [activeStampCardId, setActiveStampCardId] = useState<string>('');
  const [activeStampCard, setActiveCard] = useState<homepageUserQuery_user_stampCards | null>(
    null,
  );
  const [activeStamps, setActiveStamps] = useState<boolean[] | undefined>(undefined);

  const venue = useSelector((store) => store.venue);

  const venueFilter = (card: homepageUserQuery_user_stampCards) => {
    return card.venues.length === 0 || (venue && card.venues.map((v) => v.id).includes(venue.id));
  };

  useEffect(() => {
    if (user?.stampCards) {
      setStampCards(user.stampCards.filter(venueFilter));
      setStamps(user.stamps);
    }
  }, [user, venue]);

  useEffect(() => {
    if (!activeStampCardId) {
      return;
    }

    const activeCard = stampCards.find((card) => card.id === activeStampCardId);

    if (!activeCard) {
      return;
    }

    setActiveCard(activeCard);

    const activeStamps = times(activeCard.count, (i) => i < getStampsById(activeCard.id).length);

    if (activeStamps) {
      setActiveStamps(activeStamps);
    }
  }, [activeStampCardId]);

  useEffect(() => {
    if (!homeRef?.current) return;

    if (activeStampCardId) {
      homeRef.current.classList.add('modal-open');
    } else {
      setTimeout(() => {
        homeRef?.current?.classList.remove('modal-open');
      }, 400);
    }
  }, [activeStampCardId]);

  const getStampsById = useCallback(
    (id) => {
      return stamps.filter((stamp) => stamp.stampCard.id === id) || [];
    },
    [stamps],
  );

  if (!stampCards || stampCards.length === 0) {
    return null;
  }

  const onSetActiveStampCardId = (id: string) => {
    setActiveStampCardId(id);
  };

  return (
    <Wrapper>
      <Inner>
        {stampCards.map((card) => (
          <StampCard
            key={card.id}
            cardId={card.id}
            stamps={getStampsById(card.id)}
            requiredCount={card.count}
            name={i18n(card.nameLang)}
            description={i18n(card.descriptionLang)}
            offerImage={card.offer?.image.file.url}
            onSetActiveStampCardId={onSetActiveStampCardId}
          />
        ))}
        <Dialog visible={Boolean(activeStampCardId)} onClose={() => onSetActiveStampCardId('')}>
          <StampCardDetails
            name={i18n(activeStampCard?.nameLang)}
            description={i18n(activeStampCard?.descriptionLang)}
            stamps={activeStamps || []}
            onSetActiveStampCardId={onSetActiveStampCardId}
          />
        </Dialog>
      </Inner>
    </Wrapper>
  );
};

export default StampCardsIndex;
