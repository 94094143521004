const rules = [
  [
    {
      name: 'phone',
      regexp: /(\w\s).+/,
      message: 'SignUp.Error.Phone',
    },
  ],
  [
    {
      name: 'code',
      regexp: /^.{4,}$/,
      message: 'ForgotPassword.Error.Code',
    },
  ],
  [
    {
      name: 'password',
      regexp: /^.{4,}$/,
      message: 'SignUp.Error.Password',
    },
    {
      name: 'confirmPassword',
      compareWith: 'password',
      message: 'SignUp.Error.PasswordMatch',
    },
  ],
];

export default rules;
