import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

import useI18n from 'i18n';
import { useBridgeApi, useLogger, useSelector } from 'Hooks';
import { ModalType } from '../.';
import { resultCodeSwitch, threeDsSwitch } from '../utils';
import ReactGA from 'react-ga4';
import { useShoppingCart } from 'Components/ShoppingCartUniverse';
import useAuth from 'Hooks/useAuth';
import { useConfig } from 'Components/ConfigProvider';

const Wrapper = styled.div`
  bottom: 0;
  z-index: 999;
  left: 0;
  width: 100%;
  right: 0;
  height: 100%;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  animation: fade-in 0.4s forwards;
`;

const AdyenWrapper = styled.div`
  padding: 0 10px;
  width: 100%;
  height: calc(100vh - 70px - 60px - 80px);
  overflow-y: scroll;
`;

const Div = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  position: relative;
`;

const Error = styled.div`
  background: #b41a33;
  border-bottom: 2px solid #910909;
  /* opacity: 0.8; */
  padding: 10px;
  color: #fff;
`;

const InvoicePayment = styled.div`
  background-color: #f7f8f9;
  border: 1px solid #e6e9eb;
  padding: 12px 16px;
  border-radius: 12px;
  margin: 8px 0 0 0;
`;

const InvoiceButton = styled.button`
  background: black;
  width: 100%;
  height: 48px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  border: none;
  transition: background 0.3s ease-out, box-shadow 0.3s ease-out;

  :hover {
    background: #1c3045;
    box-shadow: 0 0, 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  }
  :hover:focus {
    box-shadow: 0 0 0 2px #99c2ff, 0 3px 4px rgba(0, 15, 45, 0.2);
  }
  :active {
    background: #3a4a5c;
  }
`;

interface Props {
  sessionData: any;
  error?: string | null;
  setShowModal: React.Dispatch<React.SetStateAction<ModalType>>;
  setPayload: React.Dispatch<React.SetStateAction<string>>;
  updateDetails: () => Promise<void>;
  locale: string;
  onInvoicePayment: () => void;
}
const AdyenDropIn: React.FC<Props> = ({
  sessionData,
  setShowModal,
  setPayload,
  updateDetails,
  error,
  locale,
  onInvoicePayment,
}) => {
  const config = useConfig();
  const user = useSelector((state) => state.user);
  const { hasAuthToken } = useAuth();
  const isSignedIn = hasAuthToken();
  const logger = useLogger('adyen-dropin');
  const divRef = useRef<HTMLDivElement | null>(null);
  const api = useBridgeApi();
  const { i18n } = useI18n();
  const [key, setKey] = useState(Math.random().toString());
  const shoppingCart = useShoppingCart();

  const initiateDropin = async (config: any) => {
    const checkout = await AdyenCheckout(config);
    if (divRef.current) {
      checkout
        .create('dropin', {
          instantPaymentTypes: ['applepay'],
        })
        .mount(divRef.current);
    } else if (document.querySelector('#adyen-dropin')) {
      checkout
        .create('dropin', {
          instantPaymentTypes: ['applepay'],
        })
        .mount('#adyen-dropin');
    }
  };

  useEffect(() => {
    if (!sessionData) {
      return;
    }
    const cardConfiguration = {
      enableStoreDetails: Boolean(isSignedIn),
      name: i18n('Cart.DropIn.Card'),
    };
    const configuration = {
      environment: config.IS_PRODUCTION ? 'live' : 'test',
      clientKey: config.CUSTOM_CLIENT_ADYEN_TOKEN ?? config.ADYEN_CLIENT_TOKEN,
      session: sessionData,
      locale: i18n('Cart.DropIn.Language'),
      paymentMethodsConfiguration: {
        card: cardConfiguration,
      },
      onAdditionalDetails: (state: any, dropin: any) => {
        setPayload(JSON.stringify(state.data));
        setShowModal(ModalType.PaymentComplete);
        // If we have a result from 3dSecure, track it.
        if (state?.data?.details?.threeDSResult) {
          const rawDetailResponse = state.data.details.threeDSResult;

          let detailResponse = null;
          // Try parsing the base64 encoded rawDetailResponse.
          try {
            detailResponse =
              typeof state.data.details.threeDSResult === 'string'
                ? JSON.parse(atob(state?.data?.details?.threeDSResult))
                : null;
          } catch (e: any) {
            logger.warn('error when parsing additionalDetails response', {
              error: e,
              rawDetailResponse: rawDetailResponse,
            });
          }
          // Switch case that logs the various results of the 3DSecure response to Datadog & Google Analytics
          threeDsSwitch(detailResponse?.transStatus, logger);
        }
      },
      onPaymentCompleted: (result: any, dropin: any) => {
        // If payment is completed, but errored (ie. if Swish fails), we will get a resultCode with "Error".
        // We want to avoid logging that the payment was successful and trigger GA events. Hence the "if"-block.
        if (result && result.resultCode === 'Error') {
          // Log the error
          logger.info('Payment error', {
            result,
          });

          // Trigger GA event that a payment is finished.
          ReactGA.event({
            category: 'Payment',
            action: 'payment_error',
          });

          setKey(Math.random().toString());
          resultCodeSwitch(result.resultCode, setShowModal, api);
          return;
        }

        logger.info('Payment succesfully completed', {
          fullyPaid: shoppingCart.isFullyPaid,
          orderId: shoppingCart.orderId,
          userId: shoppingCart.userId ?? 'anonymous',
        });

        // Trigger GA event that a payment is finished.
        ReactGA.event({
          category: 'Payment',
          action: 'payment_finished',
        });

        // Refresh the key so the adyen dropin is reloaded
        setKey(Math.random().toString());
        resultCodeSwitch(result.resultCode, setShowModal, api);
      },
      onError: (error: any, component: any) => {
        // Check if the error has a message, if not then just submit the whole error to datadog
        const errorInfo = error.message ?? error;
        console.log('error', error);
        logger.warn('Dropin error', { errorInfo, component });

        // Trigger GA event that a payment is finished.
        ReactGA.event({
          category: 'Payment',
          action: 'payment_dropin_error',
        });
        // resultCodeSwitch(error, setShowModal, api);
      },
    };

    // Create an instance of AdyenCheckout using the configuration object.
    initiateDropin(configuration);
  }, [sessionData, key]);

  return (
    <Wrapper>
      {error && <Error>{error}</Error>}
      <AdyenWrapper>
        {user?.invoiceTargetId && (
          <InvoicePayment>
            <InvoiceButton onClick={onInvoicePayment}>{i18n('Cart.DropIn.Invoice')}</InvoiceButton>
          </InvoicePayment>
        )}
        <Div key={key} ref={divRef} id="adyen-dropin"></Div>
      </AdyenWrapper>
    </Wrapper>
  );
};

export default AdyenDropIn;
