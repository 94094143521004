import styled from 'styled-components';
import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import useI18n from '../../i18n';
import Select from './Select';
import { useShoppingCartApi } from '../ShoppingCartUniverse';

const Wrapper = styled.div`
  padding: 10px 24px;
  border-bottom: 1px solid #e0e0e3;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  position: relative;
  background-color: #fff;
`;

const Title = styled.h4`
  font-size: 16px;
  letter-spacing: 0.24px;
  line-height: 20px;
`;

const Text = styled.p`
  font-size: 14px;
  color: #9e9ea1;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 82%;
  overflow: hidden;
`;

const Icon = styled.div`
  position: absolute;
  right: 24px;
  top: 15px;
  height: 24px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 0 -10px;
`;

const SelectWrapper = styled.div`
  width: 50%;
  padding: 10px 10px 0 10px;
`;

const correctTime = (date: string) => {
  let current = dayjs(date).set('seconds', 0);
  const currentMinutes = current.get('minutes');

  if (currentMinutes > 0 && currentMinutes < 15) {
    current = current.set('minute', 0);
  } else if (currentMinutes > 15 && currentMinutes < 30) {
    current = current.set('minute', 15);
  } else if (currentMinutes > 30 && currentMinutes < 45) {
    current = current.set('minute', 30);
  } else if (currentMinutes > 45 && currentMinutes <= 59) {
    current = current.set('minute', 45);
  }

  return current;
};

type TimeRange = {
  from: string;
  to: string;
};

interface Props {
  /**
   * title
   */
  title: string;
  /**
   * text
   */
  text: string;
  /**
   * icon
   */
  icon: React.SVGProps<SVGSVGElement>;
  /**
   * time
   */
  time: string;
  /**
   * onSubmit
   */
  onSubmit: (dateTime: string) => void;
}

/**
 * DateTmePicker component
 */

const DateTimePickerDesktop = ({ time, title, text, icon, onSubmit }: Props) => {
  const minutesInterval = 15;
  const maxDay = 30;
  const averageMinutes = Math.floor(minutesInterval / 2);
  const { i18n } = useI18n();
  const TODAY = i18n('Cart.Pickuptime.Today');
  const ASAP = 'ASAP';
  const minDate = dayjs().second(0).millisecond(0);
  const maxDate = dayjs(minDate).add(maxDay, 'day');
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | TimeRange | null>();

  const months: { [key: string]: string } = {
    '1': i18n('Date.Jan'),
    '2': i18n('Date.Feb'),
    '3': i18n('Date.Mars'),
    '4': i18n('Date.Apr'),
    '5': i18n('Date.May'),
    '6': i18n('Date.June'),
    '7': i18n('Date.July'),
    '8': i18n('Date.Aug'),
    '9': i18n('Date.Sep'),
    '10': i18n('Date.Oct'),
    '11': i18n('Date.Nov'),
    '12': i18n('Date.Dec'),
  };

  const days = [
    i18n('Date.Sun'),
    i18n('Date.Mon'),
    i18n('Date.Tue'),
    i18n('Date.Wed'),
    i18n('Date.Thu'),
    i18n('Date.Fri'),
    i18n('Date.Sat'),
  ];

  useEffect(() => {
    initialTime();
  }, [time]);

  const initialTime = () => {
    let newTime, newDate;

    if (time === ASAP) {
      newDate = minDate.format();
      newTime = ASAP;
    } else {
      const correctedTime = dayjs(time).isBefore(minDate)
        ? minDate.format()
        : correctTime(time).format();

      newDate = correctedTime;
      newTime = {
        from: correctedTime,
        to: dayjs(correctedTime).add(minutesInterval, 'minutes').format(),
      };
    }

    setSelectedDate(newDate);
    setSelectedTime(newTime);
  };

  const formatDate = (dateStr: string) => {
    const dateObj = dayjs(dateStr);
    const date = dateObj.get('date');
    const day = dateObj.get('day');
    const month = dateObj.get('month') + 1;

    return `${days[day]}, ${date} ${months[month]}`;
  };

  const onSelectDate = (val: string) => {
    const isNextDay = minDate.isBefore(dayjs(val), 'day');
    let newTime;

    if (isNextDay) {
      newTime = {
        from: dayjs(val).set('hours', 0).set('minutes', 0).format(),
        to: dayjs(val).set('hours', 0).set('minutes', 0).add(minutesInterval, 'minute').format(),
      };
    } else {
      newTime = ASAP;
    }

    setSelectedDate(val);
    setSelectedTime(newTime);
    handleSubmit(newTime);
  };

  const onSelectTime = (val: string | TimeRange) => {
    if (val === ASAP) {
      setSelectedTime(ASAP);
    } else if (typeof val === 'object') {
      setSelectedTime({ from: val.from, to: val.to });
    }

    handleSubmit(val);
  };

  const handleSubmit = (val: string | TimeRange) => {
    if (val === ASAP) {
      onSubmit(ASAP);
    } else if (typeof val === 'object') {
      const result = dayjs(val.from).add(averageMinutes, 'minute').format();
      onSubmit(result);
    }
  };

  const days_arr = useMemo(() => {
    const dates = [];
    let current = dayjs(minDate);

    dates.push({
      label: TODAY,
      value: current.format(),
    });

    while (current.isBefore(maxDate)) {
      current = current.add(1, 'day');
      dates.push({
        label: formatDate(current.format()),
        value: current.format(),
      });
    }

    return dates;
  }, [minDate, maxDate]);

  const times_arr = useMemo(() => {
    if (!selectedDate) {
      return [];
    }

    const times = [];
    const isNextDay = minDate.isBefore(dayjs(selectedDate), 'day');
    const startTime = isNextDay ? dayjs(selectedDate).set('hours', 0).set('minutes', 0) : minDate;
    const closeTime = dayjs(selectedDate).set('hours', 0).set('minutes', 0).add(1, 'day');
    let current = correctTime(startTime.format());

    // ASAP
    if (!isNextDay) {
      times.push({
        label: ASAP,
        value: ASAP,
      });

      current = current.add(minutesInterval, 'minutes');
    }

    while (current.isBefore(closeTime)) {
      times.push({
        label: `${current.format('HH:mm')} - ${current
          .add(minutesInterval, 'minutes')
          .format('HH:mm')}`,
        value: {
          from: current.format(),
          to: current.add(minutesInterval, 'minutes').format(),
        },
      });

      current = current.add(minutesInterval, 'minutes');
    }

    return times;
  }, [selectedDate, minDate]);

  const formattedDate = useMemo(() => {
    if (!selectedDate) {
      return '';
    }

    if (dayjs(selectedDate).isSame(minDate, 'day')) {
      return TODAY;
    }

    return formatDate(dayjs(selectedDate).format());
  }, [selectedDate]);

  const formattedTime = useMemo(() => {
    if (!selectedTime) {
      return '';
    }

    if (selectedTime === ASAP) {
      return ASAP;
    }

    if (typeof selectedTime === 'object') {
      return `${dayjs(selectedTime.from).format('HH:mm')}-${dayjs(selectedTime.to).format(
        'HH:mm',
      )}`;
    }

    return '';
  }, [selectedTime]);

  const findSelectedDateItem = (value: string) => {
    if (!selectedDate) {
      return false;
    }

    return dayjs(value).isSame(dayjs(selectedDate), 'day');
  };

  const findSelectedTimeItem = (value: TimeRange | string) => {
    if (!selectedTime) {
      return false;
    }

    // ASAP
    if (selectedTime === value) {
      return true;
    }

    if (typeof value === 'object' && typeof selectedTime === 'object') {
      return dayjs(value.from).isSame(selectedTime.from);
    }

    return false;
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
      <Icon>{icon}</Icon>
      <Content>
        <SelectWrapper>
          <Select
            items={days_arr}
            onChange={onSelectDate}
            formattedValue={formattedDate}
            findSelectedItem={findSelectedDateItem}
          />
        </SelectWrapper>
        <SelectWrapper>
          <Select
            items={times_arr}
            onChange={onSelectTime}
            formattedValue={formattedTime}
            findSelectedItem={findSelectedTimeItem}
          />
        </SelectWrapper>
      </Content>
    </Wrapper>
  );
};

export default DateTimePickerDesktop;
