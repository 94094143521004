import React from 'react';
import styled from 'styled-components';

const Spinner = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  position: relative;
  margin: 0 auto;
`;

const Bounce = styled.div<{ color: string; delay?: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: loader-bounce 2s infinite ease-in-out;
  animation-delay: ${({ delay }) => (delay ? '-1.0s' : '0s')};
`;

interface Props {
  size?: number;
  color?: string;
}

const Loader: React.FC<Props> = ({ size = 40, color = '#fff' }) => {
  return (
    <Spinner size={size}>
      <Bounce color={color} />
      <Bounce color={color} delay />
    </Spinner>
  );
};

export default Loader;
