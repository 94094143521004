import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: 0.5s visibility, 0.5s opacity;
  display: flex;
  justify-content: center;
`;

const Fade = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
`;

const Wrapper = styled.div``;

const Inner = styled.div<{ visible: boolean }>`
  position: relative;
  transform: ${({ visible }) =>
    visible
      ? window.hasSmallViewport
        ? 'translateY(60px)'
        : 'translateY(174px)'
      : 'translateY(-100%)'};
  transition: transform 0.4s ease-in-out;
  margin: 0 24px;
  width: 327px;
  z-index: 1;
`;

type Props = {
  /**
   * children
   */
  children: React.ReactNode;
  /**
   * visible
   */
  visible: boolean;
  /**
   * onClose
   */
  onClose: () => void;
  /**
   * dontCloseOnClickAnywhere
   */
  dontCloseOnClickAnywhere?: boolean;
};

/**
 * Dialog index component
 */

const Dialog = ({ children, visible, onClose, dontCloseOnClickAnywhere }: Props) => {
  return (
    <Container visible={visible} onClick={!dontCloseOnClickAnywhere ? onClose : undefined}>
      <Wrapper>
        <Inner visible={visible}>{children}</Inner>
      </Wrapper>
      <Fade visible={visible} onClick={dontCloseOnClickAnywhere ? onClose : undefined} />
    </Container>
  );
};

export default Dialog;
