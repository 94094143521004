import { useRef, useState } from 'react';

export type ClearCartAlertVisible = boolean;
export type OnShowClearCartAlert = (
  confirmCallback?: () => void,
  cancelCallback?: () => void,
) => void;
export type OnCancelClearCartAlert = () => void;
export type OnConfirmClearCartAlert = () => void;

type CallBack = (() => void) | undefined;

type Result = [
  ClearCartAlertVisible,
  OnShowClearCartAlert,
  OnCancelClearCartAlert,
  OnConfirmClearCartAlert,
];

const useClearCartAlert = (): Result => {
  const [clearCartAlertVisible, setClearCartAlertVisible] = useState(false);
  const confirmCallbackRef = useRef<CallBack>();
  const cancelCallbackRef = useRef<CallBack>();

  const onShowClearCartAlert = (confirmCallback?: CallBack, cancelCallback?: CallBack) => {
    setClearCartAlertVisible(true);
    confirmCallbackRef.current = confirmCallback;
    cancelCallbackRef.current = cancelCallback;
  };

  const onCancelClearCartAlert = () => {
    setClearCartAlertVisible(false);

    if (cancelCallbackRef.current) {
      cancelCallbackRef.current();
    }
  };

  const onConfirmClearCartAlert = () => {
    setClearCartAlertVisible(false);

    if (confirmCallbackRef.current) {
      confirmCallbackRef.current();
    }
  };

  return [
    clearCartAlertVisible,
    onShowClearCartAlert,
    onCancelClearCartAlert,
    onConfirmClearCartAlert,
  ];
};

export default useClearCartAlert;
