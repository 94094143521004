import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as actions from '../../actions';
import { useBridgeApi, useDispatch, useSelector } from '../../Hooks';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Button from '../Button';
import SidebarContent from './Components/SidebarContent';
import { ReactComponent as CardIcon } from '../../Assets/Icons/card_membership.svg';
import { ReactComponent as InfoIcon } from '../../Assets/Icons/info.svg';
import { ReactComponent as ReceiptIcon } from '../../Assets/Icons/receipt.svg';
import { ReactComponent as RedeemIcon } from '../../Assets/Icons/redeem.svg';
import { ReactComponent as RestaurantIcon } from '../../Assets/Icons/restaurant.svg';
import { ReactComponent as StoreIcon } from '../../Assets/Icons/storefront.svg';
import useI18n from 'i18n';
import { lockBody } from '../../utils/lockBody';
import MenuItem from './Components/MenuItem';
import { useConfig } from 'Components/ConfigProvider';
import { useShoppingCart, useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import useClearCartAlert from '../../Hooks/useClearCartAlert';
import ClearCartAlert from '../../Scenes/Cart/Components/ClearCartAlert';
import { useStorage } from '../Storage';
import { setLoginScreen } from '../../actions';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 9999;
`;

const Backdrop = styled.div<{ menuIsVisible: boolean }>`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: ${(props) => (props.menuIsVisible ? '0.5' : '0')};
  pointer-events: ${(props) => (props.menuIsVisible ? 'auto' : 'none')};
  transition: opacity 0.2s ease-in-out;

  @media (min-width: 768px) {
    display: none;
  }
`;

const Content = styled.div<{ menuIsVisible: boolean; sidebarWidth: number }>`
  width: ${({ sidebarWidth }) => sidebarWidth}px;
  background-color: #fff;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: auto;
  transform: ${(props) => (props.menuIsVisible ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.2s ease-in-out;

  @media (min-width: 768px) {
    box-shadow: 0 0 20px rgb(0 0 0 / 14%);
    transform: none;
  }
`;

const Header = styled.div`
  background-color: ${({ theme }) => theme.main};
  padding: 40px 24px 24px 32px;
  min-height: 218px;
`;

const LargeText = styled.p<{ longText: boolean }>`
  margin-top: 40px;
  font-size: ${(longText) => (longText ? `24` : `32`)}px;
  line-height: 141%;
  font-weight: 600;
  color: ${({ theme }) => theme.contrastingColor};
`;

const Text = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.contrastingColor};
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 24px;

  button {
    flex: 1;
  }

  button:first-child {
    margin-right: 8px;
  }
`;

const Member = styled.div`
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.contrastingColor};
  line-height: 16px;

  strong {
    display: block;
    color: ${({ theme }) => theme.contrastingColor};
  }
`;

const Menu = styled.div`
  padding: 24px;
`;

const Version = styled.div`
  margin-top: 12px;
  font-size: 12px;
  font-weight: normal;
  height: 24px;
  letter-spacing: 0.4px;
  line-height: 24px;
  color: #9e9ea1;
`;

interface Props {
  /**
   * isSignedIn
   */
  isSignedIn: boolean;
}

/**
 * DrawerNavigation component
 */

const MainNav: React.FC<Props> = ({ isSignedIn }) => {
  const config = useConfig();

  const { i18n } = useI18n();
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const dispatch = useDispatch();
  const api = useBridgeApi();
  const shoppingCart = useShoppingCart();
  const shoppingCartApi = useShoppingCartApi();
  const [, updateStorage] = useStorage();

  const {
    mainMenu: menuIsVisible,
    user,
    showNewsMenuItem,
    companyHasMembershipCards,
    loginScreen,
  } = useSelector((state) => state);
  const index = pathname.search(/order/);
  const [, venueId] = pathname.slice(index).split('/');
  const longText = i18n(config.GREETING).length > 24;

  const tableId = useSelector((state) => state.tableId);

  const name = user ? user.name : '...';
  const membershipLevel = user ? user?.membershipCard?.membershipLevel?.title : '';
  const contentRef = useRef<HTMLDivElement>(null);

  const [
    clearCartAlertVisible,
    onShowClearCartAlert,
    onCancelClearCartAlert,
    onConfirmClearCartAlert,
  ] = useClearCartAlert();

  const scrollToTop = () => {
    if (contentRef?.current?.scrollTo) {
      contentRef.current.scrollTo({ top: 0 });
    }
  };

  useEffect(() => {
    if (menuIsVisible) {
      scrollToTop();
      lockBody(true);
    }
  }, [menuIsVisible]);

  const onClearCart = () => {
    api.vibrate('impactLight');
    shoppingCartApi.clearPromoCode();
    shoppingCartApi.clearCart();
    dispatch(actions.setCartId(null));
    updateStorage({ cartId: null });
  };

  const closeMenu = () => {
    if (window.innerWidth < 768) {
      dispatch(actions.toggleMainMenu(false));
      lockBody(false);
    }
  };

  const onReplace = (path: string) => {
    onClearCart();

    replace(path);
    closeMenu();
  };

  const onClickLink = (e: React.MouseEvent<HTMLElement>, path: string) => {
    if (shoppingCart.items?.length > 0) {
      e.preventDefault();
      onShowClearCartAlert(() => onReplace(path), closeMenu);
    } else {
      closeMenu();
    }

    if (loginScreen) {
      dispatch(setLoginScreen(''));
    }
  };

  return (
    <Wrapper>
      <Backdrop menuIsVisible={menuIsVisible} onClick={closeMenu} />
      <Content menuIsVisible={menuIsVisible} sidebarWidth={window.sidebarWidth} ref={contentRef}>
        <Header>
          {isSignedIn ? (
            <>
              {membershipLevel && (
                <Member>
                  {i18n('Navigation.MembershipLevel')}:<strong>{i18n(membershipLevel)}</strong>
                </Member>
              )}
              <LargeText longText={longText}>{i18n(config.GREETING)},</LargeText>
              <Text>{name}!</Text>
            </>
          ) : (
            <>
              <Text>{/*i18n('Navigation.Welome')*/}</Text>
              <Text>{i18n(config.NAVIGATION_INFOTEXT)}</Text>
              <Buttons>
                <Link
                  to={'#'}
                  onClick={() => {
                    closeMenu();
                    dispatch(setLoginScreen('signup'));
                  }}>
                  <Button text={i18n('Navigation.CreateAccount')} small />
                </Link>
                <Link
                  to={'#'}
                  onClick={() => {
                    closeMenu();
                    dispatch(setLoginScreen('signin'));
                  }}>
                  <Button text={i18n('Navigation.Signin')} small />
                </Link>
              </Buttons>
            </>
          )}
        </Header>
        <Menu>
          <MenuItem
            path={'/'}
            icon={<StoreIcon />}
            title={'Navigation.Home'}
            onClick={(e) => onClickLink(e, '/')}
          />
          {!tableId && (
            <MenuItem
              path={'/store-listing'}
              icon={<RestaurantIcon />}
              title={'Navigation.OrderFood'}
              onClick={(e) => onClickLink(e, '/store-listing')}
            />
          )}
          <MenuItem
            path={'/order-history'}
            icon={<ReceiptIcon />}
            title={'Navigation.OrderHistory'}
            disabled={!isSignedIn}
            onClick={(e) => onClickLink(e, '/order-history')}
          />

          {companyHasMembershipCards && (
            <MenuItem
              path={'/membership'}
              icon={<CardIcon />}
              title={'Navigation.MembershipCard'}
              onClick={(e) => onClickLink(e, '/membership')}
            />
          )}
          <MenuItem
            path={'/news'}
            icon={<InfoIcon />}
            title={'Navigation.News'}
            disabled={!showNewsMenuItem}
            onClick={(e) => onClickLink(e, '/news')}
          />
          {/* <MenuItem
            path={"/activate-code"}
            icon={<RedeemIcon />}
            title={'Navigation.ActivateCode'}
            activeItem={isSignedIn}
            onClick={(e) => onClick(e, '/activate-code')}
          /> */}
          {config.USE_INVOICE_CUSTOMERS && !user?.invoiceCustomerId && (
            <Link to="/invoice-signup" onClick={(e) => onClickLink(e, '/invoice-signup')}>
              <Button text={i18n('Navigation.InvoiceCustomer')} small />
            </Link>
          )}
          <SidebarContent
            isSignedIn={isSignedIn}
            onCloseMenu={closeMenu}
            onClick={onClickLink}
            onShowClearCartAlert={onShowClearCartAlert}
          />
          {/* <Version>Version 2.6.0 (Build 133)</Version> */}
        </Menu>
      </Content>
      <ClearCartAlert
        visible={clearCartAlertVisible}
        onConfirm={onConfirmClearCartAlert}
        onCancel={onCancelClearCartAlert}
      />
    </Wrapper>
  );
};

export default MainNav;
