import React from 'react';
import styled from 'styled-components';
import useI18n from 'i18n';
import { ReactComponent as CloseIcon } from 'Assets/Icons/close.svg';
import Icon from '../Icon';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1001;
  padding: 48px 24px;
`;

const Title = styled.h2`
  color: rgb(227, 39, 59);
  font-size: 32px;
  letter-spacing: 0;
  line-height: 36px;
  margin: 48px 0 16px;
`;

const Description = styled.span`
  color: rgb(227, 39, 59);
  font-weight: 600;
`;

type Props = {
  /**
   * onClose
   */
  onClose: () => void;
  /**
   * title
   */
  title: string;
  /**
   * description
   */
  description: string;
  /**
   * company
   */
  company: string;
};

/**
 * RejectedOrder component
 */

const RejectedOrder = ({ onClose, title, description, company }: Props) => {
  const { i18n } = useI18n();

  return (
    <Wrapper>
      <Icon icon={<CloseIcon />} color={'#e3273b'} size={24} onClick={onClose} />
      <Title>{i18n(title)}</Title>
      <Description>{i18n(description)}</Description>
      <br />
      <br />
      <br />
      <Description>{i18n('Orderstatus.Rejected.BestRegards', company)}</Description>
    </Wrapper>
  );
};

export default RejectedOrder;
