import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useI18n from 'i18n';
import getImageUrlWithSize from 'utils/getImageUrlWithSize';
import { getAllMenuCategories_allCategories } from '../__queries__';
import TouchableOpacity from '../../../Components/OpacityTouchable';
import { useBridgeApi } from '../../../Hooks';
import MenuItem from '../Components/MenuItem';

const Wrapper = styled.div`
  background: #f8f8fb;
  padding: ${() => window.headerHeight + 16}px 16px 16px;
  overflow: auto;
  height: calc(var(--height) - ${window.hasNativeWrapper ? 88 : 80}px);
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 168px;
  gap: 8px;

  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
    gap: 16px;
  }
`;

type Props = {
  categories: getAllMenuCategories_allCategories[];
};

const GridView = ({ categories }: Props) => {
  const params = useParams<{ venue_id?: string }>();
  const venueId = params?.venue_id || '';
  const { i18n } = useI18n();
  const api = useBridgeApi();

  return (
    <Wrapper>
      <Inner>
        {categories.map(({ id, nameLang, image }) => {
          return (
            <TouchableOpacity key={id} onClick={() => api.vibrate('impactLight')}>
              <MenuItem
                id={id}
                venueId={venueId}
                image={getImageUrlWithSize(image?.file?.url, 686, 336)}
                name={i18n(nameLang)}
                isGrid
              />
            </TouchableOpacity>
          );
        })}
      </Inner>
    </Wrapper>
  );
};

export default GridView;
