import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import TextField from '../TextField';
import { lockBody } from '../../utils/lockBody';
import { ReactComponent as CloseIcon } from '../../Assets/Icons/close.svg';
import Icon from '../Icon';
import baemingoLogo from '../../Assets/Images/bae_logo.svg';

const LogoWrapper = styled.div`
  height: 48px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0 16px 0;
`;

const LogoContentText = styled.div`
  font-weight: 300;
  font-size: 8px;
  line-height: 24px;
`;
const BaemingoLogo = styled.img`
  height: 40%;
  padding-left: 8px;
`;
const Wrapper = styled.div<{ hidden?: boolean; closeModal: boolean | null }>`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  z-index: 999;
  transition: all 0.2s;
  animation: ${({ closeModal }) =>
    closeModal ? 'fade-out 0.2s forwards' : 'fade-in 0.2s forwards'};
`;

const Backdrop = styled.div`
  background-color: #000;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0.5;
`;

const Content = styled.div<{
  large: boolean;
  closeModal: boolean | null;
  desktopMaxWidth?: number;
}>`
  background-color: #fff;
  z-index: 11;
  position: absolute;
  top: 60px;
  left: 15px;
  right: 15px;
  width: calc(100% - 30px);
  box-shadow: 0 1px 32px 0 rgb(0 0 0 / 48%);
  border-radius: 16px;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  animation: ${({ closeModal }) =>
    closeModal ? 'fade-in 0.2s forwards' : 'fade-in 0.2s backwards'};

  @media (min-width: 768px) {
    max-width: ${({ desktopMaxWidth }) => (desktopMaxWidth ? `${desktopMaxWidth}px` : '450px')};
    left: 50%;
    top: 32%;
    transform: translate(-50%, -32%);
  }

  button {
    margin: 0;
    border-radius: 0;
  }
`;

const Close = styled.div`
  position: absolute;
  left: 16px;
  top: 34px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Header = styled.div`
  padding: 32px 64px 23px;
  border-bottom: 1px solid #e0e0e3;
  text-align: center;
`;

const Title = styled.h4`
  font-size: 16px;
  letter-spacing: 0.24px;
  line-height: 24px;
`;

const Inner = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  overflow-y: scroll;
`;

interface Props {
  /**
   * title
   */
  title: string;
  /**
   * hidden
   */
  hidden?: boolean;
  /**
   * disableClose
   */
  disableClose?: boolean;
  /**
   * onClose
   */
  onClose: () => void;
  /**
   * onSubmit
   */
  onSubmit?: (val: string) => void;
  /**
   * buttonText
   */
  buttonText?: string;
  /**
   * placeholder
   */
  placeholder?: string;
  /**
   * value
   */
  value?: string;
  /**
   * large
   */
  large?: boolean;
  /**
   * textarea
   */
  textarea?: boolean;
  /**
   * baeLogo
   */
  baeLogo?: boolean;
  /**
   * desktopMaxWidth
   */
  desktopMaxWidth?: number;
  /**
   * disableButtonTransform
   */
  disableButtonTransform?: boolean;

  /**
   * error
   */
  error?: string;
}

/**
 * Modal component
 */

const Modal: React.FC<Props> = ({
  title,
  buttonText,
  disableClose,
  onClose,
  hidden,
  onSubmit,
  placeholder,
  value,
  large,
  textarea,
  children,
  baeLogo,
  desktopMaxWidth,
  disableButtonTransform,
  error,
}) => {
  const [text, setText] = useState(value ?? '');
  const [closeModal, setCloseModal] = useState<boolean | null>(null);
  const [inputUnFocused, setInputUnFocused] = useState(false);

  useEffect(() => {
    lockBody(true);

    return () => {
      lockBody(false);
    };
  }, []);

  const handleClose = () => {
    if (disableClose) {
      return;
    }
    setCloseModal(true);
    setTimeout(() => {
      onClose();
      lockBody(false);
    }, 200);
  };

  const handleSubmit = () => {
    lockBody(false);
    if (onSubmit) {
      onSubmit(text);
    }
  };

  return (
    <Wrapper hidden={hidden} closeModal={closeModal}>
      <Backdrop onClick={handleClose} />
      <Content large={Boolean(large)} closeModal={closeModal} desktopMaxWidth={desktopMaxWidth}>
        <Header>
          {!disableClose && (
            <Close onClick={handleClose}>
              <Icon icon={<CloseIcon />} color={'#212124'} size={24} />
            </Close>
          )}
          <Title>{title}</Title>
        </Header>

        {placeholder && (
          <>
            <Inner>
              <TextField
                placeholder={placeholder}
                value={text}
                onChange={(e) => setText(e.target.value)}
                marginBottom="0px"
                type={textarea ? 'textarea' : 'text'}
                autoFocus
                unfocus={inputUnFocused}
                error={error}
              />
            </Inner>
            {handleSubmit && buttonText && (
              <Button
                text={buttonText}
                handleClick={handleSubmit}
                disableTransform={disableButtonTransform}
              />
            )}
          </>
        )}

        {children}

        {baeLogo && (
          <LogoWrapper>
            <LogoContentText>Powered by</LogoContentText>
            <BaemingoLogo src={baemingoLogo} />
          </LogoWrapper>
        )}
      </Content>
    </Wrapper>
  );
};

export default Modal;
