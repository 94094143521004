import React from 'react';
import styled from 'styled-components';
import { useConfig } from 'Components/ConfigProvider';
import MemberCard from '../../Components/MemberCard';
import useI18n from '../../i18n';
import { formatPoints } from '../../utils/numbersFormat';

const Wrapper = styled.div`
  margin-bottom: 88px;
  padding-top: ${() => window.headerHeight}px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

const Content = styled.div`
  @media (min-width: 1200px) {
    padding: 0 24px;
  }
`;

const Inner = styled.div`
  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const PointsWrapper = styled.div`
  background-color: #f8f8fb;
  padding: 28px 24px 20px;
  font-size: 14px;
  font-weight: 600;

  @media (min-width: 1200px) {
    padding: 28px 24px 20px;
  }
`;

const PointsInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const Points = styled.h3`
  font-size: 72px;
  line-height: 72px;
  font-weight: 600;
`;

const InfoWrapper = styled.div`
  background-color: #eeeef1;
  padding: 16px 24px;

  @media (min-width: 1200px) {
    padding: 16px 24px;
  }
`;

const Info = styled.p`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  font-size: 14px;

  @media (min-width: 992px) {
    max-width: 960px;
    margin: 0 auto;
  }
`;

const Title = styled.h2`
  font-size: 32px;
  margin: 32px 24px 24px;
  letter-spacing: 0;
  line-height: 36px;

  @media (min-width: 1200px) {
    margin: 32px 0 24px;
  }
`;

const Preamble = styled.p`
  font-weight: 600;
  margin: 24px 24px 40px;

  @media (min-width: 1200px) {
    margin: 24px 0 40px;
  }
`;

const Levels = styled.div`
  @media (min-width: 1200px) {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
`;

const LevelWrapper = styled.div`
  margin: 40px 16px;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    margin: 0 0 20px 0;
  }
`;

const LevelTitle = styled.h4`
  margin: 0 8px 16px;

  @media (min-width: 1200px) {
    margin: 0 0 16px;
  }
`;

const LevelText = styled.p`
  margin: 16px 8px 0;

  @media (min-width: 1200px) {
    margin: 16px 0 0;
  }
`;

const LevelPoints = styled.p`
  margin: 0 8px;

  @media (min-width: 1200px) {
    margin: 0;
  }
`;

const CardWrapper = styled.div`
  @media (min-width: 576px) {
    width: 543px;
    height: 339px;
  }

  @media (min-width: 768px) {
    width: 435px;
    height: 272px;
  }

  @media (min-width: 992px) {
    width: 500px;
    height: 312px;
  }

  @media (min-width: 1200px) {
    width: 100%;
    height: 274px;
  }

  @media (min-width: 1400px) {
    height: 294px;
  }
`;

interface Props {
  isSignedIn: boolean;
  allCards: any[];
  userEasyId: string;
  userPoints: number;
  membershipLevel: string;
}

const MembershipView: React.FC<Props> = ({
  isSignedIn,
  allCards,
  userEasyId,
  userPoints,
  membershipLevel,
}) => {
  const { i18n } = useI18n();
  const config = useConfig();

  const CardList = () => {
    const list = allCards.map((card, index) => {
      const points = formatPoints(card.pointsRequired);
      const nextPoints =
        index + 1 !== allCards.length
          ? formatPoints(allCards[index + 1].pointsRequired - 1)
          : null;

      return (
        <LevelWrapper key={index}>
          <LevelTitle>
            {i18n(card.title)} (
            {i18n('Membership.Level', (index + 1).toString(), allCards.length.toString())})
          </LevelTitle>
          <CardWrapper>
            <MemberCard
              title={i18n(card.title)}
              text={i18n(card.description)}
              background={card.image?.file?.url ?? ''}
              primaryColor={card.primaryColor}
              secondaryColor={card.secondaryColor}
            />
          </CardWrapper>
          <LevelText>
            {i18n('Membership.discount')}: {card.discountPercentage}%
          </LevelText>
          {nextPoints ? (
            <LevelPoints>
              {i18n('Membership.TotalPoints')}: {points} p - {nextPoints} p
            </LevelPoints>
          ) : (
            <LevelPoints>
              {i18n('Membership.TotalPoints')}: {points} p -
            </LevelPoints>
          )}
        </LevelWrapper>
      );
    });

    return <Levels>{list}</Levels>;
  };

  return (
    <Wrapper>
      {isSignedIn && (
        <>
          <PointsWrapper>
            <PointsInner>
              <p>{i18n('Membership.Points')}</p>
              <Points>{formatPoints(userPoints)}</Points>
            </PointsInner>
          </PointsWrapper>
          <InfoWrapper>
            <Info>
              <strong>{i18n('Membership.Level.Title')}</strong>
              {membershipLevel}
            </Info>
            <Info>
              <strong>{i18n('Membership.Number')}</strong>
              {userEasyId}
            </Info>
          </InfoWrapper>
        </>
      )}
      <Content>
        <Inner>
          <Title>{config.DISPLAY_NAME + ' ' + i18n('Membership.Benefits')}</Title>
          <Preamble>{i18n('Membership.Infotext')}</Preamble>
          <CardList />
        </Inner>
      </Content>
    </Wrapper>
  );
};

export default MembershipView;
