import React from 'react';

import ShoppingCart from './shoppingCart';
import SocialTable from './socialTable';

import { useSelector } from 'Hooks';

const Synchronizers: React.FunctionComponent = () => {
  const cartId = useSelector((state) => state.cartId);
  const tableId = useSelector((state) => state.tableId);

  return <>{cartId && <ShoppingCart cartId={cartId} />}</>;
  return <>{tableId && <SocialTable tableId={tableId!} />}</>;
};

export default Synchronizers;
