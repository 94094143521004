import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import useI18n from '../../i18n';
import getLocale from '../../i18n/getLocale';
import getImageUrlWithSize from '../../utils/getImageUrlWithSize';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as NavigateRightIcon } from '../../Assets/Icons/navigate-right.svg';
import Icon from '../Icon';
import { DEVICE_SIZES, getDeviceSize } from '../../utils/deviceSize';

const Wrapper = styled(Link)`
  display: block;
  width: 100%;
  margin: 28px 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  animation: fade-in 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  &:hover {
    @media (min-width: 768px) {
      text-decoration: underline;
      text-decoration-color: #212124;
    }
  }
`;

const Image = styled.img`
  height: 216px;
  object-fit: cover;
  width: 100%;
  margin-bottom: 24px;

  @media (min-width: 576px) {
    height: 290px;
  }

  @media (min-width: 768px) {
    width: 436px;
    height: 290px;
  }
`;

const DateText = styled.span`
  color: #9e9ea1;
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  margin-bottom: 8px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  color: #212124;
  margin-bottom: 16px;
`;

const Description = styled.p`
  color: rgb(97, 97, 100);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.24px;
  line-height: 24px;
`;

const MoreDetails = styled.div`
  display: flex;
  align-items: center;
  color: #212124;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 31px;

  div {
    margin-left: 7px;
  }
`;

interface Props {
  /**
   * id
   */
  id: string;
  /**
   * image
   */
  image: any;
  /**
   * date
   */
  date: Date;
  /**
   * title
   */
  title: string;
  /**
   * text
   */
  text?: string;
}

/**
 * NewsItem component
 */

const NewsItem: React.FC<Props> = ({ id, image, date, title, text }) => {
  const { i18n } = useI18n();
  const locale = getLocale();
  const location = useLocation();
  const deviceSize = getDeviceSize();
  const imageSize =
    deviceSize === DEVICE_SIZES.SMALL
      ? { width: (window.innerWidth - 16 * 2) * 2, height: 216 * 2 }
      : { width: 436 * 2, height: 290 * 2 };

  const formattedDate = dayjs(date).locale(locale).format('DD MMMM YYYY');
  return (
    <Wrapper to={location.pathname.includes('/news') ? `/news/${id}` : `/latestnews/${id}`}>
      <Image src={getImageUrlWithSize(image, imageSize.width, imageSize.height)} />
      <DateText>{formattedDate}</DateText>
      <Title>{title}</Title>
      {text && <Description dangerouslySetInnerHTML={{ __html: text }} />}
      <MoreDetails>
        <span>{i18n('News.Readmore')}</span>
        <Icon icon={<NavigateRightIcon />} color={'#212124'} size={15} />
      </MoreDetails>
    </Wrapper>
  );
};

export default NewsItem;
