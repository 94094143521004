import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ReactComponent as VisibilityIcon } from '../../Assets/Icons/visibility.svg';
import { ReactComponent as VisibilityOffIcon } from '../../Assets/Icons/visibility_off.svg';
import Icon from '../Icon';

const Wrapper = styled.div<{ marginBottom: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.2s ease-in-out;
`;

const Label = styled.span`
  color: rgb(33, 33, 36);
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 8px;
`;

const Input = styled.input<{ error: string }>`
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid ${({ error }) => (error ? '#e3273b' : '#9e9ea1')};
  border-width: ${({ error }) => (error ? 2 : 1)}px;
  padding: 10px 16px;
  color: ${({ error }) => (error ? '#e3273b' : '#212124')};
  line-height: 30px;
  min-height: 24px;
  width: 100%;
  cursor: pointer;
  user-select: text;
  opacity: 0.5;

  ::placeholder {
    color: ${({ error }) => (error ? '#e3273b' : 'inherit')};
  }

  &:focus {
    opacity: 1;
  }
`;

const TextArea = styled.textarea<{ error: string }>`
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid ${({ error }) => (error ? '#e3273b' : '#9e9ea1')};
  padding: 16px;
  min-height: 60px;
  color: ${({ error }) => (error ? '#e3273b' : '#212124')};
  line-height: 20px;
  width: 100%;

  cursor: pointer;
  user-select: text;
  opacity: 0.5;
  &:focus {
    opacity: 1;
  }

  @media (min-width: 768px) {
    max-width: 100%;
    min-width: 100%;
    min-height: 75px;
    max-height: 75px;
  }
`;

const InputWrapper = styled.div<{ marginTop: string }>`
  position: relative;
  margin-top: ${({ marginTop }) => marginTop};
`;

const Error = styled.small`
  color: #e3273b;
  font-size: 12px;
`;

type Props = {
  /**
   * label
   */
  label?: string;
  /**
   * placeholder
   */
  placeholder?: string;
  /**
   * value
   */
  value?: string;
  /**
   * autoFocus
   */
  autoFocus?: boolean;
  /**
   * disabled
   */
  disabled?: boolean;
  /**
   * type
   */
  type?: 'text' | 'password' | 'textarea' | 'tel' | 'number' | 'email';
  /**
   * marginBottom
   */
  marginBottom?: string;
  /**
   * marginTop
   */
  marginTop?: string;
  /**
   * height
   */
  height?: string;
  /**
   * error
   */
  error?: string;
  /**
   * onChange
   */
  onChange?: (event: React.ChangeEvent<any>) => void;
  /**
   * onBlur
   */
  onBlur?: (event: React.ChangeEvent<any>) => void;
  /**
   * changeVisibility
   */
  changeVisibility?: boolean;
  /**
   * name
   */
  name?: string;
  /**
   * unfocus
   */
  unfocus?: boolean;
};

/**
 * TextField component
 */
const TextField = ({
  label,
  value = '',
  type,
  marginTop = '0px',
  marginBottom = '24px',
  error = '',
  onChange = () => null,
  onBlur,
  changeVisibility = true,
  unfocus = false,
  ...inputProps
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleVisible = () => {
    setVisiblePassword((prev) => !prev);
  };

  useEffect(() => {
    if (unfocus && inputRef.current) {
      inputRef.current.blur();
    }
  }, [unfocus]);

  const colorIcon = useMemo(() => {
    let color = error ? '#e3273b80' : '#bdbdc0';
    if (isFocused) {
      color = error ? '#e3273b' : '#212124';
    }
    return color;
  }, [error, isFocused]);

  return (
    <Wrapper marginBottom={marginBottom}>
      {label && <Label>{label}</Label>}
      <InputWrapper marginTop={marginTop}>
        {type == 'textarea' ? (
          <TextArea
            ref={textareaRef}
            onFocus={() => setIsFocused(true)}
            onBlur={(e) => {
              setIsFocused(false);
              onBlur && onBlur(e);
            }}
            value={value}
            onChange={onChange}
            error={error}
            maxLength={150}
            {...inputProps}
          />
        ) : (
          <Input
            ref={inputRef}
            onFocus={() => setIsFocused(true)}
            onBlur={(e) => {
              setIsFocused(false);
              onBlur && onBlur(e);
            }}
            value={value}
            type={visiblePassword ? 'text' : type}
            onChange={onChange}
            error={error}
            {...inputProps}
          />
        )}
        {changeVisibility && type == 'password' && (
          <IconWrapper>
            <Icon
              icon={visiblePassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              color={colorIcon}
              size={24}
              onClick={handleVisible}
            />
          </IconWrapper>
        )}
      </InputWrapper>
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

export default TextField;
