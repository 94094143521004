import React from 'react';
import styled from 'styled-components';
import { useHistory, Link, useLocation } from 'react-router-dom';

import useAuth from 'Hooks/useAuth';
import * as actions from 'actions';
import { useDispatch } from 'Hooks';
import { useQuery } from '@apollo/client';
import { allContents, allContentsVariables } from '../../../Scenes/SidebarContent/__queries__';
import useI18n from '../../../i18n';
import { GET_SIDEBAR_CONTENT } from '../../../Scenes/SidebarContent';
import { useConfig } from 'Components/ConfigProvider';
import { useShoppingCart } from '../../ShoppingCartUniverse';
import { OnShowClearCartAlert } from '../../../Hooks/useClearCartAlert';

const SmallMenu = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  a,
  span {
    font-size: 12px;
    font-weight: normal;
    height: 16px;
    letter-spacing: 0.32px;
    text-decoration: none;
    color: #616164;
    cursor: pointer;
    margin-bottom: 24px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  a {
    &:hover {
      @media (min-width: 768px) {
        text-decoration: underline;
      }
    }
  }
`;

interface Props {
  isSignedIn: boolean;
  onCloseMenu: () => void;
  onClick: (e: React.MouseEvent<HTMLElement>, path: string) => void;
  onShowClearCartAlert: OnShowClearCartAlert;
}

const SidebarContent: React.FC<Props> = ({
  isSignedIn,
  onCloseMenu,
  onClick,
  onShowClearCartAlert,
}) => {
  const config = useConfig();
  const dispatch = useDispatch();
  const { i18n } = useI18n();
  const { logout } = useAuth();
  const { push } = useHistory();
  const shoppingCart = useShoppingCart();

  const { data } = useQuery<allContents, allContentsVariables>(GET_SIDEBAR_CONTENT, {
    variables: {
      companyId: config.COMPANY_ID,
    },
  });

  const content = data?.allContents || [];

  const checkCartBeforeLogOut = () => {
    if (shoppingCart.items?.length > 0) {
      onShowClearCartAlert(handleLogOut, onCloseMenu);
    } else {
      handleLogOut();
    }
  };

  const handleLogOut = () => {
    // Clear the users availableOffers from state
    dispatch(actions.setAvailableOffers([]));
    logout();
    // We are routing the user back to the landing page when the user logs out.
    // Otherwise, if they log out in the startPage, they are given a corrupt cart.
    push('/');
    onCloseMenu();
  };

  return (
    <SmallMenu>
      {content.map((c) => (
        <Link
          key={c.id}
          to={`/sidebar/${c.slug}/`}
          onClick={(e) => onClick(e, `/sidebar/${c.slug}/`)}>
          {i18n(c.title)}
        </Link>
      ))}
      <Link to="/support" onClick={(e) => onClick(e, '/support')}>
        {i18n('Navigation.Support')}
      </Link>
      {isSignedIn && <span onClick={checkCartBeforeLogOut}>{i18n('Navigation.Signout')}</span>}
    </SmallMenu>
  );
};

export default SidebarContent;
