import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import OrderStatus from 'Components/OrderStatus';
import getTexts from './utils/getTexts';
import { getUserOrders_orders } from './__queries__';
import RejectedOrder from './components/Rejected';
import useI18n from 'i18n';
import getLocale from 'i18n/getLocale';
import dayjs from 'dayjs';

const Wrapper = styled.div<{ floating: boolean }>`
  position: relative;

  @media (min-width: 992px) {
    padding: ${({ floating }) => (floating ? 0 : '0 5px 8px 8px')};
  }
`;

const OrderList = styled.div<{ top?: number; floating: boolean }>`
  position: ${({ floating }) => (floating ? 'absolute' : 'relative')};
  top: ${({ top }) => top}px;
  left: 8px;
  right: 8px;
  z-index: 3;

  & > div {
    margin-bottom: 8px;
  }

  @media (min-width: 992px) {
    left: ${({ floating }) => (floating ? '8px' : 0)};
  }
`;

const OrderWrapper = styled(Link)``;

type Colors = {
  [key: string]: string;
};

const colors: Colors = {
  PENDING: '#314fee',
  ACCEPTED: '#ff8400',
  FINALIZING: '#ff8400',
  READY: '#209946',
};

type Props = {
  orders: getUserOrders_orders[];
  rejectedOrders: getUserOrders_orders[];
  onClose: (id: string) => void;
  homeRef?: React.RefObject<HTMLDivElement>;
  floating: boolean;
};

const OrderStatusView = ({ orders, rejectedOrders, onClose, homeRef, floating }: Props) => {
  const params = useParams<{ venue_id?: string }>();
  const venueId = params?.venue_id || '';
  const locale = getLocale();
  const { i18n } = useI18n();
  const [currentTime, setCurrentTime] = useState(dayjs().format());

  useEffect(() => {
    if (!homeRef?.current) return;

    if (rejectedOrders) {
      homeRef.current.classList.add('modal-open');
    } else {
      homeRef.current.classList.remove('modal-open');
    }
  }, [rejectedOrders]);

  // ** UPDATE CURRENT TIME ** Update current time every 10 seconds
  useEffect(() => {
    const intervalId = setInterval(() => setCurrentTime(dayjs().format()), 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Wrapper floating={floating}>
        <OrderList top={venueId ? 0 : 90} floating={floating}>
          {orders.map(({ id, status, easyId, estimatedFinishTime }) => {
            const color = colors[status];
            const { statusText, messageText } = getTexts(
              { status, easyId, estimatedFinishTime, currentTime },
              i18n,
              locale,
            );
            return (
              <OrderWrapper
                key={id}
                to={venueId ? `/order/${venueId}/order/${id}` : `/orderstatus/${id}`}>
                <OrderStatus
                  borderWidth={0}
                  shadow={'0px 1px 32px 0px rgba(0, 0, 0, 0.32)'}
                  background={'#fff'}
                  messageColor={'rgb(97, 97, 100)'}
                  statusColor={color}
                  iconColor={color}
                  top={8}
                  status={statusText}
                  message={messageText}
                />
              </OrderWrapper>
            );
          })}
        </OrderList>
      </Wrapper>

      {rejectedOrders.map(({ id }) => (
        <RejectedOrder key={id} orderId={id} onClose={() => onClose(id)} />
      ))}
    </>
  );
};

export default OrderStatusView;
