import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import * as actions from 'actions';
import { useDispatch, useSelector } from 'Hooks';
import {
  InventorySnapshotQuery,
  InventorySnapshotQueryVariables,
} from './__queries__/InventorySnapshotQuery';

const INVENTORY_SNAPSHOT = gql`
  query InventorySnapshotQuery($venueId: ID!) {
    venue: Venue(id: $venueId) {
      id
      inventory {
        snapshot
      }
    }
  }
`;

const InventoryManager = () => {
  const dispatch = useDispatch();
  const venueId = useSelector((state) => state.venue?.id ?? undefined);

  const { data: venueData } = useQuery<InventorySnapshotQuery, InventorySnapshotQueryVariables>(
    INVENTORY_SNAPSHOT,
    {
      variables: {
        venueId: venueId!,
      },
      fetchPolicy: 'network-only',
      skip: !venueId,
      pollInterval: 30000,
    },
  );

  useEffect(() => {
    if (!venueData?.venue?.inventory?.snapshot) {
      return;
    }

    dispatch(actions.setInventory(venueData.venue.inventory.snapshot));
  }, [venueData]);

  return null;
};

export default InventoryManager;
