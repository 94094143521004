import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, useLogger } from 'Hooks';
import View from './view';
import { Location, getDistance } from '../../utils/location';
import { useBridgeApi } from 'Hooks';

import {
  getVenues,
  getVenuesVariables,
  getVenues_venues_availabilities,
  getVenues_venues_location,
  getVenues_venues_menus,
} from './__queries__';
import * as actions from '../../actions';
import { useStorage } from 'Components/Storage';
import { useShoppingCartApi } from 'Components/ShoppingCartUniverse';
import { useConfig } from 'Components/ConfigProvider';

export const GET_VENUES = gql`
  query getVenues($companyId: ID!) {
    venues: allVenues(filter: { company: { id: $companyId }, active: true, deleted: false }) {
      id
      name
      menus {
        id
        menu {
          id
          menuEntries {
            id
          }
        }
        serviceType
      }
      availabilities {
        id
        dayOfWeek
        timeRange {
          id
          start
          end
        }
      }
      location {
        id
        name
        lat
        long
        city
        zip
      }
    }
  }
`;

export type VenuesWithDistance = {
  __typename: 'Venue';
  id: string;
  name: string;
  menus: getVenues_venues_menus[];
  availabilities: getVenues_venues_availabilities[];
  location: getVenues_venues_location;
  distance: number;
};

export enum ServiceType {
  EAT_HERE = 'EAT_HERE',
  TAKE_AWAY = 'TAKE_AWAY',
  DELIVERY = 'DELIVERY',
}

const StoreListing = () => {
  const config = useConfig();
  const logger = useLogger('store-listing');
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector((state) => state.user?.id);
  const disableApp = useSelector((state) => state.disableApp);
  const shoppingCartApi = useShoppingCartApi();
  const [storageData, updateStorage] = useStorage();
  const [activeVenue, setActiveVenue] = useState<string | null>(null);
  const [deniedLocation, setDeniedLocation] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<Location | null>(
    storageData.userLocation || null,
  );
  const api = useBridgeApi();

  const { data } = useQuery<getVenues, getVenuesVariables>(GET_VENUES, {
    variables: {
      companyId: config.COMPANY_ID,
    },
  });

  useEffect(() => {
    dispatch(actions.setVenue(null));
  }, []);

  const desktopLocationSuccessCallback = (position: GeolocationPosition) => {
    const location: Location = { lat: position.coords.latitude, long: position.coords.longitude };

    setCurrentLocation(location);
    updateStorage({ userLocation: location });
  };

  const desktopLocationErrorCallback = (error: GeolocationPositionError) => {
    // Location disabled by user
    if (error.code === 1) {
      setDeniedLocation(true);
    }
  };

  const handleGetUserLocation = async () => {
    // Mobile
    if (window.hasNativeWrapper) {
      const location: any = await api.getUserLocation();

      if (location == 'DENIED') {
        setDeniedLocation(true);
      } else {
        updateStorage({ userLocation: location });
        setCurrentLocation(location);
        api.vibrate('impactLight');
      }

      return;
    }

    // Desktop
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        desktopLocationSuccessCallback,
        desktopLocationErrorCallback,
      );
    }
  };

  const getVenueDistance = (location: Location) => {
    if (currentLocation) {
      const distance = getDistance(
        {
          lat: currentLocation.lat,
          long: currentLocation.long,
        },
        {
          lat: location.lat,
          long: location.long,
        },
      );

      return Math.round(distance);
    }
    return null;
  };

  const venues = data?.venues || [];

  const venuesWithDistance: VenuesWithDistance[] = venues
    .map((venue) => {
      const distance = getVenueDistance({
        lat: parseFloat(venue.location.lat),
        long: parseFloat(venue.location.long),
      });
      return {
        ...venue,
        distance,
      } as VenuesWithDistance;
    })
    .sort((a, b) => {
      return a.distance - b.distance;
    });

  const getServiceType = (serviceType: string) => {
    switch (serviceType) {
      case 'EAT_HERE':
        return ServiceType.EAT_HERE;
      case 'TAKE_AWAY':
        return ServiceType.TAKE_AWAY;
      default:
        return null;
    }
  };

  const handleSubmit = (venueId: string, serviceType: string) => {
    api.vibrate('impactLight');
    const cartServiceType = getServiceType(serviceType);

    if (cartServiceType) {
      const id = shoppingCartApi.createCart(venueId, cartServiceType, null, false);
      logger.info('Cart created', {
        cartId: id,
        venueId,
        serviceType,
        userId: userId ?? 'anonymous',
      });
    }

    updateStorage({
      serviceType,
      qrOrder: false,
    });

    history.push({ pathname: `/order/${venueId}` });
  };

  return (
    <View
      userLocationAllowed={Boolean(currentLocation)}
      activeVenue={activeVenue}
      setActiveVenue={setActiveVenue}
      venues={venuesWithDistance}
      onGetUserLocation={handleGetUserLocation}
      onSubmit={handleSubmit}
      deniedLocation={deniedLocation}
      disableApp={disableApp}
    />
  );
};

export default StoreListing;
