import React, { useRef } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Item from './Components/item';
import styled from 'styled-components';
import { useConfig } from 'Components/ConfigProvider';

const Wrapper = styled.div`
  @media (min-width: calc(768px + 303px)) {
    max-width: 720px;
    margin: 0 auto;
  }

  @media (min-width: calc(992px + 303px)) {
    max-width: 960px;
  }

  @media (min-width: calc(1200px + 303px)) {
    max-width: 1140px;
  }
`;

export const Items = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto auto;
  gap: 8px 8px;
  grid-template-areas: 'big-image big-image';

  @media (min-width: calc(768px + 303px)) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

export const SOCIAL_QUERY = gql`
  query getSocialMedia($companyId: ID!) {
    socialMedia: getSocialFeed(companyId: $companyId) {
      success
      feed
    }
  }
`;

enum SocialMediaType {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
}

export interface ISocialMedia {
  id: string;
  createdAt: Date;
  link: string;
  externalId: string;
  description?: string;
  mediaType: string;
  mediaUrl?: string;
}

interface ISocialAccount {
  feed: ISocialMedia[];
  socialType: SocialMediaType;
  accountName: string | undefined;
}

interface ISocialMediaFeed {
  socialMedia: {
    success: boolean;
    feed: ISocialAccount[];
  };
}

/**
 * SocialMedia component
 */

const SocialMedia = () => {
  const config = useConfig();
  const companyId = config.COMPANY_ID;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { loading, error, data } = useQuery<ISocialMediaFeed>(SOCIAL_QUERY, {
    variables: { companyId },
  });

  if (loading || error || !data || !data.socialMedia) {
    return null;
  }

  return (
    <Wrapper ref={wrapperRef}>
      {data.socialMedia.feed.map((socialMediaItem) => (
        <Items key={socialMediaItem.socialType}>
          {socialMediaItem.socialType === 'INSTAGRAM'
            ? socialMediaItem.feed
                .slice(0, 5)
                .map((socialMediaItemFeed, i) => (
                  <Item key={socialMediaItemFeed.id} item={socialMediaItemFeed} isInstagram />
                ))
            : socialMediaItem.feed.map((socialMediaItemFeed, i) => (
                <Item key={socialMediaItemFeed.id} item={socialMediaItemFeed} />
              ))}
        </Items>
      ))}
    </Wrapper>
  );
};

export default SocialMedia;
