import { gql, useQuery } from '@apollo/client';
import { useConfig } from 'Components/ConfigProvider';
import View from './view';
import { allContents, allContentsVariables } from './__queries__';

export const GET_NEWS = gql`
  query allContents($companyId: ID!) {
    allContents(filter: { types: ["NEWSLETTER"], company: { id: $companyId }, deleted: false }) {
      id
      updatedAt
      image {
        file {
          url
        }
      }
      title {
        id
        en
        se
        no
      }
    }
  }
`;

/**
 * NewsListing scene
 */
const NewsListing = () => {
  const config = useConfig();
  const { data } = useQuery<allContents, allContentsVariables>(GET_NEWS, {
    variables: {
      companyId: config.COMPANY_ID,
    },
  });

  const content = data?.allContents || [];
  const sortedNews = content
    .slice()
    .sort((a, b) => Number(new Date(b.updatedAt)) - Number(new Date(a.updatedAt)));

  return <View news={sortedNews} />;
};

export default NewsListing;
